@import '@angular/material/theming';

@mixin custom-drag-drop-theme($theme, $main-typo) {
  $primary: map-get($theme, primary);

  .draggable-item {
    border-radius: 5px;
    background-color: mat-color($background, background-secondary);
    margin: 5px 0;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    $warn: map-get($theme, warn);
    .cdk-drag-handle {
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
    }
    &.cdk-drag-preview {
      pointer-events: fill !important;
      box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 1);
      .cdk-drag-handle {
        cursor: grabbing;
      }
    }
  }
}
