@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin login-password-theme($theme) {
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  .login-content {
    background-color: mat-color($background, background);
    color: mat-contrast($background, background);
    .link-send {
      color: mat-color($primary, 700);
      &:hover {
        color: mat-contrast($primary, A100);
      }
    }
  }
}
