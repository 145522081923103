@import '@angular/material/theming';
@import 'utils/core';

@mixin globals-theme($theme, $main-typo) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);

  html,
  body {
    @include mat-typography-level-to-styles($main-typo, body-1);
    height: 100%;
  }

  body {
    margin: 0;
  }
  //summernote workarround
  .ngx-summernote-view {
    display: none !important;
  }
  .content-wrapper {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 1120px;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .mat-app-background {
    height: 100%;
    background-color: mat-color($background, background-secondary);
  }

  .uppercase {
    text-transform: uppercase;
  }
  .capitalize {
    text-transform: capitalize;
  }

  .message-block {
    align-items: center;
    background-color: mat-color($primary, statusNeutral);
    border-radius: 0.571rem;
    box-shadow: 0 10px 22px 0 rgba(0, 0, 0, 0.04);
    color: mat-contrast($primary, statusNeutral);
    display: flex;
    font-size: 1.143rem;
    font-weight: 600;
    line-height: 1.5;
    overflow-wrap: anywhere;
    padding: 1.429rem 1.714rem;
    word-wrap: anywhere;

    .mat-icon {
      flex: 0 0 auto;
      margin-right: 1.143rem;
    }

    &.message-block__success {
      background-color: mat-color($primary, 500);
      color: mat-contrast($primary, 400);
    }

    &.message-block__warning {
      background-color: mat-color($primary, statusWarning);
      color: mat-contrast($primary, statusWarning);
    }

    &.message-block__error {
      background-color: mat-color($primary, statusError);
      color: mat-contrast($primary, statusError);
    }
  }

  .hidden,
  *[hidden] {
    display: none !important;
    overflow: hidden !important;

    &.block {
      display: hidden !important;
    }
  }

  .inlineBlock {
    display: inline-block;
  }

  .underline {
    text-decoration: underline;
  }

  .opacity-1 {
    opacity: 1;
  }
  .opacity-08 {
    opacity: 0.8;
  }
  .opacity-06 {
    opacity: 0.6;
  }
  .opacity-04 {
    opacity: 0.4;
  }
  .opacity-02 {
    opacity: 0.2;
  }
  .opacity-0 {
    opacity: 0;
  }

  // Spinner
  .overlay-spinner {
    background: rgba(255, 255, 255, 0.4);
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;

    > .mat-spinner {
      margin: 3rem auto;
    }
  }

  .spin-icon {
    animation: spinner 0.6s linear infinite;
  }

  // Utils
  .height-100 {
    height: 100%;
  }

  .note-modal-backdrop {
    display: none !important;
  }
  .note-editing-area {
    background-color: white;
  }
  .note-editor .note-editing-area .note-editable table.table-no-bordered {
    td,
    th {
      border: 0px;
    }
  }
  .autocomplete-panel-class,
  .notification-mention-menu {
    max-height: calc(var(--100vh) - 65px) !important;
    height: 550px !important;
  }

  .notification-mention-menu {
    .mat-menu-content {
      height: calc(100% - 16px);
    }
  }
}
