@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin media-viewer-dialog-theme($theme) {
  @include respond-to($tablet) {
    ngx-extended-pdf-viewer #mainContainer {
      min-width: unset !important;
      max-width: 100% !important;
    }
    #toolbarViewerLeft {
      pdf-find-button,
      pdf-paging-area {
        display: none !important;
      }
    }
    #toolbarViewerRight {
      display: none !important;
    }

    #toolbarViewerMiddle {
      display: flex !important;
    }
  }
}
