@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin order-list-theme($theme) {
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  .orders {
    background-color: rgba(mat-color($background, background), 0);
    color: mat-contrast($background, background);
    &__wrapper {
      background-color: mat-color($primary, 600);
      color: mat-contrast($primary, 600);
    }
    &__see-all-btn {
      .see-all-orders-btn {
        background-color: mat-color($primary, 600);
        color: mat-contrast($primary, 600);
        border: 1px solid lighten(mat-color($primary, 700), 50%);
        &:hover {
          background-color: lighten(mat-color($primary, 700), 10%);
        }
      }
    }
    &__banners {
      background-color: mat-color($background, background-secondary);
      color: mat-contrast($primary, A100);
      .see-more-banners-btn {
        background-color: lighten(mat-color($primary, 50), 10%);
        color: mat-contrast($primary, 50);
        border: 1px solid rgba(mat-contrast($primary, 50), 50%);
        &:hover {
          background-color: mat-color($primary, 50);
        }
      }
    }
  }
  .footer {
    background-color: lighten(mat-color($background, footer), 10%);
    color: mat-contrast($background, footer);
    &__facility {
      &__item {
        &__head {
          font-weight: bold;
        }
        mat-divider {
          background-color: mat-contrast($background, footer);
        }
      }
    }
  }
}
