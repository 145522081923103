@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin order-detail-documents-theme($theme) {
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  .card-docs {
    &__section {
      &__content {
        &__budget,
        &__remote-sign,
        &__payment {
          background-color: mat-color($primary, statusSuccess);
          color: mat-color($background, background);
          font-size: bold;
          mat-icon {
            background-color: lighten(mat-color($primary, statusSuccess), 10%);
            &.alert-icon {
              color: yellow;
              background-color: transparent;
              margin-left: -7px;
            }
          }
          &.pending {
            background-color: mat-color($primary, 500);
            color: mat-color($background, background);
            mat-icon {
              background-color: lighten(mat-color($primary, 500), 10%);
              &.alert-icon {
                background-color: transparent;
                margin-left: -7px;
              }
            }
          }
          &.payments {
            background-color: mat-color($primary, A400);
            color: mat-contrast($primary, A400);
            mat-icon {
              background-color: lighten(mat-color($primary, A400), 10%);
              &.alert-icon {
                background-color: transparent;
                margin-left: -7px;
              }
            }
          }
          &.payments.pendingPayments {
            background-color: mat-color($primary, A200);
            color: mat-contrast($primary, A200);
            mat-icon {
              background-color: lighten(mat-color($primary, A200), 10%);
              &.alert-icon {
                background-color: transparent;
                margin-left: -7px;
              }
            }
          }
        }
      }
    }
  }
}
