@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin budget-viewer-dialog-theme($theme) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  .budget-viewer-wrapper .mat-dialog-container {
    padding: 0px;
  }
  .budget-viewer {
    &__header {
      border-bottom: 1px solid mat-color($foreground, divider);
      h2 {
        color: mat-color($primary, 700);
      }
    }
    &__content {
      &__lines {
        .budget-line {
          border: 1px solid mat-color($foreground, divider);
          &.accepted {
            border: 1px solid lighten(mat-color($primary, statusSuccess), 5%);
            background-color: lighten(mat-color($primary, statusSuccess), 5%);
            color: mat-color($background, background);
            &.authorized {
              border: 1px solid darken(mat-color($primary, statusSuccess), 10%);
              background-color: darken(mat-color($primary, statusSuccess), 10%);
              .mat-checkbox-disabled .mat-checkbox-label {
                color: mat-color($background, background);
              }
            }
          }
          // Lo necesitamos aquí para que se aplique debido a la encapsulación
          mat-checkbox {
            width: 100%;
            padding: 15px;
            .mat-checkbox-layout {
              width: 100%;
              .mat-checkbox-label {
                width: 90%;
                @include respond-to($tablet-small) {
                  width: 80%;
                }
                .checkbox-budget-label {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  .checkbox-budget-label-1 {
                    font-weight: 600;
                  }
                  .checkbox-budget-label-2 {
                    font-weight: 500;
                    margin-right: 30px;
                    @include respond-to($tablet-small) {
                      margin-right: 15px;
                    }
                  }
                }
              }
            }
            &.mat-checkbox-checked {
              .mat-checkbox-background {
                background-color: mat-color($background, background);
                .mat-checkbox-checkmark {
                  fill: mat-color($primary, statusSuccess) !important;
                }
                .mat-checkbox-checkmark-path {
                  stroke: mat-color($primary, statusSuccess) !important;
                }
              }
            }
          }
        }
      }
    }
    &__footer {
      border-top: 1px solid mat-color($foreground, divider);
    }
  }
}
