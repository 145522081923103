@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin banner-card-theme($theme) {
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  .banner {
    background-color: mat-color($background, background);
    color: mat-contrast($background, background);
    &__content {
      &__title {
        color: mat-color($primary, A700);
      }
    }
  }
}
