@import '@angular/material/theming';

@mixin custom-mat-tab-theme($theme, $main-typo) {
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);

  //Used for sections in administration pages
  .mat-tab-page-sections {
    .mat-tab-header {
      border-bottom: 0px;

      .mat-tab-label {
        // color: mat-color($foreground, secondary-text);
        font-size: 1.5rem;
        font-weight: 400;
        padding: 0 15px;
      }
    }

    &.font-size-smaller {
      .mat-tab-header {
        .mat-tab-label {
          font-size: 1.1rem;
          font-weight: 200;
        }
      }
    }
  }

  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    height: 3px;
    background-color: mat-color($primary, 500);
  }

  .mat-tab-label.text-red,
  .mat-tab-label.mat-tab-label-active.text-red {
    color: darken(mat-color($primary, statusError), 20%);
  }
}
