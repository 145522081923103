@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin order-detail-messages-theme($theme) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  .messages {
    background-color: mat-color($background, background);
    &__list {
      .message {
        &__content {
          background-color: mat-color($background, background-system-message);
          color: mat-contrast($background, background-system-message);
          &.budget {
            background-color: mat-color($primary, statusSuccess);
            color: mat-contrast($primary, statusSuccess);
          }
          &.remote-sign {
            background-color: mat-color($primary, 500);
            color: mat-contrast($primary, 500);
          }
          &.payment {
            background-color: mat-color($primary, A200);
            color: mat-contrast($primary, A200);
          }
          .budget-icon {
            background-color: lighten(mat-color($primary, statusSuccess), 10%);
          }
          .remote-sign-icon {
            background-color: lighten(mat-color($primary, 500), 10%);
          }
          .payment-icon {
            background-color: lighten(mat-color($primary, A200), 10%);
          }
        }
        &__date {
          color: lighten(mat-contrast($primary, 100), 10%);
        }
        &.customer {
          .message__content {
            background-color: mat-color($primary, 800);
            color: mat-contrast($primary, 800);
          }
        }
      }
    }
    &__input {
      border-top: 1px solid mat-color($foreground, divider);
      &__wrapper {
        border: 1px solid mat-color($foreground, divider);
        .mat-badge-medium.mat-badge-before .mat-badge-content {
          left: -6px;
        }
        .mat-badge-medium.mat-badge-above .mat-badge-content {
          top: -4px;
        }
        .send-icon {
          color: mat-contrast($primary, 50);
        }
        .attach-icon {
          color: mat-contrast($primary, 50);
        }
      }
    }
  }
}
