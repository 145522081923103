@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin ask-for-code-theme($theme) {
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  .ask-for-code-content {
    background-color: mat-color($background, background);
    color: mat-contrast($background, background);
  }
}
