@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin order-card-theme($theme) {
  $primary: map-get($theme, primary);
  .order {
    background-color: mat-color($background, background);
    color: mat-contrast($background, background);
    .line1 {
      .detail-btn {
        color: mat-color($primary, 600);
      }
    }
    .important-text {
      color: mat-color($primary, 900);
      font-weight: 600;
    }
    .status {
      .status-chip {
        background-color: mat-color($primary, 300);
        color: mat-contrast($primary, 300);
        &.closed {
          background-color: mat-color($primary, statusSuccess);
          color: mat-contrast($primary, statusSuccess);
        }
      }
    }
  }
}
