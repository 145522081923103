@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin login-send-password-theme($theme) {
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  .login-content {
    background-color: mat-color($background, background);
    color: mat-contrast($background, background);
    &__link {
      border: 0.5px solid rgba(mat-contrast($background, background), 30%);
      color: mat-color($primary, 700);
      background-color: mat-contrast($primary, 700);
      &:hover {
        background-color: mat-color($primary, 700);
        color: mat-contrast($primary, 700) !important;
      }
    }
  }
}
