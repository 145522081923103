@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin order-detail-theme($theme) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  .order-detail {
    &__header {
      .title {
        color: mat-color($primary, 600);
      }
      background-color: mat-color($background, background);
      color: mat-contrast($background, background);
      border-top: 1px solid mat-color($foreground, divider);
    }
    &__steps {
      background-color: mat-color($background, background);
      color: mat-contrast($background, background);
      border-top: 1px solid mat-color($foreground, divider);
      mat-stepper {
        background-color: transparent;
        pointer-events: none;
        min-width: 100%;
        mat-step-header {
          padding: 0 !important;
          min-width: 130px !important;
          overflow: visible !important;
          .mat-step-icon.mat-step-icon-state-noicon {
            background-color: mat-color($background, selected-disabled-button);
          }
          .mat-step-icon {
            height: 15px !important;
            width: 15px !important;
            margin-top: 5px;
            .mat-icon {
              font-size: 14px;
              height: 14px;
              width: 14px;
            }
            .currentState {
              color: mat-color($primary, statusSuccess);
              font-size: 20px;
              height: 20px;
              width: 20px;
              margin-top: -27px;
            }
          }
          .mat-step-label {
            padding: 5px 5px 0 5px !important;
            font-size: 0.7rem;
            .disabled {
              color: mat-color($background, selected-disabled-button);
            }
          }
        }
      }
      .mat-stepper-horizontal-line {
        display: none !important;
      }
      .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:after,
      .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:before {
        top: 13px !important;
      }
      .mat-horizontal-stepper-wrapper {
        width: fit-content !important;
        margin: auto !important;
      }
    }
    &__tabs {
      background-color: mat-contrast($primary, 500);
      color: mat-contrast($background, background);
      border-top: 1px solid mat-color($foreground, divider);
      mat-tab-group {
        .mat-tab-body-wrapper {
          height: 100%;
        }
        .newMessagesIcon {
          color: mat-color($warn, 600);
        }
        .mat-tab-label {
          opacity: 1 !important;
        }
      }
      .desktopTabs {
        .item {
          border-right: 1px solid mat-color($foreground, divider);
        }
        .item:first-child {
          background-color: mat-color($background, background-secondary);
        }
      }
    }
  }
}
