@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin navbar-theme($theme) {
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  .navbar {
    background-color: mat-color($background, background) !important;
    color: mat-contrast($background, background) !important;
    mat-divider {
      background-color: mat-contrast($background, background);
    }
  }
  @include respond-to($tablet-small) {
    .navbar {
      background-color: mat-color($primary, 700) !important;
      color: mat-contrast($primary, 700) !important;
      mat-divider {
        background-color: mat-color($background, background);
      }
    }
  }
}
