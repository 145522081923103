@font-face {
  font-family: "concenet";
  src: url("/assets/fonts/concenet.eot?z9bjzz");
  src: url("/assets/fonts/concenet.eot?z9bjzz#iefix") format("embedded-opentype"), url("/assets/fonts/concenet.ttf?z9bjzz") format("truetype"), url("/assets/fonts/concenet.woff?z9bjzz") format("woff"), url("/assets/fonts/concenet.svg?z9bjzz#concenet") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.concenet {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "concenet" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: 24px;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.search:before {
  content: "\e90e";
}

.delete:before {
  content: "\e90c";
}

.edit:before {
  content: "\e90d";
}

.translate:before {
  content: "\e90b";
}

.workflows:before {
  content: "\e905";
}

.templates:before {
  content: "\e906";
}

.organization:before {
  content: "\e907";
}

.forms:before {
  content: "\e908";
}

.users:before {
  content: "\e909";
}

.user-profile:before {
  content: "\e90a";
}

.user:before {
  content: "\e900";
}

.notifications:before {
  content: "\e901";
}

.notifications-on:before {
  content: "\e902";
}

.comments:before {
  content: "\e903";
}

.comments-on:before {
  content: "\e904";
}

.order {
  background-color: white;
  color: #000000;
}
.order .line1 .detail-btn {
  color: #403f9b;
}
.order .important-text {
  color: #202077;
  font-weight: 600;
}
.order .status .status-chip {
  background-color: #7e7ebf;
  color: #ffffff;
}
.order .status .status-chip.closed {
  background-color: #42c778;
  color: #fff;
}

.banner {
  background-color: white;
  color: #000000;
}
.banner__content__title {
  color: #3535ff;
}

.login {
  background-color: white;
  color: #000000;
}
.login__header {
  background-color: #e9e9f4;
  color: #000000;
}

.login-content {
  background-color: white;
  color: #000000;
}
.login-content__link {
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  color: #373791;
  background-color: #ffffff;
}
.login-content__link:hover {
  background-color: #373791;
  color: #ffffff !important;
}

.login-content {
  background-color: white;
  color: #000000;
}
.login-content .link-send {
  color: #373791;
}
.login-content .link-send:hover {
  color: #000000;
}

.login-content {
  background-color: white;
  color: #000000;
}

.landing-container .sidenav-content {
  background-color: white;
  color: #000000;
}
.landing-container .sidenav-content__linkContainer__link.static {
  background-color: #ffffff;
  color: #373791;
}
.landing-container .sidenav-content__footer {
  background-color: rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 768px) {
  .landing-container .landing-content {
    background-color: #f4f4f9;
    color: #4746a3;
  }
}
@media (max-height: 555px) {
  .landing-container .landing-content {
    background-color: #f4f4f9;
    color: #4746a3;
  }
}

.orders {
  background-color: rgba(255, 255, 255, 0);
  color: #000000;
}
.orders__wrapper {
  background-color: #403f9b;
  color: #ffffff;
}
.orders__see-all-btn .see-all-orders-btn {
  background-color: #403f9b;
  color: #ffffff;
  border: 1px solid #d7d7f0;
}
.orders__see-all-btn .see-all-orders-btn:hover {
  background-color: #4545b6;
}
.orders__banners {
  background-color: #f4f4f9;
  color: #000000;
}
.orders__banners .see-more-banners-btn {
  background-color: white;
  color: #000000;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.orders__banners .see-more-banners-btn:hover {
  background-color: #e9e9f4;
}

.footer {
  background-color: #9595a8;
  color: white;
}
.footer__facility__item__head {
  font-weight: bold;
}
.footer__facility__item mat-divider {
  background-color: white;
}

.all-orders {
  background-color: rgba(255, 255, 255, 0);
  color: #000000;
}
.all-orders__wrapper {
  background-color: #f4f4f9;
  color: #000000;
}
.all-orders__wrapper__header {
  background-color: white;
  color: #373791;
}
.all-orders__wrapper__filter .filter-field .mat-form-field-outline {
  background-color: white;
}

.order-detail__header {
  background-color: white;
  color: #000000;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.order-detail__header .title {
  color: #403f9b;
}
.order-detail__steps {
  background-color: white;
  color: #000000;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.order-detail__steps mat-stepper {
  background-color: transparent;
  pointer-events: none;
  min-width: 100%;
}
.order-detail__steps mat-stepper mat-step-header {
  padding: 0 !important;
  min-width: 130px !important;
  overflow: visible !important;
}
.order-detail__steps mat-stepper mat-step-header .mat-step-icon.mat-step-icon-state-noicon {
  background-color: #bdbdbd;
}
.order-detail__steps mat-stepper mat-step-header .mat-step-icon {
  height: 15px !important;
  width: 15px !important;
  margin-top: 5px;
}
.order-detail__steps mat-stepper mat-step-header .mat-step-icon .mat-icon {
  font-size: 14px;
  height: 14px;
  width: 14px;
}
.order-detail__steps mat-stepper mat-step-header .mat-step-icon .currentState {
  color: #42c778;
  font-size: 20px;
  height: 20px;
  width: 20px;
  margin-top: -27px;
}
.order-detail__steps mat-stepper mat-step-header .mat-step-label {
  padding: 5px 5px 0 5px !important;
  font-size: 0.7rem;
}
.order-detail__steps mat-stepper mat-step-header .mat-step-label .disabled {
  color: #bdbdbd;
}
.order-detail__steps .mat-stepper-horizontal-line {
  display: none !important;
}
.order-detail__steps .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:after,
.order-detail__steps .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:before {
  top: 13px !important;
}
.order-detail__steps .mat-horizontal-stepper-wrapper {
  width: fit-content !important;
  margin: auto !important;
}
.order-detail__tabs {
  background-color: #ffffff;
  color: #000000;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.order-detail__tabs mat-tab-group .mat-tab-body-wrapper {
  height: 100%;
}
.order-detail__tabs mat-tab-group .newMessagesIcon {
  color: #e5104c;
}
.order-detail__tabs mat-tab-group .mat-tab-label {
  opacity: 1 !important;
}
.order-detail__tabs .desktopTabs .item {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.order-detail__tabs .desktopTabs .item:first-child {
  background-color: #f4f4f9;
}

.messages {
  background-color: white;
}
.messages__list .message__content {
  background-color: #e4e9f2;
  color: #000000;
}
.messages__list .message__content.budget {
  background-color: #42c778;
  color: #fff;
}
.messages__list .message__content.remote-sign {
  background-color: #4746a3;
  color: #ffffff;
}
.messages__list .message__content.payment {
  background-color: #8282ff;
  color: #ffffff;
}
.messages__list .message__content .budget-icon {
  background-color: #69d394;
}
.messages__list .message__content .remote-sign-icon {
  background-color: #6261bb;
}
.messages__list .message__content .payment-icon {
  background-color: #b5b5ff;
}
.messages__list .message__date {
  color: #1a1a1a;
}
.messages__list .message.customer .message__content {
  background-color: #2f2f88;
  color: #ffffff;
}
.messages__input {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.messages__input__wrapper {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.messages__input__wrapper .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -6px;
}
.messages__input__wrapper .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -4px;
}
.messages__input__wrapper .send-icon {
  color: #000000;
}
.messages__input__wrapper .attach-icon {
  color: #000000;
}

.navbar {
  background-color: white !important;
  color: #000000 !important;
}
.navbar mat-divider {
  background-color: #000000;
}

@media screen and (max-width: 768px) {
  .navbar {
    background-color: #373791 !important;
    color: #ffffff !important;
  }
  .navbar mat-divider {
    background-color: white;
  }
}
.attachments-item-container {
  background-color: #e4e9f2;
}
.attachments-item-container .file-name {
  color: #373791;
}
.attachments-item-container .file-name mat-icon {
  color: #373791;
}

@media screen and (max-width: 992px) {
  ngx-extended-pdf-viewer #mainContainer {
    min-width: unset !important;
    max-width: 100% !important;
  }
  #toolbarViewerLeft pdf-find-button,
#toolbarViewerLeft pdf-paging-area {
    display: none !important;
  }
  #toolbarViewerRight {
    display: none !important;
  }
  #toolbarViewerMiddle {
    display: flex !important;
  }
}
.budget-viewer-wrapper .mat-dialog-container {
  padding: 0px;
}

.budget-viewer__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.budget-viewer__header h2 {
  color: #373791;
}
.budget-viewer__content__lines .budget-line {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.budget-viewer__content__lines .budget-line.accepted {
  border: 1px solid #56cd86;
  background-color: #56cd86;
  color: white;
}
.budget-viewer__content__lines .budget-line.accepted.authorized {
  border: 1px solid #31a560;
  background-color: #31a560;
}
.budget-viewer__content__lines .budget-line.accepted.authorized .mat-checkbox-disabled .mat-checkbox-label {
  color: white;
}
.budget-viewer__content__lines .budget-line mat-checkbox {
  width: 100%;
  padding: 15px;
}
.budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout {
  width: 100%;
}
.budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
  width: 90%;
}
@media screen and (max-width: 768px) {
  .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
    width: 80%;
  }
}
.budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label .checkbox-budget-label-1 {
  font-weight: 600;
}
.budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label .checkbox-budget-label-2 {
  font-weight: 500;
  margin-right: 30px;
}
@media screen and (max-width: 768px) {
  .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label .checkbox-budget-label-2 {
    margin-right: 15px;
  }
}
.budget-viewer__content__lines .budget-line mat-checkbox.mat-checkbox-checked .mat-checkbox-background {
  background-color: white;
}
.budget-viewer__content__lines .budget-line mat-checkbox.mat-checkbox-checked .mat-checkbox-background .mat-checkbox-checkmark {
  fill: #42c778 !important;
}
.budget-viewer__content__lines .budget-line mat-checkbox.mat-checkbox-checked .mat-checkbox-background .mat-checkbox-checkmark-path {
  stroke: #42c778 !important;
}
.budget-viewer__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.payments-viewer-wrapper .mat-dialog-container {
  padding: 0px;
}

.payments-viewer__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.payments-viewer__header h2 {
  color: #373791;
}
.payments-viewer__content__lines .payments-line {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.payments-viewer__content__lines .payments-line.pending {
  border: 1px solid #6969ff;
  background-color: #6969ff;
  color: white;
}
.payments-viewer__content__lines .payments-line.accepted {
  border: 1px solid #2b9155;
  background-color: #2b9155;
  color: white;
}
.payments-viewer__content__lines .payments-line.error {
  border: 1px solid #c10c0c;
  background-color: #c10c0c;
  color: white;
}
.payments-viewer__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.signature-viewer-wrapper .mat-dialog-container {
  padding: 0px;
}

.signature-viewer__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.signature-viewer__header h2 {
  color: #373791;
}
.signature-viewer__content .mat-step-icon.mat-step-icon-state-edit mat-icon {
  border-radius: 20px;
  padding: 5px;
  background-color: #42c778;
}
.signature-viewer__content .mat-step-icon.mat-step-icon-state-number {
  background-color: #2f2f88;
}
.signature-viewer__content mat-stepper {
  background-color: transparent;
  min-width: 100%;
}
.signature-viewer__content mat-stepper mat-step-header {
  padding: 0 !important;
  overflow: visible !important;
}
.signature-viewer__content mat-stepper mat-step-header .mat-step-label {
  padding: 5px 5px 0 5px !important;
  font-size: 0.7rem;
}
.signature-viewer__content mat-stepper mat-step-header .mat-step-label .disabled {
  color: #bdbdbd;
}
.signature-viewer__content .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:after,
.signature-viewer__content .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:before,
.signature-viewer__content .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 13px !important;
}
.signature-viewer__content .mat-horizontal-content-container {
  padding: 5px 0 0 0;
}
.signature-viewer__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.remote-signature-viewer-wrapper .mat-dialog-container {
  padding: 0;
}

.unverified-signature-warning {
  display: none !important;
}

.card-docs__section__content__budget, .card-docs__section__content__remote-sign, .card-docs__section__content__payment {
  background-color: #42c778;
  color: white;
  font-size: bold;
}
.card-docs__section__content__budget mat-icon, .card-docs__section__content__remote-sign mat-icon, .card-docs__section__content__payment mat-icon {
  background-color: #69d394;
}
.card-docs__section__content__budget mat-icon.alert-icon, .card-docs__section__content__remote-sign mat-icon.alert-icon, .card-docs__section__content__payment mat-icon.alert-icon {
  color: yellow;
  background-color: transparent;
  margin-left: -7px;
}
.card-docs__section__content__budget.pending, .card-docs__section__content__remote-sign.pending, .card-docs__section__content__payment.pending {
  background-color: #4746a3;
  color: white;
}
.card-docs__section__content__budget.pending mat-icon, .card-docs__section__content__remote-sign.pending mat-icon, .card-docs__section__content__payment.pending mat-icon {
  background-color: #6261bb;
}
.card-docs__section__content__budget.pending mat-icon.alert-icon, .card-docs__section__content__remote-sign.pending mat-icon.alert-icon, .card-docs__section__content__payment.pending mat-icon.alert-icon {
  background-color: transparent;
  margin-left: -7px;
}
.card-docs__section__content__budget.payments, .card-docs__section__content__remote-sign.payments, .card-docs__section__content__payment.payments {
  background-color: #4f4fff;
  color: #ffffff;
}
.card-docs__section__content__budget.payments mat-icon, .card-docs__section__content__remote-sign.payments mat-icon, .card-docs__section__content__payment.payments mat-icon {
  background-color: #8282ff;
}
.card-docs__section__content__budget.payments mat-icon.alert-icon, .card-docs__section__content__remote-sign.payments mat-icon.alert-icon, .card-docs__section__content__payment.payments mat-icon.alert-icon {
  background-color: transparent;
  margin-left: -7px;
}
.card-docs__section__content__budget.payments.pendingPayments, .card-docs__section__content__remote-sign.payments.pendingPayments, .card-docs__section__content__payment.payments.pendingPayments {
  background-color: #8282ff;
  color: #ffffff;
}
.card-docs__section__content__budget.payments.pendingPayments mat-icon, .card-docs__section__content__remote-sign.payments.pendingPayments mat-icon, .card-docs__section__content__payment.payments.pendingPayments mat-icon {
  background-color: #b5b5ff;
}
.card-docs__section__content__budget.payments.pendingPayments mat-icon.alert-icon, .card-docs__section__content__remote-sign.payments.pendingPayments mat-icon.alert-icon, .card-docs__section__content__payment.payments.pendingPayments mat-icon.alert-icon {
  background-color: transparent;
  margin-left: -7px;
}

.send-code-content {
  background-color: white;
  color: #000000;
}
.send-code-content__link {
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  color: #373791;
  background-color: #ffffff;
}
.send-code-content__link:hover {
  background-color: #373791;
  color: #ffffff !important;
}

.ask-for-code-content {
  background-color: white;
  color: #000000;
}

.global-message--success {
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;
}
.global-message--success .mat-simple-snackbar-action {
  color: #155724 !important;
}

.global-message--warning {
  color: #2c2c2c !important;
  background-color: rgb(206, 134, 0) !important;
  border-color: #be8f00 !important;
}
.global-message--warning .mat-simple-snackbar-action {
  color: #2c2c2c !important;
}

.global-message--error {
  color: rgb(245, 245, 245) !important;
  background-color: rgb(138, 0, 0) !important;
  border-color: rgb(114, 2, 2) !important;
}
.global-message--error .mat-simple-snackbar-action {
  color: rgb(245, 219, 219) !important;
}

html,
body {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
  height: 100%;
}

body {
  margin: 0;
}

.ngx-summernote-view {
  display: none !important;
}

.content-wrapper {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1120px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.mat-app-background {
  height: 100%;
  background-color: #f4f4f9;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.message-block {
  align-items: center;
  background-color: #848484;
  border-radius: 0.571rem;
  box-shadow: 0 10px 22px 0 rgba(0, 0, 0, 0.04);
  color: #fff;
  display: flex;
  font-size: 1.143rem;
  font-weight: 600;
  line-height: 1.5;
  overflow-wrap: anywhere;
  padding: 1.429rem 1.714rem;
  word-wrap: anywhere;
}
.message-block .mat-icon {
  flex: 0 0 auto;
  margin-right: 1.143rem;
}
.message-block.message-block__success {
  background-color: #4746a3;
  color: #ffffff;
}
.message-block.message-block__warning {
  background-color: #ffb025;
  color: #fff;
}
.message-block.message-block__error {
  background-color: #f34040;
  color: #fff;
}

.hidden,
*[hidden] {
  display: none !important;
  overflow: hidden !important;
}
.hidden.block,
*[hidden].block {
  display: hidden !important;
}

.inlineBlock {
  display: inline-block;
}

.underline {
  text-decoration: underline;
}

.opacity-1 {
  opacity: 1;
}

.opacity-08 {
  opacity: 0.8;
}

.opacity-06 {
  opacity: 0.6;
}

.opacity-04 {
  opacity: 0.4;
}

.opacity-02 {
  opacity: 0.2;
}

.opacity-0 {
  opacity: 0;
}

.overlay-spinner {
  background: rgba(255, 255, 255, 0.4);
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.overlay-spinner > .mat-spinner {
  margin: 3rem auto;
}

.spin-icon {
  animation: spinner 0.6s linear infinite;
}

.height-100 {
  height: 100%;
}

.note-modal-backdrop {
  display: none !important;
}

.note-editing-area {
  background-color: white;
}

.note-editor .note-editing-area .note-editable table.table-no-bordered td,
.note-editor .note-editing-area .note-editable table.table-no-bordered th {
  border: 0px;
}

.autocomplete-panel-class,
.notification-mention-menu {
  max-height: calc(var(--100vh) - 65px) !important;
  height: 550px !important;
}

.notification-mention-menu .mat-menu-content {
  height: calc(100% - 16px);
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #4746a3;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  color: #4746a3;
  font-weight: 600;
  text-transform: uppercase;
}
.mat-form-field-appearance-outline input.mat-input-element {
  text-overflow: ellipsis;
}
.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  z-index: 1;
  margin-top: 0.2em;
}
.mat-form-field-appearance-outline.date-picker .mat-form-field-subscript-wrapper {
  margin-top: 2.2em;
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
  color: #2e2d6a;
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-label {
  color: #2e2d6a;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: transparent;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: #4746a3;
}
.mat-form-field-appearance-outline.mat-form-field-disabled input.mat-input-element {
  color: #121212;
}

.smaller-mat-form-fields .mat-form-field-appearance-outline .mat-form-field-infix,
.smaller-mat-form-fields .mat-form-field-appearance-outline .mat-form-field-suffix {
  padding: 0.25em 0 0.75em 0;
  font-size: 1em;
}
.smaller-mat-form-fields .mat-form-field-appearance-outline.date-picker .mat-form-field-infix,
.smaller-mat-form-fields .mat-form-field-appearance-outline.date-picker .mat-form-field-suffix {
  padding: 0.25em 0 0.6em 0;
  margin-top: -0.3em;
}
.smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-infix, .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-suffix {
  padding: 0.2em 0 0.25em 0;
}
.smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-infix .mat-input-element, .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-suffix .mat-input-element {
  position: absolute;
  margin-top: -11px;
}
.smaller-mat-form-fields .mat-form-field-appearance-outline .mat-form-field-infix .mat-select-arrow {
  margin-top: 10px;
}
.smaller-mat-form-fields .mat-form-field-appearance-outline .mat-select-empty + .mat-form-field-label-wrapper .mat-form-field-label {
  margin-top: -0.75em;
}
.smaller-mat-form-fields .mat-form-field-appearance-outline .mat-select + .mat-form-field-label-wrapper .mat-form-field-label {
  margin-top: -0.3em;
}
.mat-select .smaller-mat-form-fields__no-margin.mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding: 0 0;
  margin: 0 0;
}
.smaller-mat-form-fields__no-margin.mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding: 0 0;
  margin: 0 0;
}
.smaller-mat-form-fields .mat-form-field__no-border .mat-form-field-outline-start,
.smaller-mat-form-fields .mat-form-field__no-border .mat-form-field-outline-gap,
.smaller-mat-form-fields .mat-form-field__no-border .mat-form-field-outline-end {
  border: none;
}
.smaller-mat-form-fields .mat-form-field__border-more-radius .mat-form-field-outline-start, .smaller-mat-form-fields.mat-form-field__border-more-radius .mat-form-field-outline-start {
  border-radius: 28px 0 0 28px !important;
  min-width: 28px;
}
.smaller-mat-form-fields .mat-form-field__border-more-radius .mat-form-field-outline-end, .smaller-mat-form-fields.mat-form-field__border-more-radius .mat-form-field-outline-end {
  border-radius: 0 28px 28px 0 !important;
}

button.mat-button, button.mat-flat-button {
  font-size: 1rem;
}
button.mat-button.mat-button-fill, button.mat-flat-button.mat-button-fill {
  width: 100%;
}
button.mat-button-w-100-left {
  width: 100%;
  text-align: left;
}
button.mat-flat-button:hover {
  opacity: 0.85;
}
button.close-modal-button.mat-icon-button {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  background-color: rgba(181, 181, 255, 0.3);
  color: #202077;
}
button.close-modal-button.mat-icon-button:hover {
  background-color: rgba(181, 181, 255, 0.4);
}
button.mat-square-icon {
  border-radius: 5px;
  padding: 0 0;
  min-width: 36px;
  line-height: 36px;
}
button.mat-square-icon.square-28 {
  min-width: 28px;
  line-height: 28px;
}
button.mat-dark {
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
}
button.mat-dark:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
button .mat-icon {
  overflow: visible;
}

.mat-flat-button.mat-primary.mat-button-disabled,
.mat-flat-button.mat-button-disabled.mat-button-disabled,
.mat-raised-button.mat-primary.mat-button-disabled,
.mat-raised-button.mat-button-disabled.mat-button-disabled,
.mat-fab.mat-primary.mat-button-disabled,
.mat-fab.mat-button-disabled.mat-button-disabled,
.mat-mini-fab.mat-primary.mat-button-disabled,
.mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: #4746a3;
  color: #ffffff;
  opacity: 0.5;
}

.mat-button.v-align-bottom .mat-button-wrapper > *,
.mat-flat-button.v-align-bottom .mat-button-wrapper > *,
.mat-stroked-button.v-align-bottom .mat-button-wrapper > *,
.mat-raised-button.v-align-bottom .mat-button-wrapper > *,
.mat-icon-button.v-align-bottom .mat-button-wrapper > *,
.mat-fab.v-align-bottom .mat-button-wrapper > *,
.mat-mini-fab.v-align-bottom .mat-button-wrapper > * {
  vertical-align: text-bottom;
}

.mat-button.no-padding,
.mat-flat-button.no-padding,
.mat-stroked-button.no-padding,
.mat-raised-button.no-padding,
.mat-icon-button.no-padding,
.mat-fab.no-padding,
.mat-mini-fab.no-padding {
  padding: 0px;
}

mat-expansion-panel.no-padding .mat-expansion-panel-header {
  padding: 0 !important;
}
mat-expansion-panel.no-padding .mat-expansion-indicator {
  padding: 0 10px;
}

.mat-expansion-panel-header {
  border-radius: initial;
  border-bottom: 1px solid #c8c8e3;
}
.mat-expansion-panel-header.mat-expanded {
  height: 49px;
}
.mat-expansion-panel-header .mat-expansion-indicator {
  border-width: 0 3px 3px 0;
  color: #000000;
}
.mat-expansion-panel-header .mat-expansion-panel-header-title {
  color: #4746a3;
  font-size: 1rem;
  font-weight: 600;
}

.header-color-primary .mat-expansion-panel-header {
  background-color: #4746a3 !important;
}
.header-color-primary .mat-expansion-panel-header:hover {
  background-color: #403f9b !important;
}
.header-color-primary .mat-expansion-indicator::after {
  color: #ffffff;
}
.header-color-primary .mat-expansion-panel-header-title {
  color: #ffffff;
}

.mat-expanded > .mat-expansion-panel-content {
  padding-top: 16px;
}

mat-expansion-panel.no-padding .mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0 0 16px 0;
}

.mat-select-panel.bigger-selection-panel-class {
  max-height: calc(var(--100vh) - 100px);
}
.mat-select-panel.bigger-selection-panel-class .mat-optgroup-label {
  overflow: visible !important;
}

.mat-tab-page-sections .mat-tab-header {
  border-bottom: 0px;
}
.mat-tab-page-sections .mat-tab-header .mat-tab-label {
  font-size: 1.5rem;
  font-weight: 400;
  padding: 0 15px;
}
.mat-tab-page-sections.font-size-smaller .mat-tab-header .mat-tab-label {
  font-size: 1.1rem;
  font-weight: 200;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  height: 3px;
  background-color: #4746a3;
}

.mat-tab-label.text-red,
.mat-tab-label.mat-tab-label-active.text-red {
  color: #c10c0c;
}

.mat-item-card {
  margin-bottom: 20px;
  max-width: calc(50% - 20px);
}
.mat-item-card__title {
  font-size: 1rem;
  color: #26273b;
}
.mat-item-card__icons .mat-icon {
  padding-right: 24px;
  color: #4746a3;
}
.mat-item-card__icons .mat-icon:last-child {
  padding-right: 0;
}

.mat-table {
  background-color: transparent;
}
.mat-table.table-ellipsis {
  width: 100%;
  table-layout: fixed;
}
.mat-table.table-ellipsis th,
.mat-table.table-ellipsis td {
  overflow: hidden;
  width: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 10px;
}
.mat-table.table-ellipsis th.no-ellipsis,
.mat-table.table-ellipsis td.no-ellipsis {
  overflow: inherit;
}
.mat-table .mat-header-row .mat-header-cell {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  color: #000000;
}
.mat-table tr.mat-row {
  background-color: white;
  height: 65px;
}
.mat-table tr.mat-row .mat-cell {
  font-size: 16px;
  color: #26273b;
}
.mat-table tr.mat-row .mat-cell.action-buttons {
  color: #4746a3;
  font-size: 24px;
}

.mat-paginator {
  background-color: transparent;
}

.mat-checkbox-layout {
  white-space: normal !important;
}

mat-checkbox.light-border .mat-checkbox-frame {
  border-color: #ffffff;
}

.mat-tooltip {
  font-size: 0.9em;
  max-width: 350px !important;
}

.mat-menu-panel.dark-theme {
  min-width: 150px;
  background-color: #000000;
}
.mat-menu-panel.dark-theme .mat-menu-item {
  color: #ffffff;
}
.mat-menu-panel.dark-theme .mat-divider {
  background-color: #000000;
}

.draggable-item {
  border-radius: 5px;
  background-color: #f4f4f9;
  margin: 5px 0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.draggable-item .cdk-drag-handle {
  cursor: grab;
}
.draggable-item .cdk-drag-handle:active {
  cursor: grabbing;
}
.draggable-item.cdk-drag-preview {
  pointer-events: fill !important;
  box-shadow: 0px 0px 25px 0px rgb(0, 0, 0);
}
.draggable-item.cdk-drag-preview .cdk-drag-handle {
  cursor: grabbing;
}

.remove-padding mat-dialog-container {
  padding: 0px;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Saira;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 900 24px / 32px Saira;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 600 20px / 28px Saira;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Saira;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 600 14px / 1em Saira;
  letter-spacing: 3px;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(16px * 0.83) / 20px Saira;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(16px * 0.67) / 20px Saira;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 400 18px / 26px Saira;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Saira;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 400 112px / 112px Saira;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 900 56px / 56px Saira;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 900 40px / 1.2 Saira;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 600 32px / 1.13 Saira;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Saira;
  font-size: 14px;
  font-weight: 600;
}

.mat-button-toggle {
  font-family: Saira;
}

.mat-card {
  font-family: Saira;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 600;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 16px;
}

.mat-checkbox {
  font-family: Saira;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 26px;
}

.mat-chip {
  font-size: 18px;
  font-weight: 400;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Saira;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 400;
}

.mat-cell, .mat-footer-cell {
  font-size: 16px;
}

.mat-calendar {
  font-family: Saira;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 600;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 600 20px / 28px Saira;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Saira;
  font-size: 14px;
  font-weight: 600;
}

.mat-expansion-panel-content {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Saira;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 16px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Saira;
  font-size: 16px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Saira;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Saira;
}

.mat-select {
  font-family: Saira;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Saira;
}

.mat-slider-thumb-label-text {
  font-family: Saira;
  font-size: 12px;
  font-weight: 400;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Saira;
}

.mat-step-label {
  font-size: 16px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 18px;
}

.mat-step-label-selected {
  font-size: 18px;
  font-weight: 400;
}

.mat-tab-group {
  font-family: Saira;
}

.mat-tab-label, .mat-tab-link {
  font-family: Saira;
  font-size: 14px;
  font-weight: 600;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 600 20px / 28px Saira;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Saira;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Saira;
}

.mat-list-option {
  font-family: Saira;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.mat-list-base .mat-subheader {
  font-family: Saira;
  font-size: 18px;
  font-weight: 400;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Saira;
  font-size: 12px;
  font-weight: 400;
}

.mat-option {
  font-family: Saira;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 400 18px / 26px Saira;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Saira;
  font-size: 16px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 600;
}

.mat-tree {
  font-family: Saira;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 16px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(18, 18, 18, 0.1);
}

.mat-option {
  color: #121212;
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: #121212;
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #4746a3;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #9fa8da;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e81253;
}

.mat-optgroup-label {
  color: #26273b;
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: #26273b;
}
.mat-pseudo-checkbox::after {
  color: white;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #4746a3;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #9fa8da;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e81253;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: white;
  color: #121212;
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(18, 18, 18, 0.2), 0px 1px 1px 0px rgba(18, 18, 18, 0.14), 0px 1px 3px 0px rgba(18, 18, 18, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(18, 18, 18, 0.2), 0px 3px 4px 0px rgba(18, 18, 18, 0.14), 0px 1px 8px 0px rgba(18, 18, 18, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 5px 8px 0px rgba(18, 18, 18, 0.14), 0px 1px 14px 0px rgba(18, 18, 18, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 6px 10px 0px rgba(18, 18, 18, 0.14), 0px 1px 18px 0px rgba(18, 18, 18, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(18, 18, 18, 0.2), 0px 7px 10px 1px rgba(18, 18, 18, 0.14), 0px 2px 16px 1px rgba(18, 18, 18, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(18, 18, 18, 0.2), 0px 8px 10px 1px rgba(18, 18, 18, 0.14), 0px 3px 14px 2px rgba(18, 18, 18, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(18, 18, 18, 0.2), 0px 9px 12px 1px rgba(18, 18, 18, 0.14), 0px 3px 16px 2px rgba(18, 18, 18, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(18, 18, 18, 0.2), 0px 10px 14px 1px rgba(18, 18, 18, 0.14), 0px 4px 18px 3px rgba(18, 18, 18, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(18, 18, 18, 0.2), 0px 11px 15px 1px rgba(18, 18, 18, 0.14), 0px 4px 20px 3px rgba(18, 18, 18, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(18, 18, 18, 0.2), 0px 12px 17px 2px rgba(18, 18, 18, 0.14), 0px 5px 22px 4px rgba(18, 18, 18, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(18, 18, 18, 0.2), 0px 13px 19px 2px rgba(18, 18, 18, 0.14), 0px 5px 24px 4px rgba(18, 18, 18, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(18, 18, 18, 0.2), 0px 14px 21px 2px rgba(18, 18, 18, 0.14), 0px 5px 26px 4px rgba(18, 18, 18, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(18, 18, 18, 0.2), 0px 15px 22px 2px rgba(18, 18, 18, 0.14), 0px 6px 28px 5px rgba(18, 18, 18, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(18, 18, 18, 0.2), 0px 16px 24px 2px rgba(18, 18, 18, 0.14), 0px 6px 30px 5px rgba(18, 18, 18, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(18, 18, 18, 0.2), 0px 17px 26px 2px rgba(18, 18, 18, 0.14), 0px 6px 32px 5px rgba(18, 18, 18, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(18, 18, 18, 0.2), 0px 18px 28px 2px rgba(18, 18, 18, 0.14), 0px 7px 34px 6px rgba(18, 18, 18, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(18, 18, 18, 0.2), 0px 19px 29px 2px rgba(18, 18, 18, 0.14), 0px 7px 36px 6px rgba(18, 18, 18, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(18, 18, 18, 0.2), 0px 20px 31px 3px rgba(18, 18, 18, 0.14), 0px 8px 38px 7px rgba(18, 18, 18, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(18, 18, 18, 0.2), 0px 21px 33px 3px rgba(18, 18, 18, 0.14), 0px 8px 40px 7px rgba(18, 18, 18, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(18, 18, 18, 0.2), 0px 22px 35px 3px rgba(18, 18, 18, 0.14), 0px 8px 42px 7px rgba(18, 18, 18, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(18, 18, 18, 0.2), 0px 23px 36px 3px rgba(18, 18, 18, 0.14), 0px 9px 44px 8px rgba(18, 18, 18, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(18, 18, 18, 0.2), 0px 24px 38px 3px rgba(18, 18, 18, 0.14), 0px 9px 46px 8px rgba(18, 18, 18, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: #121212;
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: #121212;
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #4746a3;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #e81253;
}

.mat-badge-disabled .mat-badge-content {
  background: #c1c1c1;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(18, 18, 18, 0.2), 0px 16px 24px 2px rgba(18, 18, 18, 0.14), 0px 6px 30px 5px rgba(18, 18, 18, 0.12);
  background: white;
  color: #121212;
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #4746a3;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #9fa8da;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #e81253;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(18, 18, 18, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #4746a3;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #9fa8da;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e81253;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: #121212;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: #121212;
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(18, 18, 18, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #4746a3;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #9fa8da;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #e81253;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: #f7f7f7;
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(18, 18, 18, 0.2), 0px 8px 10px 1px rgba(18, 18, 18, 0.14), 0px 3px 14px 2px rgba(18, 18, 18, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 6px 10px 0px rgba(18, 18, 18, 0.14), 0px 1px 18px 0px rgba(18, 18, 18, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(18, 18, 18, 0.2), 0px 12px 17px 2px rgba(18, 18, 18, 0.14), 0px 5px 22px 4px rgba(18, 18, 18, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: #121212;
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: #26273b;
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #121212;
}

.mat-button-toggle-disabled {
  color: rgba(18, 18, 18, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: #121212;
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(18, 18, 18, 0.2), 0px 1px 1px 0px rgba(18, 18, 18, 0.14), 0px 1px 3px 0px rgba(18, 18, 18, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}

.mat-card-subtitle {
  color: #26273b;
}

.mat-checkbox-frame {
  border-color: #26273b;
}

.mat-checkbox-checkmark {
  fill: white;
}

.mat-checkbox-checkmark-path {
  stroke: white !important;
}

.mat-checkbox-mixedmark {
  background-color: white;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #4746a3;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #9fa8da;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e81253;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: #121212;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #4746a3;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #9fa8da;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e81253;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: #121212;
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: #121212;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(18, 18, 18, 0.2), 0px 3px 4px 0px rgba(18, 18, 18, 0.14), 0px 1px 8px 0px rgba(18, 18, 18, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: #121212;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #4746a3;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e81253;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: #26273b;
}

.mat-cell, .mat-footer-cell {
  color: #121212;
}

.mat-calendar-arrow {
  fill: rgba(18, 18, 18, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(18, 18, 18, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: #26273b;
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: #121212;
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(71, 70, 163, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(71, 70, 163, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(71, 70, 163, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #4746a3;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(71, 70, 163, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(71, 70, 163, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(71, 70, 163, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
  background-color: white;
  color: #121212;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(159, 168, 218, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(159, 168, 218, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(159, 168, 218, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(159, 168, 218, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(159, 168, 218, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(159, 168, 218, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(232, 18, 83, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(232, 18, 83, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(232, 18, 83, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e81253;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(232, 18, 83, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(232, 18, 83, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(232, 18, 83, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(18, 18, 18, 0.2), 0px 24px 38px 3px rgba(18, 18, 18, 0.14), 0px 9px 46px 8px rgba(18, 18, 18, 0.12);
}

.mat-datepicker-toggle-active {
  color: #4746a3;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #9fa8da;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #e81253;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(18, 18, 18, 0.2), 0px 24px 38px 3px rgba(18, 18, 18, 0.14), 0px 9px 46px 8px rgba(18, 18, 18, 0.12);
  background: white;
  color: #121212;
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: #121212;
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: #121212;
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: #26273b;
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(18, 18, 18, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(38, 39, 59, 0.6);
}

.mat-hint {
  color: rgba(38, 39, 59, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #4746a3;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #9fa8da;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e81253;
}

.mat-focused .mat-form-field-required-marker {
  color: #9fa8da;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #4746a3;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #9fa8da;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e81253;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #4746a3;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #9fa8da;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e81253;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e81253;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e81253;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e81253;
}

.mat-error {
  color: #e81253;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: #26273b;
}
.mat-form-field-appearance-legacy .mat-hint {
  color: #26273b;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(18, 18, 18, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(18, 18, 18, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #4746a3;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #9fa8da;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e81253;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e81253;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #4746a3;
}
.mat-icon.mat-accent {
  color: #9fa8da;
}
.mat-icon.mat-warn {
  color: #e81253;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: #26273b;
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #4746a3;
}
.mat-input-element::placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(38, 39, 59, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #9fa8da;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #e81253;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e81253;
}

.mat-list-base .mat-list-item {
  color: #121212;
}
.mat-list-base .mat-list-option {
  color: #121212;
}
.mat-list-base .mat-subheader {
  color: #26273b;
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: #121212;
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(18, 18, 18, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: #26273b;
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(18, 18, 18, 0.54);
  border-right: 2px solid rgba(18, 18, 18, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(18, 18, 18, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #d1d1e8;
}

.mat-progress-bar-buffer {
  background-color: #d1d1e8;
}

.mat-progress-bar-fill::after {
  background-color: #4746a3;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #e7e9f6;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #e7e9f6;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #9fa8da;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9c4d4;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9c4d4;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e81253;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #4746a3;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #9fa8da;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #e81253;
}

.mat-radio-outer-circle {
  border-color: #26273b;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #4746a3;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #4746a3;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #9fa8da;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #9fa8da;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e81253;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e81253;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: #121212;
}

.mat-select-value {
  color: #121212;
}

.mat-select-placeholder {
  color: rgba(38, 39, 59, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: #26273b;
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #4746a3;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #9fa8da;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e81253;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e81253;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: white;
  color: #121212;
}

.mat-drawer {
  background-color: white;
  color: #121212;
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(18, 18, 18, 0.2), 0px 16px 24px 2px rgba(18, 18, 18, 0.14), 0px 6px 30px 5px rgba(18, 18, 18, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #9fa8da;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(159, 168, 218, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #9fa8da;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #4746a3;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(71, 70, 163, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #4746a3;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e81253;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(232, 18, 83, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e81253;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: #121212;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(18, 18, 18, 0.2), 0px 1px 1px 0px rgba(18, 18, 18, 0.14), 0px 1px 3px 0px rgba(18, 18, 18, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(18, 18, 18, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #4746a3;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(71, 70, 163, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #9fa8da;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(159, 168, 218, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e81253;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(232, 18, 83, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(18, 18, 18, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(18, 18, 18, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(18, 18, 18, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(18, 18, 18, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(18, 18, 18, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(18, 18, 18, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(18, 18, 18, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(18, 18, 18, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(18, 18, 18, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(18, 18, 18, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(18, 18, 18, 0.7), rgba(18, 18, 18, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(18, 18, 18, 0.7), rgba(18, 18, 18, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(18, 18, 18, 0.7), rgba(18, 18, 18, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: #26273b;
}
.mat-step-header .mat-step-icon {
  background-color: #26273b;
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #4746a3;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e81253;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e81253;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: #121212;
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #e81253;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #26273b;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: #121212;
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: #121212;
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(200, 200, 227, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #4746a3;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #9fa8da;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 184, 203, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e81253;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(200, 200, 227, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #4746a3;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #9fa8da;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 184, 203, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e81253;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-toolbar {
  background: whitesmoke;
  color: #121212;
}
.mat-toolbar.mat-primary {
  background: #4746a3;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-warn {
  background: #e81253;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: #121212;
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 6px 10px 0px rgba(18, 18, 18, 0.14), 0px 1px 18px 0px rgba(18, 18, 18, 0.12);
}

.mat-simple-snackbar-action {
  color: #9fa8da;
}

.green-theme .order {
  background-color: white;
  color: #000000;
}
.green-theme .order .line1 .detail-btn {
  color: #11675b;
}
.green-theme .order .important-text {
  color: #064035;
  font-weight: 600;
}
.green-theme .order .status .status-chip {
  background-color: #5a9a92;
  color: #ffffff;
}
.green-theme .order .status .status-chip.closed {
  background-color: #42c778;
  color: #fff;
}
.green-theme .banner {
  background-color: white;
  color: #000000;
}
.green-theme .banner__content__title {
  color: #00f6c1;
}
.green-theme .login {
  background-color: white;
  color: #000000;
}
.green-theme .login__header {
  background-color: #e3eeec;
  color: #000000;
}
.green-theme .login-content {
  background-color: white;
  color: #000000;
}
.green-theme .login-content__link {
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  color: #0e5c51;
  background-color: #ffffff;
}
.green-theme .login-content__link:hover {
  background-color: #0e5c51;
  color: #ffffff !important;
}
.green-theme .login-content {
  background-color: white;
  color: #000000;
}
.green-theme .login-content .link-send {
  color: #0e5c51;
}
.green-theme .login-content .link-send:hover {
  color: #000000;
}
.green-theme .login-content {
  background-color: white;
  color: #000000;
}
.green-theme .landing-container .sidenav-content {
  background-color: white;
  color: #000000;
}
.green-theme .landing-container .sidenav-content__linkContainer__link.static {
  background-color: #ffffff;
  color: #0e5c51;
}
.green-theme .landing-container .sidenav-content__footer {
  background-color: rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 768px) {
  .green-theme .landing-container .landing-content {
    background-color: #f4f4f9;
    color: #136f63;
  }
}
@media (max-height: 555px) {
  .green-theme .landing-container .landing-content {
    background-color: #f4f4f9;
    color: #136f63;
  }
}
.green-theme .orders {
  background-color: rgba(255, 255, 255, 0);
  color: #000000;
}
.green-theme .orders__wrapper {
  background-color: #11675b;
  color: #ffffff;
}
.green-theme .orders__see-all-btn .see-all-orders-btn {
  background-color: #11675b;
  color: #ffffff;
  border: 1px solid #7eebdc;
}
.green-theme .orders__see-all-btn .see-all-orders-btn:hover {
  background-color: #158878;
}
.green-theme .orders__banners {
  background-color: #f4f4f9;
  color: #000000;
}
.green-theme .orders__banners .see-more-banners-btn {
  background-color: white;
  color: #000000;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.green-theme .orders__banners .see-more-banners-btn:hover {
  background-color: #e3eeec;
}
.green-theme .footer {
  background-color: #9595a8;
  color: white;
}
.green-theme .footer__facility__item__head {
  font-weight: bold;
}
.green-theme .footer__facility__item mat-divider {
  background-color: white;
}
.green-theme .all-orders {
  background-color: rgba(255, 255, 255, 0);
  color: #000000;
}
.green-theme .all-orders__wrapper {
  background-color: #f4f4f9;
  color: #000000;
}
.green-theme .all-orders__wrapper__header {
  background-color: white;
  color: #0e5c51;
}
.green-theme .all-orders__wrapper__filter .filter-field .mat-form-field-outline {
  background-color: white;
}
.green-theme .order-detail__header {
  background-color: white;
  color: #000000;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.green-theme .order-detail__header .title {
  color: #11675b;
}
.green-theme .order-detail__steps {
  background-color: white;
  color: #000000;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.green-theme .order-detail__steps mat-stepper {
  background-color: transparent;
  pointer-events: none;
  min-width: 100%;
}
.green-theme .order-detail__steps mat-stepper mat-step-header {
  padding: 0 !important;
  min-width: 130px !important;
  overflow: visible !important;
}
.green-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon.mat-step-icon-state-noicon {
  background-color: #bdbdbd;
}
.green-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon {
  height: 15px !important;
  width: 15px !important;
  margin-top: 5px;
}
.green-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon .mat-icon {
  font-size: 14px;
  height: 14px;
  width: 14px;
}
.green-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon .currentState {
  color: #42c778;
  font-size: 20px;
  height: 20px;
  width: 20px;
  margin-top: -27px;
}
.green-theme .order-detail__steps mat-stepper mat-step-header .mat-step-label {
  padding: 5px 5px 0 5px !important;
  font-size: 0.7rem;
}
.green-theme .order-detail__steps mat-stepper mat-step-header .mat-step-label .disabled {
  color: #bdbdbd;
}
.green-theme .order-detail__steps .mat-stepper-horizontal-line {
  display: none !important;
}
.green-theme .order-detail__steps .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:after,
.green-theme .order-detail__steps .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:before {
  top: 13px !important;
}
.green-theme .order-detail__steps .mat-horizontal-stepper-wrapper {
  width: fit-content !important;
  margin: auto !important;
}
.green-theme .order-detail__tabs {
  background-color: #ffffff;
  color: #000000;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.green-theme .order-detail__tabs mat-tab-group .mat-tab-body-wrapper {
  height: 100%;
}
.green-theme .order-detail__tabs mat-tab-group .newMessagesIcon {
  color: #e5104c;
}
.green-theme .order-detail__tabs mat-tab-group .mat-tab-label {
  opacity: 1 !important;
}
.green-theme .order-detail__tabs .desktopTabs .item {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.green-theme .order-detail__tabs .desktopTabs .item:first-child {
  background-color: #f4f4f9;
}
.green-theme .messages {
  background-color: white;
}
.green-theme .messages__list .message__content {
  background-color: #e4e9f2;
  color: #000000;
}
.green-theme .messages__list .message__content.budget {
  background-color: #42c778;
  color: #fff;
}
.green-theme .messages__list .message__content.remote-sign {
  background-color: #136f63;
  color: #ffffff;
}
.green-theme .messages__list .message__content.payment {
  background-color: #43ffd7;
  color: #ffffff;
}
.green-theme .messages__list .message__content .budget-icon {
  background-color: #69d394;
}
.green-theme .messages__list .message__content .remote-sign-icon {
  background-color: #1a9b8a;
}
.green-theme .messages__list .message__content .payment-icon {
  background-color: #76ffe2;
}
.green-theme .messages__list .message__date {
  color: #1a1a1a;
}
.green-theme .messages__list .message.customer .message__content {
  background-color: #0b5247;
  color: #ffffff;
}
.green-theme .messages__input {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.green-theme .messages__input__wrapper {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.green-theme .messages__input__wrapper .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -6px;
}
.green-theme .messages__input__wrapper .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -4px;
}
.green-theme .messages__input__wrapper .send-icon {
  color: #000000;
}
.green-theme .messages__input__wrapper .attach-icon {
  color: #000000;
}
.green-theme .navbar {
  background-color: white !important;
  color: #000000 !important;
}
.green-theme .navbar mat-divider {
  background-color: #000000;
}
@media screen and (max-width: 768px) {
  .green-theme .navbar {
    background-color: #0e5c51 !important;
    color: #ffffff !important;
  }
  .green-theme .navbar mat-divider {
    background-color: white;
  }
}
.green-theme .attachments-item-container {
  background-color: #e4e9f2;
}
.green-theme .attachments-item-container .file-name {
  color: #0e5c51;
}
.green-theme .attachments-item-container .file-name mat-icon {
  color: #0e5c51;
}
@media screen and (max-width: 992px) {
  .green-theme ngx-extended-pdf-viewer #mainContainer {
    min-width: unset !important;
    max-width: 100% !important;
  }
  .green-theme #toolbarViewerLeft pdf-find-button,
.green-theme #toolbarViewerLeft pdf-paging-area {
    display: none !important;
  }
  .green-theme #toolbarViewerRight {
    display: none !important;
  }
  .green-theme #toolbarViewerMiddle {
    display: flex !important;
  }
}
.green-theme .budget-viewer-wrapper .mat-dialog-container {
  padding: 0px;
}
.green-theme .budget-viewer__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.green-theme .budget-viewer__header h2 {
  color: #0e5c51;
}
.green-theme .budget-viewer__content__lines .budget-line {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.green-theme .budget-viewer__content__lines .budget-line.accepted {
  border: 1px solid #56cd86;
  background-color: #56cd86;
  color: white;
}
.green-theme .budget-viewer__content__lines .budget-line.accepted.authorized {
  border: 1px solid #31a560;
  background-color: #31a560;
}
.green-theme .budget-viewer__content__lines .budget-line.accepted.authorized .mat-checkbox-disabled .mat-checkbox-label {
  color: white;
}
.green-theme .budget-viewer__content__lines .budget-line mat-checkbox {
  width: 100%;
  padding: 15px;
}
.green-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout {
  width: 100%;
}
.green-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
  width: 90%;
}
@media screen and (max-width: 768px) {
  .green-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
    width: 80%;
  }
}
.green-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.green-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label .checkbox-budget-label-1 {
  font-weight: 600;
}
.green-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label .checkbox-budget-label-2 {
  font-weight: 500;
  margin-right: 30px;
}
@media screen and (max-width: 768px) {
  .green-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label .checkbox-budget-label-2 {
    margin-right: 15px;
  }
}
.green-theme .budget-viewer__content__lines .budget-line mat-checkbox.mat-checkbox-checked .mat-checkbox-background {
  background-color: white;
}
.green-theme .budget-viewer__content__lines .budget-line mat-checkbox.mat-checkbox-checked .mat-checkbox-background .mat-checkbox-checkmark {
  fill: #42c778 !important;
}
.green-theme .budget-viewer__content__lines .budget-line mat-checkbox.mat-checkbox-checked .mat-checkbox-background .mat-checkbox-checkmark-path {
  stroke: #42c778 !important;
}
.green-theme .budget-viewer__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.green-theme .payments-viewer-wrapper .mat-dialog-container {
  padding: 0px;
}
.green-theme .payments-viewer__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.green-theme .payments-viewer__header h2 {
  color: #0e5c51;
}
.green-theme .payments-viewer__content__lines .payments-line {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.green-theme .payments-viewer__content__lines .payments-line.pending {
  border: 1px solid #2affd1;
  background-color: #2affd1;
  color: white;
}
.green-theme .payments-viewer__content__lines .payments-line.accepted {
  border: 1px solid #2b9155;
  background-color: #2b9155;
  color: white;
}
.green-theme .payments-viewer__content__lines .payments-line.error {
  border: 1px solid #c10c0c;
  background-color: #c10c0c;
  color: white;
}
.green-theme .payments-viewer__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.green-theme .signature-viewer-wrapper .mat-dialog-container {
  padding: 0px;
}
.green-theme .signature-viewer__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.green-theme .signature-viewer__header h2 {
  color: #0e5c51;
}
.green-theme .signature-viewer__content .mat-step-icon.mat-step-icon-state-edit mat-icon {
  border-radius: 20px;
  padding: 5px;
  background-color: #42c778;
}
.green-theme .signature-viewer__content .mat-step-icon.mat-step-icon-state-number {
  background-color: #0b5247;
}
.green-theme .signature-viewer__content mat-stepper {
  background-color: transparent;
  min-width: 100%;
}
.green-theme .signature-viewer__content mat-stepper mat-step-header {
  padding: 0 !important;
  overflow: visible !important;
}
.green-theme .signature-viewer__content mat-stepper mat-step-header .mat-step-label {
  padding: 5px 5px 0 5px !important;
  font-size: 0.7rem;
}
.green-theme .signature-viewer__content mat-stepper mat-step-header .mat-step-label .disabled {
  color: #bdbdbd;
}
.green-theme .signature-viewer__content .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:after,
.green-theme .signature-viewer__content .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:before,
.green-theme .signature-viewer__content .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 13px !important;
}
.green-theme .signature-viewer__content .mat-horizontal-content-container {
  padding: 5px 0 0 0;
}
.green-theme .signature-viewer__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.green-theme .remote-signature-viewer-wrapper .mat-dialog-container {
  padding: 0;
}
.green-theme .unverified-signature-warning {
  display: none !important;
}
.green-theme .card-docs__section__content__budget, .green-theme .card-docs__section__content__remote-sign, .green-theme .card-docs__section__content__payment {
  background-color: #42c778;
  color: white;
  font-size: bold;
}
.green-theme .card-docs__section__content__budget mat-icon, .green-theme .card-docs__section__content__remote-sign mat-icon, .green-theme .card-docs__section__content__payment mat-icon {
  background-color: #69d394;
}
.green-theme .card-docs__section__content__budget mat-icon.alert-icon, .green-theme .card-docs__section__content__remote-sign mat-icon.alert-icon, .green-theme .card-docs__section__content__payment mat-icon.alert-icon {
  color: yellow;
  background-color: transparent;
  margin-left: -7px;
}
.green-theme .card-docs__section__content__budget.pending, .green-theme .card-docs__section__content__remote-sign.pending, .green-theme .card-docs__section__content__payment.pending {
  background-color: #136f63;
  color: white;
}
.green-theme .card-docs__section__content__budget.pending mat-icon, .green-theme .card-docs__section__content__remote-sign.pending mat-icon, .green-theme .card-docs__section__content__payment.pending mat-icon {
  background-color: #1a9b8a;
}
.green-theme .card-docs__section__content__budget.pending mat-icon.alert-icon, .green-theme .card-docs__section__content__remote-sign.pending mat-icon.alert-icon, .green-theme .card-docs__section__content__payment.pending mat-icon.alert-icon {
  background-color: transparent;
  margin-left: -7px;
}
.green-theme .card-docs__section__content__budget.payments, .green-theme .card-docs__section__content__remote-sign.payments, .green-theme .card-docs__section__content__payment.payments {
  background-color: #10ffcc;
  color: #ffffff;
}
.green-theme .card-docs__section__content__budget.payments mat-icon, .green-theme .card-docs__section__content__remote-sign.payments mat-icon, .green-theme .card-docs__section__content__payment.payments mat-icon {
  background-color: #43ffd7;
}
.green-theme .card-docs__section__content__budget.payments mat-icon.alert-icon, .green-theme .card-docs__section__content__remote-sign.payments mat-icon.alert-icon, .green-theme .card-docs__section__content__payment.payments mat-icon.alert-icon {
  background-color: transparent;
  margin-left: -7px;
}
.green-theme .card-docs__section__content__budget.payments.pendingPayments, .green-theme .card-docs__section__content__remote-sign.payments.pendingPayments, .green-theme .card-docs__section__content__payment.payments.pendingPayments {
  background-color: #43ffd7;
  color: #ffffff;
}
.green-theme .card-docs__section__content__budget.payments.pendingPayments mat-icon, .green-theme .card-docs__section__content__remote-sign.payments.pendingPayments mat-icon, .green-theme .card-docs__section__content__payment.payments.pendingPayments mat-icon {
  background-color: #76ffe2;
}
.green-theme .card-docs__section__content__budget.payments.pendingPayments mat-icon.alert-icon, .green-theme .card-docs__section__content__remote-sign.payments.pendingPayments mat-icon.alert-icon, .green-theme .card-docs__section__content__payment.payments.pendingPayments mat-icon.alert-icon {
  background-color: transparent;
  margin-left: -7px;
}
.green-theme .send-code-content {
  background-color: white;
  color: #000000;
}
.green-theme .send-code-content__link {
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  color: #0e5c51;
  background-color: #ffffff;
}
.green-theme .send-code-content__link:hover {
  background-color: #0e5c51;
  color: #ffffff !important;
}
.green-theme .ask-for-code-content {
  background-color: white;
  color: #000000;
}
.green-theme .global-message--success {
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;
}
.green-theme .global-message--success .mat-simple-snackbar-action {
  color: #155724 !important;
}
.green-theme .global-message--warning {
  color: #2c2c2c !important;
  background-color: rgb(206, 134, 0) !important;
  border-color: #be8f00 !important;
}
.green-theme .global-message--warning .mat-simple-snackbar-action {
  color: #2c2c2c !important;
}
.green-theme .global-message--error {
  color: rgb(245, 245, 245) !important;
  background-color: rgb(138, 0, 0) !important;
  border-color: rgb(114, 2, 2) !important;
}
.green-theme .global-message--error .mat-simple-snackbar-action {
  color: rgb(245, 219, 219) !important;
}
.green-theme html,
.green-theme body {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
  height: 100%;
}
.green-theme body {
  margin: 0;
}
.green-theme .ngx-summernote-view {
  display: none !important;
}
.green-theme .content-wrapper {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1120px;
}
.green-theme .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.green-theme .mat-app-background {
  height: 100%;
  background-color: #f4f4f9;
}
.green-theme .uppercase {
  text-transform: uppercase;
}
.green-theme .capitalize {
  text-transform: capitalize;
}
.green-theme .message-block {
  align-items: center;
  background-color: #848484;
  border-radius: 0.571rem;
  box-shadow: 0 10px 22px 0 rgba(0, 0, 0, 0.04);
  color: #fff;
  display: flex;
  font-size: 1.143rem;
  font-weight: 600;
  line-height: 1.5;
  overflow-wrap: anywhere;
  padding: 1.429rem 1.714rem;
  word-wrap: anywhere;
}
.green-theme .message-block .mat-icon {
  flex: 0 0 auto;
  margin-right: 1.143rem;
}
.green-theme .message-block.message-block__success {
  background-color: #136f63;
  color: #ffffff;
}
.green-theme .message-block.message-block__warning {
  background-color: #ffb025;
  color: #fff;
}
.green-theme .message-block.message-block__error {
  background-color: #f34040;
  color: #fff;
}
.green-theme .hidden,
.green-theme *[hidden] {
  display: none !important;
  overflow: hidden !important;
}
.green-theme .hidden.block,
.green-theme *[hidden].block {
  display: hidden !important;
}
.green-theme .inlineBlock {
  display: inline-block;
}
.green-theme .underline {
  text-decoration: underline;
}
.green-theme .opacity-1 {
  opacity: 1;
}
.green-theme .opacity-08 {
  opacity: 0.8;
}
.green-theme .opacity-06 {
  opacity: 0.6;
}
.green-theme .opacity-04 {
  opacity: 0.4;
}
.green-theme .opacity-02 {
  opacity: 0.2;
}
.green-theme .opacity-0 {
  opacity: 0;
}
.green-theme .overlay-spinner {
  background: rgba(255, 255, 255, 0.4);
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.green-theme .overlay-spinner > .mat-spinner {
  margin: 3rem auto;
}
.green-theme .spin-icon {
  animation: spinner 0.6s linear infinite;
}
.green-theme .height-100 {
  height: 100%;
}
.green-theme .note-modal-backdrop {
  display: none !important;
}
.green-theme .note-editing-area {
  background-color: white;
}
.green-theme .note-editor .note-editing-area .note-editable table.table-no-bordered td,
.green-theme .note-editor .note-editing-area .note-editable table.table-no-bordered th {
  border: 0px;
}
.green-theme .autocomplete-panel-class,
.green-theme .notification-mention-menu {
  max-height: calc(var(--100vh) - 65px) !important;
  height: 550px !important;
}
.green-theme .notification-mention-menu .mat-menu-content {
  height: calc(100% - 16px);
}
.green-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #136f63;
}
.green-theme .mat-form-field-appearance-outline .mat-form-field-label {
  color: #136f63;
  font-weight: 600;
  text-transform: uppercase;
}
.green-theme .mat-form-field-appearance-outline input.mat-input-element {
  text-overflow: ellipsis;
}
.green-theme .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  z-index: 1;
  margin-top: 0.2em;
}
.green-theme .mat-form-field-appearance-outline.date-picker .mat-form-field-subscript-wrapper {
  margin-top: 2.2em;
}
.green-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
  color: #072925;
}
.green-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-label {
  color: #072925;
}
.green-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: transparent;
}
.green-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: #136f63;
}
.green-theme .mat-form-field-appearance-outline.mat-form-field-disabled input.mat-input-element {
  color: #121212;
}
.green-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-form-field-infix,
.green-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-form-field-suffix {
  padding: 0.25em 0 0.75em 0;
  font-size: 1em;
}
.green-theme .smaller-mat-form-fields .mat-form-field-appearance-outline.date-picker .mat-form-field-infix,
.green-theme .smaller-mat-form-fields .mat-form-field-appearance-outline.date-picker .mat-form-field-suffix {
  padding: 0.25em 0 0.6em 0;
  margin-top: -0.3em;
}
.green-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-infix, .green-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-suffix {
  padding: 0.2em 0 0.25em 0;
}
.green-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-infix .mat-input-element, .green-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-suffix .mat-input-element {
  position: absolute;
  margin-top: -11px;
}
.green-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-form-field-infix .mat-select-arrow {
  margin-top: 10px;
}
.green-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-select-empty + .mat-form-field-label-wrapper .mat-form-field-label {
  margin-top: -0.75em;
}
.green-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-select + .mat-form-field-label-wrapper .mat-form-field-label {
  margin-top: -0.3em;
}
.mat-select .green-theme .smaller-mat-form-fields__no-margin.mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding: 0 0;
  margin: 0 0;
}
.green-theme .smaller-mat-form-fields__no-margin.mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding: 0 0;
  margin: 0 0;
}
.green-theme .smaller-mat-form-fields .mat-form-field__no-border .mat-form-field-outline-start,
.green-theme .smaller-mat-form-fields .mat-form-field__no-border .mat-form-field-outline-gap,
.green-theme .smaller-mat-form-fields .mat-form-field__no-border .mat-form-field-outline-end {
  border: none;
}
.green-theme .smaller-mat-form-fields .mat-form-field__border-more-radius .mat-form-field-outline-start, .green-theme .smaller-mat-form-fields.mat-form-field__border-more-radius .mat-form-field-outline-start {
  border-radius: 28px 0 0 28px !important;
  min-width: 28px;
}
.green-theme .smaller-mat-form-fields .mat-form-field__border-more-radius .mat-form-field-outline-end, .green-theme .smaller-mat-form-fields.mat-form-field__border-more-radius .mat-form-field-outline-end {
  border-radius: 0 28px 28px 0 !important;
}
.green-theme button.mat-button, .green-theme button.mat-flat-button {
  font-size: 1rem;
}
.green-theme button.mat-button.mat-button-fill, .green-theme button.mat-flat-button.mat-button-fill {
  width: 100%;
}
.green-theme button.mat-button-w-100-left {
  width: 100%;
  text-align: left;
}
.green-theme button.mat-flat-button:hover {
  opacity: 0.85;
}
.green-theme button.close-modal-button.mat-icon-button {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  background-color: rgba(118, 255, 226, 0.3);
  color: #064035;
}
.green-theme button.close-modal-button.mat-icon-button:hover {
  background-color: rgba(118, 255, 226, 0.4);
}
.green-theme button.mat-square-icon {
  border-radius: 5px;
  padding: 0 0;
  min-width: 36px;
  line-height: 36px;
}
.green-theme button.mat-square-icon.square-28 {
  min-width: 28px;
  line-height: 28px;
}
.green-theme button.mat-dark {
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
}
.green-theme button.mat-dark:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
.green-theme button .mat-icon {
  overflow: visible;
}
.green-theme .mat-flat-button.mat-primary.mat-button-disabled,
.green-theme .mat-flat-button.mat-button-disabled.mat-button-disabled,
.green-theme .mat-raised-button.mat-primary.mat-button-disabled,
.green-theme .mat-raised-button.mat-button-disabled.mat-button-disabled,
.green-theme .mat-fab.mat-primary.mat-button-disabled,
.green-theme .mat-fab.mat-button-disabled.mat-button-disabled,
.green-theme .mat-mini-fab.mat-primary.mat-button-disabled,
.green-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: #136f63;
  color: #ffffff;
  opacity: 0.5;
}
.green-theme .mat-button.v-align-bottom .mat-button-wrapper > *,
.green-theme .mat-flat-button.v-align-bottom .mat-button-wrapper > *,
.green-theme .mat-stroked-button.v-align-bottom .mat-button-wrapper > *,
.green-theme .mat-raised-button.v-align-bottom .mat-button-wrapper > *,
.green-theme .mat-icon-button.v-align-bottom .mat-button-wrapper > *,
.green-theme .mat-fab.v-align-bottom .mat-button-wrapper > *,
.green-theme .mat-mini-fab.v-align-bottom .mat-button-wrapper > * {
  vertical-align: text-bottom;
}
.green-theme .mat-button.no-padding,
.green-theme .mat-flat-button.no-padding,
.green-theme .mat-stroked-button.no-padding,
.green-theme .mat-raised-button.no-padding,
.green-theme .mat-icon-button.no-padding,
.green-theme .mat-fab.no-padding,
.green-theme .mat-mini-fab.no-padding {
  padding: 0px;
}
.green-theme mat-expansion-panel.no-padding .mat-expansion-panel-header {
  padding: 0 !important;
}
.green-theme mat-expansion-panel.no-padding .mat-expansion-indicator {
  padding: 0 10px;
}
.green-theme .mat-expansion-panel-header {
  border-radius: initial;
  border-bottom: 1px solid #b8d4d0;
}
.green-theme .mat-expansion-panel-header.mat-expanded {
  height: 49px;
}
.green-theme .mat-expansion-panel-header .mat-expansion-indicator {
  border-width: 0 3px 3px 0;
  color: #000000;
}
.green-theme .mat-expansion-panel-header .mat-expansion-panel-header-title {
  color: #136f63;
  font-size: 1rem;
  font-weight: 600;
}
.green-theme .header-color-primary .mat-expansion-panel-header {
  background-color: #136f63 !important;
}
.green-theme .header-color-primary .mat-expansion-panel-header:hover {
  background-color: #11675b !important;
}
.green-theme .header-color-primary .mat-expansion-indicator::after {
  color: #ffffff;
}
.green-theme .header-color-primary .mat-expansion-panel-header-title {
  color: #ffffff;
}
.green-theme .mat-expanded > .mat-expansion-panel-content {
  padding-top: 16px;
}
.green-theme mat-expansion-panel.no-padding .mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0 0 16px 0;
}
.green-theme .mat-select-panel.bigger-selection-panel-class {
  max-height: calc(var(--100vh) - 100px);
}
.green-theme .mat-select-panel.bigger-selection-panel-class .mat-optgroup-label {
  overflow: visible !important;
}
.green-theme .mat-tab-page-sections .mat-tab-header {
  border-bottom: 0px;
}
.green-theme .mat-tab-page-sections .mat-tab-header .mat-tab-label {
  font-size: 1.5rem;
  font-weight: 400;
  padding: 0 15px;
}
.green-theme .mat-tab-page-sections.font-size-smaller .mat-tab-header .mat-tab-label {
  font-size: 1.1rem;
  font-weight: 200;
}
.green-theme .mat-tab-group.mat-primary .mat-ink-bar,
.green-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  height: 3px;
  background-color: #136f63;
}
.green-theme .mat-tab-label.text-red,
.green-theme .mat-tab-label.mat-tab-label-active.text-red {
  color: #c10c0c;
}
.green-theme .mat-item-card {
  margin-bottom: 20px;
  max-width: calc(50% - 20px);
}
.green-theme .mat-item-card__title {
  font-size: 1rem;
  color: #26273b;
}
.green-theme .mat-item-card__icons .mat-icon {
  padding-right: 24px;
  color: #136f63;
}
.green-theme .mat-item-card__icons .mat-icon:last-child {
  padding-right: 0;
}
.green-theme .mat-table {
  background-color: transparent;
}
.green-theme .mat-table.table-ellipsis {
  width: 100%;
  table-layout: fixed;
}
.green-theme .mat-table.table-ellipsis th,
.green-theme .mat-table.table-ellipsis td {
  overflow: hidden;
  width: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 10px;
}
.green-theme .mat-table.table-ellipsis th.no-ellipsis,
.green-theme .mat-table.table-ellipsis td.no-ellipsis {
  overflow: inherit;
}
.green-theme .mat-table .mat-header-row .mat-header-cell {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  color: #000000;
}
.green-theme .mat-table tr.mat-row {
  background-color: white;
  height: 65px;
}
.green-theme .mat-table tr.mat-row .mat-cell {
  font-size: 16px;
  color: #26273b;
}
.green-theme .mat-table tr.mat-row .mat-cell.action-buttons {
  color: #136f63;
  font-size: 24px;
}
.green-theme .mat-paginator {
  background-color: transparent;
}
.green-theme .mat-checkbox-layout {
  white-space: normal !important;
}
.green-theme mat-checkbox.light-border .mat-checkbox-frame {
  border-color: #ffffff;
}
.green-theme .mat-tooltip {
  font-size: 0.9em;
  max-width: 350px !important;
}
.green-theme .mat-menu-panel.dark-theme {
  min-width: 150px;
  background-color: #000000;
}
.green-theme .mat-menu-panel.dark-theme .mat-menu-item {
  color: #ffffff;
}
.green-theme .mat-menu-panel.dark-theme .mat-divider {
  background-color: #000000;
}
.green-theme .draggable-item {
  border-radius: 5px;
  background-color: #f4f4f9;
  margin: 5px 0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.green-theme .draggable-item .cdk-drag-handle {
  cursor: grab;
}
.green-theme .draggable-item .cdk-drag-handle:active {
  cursor: grabbing;
}
.green-theme .draggable-item.cdk-drag-preview {
  pointer-events: fill !important;
  box-shadow: 0px 0px 25px 0px rgb(0, 0, 0);
}
.green-theme .draggable-item.cdk-drag-preview .cdk-drag-handle {
  cursor: grabbing;
}
.green-theme .remove-padding mat-dialog-container {
  padding: 0px;
}
.green-theme .mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Saira;
}
.green-theme .mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.green-theme .mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.green-theme .mat-h1,
.green-theme .mat-headline,
.green-theme .mat-typography .mat-h1,
.green-theme .mat-typography .mat-headline,
.green-theme .mat-typography h1 {
  font: 900 24px / 32px Saira;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.green-theme .mat-h2,
.green-theme .mat-title,
.green-theme .mat-typography .mat-h2,
.green-theme .mat-typography .mat-title,
.green-theme .mat-typography h2 {
  font: 600 20px / 28px Saira;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.green-theme .mat-h3,
.green-theme .mat-subheading-2,
.green-theme .mat-typography .mat-h3,
.green-theme .mat-typography .mat-subheading-2,
.green-theme .mat-typography h3 {
  font: 400 16px / 28px Saira;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.green-theme .mat-h4,
.green-theme .mat-subheading-1,
.green-theme .mat-typography .mat-h4,
.green-theme .mat-typography .mat-subheading-1,
.green-theme .mat-typography h4 {
  font: 600 14px / 1em Saira;
  letter-spacing: 3px;
  margin: 0 0 16px;
}
.green-theme .mat-h5,
.green-theme .mat-typography .mat-h5,
.green-theme .mat-typography h5 {
  font: 400 calc(16px * 0.83) / 20px Saira;
  margin: 0 0 12px;
}
.green-theme .mat-h6,
.green-theme .mat-typography .mat-h6,
.green-theme .mat-typography h6 {
  font: 400 calc(16px * 0.67) / 20px Saira;
  margin: 0 0 12px;
}
.green-theme .mat-body-strong,
.green-theme .mat-body-2,
.green-theme .mat-typography .mat-body-strong,
.green-theme .mat-typography .mat-body-2 {
  font: 400 18px / 26px Saira;
  letter-spacing: normal;
}
.green-theme .mat-body,
.green-theme .mat-body-1,
.green-theme .mat-typography .mat-body,
.green-theme .mat-typography .mat-body-1,
.green-theme .mat-typography {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
}
.green-theme .mat-body p,
.green-theme .mat-body-1 p,
.green-theme .mat-typography .mat-body p,
.green-theme .mat-typography .mat-body-1 p,
.green-theme .mat-typography p {
  margin: 0 0 12px;
}
.green-theme .mat-small,
.green-theme .mat-caption,
.green-theme .mat-typography .mat-small,
.green-theme .mat-typography .mat-caption {
  font: 400 12px / 20px Saira;
  letter-spacing: normal;
}
.green-theme .mat-display-4,
.green-theme .mat-typography .mat-display-4 {
  font: 400 112px / 112px Saira;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.green-theme .mat-display-3,
.green-theme .mat-typography .mat-display-3 {
  font: 900 56px / 56px Saira;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.green-theme .mat-display-2,
.green-theme .mat-typography .mat-display-2 {
  font: 900 40px / 1.2 Saira;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.green-theme .mat-display-1,
.green-theme .mat-typography .mat-display-1 {
  font: 600 32px / 1.13 Saira;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.green-theme .mat-bottom-sheet-container {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
}
.green-theme .mat-button, .green-theme .mat-raised-button, .green-theme .mat-icon-button, .green-theme .mat-stroked-button,
.green-theme .mat-flat-button, .green-theme .mat-fab, .green-theme .mat-mini-fab {
  font-family: Saira;
  font-size: 14px;
  font-weight: 600;
}
.green-theme .mat-button-toggle {
  font-family: Saira;
}
.green-theme .mat-card {
  font-family: Saira;
}
.green-theme .mat-card-title {
  font-size: 24px;
  font-weight: 600;
}
.green-theme .mat-card-header .mat-card-title {
  font-size: 20px;
}
.green-theme .mat-card-subtitle,
.green-theme .mat-card-content {
  font-size: 16px;
}
.green-theme .mat-checkbox {
  font-family: Saira;
}
.green-theme .mat-checkbox-layout .mat-checkbox-label {
  line-height: 26px;
}
.green-theme .mat-chip {
  font-size: 18px;
  font-weight: 400;
}
.green-theme .mat-chip .mat-chip-trailing-icon.mat-icon,
.green-theme .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.green-theme .mat-table {
  font-family: Saira;
}
.green-theme .mat-header-cell {
  font-size: 12px;
  font-weight: 400;
}
.green-theme .mat-cell, .green-theme .mat-footer-cell {
  font-size: 16px;
}
.green-theme .mat-calendar {
  font-family: Saira;
}
.green-theme .mat-calendar-body {
  font-size: 13px;
}
.green-theme .mat-calendar-body-label,
.green-theme .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 600;
}
.green-theme .mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.green-theme .mat-dialog-title {
  font: 600 20px / 28px Saira;
  letter-spacing: normal;
}
.green-theme .mat-expansion-panel-header {
  font-family: Saira;
  font-size: 14px;
  font-weight: 600;
}
.green-theme .mat-expansion-panel-content {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
}
.green-theme .mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Saira;
  letter-spacing: normal;
}
.green-theme .mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.green-theme .mat-form-field-prefix .mat-icon,
.green-theme .mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.green-theme .mat-form-field-prefix .mat-icon-button,
.green-theme .mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.green-theme .mat-form-field-prefix .mat-icon-button .mat-icon,
.green-theme .mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.green-theme .mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.green-theme .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.green-theme .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34373em) scale(0.75);
  width: 133.3333533333%;
}
.green-theme .mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34372em) scale(0.75);
  width: 133.3333633333%;
}
.green-theme .mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.green-theme .mat-form-field-label {
  top: 1.34375em;
}
.green-theme .mat-form-field-underline {
  bottom: 1.34375em;
}
.green-theme .mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.green-theme .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.green-theme .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.green-theme .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.green-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00106px);
  width: 133.3333933333%;
}
.green-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00107px);
  width: 133.3334033333%;
}
.green-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00108px);
  width: 133.3334133333%;
}
.green-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.green-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.green-theme .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .green-theme .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.green-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28116em) scale(0.75);
  }
  .green-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28115em) scale(0.75);
  }
  .green-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28114em) scale(0.75);
  }
}
.green-theme .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.green-theme .mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.green-theme .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.green-theme .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59373em) scale(0.75);
  width: 133.3333533333%;
}
.green-theme .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59372em) scale(0.75);
  width: 133.3333633333%;
}
.green-theme .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.green-theme .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.green-theme .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.green-theme .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59373em) scale(0.75);
  width: 133.3333533333%;
}
.green-theme .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59372em) scale(0.75);
  width: 133.3333633333%;
}
.green-theme .mat-grid-tile-header,
.green-theme .mat-grid-tile-footer {
  font-size: 16px;
}
.green-theme .mat-grid-tile-header .mat-line,
.green-theme .mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.green-theme .mat-grid-tile-header .mat-line:nth-child(n+2),
.green-theme .mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.green-theme input.mat-input-element {
  margin-top: -0.0625em;
}
.green-theme .mat-menu-item {
  font-family: Saira;
  font-size: 16px;
  font-weight: 400;
}
.green-theme .mat-paginator,
.green-theme .mat-paginator-page-size .mat-select-trigger {
  font-family: Saira;
  font-size: 12px;
}
.green-theme .mat-radio-button {
  font-family: Saira;
}
.green-theme .mat-select {
  font-family: Saira;
}
.green-theme .mat-select-trigger {
  height: 1.125em;
}
.green-theme .mat-slide-toggle-content {
  font-family: Saira;
}
.green-theme .mat-slider-thumb-label-text {
  font-family: Saira;
  font-size: 12px;
  font-weight: 400;
}
.green-theme .mat-stepper-vertical, .green-theme .mat-stepper-horizontal {
  font-family: Saira;
}
.green-theme .mat-step-label {
  font-size: 16px;
  font-weight: 400;
}
.green-theme .mat-step-sub-label-error {
  font-weight: normal;
}
.green-theme .mat-step-label-error {
  font-size: 18px;
}
.green-theme .mat-step-label-selected {
  font-size: 18px;
  font-weight: 400;
}
.green-theme .mat-tab-group {
  font-family: Saira;
}
.green-theme .mat-tab-label, .green-theme .mat-tab-link {
  font-family: Saira;
  font-size: 14px;
  font-weight: 600;
}
.green-theme .mat-toolbar,
.green-theme .mat-toolbar h1,
.green-theme .mat-toolbar h2,
.green-theme .mat-toolbar h3,
.green-theme .mat-toolbar h4,
.green-theme .mat-toolbar h5,
.green-theme .mat-toolbar h6 {
  font: 600 20px / 28px Saira;
  letter-spacing: normal;
  margin: 0;
}
.green-theme .mat-tooltip {
  font-family: Saira;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.green-theme .mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.green-theme .mat-list-item {
  font-family: Saira;
}
.green-theme .mat-list-option {
  font-family: Saira;
}
.green-theme .mat-list-base .mat-list-item {
  font-size: 16px;
}
.green-theme .mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.green-theme .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.green-theme .mat-list-base .mat-list-option {
  font-size: 16px;
}
.green-theme .mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.green-theme .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.green-theme .mat-list-base .mat-subheader {
  font-family: Saira;
  font-size: 18px;
  font-weight: 400;
}
.green-theme .mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.green-theme .mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.green-theme .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.green-theme .mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.green-theme .mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.green-theme .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.green-theme .mat-list-base[dense] .mat-subheader {
  font-family: Saira;
  font-size: 12px;
  font-weight: 400;
}
.green-theme .mat-option {
  font-family: Saira;
  font-size: 16px;
}
.green-theme .mat-optgroup-label {
  font: 400 18px / 26px Saira;
  letter-spacing: normal;
}
.green-theme .mat-simple-snackbar {
  font-family: Saira;
  font-size: 16px;
}
.green-theme .mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 600;
}
.green-theme .mat-tree {
  font-family: Saira;
}
.green-theme .mat-tree-node,
.green-theme .mat-nested-tree-node {
  font-weight: 400;
  font-size: 16px;
}
.green-theme .mat-ripple {
  overflow: hidden;
  position: relative;
}
.green-theme .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.green-theme .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.green-theme .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .green-theme .mat-ripple-element {
  display: none;
}

.green-theme .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .green-theme .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.green-theme .cdk-overlay-container, .green-theme .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.green-theme .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.green-theme .cdk-overlay-container:empty {
  display: none;
}
.green-theme .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.green-theme .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.green-theme .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.green-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .green-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.green-theme .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.green-theme .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.green-theme .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.green-theme .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.green-theme .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.green-theme .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.green-theme textarea.cdk-textarea-autosize {
  resize: none;
}
.green-theme textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.green-theme textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.green-theme .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.green-theme .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.green-theme .mat-focus-indicator {
  position: relative;
}
.green-theme .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.green-theme .mat-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .green-theme {
  --mat-focus-indicator-display: block;
}

.green-theme .mat-mdc-focus-indicator {
  position: relative;
}
.green-theme .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.green-theme .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .green-theme {
  --mat-mdc-focus-indicator-display: block;
}

.green-theme .mat-ripple-element {
  background-color: rgba(18, 18, 18, 0.1);
}
.green-theme .mat-option {
  color: #121212;
}
.green-theme .mat-option:hover:not(.mat-option-disabled), .green-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.green-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.green-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: #121212;
}
.green-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.green-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #136f63;
}
.green-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #9fa8da;
}
.green-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e81253;
}
.green-theme .mat-optgroup-label {
  color: #26273b;
}
.green-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.green-theme .mat-pseudo-checkbox {
  color: #26273b;
}
.green-theme .mat-pseudo-checkbox::after {
  color: white;
}
.green-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.green-theme .mat-primary .mat-pseudo-checkbox-checked,
.green-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #136f63;
}
.green-theme .mat-pseudo-checkbox-checked,
.green-theme .mat-pseudo-checkbox-indeterminate,
.green-theme .mat-accent .mat-pseudo-checkbox-checked,
.green-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #9fa8da;
}
.green-theme .mat-warn .mat-pseudo-checkbox-checked,
.green-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e81253;
}
.green-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.green-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.green-theme .mat-app-background, .green-theme.mat-app-background {
  background-color: white;
  color: #121212;
}
.green-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(18, 18, 18, 0.2), 0px 1px 1px 0px rgba(18, 18, 18, 0.14), 0px 1px 3px 0px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(18, 18, 18, 0.2), 0px 3px 4px 0px rgba(18, 18, 18, 0.14), 0px 1px 8px 0px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 5px 8px 0px rgba(18, 18, 18, 0.14), 0px 1px 14px 0px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 6px 10px 0px rgba(18, 18, 18, 0.14), 0px 1px 18px 0px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(18, 18, 18, 0.2), 0px 7px 10px 1px rgba(18, 18, 18, 0.14), 0px 2px 16px 1px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(18, 18, 18, 0.2), 0px 8px 10px 1px rgba(18, 18, 18, 0.14), 0px 3px 14px 2px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(18, 18, 18, 0.2), 0px 9px 12px 1px rgba(18, 18, 18, 0.14), 0px 3px 16px 2px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(18, 18, 18, 0.2), 0px 10px 14px 1px rgba(18, 18, 18, 0.14), 0px 4px 18px 3px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(18, 18, 18, 0.2), 0px 11px 15px 1px rgba(18, 18, 18, 0.14), 0px 4px 20px 3px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(18, 18, 18, 0.2), 0px 12px 17px 2px rgba(18, 18, 18, 0.14), 0px 5px 22px 4px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(18, 18, 18, 0.2), 0px 13px 19px 2px rgba(18, 18, 18, 0.14), 0px 5px 24px 4px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(18, 18, 18, 0.2), 0px 14px 21px 2px rgba(18, 18, 18, 0.14), 0px 5px 26px 4px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(18, 18, 18, 0.2), 0px 15px 22px 2px rgba(18, 18, 18, 0.14), 0px 6px 28px 5px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(18, 18, 18, 0.2), 0px 16px 24px 2px rgba(18, 18, 18, 0.14), 0px 6px 30px 5px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(18, 18, 18, 0.2), 0px 17px 26px 2px rgba(18, 18, 18, 0.14), 0px 6px 32px 5px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(18, 18, 18, 0.2), 0px 18px 28px 2px rgba(18, 18, 18, 0.14), 0px 7px 34px 6px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(18, 18, 18, 0.2), 0px 19px 29px 2px rgba(18, 18, 18, 0.14), 0px 7px 36px 6px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(18, 18, 18, 0.2), 0px 20px 31px 3px rgba(18, 18, 18, 0.14), 0px 8px 38px 7px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(18, 18, 18, 0.2), 0px 21px 33px 3px rgba(18, 18, 18, 0.14), 0px 8px 40px 7px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(18, 18, 18, 0.2), 0px 22px 35px 3px rgba(18, 18, 18, 0.14), 0px 8px 42px 7px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(18, 18, 18, 0.2), 0px 23px 36px 3px rgba(18, 18, 18, 0.14), 0px 9px 44px 8px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(18, 18, 18, 0.2), 0px 24px 38px 3px rgba(18, 18, 18, 0.14), 0px 9px 46px 8px rgba(18, 18, 18, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.green-theme .mat-autocomplete-panel {
  background: white;
  color: #121212;
}
.green-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.green-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: #121212;
}
.green-theme .mat-badge-content {
  color: white;
  background: #136f63;
}
.cdk-high-contrast-active .green-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.green-theme .mat-badge-accent .mat-badge-content {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.green-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e81253;
}
.green-theme .mat-badge-disabled .mat-badge-content {
  background: #c1c1c1;
  color: rgba(0, 0, 0, 0.38);
}
.green-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(18, 18, 18, 0.2), 0px 16px 24px 2px rgba(18, 18, 18, 0.14), 0px 6px 30px 5px rgba(18, 18, 18, 0.12);
  background: white;
  color: #121212;
}
.green-theme .mat-button, .green-theme .mat-icon-button, .green-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.green-theme .mat-button.mat-primary, .green-theme .mat-icon-button.mat-primary, .green-theme .mat-stroked-button.mat-primary {
  color: #136f63;
}
.green-theme .mat-button.mat-accent, .green-theme .mat-icon-button.mat-accent, .green-theme .mat-stroked-button.mat-accent {
  color: #9fa8da;
}
.green-theme .mat-button.mat-warn, .green-theme .mat-icon-button.mat-warn, .green-theme .mat-stroked-button.mat-warn {
  color: #e81253;
}
.green-theme .mat-button.mat-primary.mat-button-disabled, .green-theme .mat-button.mat-accent.mat-button-disabled, .green-theme .mat-button.mat-warn.mat-button-disabled, .green-theme .mat-button.mat-button-disabled.mat-button-disabled, .green-theme .mat-icon-button.mat-primary.mat-button-disabled, .green-theme .mat-icon-button.mat-accent.mat-button-disabled, .green-theme .mat-icon-button.mat-warn.mat-button-disabled, .green-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .green-theme .mat-stroked-button.mat-primary.mat-button-disabled, .green-theme .mat-stroked-button.mat-accent.mat-button-disabled, .green-theme .mat-stroked-button.mat-warn.mat-button-disabled, .green-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(18, 18, 18, 0.26);
}
.green-theme .mat-button.mat-primary .mat-button-focus-overlay, .green-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .green-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #136f63;
}
.green-theme .mat-button.mat-accent .mat-button-focus-overlay, .green-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .green-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #9fa8da;
}
.green-theme .mat-button.mat-warn .mat-button-focus-overlay, .green-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .green-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e81253;
}
.green-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .green-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .green-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.green-theme .mat-button .mat-ripple-element, .green-theme .mat-icon-button .mat-ripple-element, .green-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.green-theme .mat-button-focus-overlay {
  background: #121212;
}
.green-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.green-theme .mat-flat-button, .green-theme .mat-raised-button, .green-theme .mat-fab, .green-theme .mat-mini-fab {
  color: #121212;
  background-color: white;
}
.green-theme .mat-flat-button.mat-primary, .green-theme .mat-raised-button.mat-primary, .green-theme .mat-fab.mat-primary, .green-theme .mat-mini-fab.mat-primary {
  color: white;
}
.green-theme .mat-flat-button.mat-accent, .green-theme .mat-raised-button.mat-accent, .green-theme .mat-fab.mat-accent, .green-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.green-theme .mat-flat-button.mat-warn, .green-theme .mat-raised-button.mat-warn, .green-theme .mat-fab.mat-warn, .green-theme .mat-mini-fab.mat-warn {
  color: white;
}
.green-theme .mat-flat-button.mat-primary.mat-button-disabled, .green-theme .mat-flat-button.mat-accent.mat-button-disabled, .green-theme .mat-flat-button.mat-warn.mat-button-disabled, .green-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .green-theme .mat-raised-button.mat-primary.mat-button-disabled, .green-theme .mat-raised-button.mat-accent.mat-button-disabled, .green-theme .mat-raised-button.mat-warn.mat-button-disabled, .green-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .green-theme .mat-fab.mat-primary.mat-button-disabled, .green-theme .mat-fab.mat-accent.mat-button-disabled, .green-theme .mat-fab.mat-warn.mat-button-disabled, .green-theme .mat-fab.mat-button-disabled.mat-button-disabled, .green-theme .mat-mini-fab.mat-primary.mat-button-disabled, .green-theme .mat-mini-fab.mat-accent.mat-button-disabled, .green-theme .mat-mini-fab.mat-warn.mat-button-disabled, .green-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(18, 18, 18, 0.26);
}
.green-theme .mat-flat-button.mat-primary, .green-theme .mat-raised-button.mat-primary, .green-theme .mat-fab.mat-primary, .green-theme .mat-mini-fab.mat-primary {
  background-color: #136f63;
}
.green-theme .mat-flat-button.mat-accent, .green-theme .mat-raised-button.mat-accent, .green-theme .mat-fab.mat-accent, .green-theme .mat-mini-fab.mat-accent {
  background-color: #9fa8da;
}
.green-theme .mat-flat-button.mat-warn, .green-theme .mat-raised-button.mat-warn, .green-theme .mat-fab.mat-warn, .green-theme .mat-mini-fab.mat-warn {
  background-color: #e81253;
}
.green-theme .mat-flat-button.mat-primary.mat-button-disabled, .green-theme .mat-flat-button.mat-accent.mat-button-disabled, .green-theme .mat-flat-button.mat-warn.mat-button-disabled, .green-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .green-theme .mat-raised-button.mat-primary.mat-button-disabled, .green-theme .mat-raised-button.mat-accent.mat-button-disabled, .green-theme .mat-raised-button.mat-warn.mat-button-disabled, .green-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .green-theme .mat-fab.mat-primary.mat-button-disabled, .green-theme .mat-fab.mat-accent.mat-button-disabled, .green-theme .mat-fab.mat-warn.mat-button-disabled, .green-theme .mat-fab.mat-button-disabled.mat-button-disabled, .green-theme .mat-mini-fab.mat-primary.mat-button-disabled, .green-theme .mat-mini-fab.mat-accent.mat-button-disabled, .green-theme .mat-mini-fab.mat-warn.mat-button-disabled, .green-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: #f7f7f7;
}
.green-theme .mat-flat-button.mat-primary .mat-ripple-element, .green-theme .mat-raised-button.mat-primary .mat-ripple-element, .green-theme .mat-fab.mat-primary .mat-ripple-element, .green-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.green-theme .mat-flat-button.mat-accent .mat-ripple-element, .green-theme .mat-raised-button.mat-accent .mat-ripple-element, .green-theme .mat-fab.mat-accent .mat-ripple-element, .green-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.green-theme .mat-flat-button.mat-warn .mat-ripple-element, .green-theme .mat-raised-button.mat-warn .mat-ripple-element, .green-theme .mat-fab.mat-warn .mat-ripple-element, .green-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.green-theme .mat-stroked-button:not([class*=mat-elevation-z]), .green-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(18, 18, 18, 0.2), 0px 8px 10px 1px rgba(18, 18, 18, 0.14), 0px 3px 14px 2px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-fab:not([class*=mat-elevation-z]), .green-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 6px 10px 0px rgba(18, 18, 18, 0.14), 0px 1px 18px 0px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .green-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(18, 18, 18, 0.2), 0px 12px 17px 2px rgba(18, 18, 18, 0.14), 0px 5px 22px 4px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .green-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.green-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.green-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.green-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.green-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.green-theme .mat-button-toggle-appearance-standard {
  color: #121212;
  background: white;
}
.green-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.green-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.green-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.green-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.green-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: #26273b;
}
.green-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #121212;
}
.green-theme .mat-button-toggle-disabled {
  color: rgba(18, 18, 18, 0.26);
  background-color: #eeeeee;
}
.green-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.green-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.green-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.green-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.green-theme .mat-card {
  background: white;
  color: #121212;
}
.green-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(18, 18, 18, 0.2), 0px 1px 1px 0px rgba(18, 18, 18, 0.14), 0px 1px 3px 0px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-card-subtitle {
  color: #26273b;
}
.green-theme .mat-checkbox-frame {
  border-color: #26273b;
}
.green-theme .mat-checkbox-checkmark {
  fill: white;
}
.green-theme .mat-checkbox-checkmark-path {
  stroke: white !important;
}
.green-theme .mat-checkbox-mixedmark {
  background-color: white;
}
.green-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .green-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #136f63;
}
.green-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .green-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #9fa8da;
}
.green-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .green-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e81253;
}
.green-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .green-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.green-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.green-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.green-theme .mat-checkbox .mat-ripple-element {
  background-color: #121212;
}
.green-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.green-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #136f63;
}
.green-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.green-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #9fa8da;
}
.green-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.green-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e81253;
}
.green-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: #121212;
}
.green-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: #121212;
  opacity: 0.4;
}
.green-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(18, 18, 18, 0.2), 0px 3px 4px 0px rgba(18, 18, 18, 0.14), 0px 1px 8px 0px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.green-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.green-theme .mat-chip.mat-standard-chip::after {
  background: #121212;
}
.green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #136f63;
  color: white;
}
.green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e81253;
  color: white;
}
.green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.green-theme .mat-table {
  background: white;
}
.green-theme .mat-table thead, .green-theme .mat-table tbody, .green-theme .mat-table tfoot,
.green-theme mat-header-row, .green-theme mat-row, .green-theme mat-footer-row,
.green-theme [mat-header-row], .green-theme [mat-row], .green-theme [mat-footer-row],
.green-theme .mat-table-sticky {
  background: inherit;
}
.green-theme mat-row, .green-theme mat-header-row, .green-theme mat-footer-row,
.green-theme th.mat-header-cell, .green-theme td.mat-cell, .green-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.green-theme .mat-header-cell {
  color: #26273b;
}
.green-theme .mat-cell, .green-theme .mat-footer-cell {
  color: #121212;
}
.green-theme .mat-calendar-arrow {
  fill: rgba(18, 18, 18, 0.54);
}
.green-theme .mat-datepicker-toggle,
.green-theme .mat-datepicker-content .mat-calendar-next-button,
.green-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(18, 18, 18, 0.54);
}
.green-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.green-theme .mat-calendar-table-header,
.green-theme .mat-calendar-body-label {
  color: #26273b;
}
.green-theme .mat-calendar-body-cell-content,
.green-theme .mat-date-range-input-separator {
  color: #121212;
  border-color: transparent;
}
.green-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.green-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.green-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.green-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.green-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.green-theme .mat-calendar-body-in-range::before {
  background: rgba(19, 111, 99, 0.2);
}
.green-theme .mat-calendar-body-comparison-identical,
.green-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.green-theme .mat-calendar-body-comparison-bridge-start::before,
.green-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(19, 111, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.green-theme .mat-calendar-body-comparison-bridge-end::before,
.green-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(19, 111, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.green-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.green-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.green-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.green-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.green-theme .mat-calendar-body-selected {
  background-color: #136f63;
  color: white;
}
.green-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(19, 111, 99, 0.4);
}
.green-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.green-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.green-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(19, 111, 99, 0.3);
}
@media (hover: hover) {
  .green-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(19, 111, 99, 0.3);
  }
}
.green-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
  background-color: white;
  color: #121212;
}
.green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(159, 168, 218, 0.2);
}
.green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.green-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(159, 168, 218, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.green-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(159, 168, 218, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(159, 168, 218, 0.4);
}
.green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.green-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.green-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(159, 168, 218, 0.3);
}
@media (hover: hover) {
  .green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(159, 168, 218, 0.3);
  }
}
.green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(232, 18, 83, 0.2);
}
.green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.green-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(232, 18, 83, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.green-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(232, 18, 83, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e81253;
  color: white;
}
.green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(232, 18, 83, 0.4);
}
.green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.green-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.green-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(232, 18, 83, 0.3);
}
@media (hover: hover) {
  .green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(232, 18, 83, 0.3);
  }
}
.green-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(18, 18, 18, 0.2), 0px 24px 38px 3px rgba(18, 18, 18, 0.14), 0px 9px 46px 8px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-datepicker-toggle-active {
  color: #136f63;
}
.green-theme .mat-datepicker-toggle-active.mat-accent {
  color: #9fa8da;
}
.green-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e81253;
}
.green-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.green-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(18, 18, 18, 0.2), 0px 24px 38px 3px rgba(18, 18, 18, 0.14), 0px 9px 46px 8px rgba(18, 18, 18, 0.12);
  background: white;
  color: #121212;
}
.green-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.green-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.green-theme .mat-expansion-panel {
  background: white;
  color: #121212;
}
.green-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.green-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .green-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .green-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .green-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.green-theme .mat-expansion-panel-header-title {
  color: #121212;
}
.green-theme .mat-expansion-panel-header-description,
.green-theme .mat-expansion-indicator::after {
  color: #26273b;
}
.green-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(18, 18, 18, 0.26);
}
.green-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.green-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.green-theme .mat-form-field-label {
  color: rgba(38, 39, 59, 0.6);
}
.green-theme .mat-hint {
  color: rgba(38, 39, 59, 0.6);
}
.green-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #136f63;
}
.green-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #9fa8da;
}
.green-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e81253;
}
.green-theme .mat-focused .mat-form-field-required-marker {
  color: #9fa8da;
}
.green-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.green-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #136f63;
}
.green-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #9fa8da;
}
.green-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e81253;
}
.green-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #136f63;
}
.green-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #9fa8da;
}
.green-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e81253;
}
.green-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e81253;
}
.green-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.green-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e81253;
}
.green-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.green-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e81253;
}
.green-theme .mat-error {
  color: #e81253;
}
.green-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: #26273b;
}
.green-theme .mat-form-field-appearance-legacy .mat-hint {
  color: #26273b;
}
.green-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.green-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.green-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.green-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.green-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(18, 18, 18, 0.04);
}
.green-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(18, 18, 18, 0.02);
}
.green-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.green-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.green-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.green-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.green-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.green-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #136f63;
}
.green-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #9fa8da;
}
.green-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e81253;
}
.green-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e81253;
}
.green-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.green-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.green-theme .mat-icon.mat-primary {
  color: #136f63;
}
.green-theme .mat-icon.mat-accent {
  color: #9fa8da;
}
.green-theme .mat-icon.mat-warn {
  color: #e81253;
}
.green-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: #26273b;
}
.green-theme .mat-input-element:disabled,
.green-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.green-theme .mat-input-element {
  caret-color: #136f63;
}
.green-theme .mat-input-element::placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.green-theme .mat-input-element::-moz-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.green-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.green-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.green-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #9fa8da;
}
.green-theme .mat-form-field.mat-warn .mat-input-element,
.green-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e81253;
}
.green-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e81253;
}
.green-theme .mat-list-base .mat-list-item {
  color: #121212;
}
.green-theme .mat-list-base .mat-list-option {
  color: #121212;
}
.green-theme .mat-list-base .mat-subheader {
  color: #26273b;
}
.green-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.green-theme .mat-list-option:hover, .green-theme .mat-list-option:focus,
.green-theme .mat-nav-list .mat-list-item:hover,
.green-theme .mat-nav-list .mat-list-item:focus,
.green-theme .mat-action-list .mat-list-item:hover,
.green-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.green-theme .mat-list-single-selected-option, .green-theme .mat-list-single-selected-option:hover, .green-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.green-theme .mat-menu-panel {
  background: white;
}
.green-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-menu-item {
  background: transparent;
  color: #121212;
}
.green-theme .mat-menu-item[disabled],
.green-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.green-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.green-theme .mat-menu-item .mat-icon-no-color,
.green-theme .mat-menu-submenu-icon {
  color: rgba(18, 18, 18, 0.54);
}
.green-theme .mat-menu-item:hover:not([disabled]),
.green-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.green-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.green-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.green-theme .mat-paginator {
  background: white;
}
.green-theme .mat-paginator,
.green-theme .mat-paginator-page-size .mat-select-trigger {
  color: #26273b;
}
.green-theme .mat-paginator-decrement,
.green-theme .mat-paginator-increment {
  border-top: 2px solid rgba(18, 18, 18, 0.54);
  border-right: 2px solid rgba(18, 18, 18, 0.54);
}
.green-theme .mat-paginator-first,
.green-theme .mat-paginator-last {
  border-top: 2px solid rgba(18, 18, 18, 0.54);
}
.green-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.green-theme .mat-icon-button[disabled] .mat-paginator-increment,
.green-theme .mat-icon-button[disabled] .mat-paginator-first,
.green-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.green-theme .mat-progress-bar-background {
  fill: #c4dbd8;
}
.green-theme .mat-progress-bar-buffer {
  background-color: #c4dbd8;
}
.green-theme .mat-progress-bar-fill::after {
  background-color: #136f63;
}
.green-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #e7e9f6;
}
.green-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #e7e9f6;
}
.green-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #9fa8da;
}
.green-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9c4d4;
}
.green-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9c4d4;
}
.green-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e81253;
}
.green-theme .mat-progress-spinner circle, .green-theme .mat-spinner circle {
  stroke: #136f63;
}
.green-theme .mat-progress-spinner.mat-accent circle, .green-theme .mat-spinner.mat-accent circle {
  stroke: #9fa8da;
}
.green-theme .mat-progress-spinner.mat-warn circle, .green-theme .mat-spinner.mat-warn circle {
  stroke: #e81253;
}
.green-theme .mat-radio-outer-circle {
  border-color: #26273b;
}
.green-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #136f63;
}
.green-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.green-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .green-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .green-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #136f63;
}
.green-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #9fa8da;
}
.green-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.green-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .green-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .green-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #9fa8da;
}
.green-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e81253;
}
.green-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.green-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .green-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .green-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e81253;
}
.green-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.green-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.green-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.green-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.green-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.green-theme .mat-radio-button .mat-ripple-element {
  background-color: #121212;
}
.green-theme .mat-select-value {
  color: #121212;
}
.green-theme .mat-select-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.green-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.green-theme .mat-select-arrow {
  color: #26273b;
}
.green-theme .mat-select-panel {
  background: white;
}
.green-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.green-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #136f63;
}
.green-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #9fa8da;
}
.green-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e81253;
}
.green-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e81253;
}
.green-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.green-theme .mat-drawer-container {
  background-color: white;
  color: #121212;
}
.green-theme .mat-drawer {
  background-color: white;
  color: #121212;
}
.green-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.green-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(18, 18, 18, 0.2), 0px 16px 24px 2px rgba(18, 18, 18, 0.14), 0px 6px 30px 5px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.green-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.green-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.green-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.green-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.green-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #9fa8da;
}
.green-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(159, 168, 218, 0.54);
}
.green-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #9fa8da;
}
.green-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #136f63;
}
.green-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(19, 111, 99, 0.54);
}
.green-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #136f63;
}
.green-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e81253;
}
.green-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(232, 18, 83, 0.54);
}
.green-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e81253;
}
.green-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: #121212;
}
.green-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(18, 18, 18, 0.2), 0px 1px 1px 0px rgba(18, 18, 18, 0.14), 0px 1px 3px 0px rgba(18, 18, 18, 0.12);
  background-color: #fafafa;
}
.green-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.green-theme .mat-slider-track-background {
  background-color: rgba(18, 18, 18, 0.26);
}
.green-theme .mat-slider.mat-primary .mat-slider-track-fill,
.green-theme .mat-slider.mat-primary .mat-slider-thumb,
.green-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #136f63;
}
.green-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.green-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(19, 111, 99, 0.2);
}
.green-theme .mat-slider.mat-accent .mat-slider-track-fill,
.green-theme .mat-slider.mat-accent .mat-slider-thumb,
.green-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #9fa8da;
}
.green-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.green-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(159, 168, 218, 0.2);
}
.green-theme .mat-slider.mat-warn .mat-slider-track-fill,
.green-theme .mat-slider.mat-warn .mat-slider-thumb,
.green-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e81253;
}
.green-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.green-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(232, 18, 83, 0.2);
}
.green-theme .mat-slider:hover .mat-slider-track-background,
.green-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(18, 18, 18, 0.38);
}
.green-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.green-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.green-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(18, 18, 18, 0.26);
}
.green-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(18, 18, 18, 0.26);
}
.green-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(18, 18, 18, 0.12);
}
.green-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.green-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(18, 18, 18, 0.87);
}
.green-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.green-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(18, 18, 18, 0.26);
}
.green-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(18, 18, 18, 0.26);
  background-color: transparent;
}
.green-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .green-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(18, 18, 18, 0.38);
}
.green-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .green-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(18, 18, 18, 0.26);
}
.green-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(18, 18, 18, 0.7);
}
.green-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(18, 18, 18, 0.7), rgba(18, 18, 18, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(18, 18, 18, 0.7), rgba(18, 18, 18, 0.7) 2px, transparent 0, transparent);
}
.green-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(18, 18, 18, 0.7), rgba(18, 18, 18, 0.7) 2px, transparent 0, transparent);
}
.green-theme .mat-step-header.cdk-keyboard-focused, .green-theme .mat-step-header.cdk-program-focused, .green-theme .mat-step-header:hover:not([aria-disabled]), .green-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.green-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .green-theme .mat-step-header:hover {
    background: none;
  }
}
.green-theme .mat-step-header .mat-step-label,
.green-theme .mat-step-header .mat-step-optional {
  color: #26273b;
}
.green-theme .mat-step-header .mat-step-icon {
  background-color: #26273b;
  color: white;
}
.green-theme .mat-step-header .mat-step-icon-selected,
.green-theme .mat-step-header .mat-step-icon-state-done,
.green-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #136f63;
  color: white;
}
.green-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.green-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.green-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.green-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.green-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.green-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.green-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.green-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e81253;
  color: white;
}
.green-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e81253;
}
.green-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: #121212;
}
.green-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e81253;
}
.green-theme .mat-stepper-horizontal, .green-theme .mat-stepper-vertical {
  background-color: white;
}
.green-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.green-theme .mat-horizontal-stepper-header::before,
.green-theme .mat-horizontal-stepper-header::after,
.green-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.green-theme .mat-sort-header-arrow {
  color: #26273b;
}
.green-theme .mat-tab-nav-bar,
.green-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.green-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.green-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.green-theme .mat-tab-label, .green-theme .mat-tab-link {
  color: #121212;
}
.green-theme .mat-tab-label.mat-tab-disabled, .green-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.green-theme .mat-tab-header-pagination-chevron {
  border-color: #121212;
}
.green-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.green-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.green-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.green-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .green-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.green-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.green-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .green-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .green-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.green-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.green-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(184, 212, 208, 0.3);
}
.green-theme .mat-tab-group.mat-primary .mat-ink-bar, .green-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #136f63;
}
.green-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .green-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .green-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .green-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.green-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .green-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.green-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.green-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .green-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .green-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.green-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.green-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.green-theme .mat-tab-group.mat-accent .mat-ink-bar, .green-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #9fa8da;
}
.green-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .green-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .green-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .green-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.green-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .green-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.green-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.green-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .green-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .green-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.green-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.green-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 184, 203, 0.3);
}
.green-theme .mat-tab-group.mat-warn .mat-ink-bar, .green-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e81253;
}
.green-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .green-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .green-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .green-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.green-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .green-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.green-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.green-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .green-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .green-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.green-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.green-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(184, 212, 208, 0.3);
}
.green-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .green-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .green-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #136f63;
}
.green-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .green-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.green-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .green-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.green-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.green-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.green-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.green-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.green-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.green-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.green-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.green-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.green-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.green-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .green-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.green-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.green-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .green-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .green-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.green-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.green-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.green-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .green-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .green-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #9fa8da;
}
.green-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .green-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.green-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .green-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.green-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.green-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.green-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.green-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.green-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.green-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.green-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.green-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.green-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.green-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .green-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.green-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.green-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .green-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .green-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.green-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.green-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 184, 203, 0.3);
}
.green-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .green-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .green-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e81253;
}
.green-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .green-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.green-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .green-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.green-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.green-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.green-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.green-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.green-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.green-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.green-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.green-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.green-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.green-theme .mat-toolbar {
  background: whitesmoke;
  color: #121212;
}
.green-theme .mat-toolbar.mat-primary {
  background: #136f63;
  color: white;
}
.green-theme .mat-toolbar.mat-accent {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.green-theme .mat-toolbar.mat-warn {
  background: #e81253;
  color: white;
}
.green-theme .mat-toolbar .mat-form-field-underline,
.green-theme .mat-toolbar .mat-form-field-ripple,
.green-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.green-theme .mat-toolbar .mat-form-field-label,
.green-theme .mat-toolbar .mat-focused .mat-form-field-label,
.green-theme .mat-toolbar .mat-select-value,
.green-theme .mat-toolbar .mat-select-arrow,
.green-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.green-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.green-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.green-theme .mat-tree {
  background: white;
}
.green-theme .mat-tree-node,
.green-theme .mat-nested-tree-node {
  color: #121212;
}
.green-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 6px 10px 0px rgba(18, 18, 18, 0.14), 0px 1px 18px 0px rgba(18, 18, 18, 0.12);
}
.green-theme .mat-simple-snackbar-action {
  color: #9fa8da;
}

.blue-theme .order {
  background-color: white;
  color: #000000;
}
.blue-theme .order .line1 .detail-btn {
  color: #00305e;
}
.blue-theme .order .important-text {
  color: #001638;
  font-weight: 600;
}
.blue-theme .order .status .status-chip {
  background-color: #4d7294;
  color: #ffffff;
}
.blue-theme .order .status .status-chip.closed {
  background-color: #42c778;
  color: #fff;
}
.blue-theme .banner {
  background-color: white;
  color: #000000;
}
.blue-theme .banner__content__title {
  color: #0041ee;
}
.blue-theme .login {
  background-color: white;
  color: #000000;
}
.blue-theme .login__header {
  background-color: #e0e7ed;
  color: #000000;
}
.blue-theme .login-content {
  background-color: white;
  color: #000000;
}
.blue-theme .login-content__link {
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  color: #002853;
  background-color: #ffffff;
}
.blue-theme .login-content__link:hover {
  background-color: #002853;
  color: #ffffff !important;
}
.blue-theme .login-content {
  background-color: white;
  color: #000000;
}
.blue-theme .login-content .link-send {
  color: #002853;
}
.blue-theme .login-content .link-send:hover {
  color: #000000;
}
.blue-theme .login-content {
  background-color: white;
  color: #000000;
}
.blue-theme .landing-container .sidenav-content {
  background-color: white;
  color: #000000;
}
.blue-theme .landing-container .sidenav-content__linkContainer__link.static {
  background-color: #ffffff;
  color: #002853;
}
.blue-theme .landing-container .sidenav-content__footer {
  background-color: rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 768px) {
  .blue-theme .landing-container .landing-content {
    background-color: #f4f4f9;
    color: #003566;
  }
}
@media (max-height: 555px) {
  .blue-theme .landing-container .landing-content {
    background-color: #f4f4f9;
    color: #003566;
  }
}
.blue-theme .orders {
  background-color: rgba(255, 255, 255, 0);
  color: #000000;
}
.blue-theme .orders__wrapper {
  background-color: #00305e;
  color: #ffffff;
}
.blue-theme .orders__see-all-btn .see-all-orders-btn {
  background-color: #00305e;
  color: #ffffff;
  border: 1px solid #53a6ff;
}
.blue-theme .orders__see-all-btn .see-all-orders-btn:hover {
  background-color: #004186;
}
.blue-theme .orders__banners {
  background-color: #f4f4f9;
  color: #000000;
}
.blue-theme .orders__banners .see-more-banners-btn {
  background-color: white;
  color: #000000;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.blue-theme .orders__banners .see-more-banners-btn:hover {
  background-color: #e0e7ed;
}
.blue-theme .footer {
  background-color: #9595a8;
  color: white;
}
.blue-theme .footer__facility__item__head {
  font-weight: bold;
}
.blue-theme .footer__facility__item mat-divider {
  background-color: white;
}
.blue-theme .all-orders {
  background-color: rgba(255, 255, 255, 0);
  color: #000000;
}
.blue-theme .all-orders__wrapper {
  background-color: #f4f4f9;
  color: #000000;
}
.blue-theme .all-orders__wrapper__header {
  background-color: white;
  color: #002853;
}
.blue-theme .all-orders__wrapper__filter .filter-field .mat-form-field-outline {
  background-color: white;
}
.blue-theme .order-detail__header {
  background-color: white;
  color: #000000;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.blue-theme .order-detail__header .title {
  color: #00305e;
}
.blue-theme .order-detail__steps {
  background-color: white;
  color: #000000;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.blue-theme .order-detail__steps mat-stepper {
  background-color: transparent;
  pointer-events: none;
  min-width: 100%;
}
.blue-theme .order-detail__steps mat-stepper mat-step-header {
  padding: 0 !important;
  min-width: 130px !important;
  overflow: visible !important;
}
.blue-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon.mat-step-icon-state-noicon {
  background-color: #bdbdbd;
}
.blue-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon {
  height: 15px !important;
  width: 15px !important;
  margin-top: 5px;
}
.blue-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon .mat-icon {
  font-size: 14px;
  height: 14px;
  width: 14px;
}
.blue-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon .currentState {
  color: #42c778;
  font-size: 20px;
  height: 20px;
  width: 20px;
  margin-top: -27px;
}
.blue-theme .order-detail__steps mat-stepper mat-step-header .mat-step-label {
  padding: 5px 5px 0 5px !important;
  font-size: 0.7rem;
}
.blue-theme .order-detail__steps mat-stepper mat-step-header .mat-step-label .disabled {
  color: #bdbdbd;
}
.blue-theme .order-detail__steps .mat-stepper-horizontal-line {
  display: none !important;
}
.blue-theme .order-detail__steps .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:after,
.blue-theme .order-detail__steps .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:before {
  top: 13px !important;
}
.blue-theme .order-detail__steps .mat-horizontal-stepper-wrapper {
  width: fit-content !important;
  margin: auto !important;
}
.blue-theme .order-detail__tabs {
  background-color: #ffffff;
  color: #000000;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.blue-theme .order-detail__tabs mat-tab-group .mat-tab-body-wrapper {
  height: 100%;
}
.blue-theme .order-detail__tabs mat-tab-group .newMessagesIcon {
  color: #e5104c;
}
.blue-theme .order-detail__tabs mat-tab-group .mat-tab-label {
  opacity: 1 !important;
}
.blue-theme .order-detail__tabs .desktopTabs .item {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.blue-theme .order-detail__tabs .desktopTabs .item:first-child {
  background-color: #f4f4f9;
}
.blue-theme .messages {
  background-color: white;
}
.blue-theme .messages__list .message__content {
  background-color: #e4e9f2;
  color: #000000;
}
.blue-theme .messages__list .message__content.budget {
  background-color: #42c778;
  color: #fff;
}
.blue-theme .messages__list .message__content.remote-sign {
  background-color: #003566;
  color: #ffffff;
}
.blue-theme .messages__list .message__content.payment {
  background-color: #3b71ff;
  color: #ffffff;
}
.blue-theme .messages__list .message__content .budget-icon {
  background-color: #69d394;
}
.blue-theme .messages__list .message__content .remote-sign-icon {
  background-color: #005099;
}
.blue-theme .messages__list .message__content .payment-icon {
  background-color: #6e96ff;
}
.blue-theme .messages__list .message__date {
  color: #1a1a1a;
}
.blue-theme .messages__list .message.customer .message__content {
  background-color: #002249;
  color: #ffffff;
}
.blue-theme .messages__input {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.blue-theme .messages__input__wrapper {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.blue-theme .messages__input__wrapper .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -6px;
}
.blue-theme .messages__input__wrapper .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -4px;
}
.blue-theme .messages__input__wrapper .send-icon {
  color: #000000;
}
.blue-theme .messages__input__wrapper .attach-icon {
  color: #000000;
}
.blue-theme .navbar {
  background-color: white !important;
  color: #000000 !important;
}
.blue-theme .navbar mat-divider {
  background-color: #000000;
}
@media screen and (max-width: 768px) {
  .blue-theme .navbar {
    background-color: #002853 !important;
    color: #ffffff !important;
  }
  .blue-theme .navbar mat-divider {
    background-color: white;
  }
}
.blue-theme .attachments-item-container {
  background-color: #e4e9f2;
}
.blue-theme .attachments-item-container .file-name {
  color: #002853;
}
.blue-theme .attachments-item-container .file-name mat-icon {
  color: #002853;
}
@media screen and (max-width: 992px) {
  .blue-theme ngx-extended-pdf-viewer #mainContainer {
    min-width: unset !important;
    max-width: 100% !important;
  }
  .blue-theme #toolbarViewerLeft pdf-find-button,
.blue-theme #toolbarViewerLeft pdf-paging-area {
    display: none !important;
  }
  .blue-theme #toolbarViewerRight {
    display: none !important;
  }
  .blue-theme #toolbarViewerMiddle {
    display: flex !important;
  }
}
.blue-theme .budget-viewer-wrapper .mat-dialog-container {
  padding: 0px;
}
.blue-theme .budget-viewer__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.blue-theme .budget-viewer__header h2 {
  color: #002853;
}
.blue-theme .budget-viewer__content__lines .budget-line {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.blue-theme .budget-viewer__content__lines .budget-line.accepted {
  border: 1px solid #56cd86;
  background-color: #56cd86;
  color: white;
}
.blue-theme .budget-viewer__content__lines .budget-line.accepted.authorized {
  border: 1px solid #31a560;
  background-color: #31a560;
}
.blue-theme .budget-viewer__content__lines .budget-line.accepted.authorized .mat-checkbox-disabled .mat-checkbox-label {
  color: white;
}
.blue-theme .budget-viewer__content__lines .budget-line mat-checkbox {
  width: 100%;
  padding: 15px;
}
.blue-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout {
  width: 100%;
}
.blue-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
  width: 90%;
}
@media screen and (max-width: 768px) {
  .blue-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
    width: 80%;
  }
}
.blue-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.blue-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label .checkbox-budget-label-1 {
  font-weight: 600;
}
.blue-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label .checkbox-budget-label-2 {
  font-weight: 500;
  margin-right: 30px;
}
@media screen and (max-width: 768px) {
  .blue-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label .checkbox-budget-label-2 {
    margin-right: 15px;
  }
}
.blue-theme .budget-viewer__content__lines .budget-line mat-checkbox.mat-checkbox-checked .mat-checkbox-background {
  background-color: white;
}
.blue-theme .budget-viewer__content__lines .budget-line mat-checkbox.mat-checkbox-checked .mat-checkbox-background .mat-checkbox-checkmark {
  fill: #42c778 !important;
}
.blue-theme .budget-viewer__content__lines .budget-line mat-checkbox.mat-checkbox-checked .mat-checkbox-background .mat-checkbox-checkmark-path {
  stroke: #42c778 !important;
}
.blue-theme .budget-viewer__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.blue-theme .payments-viewer-wrapper .mat-dialog-container {
  padding: 0px;
}
.blue-theme .payments-viewer__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.blue-theme .payments-viewer__header h2 {
  color: #002853;
}
.blue-theme .payments-viewer__content__lines .payments-line {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.blue-theme .payments-viewer__content__lines .payments-line.pending {
  border: 1px solid #225eff;
  background-color: #225eff;
  color: white;
}
.blue-theme .payments-viewer__content__lines .payments-line.accepted {
  border: 1px solid #2b9155;
  background-color: #2b9155;
  color: white;
}
.blue-theme .payments-viewer__content__lines .payments-line.error {
  border: 1px solid #c10c0c;
  background-color: #c10c0c;
  color: white;
}
.blue-theme .payments-viewer__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.blue-theme .signature-viewer-wrapper .mat-dialog-container {
  padding: 0px;
}
.blue-theme .signature-viewer__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.blue-theme .signature-viewer__header h2 {
  color: #002853;
}
.blue-theme .signature-viewer__content .mat-step-icon.mat-step-icon-state-edit mat-icon {
  border-radius: 20px;
  padding: 5px;
  background-color: #42c778;
}
.blue-theme .signature-viewer__content .mat-step-icon.mat-step-icon-state-number {
  background-color: #002249;
}
.blue-theme .signature-viewer__content mat-stepper {
  background-color: transparent;
  min-width: 100%;
}
.blue-theme .signature-viewer__content mat-stepper mat-step-header {
  padding: 0 !important;
  overflow: visible !important;
}
.blue-theme .signature-viewer__content mat-stepper mat-step-header .mat-step-label {
  padding: 5px 5px 0 5px !important;
  font-size: 0.7rem;
}
.blue-theme .signature-viewer__content mat-stepper mat-step-header .mat-step-label .disabled {
  color: #bdbdbd;
}
.blue-theme .signature-viewer__content .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:after,
.blue-theme .signature-viewer__content .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:before,
.blue-theme .signature-viewer__content .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 13px !important;
}
.blue-theme .signature-viewer__content .mat-horizontal-content-container {
  padding: 5px 0 0 0;
}
.blue-theme .signature-viewer__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.blue-theme .remote-signature-viewer-wrapper .mat-dialog-container {
  padding: 0;
}
.blue-theme .unverified-signature-warning {
  display: none !important;
}
.blue-theme .card-docs__section__content__budget, .blue-theme .card-docs__section__content__remote-sign, .blue-theme .card-docs__section__content__payment {
  background-color: #42c778;
  color: white;
  font-size: bold;
}
.blue-theme .card-docs__section__content__budget mat-icon, .blue-theme .card-docs__section__content__remote-sign mat-icon, .blue-theme .card-docs__section__content__payment mat-icon {
  background-color: #69d394;
}
.blue-theme .card-docs__section__content__budget mat-icon.alert-icon, .blue-theme .card-docs__section__content__remote-sign mat-icon.alert-icon, .blue-theme .card-docs__section__content__payment mat-icon.alert-icon {
  color: yellow;
  background-color: transparent;
  margin-left: -7px;
}
.blue-theme .card-docs__section__content__budget.pending, .blue-theme .card-docs__section__content__remote-sign.pending, .blue-theme .card-docs__section__content__payment.pending {
  background-color: #003566;
  color: white;
}
.blue-theme .card-docs__section__content__budget.pending mat-icon, .blue-theme .card-docs__section__content__remote-sign.pending mat-icon, .blue-theme .card-docs__section__content__payment.pending mat-icon {
  background-color: #005099;
}
.blue-theme .card-docs__section__content__budget.pending mat-icon.alert-icon, .blue-theme .card-docs__section__content__remote-sign.pending mat-icon.alert-icon, .blue-theme .card-docs__section__content__payment.pending mat-icon.alert-icon {
  background-color: transparent;
  margin-left: -7px;
}
.blue-theme .card-docs__section__content__budget.payments, .blue-theme .card-docs__section__content__remote-sign.payments, .blue-theme .card-docs__section__content__payment.payments {
  background-color: #084cff;
  color: #ffffff;
}
.blue-theme .card-docs__section__content__budget.payments mat-icon, .blue-theme .card-docs__section__content__remote-sign.payments mat-icon, .blue-theme .card-docs__section__content__payment.payments mat-icon {
  background-color: #3b71ff;
}
.blue-theme .card-docs__section__content__budget.payments mat-icon.alert-icon, .blue-theme .card-docs__section__content__remote-sign.payments mat-icon.alert-icon, .blue-theme .card-docs__section__content__payment.payments mat-icon.alert-icon {
  background-color: transparent;
  margin-left: -7px;
}
.blue-theme .card-docs__section__content__budget.payments.pendingPayments, .blue-theme .card-docs__section__content__remote-sign.payments.pendingPayments, .blue-theme .card-docs__section__content__payment.payments.pendingPayments {
  background-color: #3b71ff;
  color: #ffffff;
}
.blue-theme .card-docs__section__content__budget.payments.pendingPayments mat-icon, .blue-theme .card-docs__section__content__remote-sign.payments.pendingPayments mat-icon, .blue-theme .card-docs__section__content__payment.payments.pendingPayments mat-icon {
  background-color: #6e96ff;
}
.blue-theme .card-docs__section__content__budget.payments.pendingPayments mat-icon.alert-icon, .blue-theme .card-docs__section__content__remote-sign.payments.pendingPayments mat-icon.alert-icon, .blue-theme .card-docs__section__content__payment.payments.pendingPayments mat-icon.alert-icon {
  background-color: transparent;
  margin-left: -7px;
}
.blue-theme .send-code-content {
  background-color: white;
  color: #000000;
}
.blue-theme .send-code-content__link {
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  color: #002853;
  background-color: #ffffff;
}
.blue-theme .send-code-content__link:hover {
  background-color: #002853;
  color: #ffffff !important;
}
.blue-theme .ask-for-code-content {
  background-color: white;
  color: #000000;
}
.blue-theme .global-message--success {
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;
}
.blue-theme .global-message--success .mat-simple-snackbar-action {
  color: #155724 !important;
}
.blue-theme .global-message--warning {
  color: #2c2c2c !important;
  background-color: rgb(206, 134, 0) !important;
  border-color: #be8f00 !important;
}
.blue-theme .global-message--warning .mat-simple-snackbar-action {
  color: #2c2c2c !important;
}
.blue-theme .global-message--error {
  color: rgb(245, 245, 245) !important;
  background-color: rgb(138, 0, 0) !important;
  border-color: rgb(114, 2, 2) !important;
}
.blue-theme .global-message--error .mat-simple-snackbar-action {
  color: rgb(245, 219, 219) !important;
}
.blue-theme html,
.blue-theme body {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
  height: 100%;
}
.blue-theme body {
  margin: 0;
}
.blue-theme .ngx-summernote-view {
  display: none !important;
}
.blue-theme .content-wrapper {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1120px;
}
.blue-theme .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.blue-theme .mat-app-background {
  height: 100%;
  background-color: #f4f4f9;
}
.blue-theme .uppercase {
  text-transform: uppercase;
}
.blue-theme .capitalize {
  text-transform: capitalize;
}
.blue-theme .message-block {
  align-items: center;
  background-color: #848484;
  border-radius: 0.571rem;
  box-shadow: 0 10px 22px 0 rgba(0, 0, 0, 0.04);
  color: #fff;
  display: flex;
  font-size: 1.143rem;
  font-weight: 600;
  line-height: 1.5;
  overflow-wrap: anywhere;
  padding: 1.429rem 1.714rem;
  word-wrap: anywhere;
}
.blue-theme .message-block .mat-icon {
  flex: 0 0 auto;
  margin-right: 1.143rem;
}
.blue-theme .message-block.message-block__success {
  background-color: #003566;
  color: #ffffff;
}
.blue-theme .message-block.message-block__warning {
  background-color: #ffb025;
  color: #fff;
}
.blue-theme .message-block.message-block__error {
  background-color: #f34040;
  color: #fff;
}
.blue-theme .hidden,
.blue-theme *[hidden] {
  display: none !important;
  overflow: hidden !important;
}
.blue-theme .hidden.block,
.blue-theme *[hidden].block {
  display: hidden !important;
}
.blue-theme .inlineBlock {
  display: inline-block;
}
.blue-theme .underline {
  text-decoration: underline;
}
.blue-theme .opacity-1 {
  opacity: 1;
}
.blue-theme .opacity-08 {
  opacity: 0.8;
}
.blue-theme .opacity-06 {
  opacity: 0.6;
}
.blue-theme .opacity-04 {
  opacity: 0.4;
}
.blue-theme .opacity-02 {
  opacity: 0.2;
}
.blue-theme .opacity-0 {
  opacity: 0;
}
.blue-theme .overlay-spinner {
  background: rgba(255, 255, 255, 0.4);
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.blue-theme .overlay-spinner > .mat-spinner {
  margin: 3rem auto;
}
.blue-theme .spin-icon {
  animation: spinner 0.6s linear infinite;
}
.blue-theme .height-100 {
  height: 100%;
}
.blue-theme .note-modal-backdrop {
  display: none !important;
}
.blue-theme .note-editing-area {
  background-color: white;
}
.blue-theme .note-editor .note-editing-area .note-editable table.table-no-bordered td,
.blue-theme .note-editor .note-editing-area .note-editable table.table-no-bordered th {
  border: 0px;
}
.blue-theme .autocomplete-panel-class,
.blue-theme .notification-mention-menu {
  max-height: calc(var(--100vh) - 65px) !important;
  height: 550px !important;
}
.blue-theme .notification-mention-menu .mat-menu-content {
  height: calc(100% - 16px);
}
.blue-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #003566;
}
.blue-theme .mat-form-field-appearance-outline .mat-form-field-label {
  color: #003566;
  font-weight: 600;
  text-transform: uppercase;
}
.blue-theme .mat-form-field-appearance-outline input.mat-input-element {
  text-overflow: ellipsis;
}
.blue-theme .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  z-index: 1;
  margin-top: 0.2em;
}
.blue-theme .mat-form-field-appearance-outline.date-picker .mat-form-field-subscript-wrapper {
  margin-top: 2.2em;
}
.blue-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
  color: #000b14;
}
.blue-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-label {
  color: #000b14;
}
.blue-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: transparent;
}
.blue-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: #003566;
}
.blue-theme .mat-form-field-appearance-outline.mat-form-field-disabled input.mat-input-element {
  color: #121212;
}
.blue-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-form-field-infix,
.blue-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-form-field-suffix {
  padding: 0.25em 0 0.75em 0;
  font-size: 1em;
}
.blue-theme .smaller-mat-form-fields .mat-form-field-appearance-outline.date-picker .mat-form-field-infix,
.blue-theme .smaller-mat-form-fields .mat-form-field-appearance-outline.date-picker .mat-form-field-suffix {
  padding: 0.25em 0 0.6em 0;
  margin-top: -0.3em;
}
.blue-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-infix, .blue-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-suffix {
  padding: 0.2em 0 0.25em 0;
}
.blue-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-infix .mat-input-element, .blue-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-suffix .mat-input-element {
  position: absolute;
  margin-top: -11px;
}
.blue-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-form-field-infix .mat-select-arrow {
  margin-top: 10px;
}
.blue-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-select-empty + .mat-form-field-label-wrapper .mat-form-field-label {
  margin-top: -0.75em;
}
.blue-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-select + .mat-form-field-label-wrapper .mat-form-field-label {
  margin-top: -0.3em;
}
.mat-select .blue-theme .smaller-mat-form-fields__no-margin.mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding: 0 0;
  margin: 0 0;
}
.blue-theme .smaller-mat-form-fields__no-margin.mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding: 0 0;
  margin: 0 0;
}
.blue-theme .smaller-mat-form-fields .mat-form-field__no-border .mat-form-field-outline-start,
.blue-theme .smaller-mat-form-fields .mat-form-field__no-border .mat-form-field-outline-gap,
.blue-theme .smaller-mat-form-fields .mat-form-field__no-border .mat-form-field-outline-end {
  border: none;
}
.blue-theme .smaller-mat-form-fields .mat-form-field__border-more-radius .mat-form-field-outline-start, .blue-theme .smaller-mat-form-fields.mat-form-field__border-more-radius .mat-form-field-outline-start {
  border-radius: 28px 0 0 28px !important;
  min-width: 28px;
}
.blue-theme .smaller-mat-form-fields .mat-form-field__border-more-radius .mat-form-field-outline-end, .blue-theme .smaller-mat-form-fields.mat-form-field__border-more-radius .mat-form-field-outline-end {
  border-radius: 0 28px 28px 0 !important;
}
.blue-theme button.mat-button, .blue-theme button.mat-flat-button {
  font-size: 1rem;
}
.blue-theme button.mat-button.mat-button-fill, .blue-theme button.mat-flat-button.mat-button-fill {
  width: 100%;
}
.blue-theme button.mat-button-w-100-left {
  width: 100%;
  text-align: left;
}
.blue-theme button.mat-flat-button:hover {
  opacity: 0.85;
}
.blue-theme button.close-modal-button.mat-icon-button {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  background-color: rgba(110, 150, 255, 0.3);
  color: #001638;
}
.blue-theme button.close-modal-button.mat-icon-button:hover {
  background-color: rgba(110, 150, 255, 0.4);
}
.blue-theme button.mat-square-icon {
  border-radius: 5px;
  padding: 0 0;
  min-width: 36px;
  line-height: 36px;
}
.blue-theme button.mat-square-icon.square-28 {
  min-width: 28px;
  line-height: 28px;
}
.blue-theme button.mat-dark {
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
}
.blue-theme button.mat-dark:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
.blue-theme button .mat-icon {
  overflow: visible;
}
.blue-theme .mat-flat-button.mat-primary.mat-button-disabled,
.blue-theme .mat-flat-button.mat-button-disabled.mat-button-disabled,
.blue-theme .mat-raised-button.mat-primary.mat-button-disabled,
.blue-theme .mat-raised-button.mat-button-disabled.mat-button-disabled,
.blue-theme .mat-fab.mat-primary.mat-button-disabled,
.blue-theme .mat-fab.mat-button-disabled.mat-button-disabled,
.blue-theme .mat-mini-fab.mat-primary.mat-button-disabled,
.blue-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: #003566;
  color: #ffffff;
  opacity: 0.5;
}
.blue-theme .mat-button.v-align-bottom .mat-button-wrapper > *,
.blue-theme .mat-flat-button.v-align-bottom .mat-button-wrapper > *,
.blue-theme .mat-stroked-button.v-align-bottom .mat-button-wrapper > *,
.blue-theme .mat-raised-button.v-align-bottom .mat-button-wrapper > *,
.blue-theme .mat-icon-button.v-align-bottom .mat-button-wrapper > *,
.blue-theme .mat-fab.v-align-bottom .mat-button-wrapper > *,
.blue-theme .mat-mini-fab.v-align-bottom .mat-button-wrapper > * {
  vertical-align: text-bottom;
}
.blue-theme .mat-button.no-padding,
.blue-theme .mat-flat-button.no-padding,
.blue-theme .mat-stroked-button.no-padding,
.blue-theme .mat-raised-button.no-padding,
.blue-theme .mat-icon-button.no-padding,
.blue-theme .mat-fab.no-padding,
.blue-theme .mat-mini-fab.no-padding {
  padding: 0px;
}
.blue-theme mat-expansion-panel.no-padding .mat-expansion-panel-header {
  padding: 0 !important;
}
.blue-theme mat-expansion-panel.no-padding .mat-expansion-indicator {
  padding: 0 10px;
}
.blue-theme .mat-expansion-panel-header {
  border-radius: initial;
  border-bottom: 1px solid #b3c2d1;
}
.blue-theme .mat-expansion-panel-header.mat-expanded {
  height: 49px;
}
.blue-theme .mat-expansion-panel-header .mat-expansion-indicator {
  border-width: 0 3px 3px 0;
  color: #000000;
}
.blue-theme .mat-expansion-panel-header .mat-expansion-panel-header-title {
  color: #003566;
  font-size: 1rem;
  font-weight: 600;
}
.blue-theme .header-color-primary .mat-expansion-panel-header {
  background-color: #003566 !important;
}
.blue-theme .header-color-primary .mat-expansion-panel-header:hover {
  background-color: #00305e !important;
}
.blue-theme .header-color-primary .mat-expansion-indicator::after {
  color: #ffffff;
}
.blue-theme .header-color-primary .mat-expansion-panel-header-title {
  color: #ffffff;
}
.blue-theme .mat-expanded > .mat-expansion-panel-content {
  padding-top: 16px;
}
.blue-theme mat-expansion-panel.no-padding .mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0 0 16px 0;
}
.blue-theme .mat-select-panel.bigger-selection-panel-class {
  max-height: calc(var(--100vh) - 100px);
}
.blue-theme .mat-select-panel.bigger-selection-panel-class .mat-optgroup-label {
  overflow: visible !important;
}
.blue-theme .mat-tab-page-sections .mat-tab-header {
  border-bottom: 0px;
}
.blue-theme .mat-tab-page-sections .mat-tab-header .mat-tab-label {
  font-size: 1.5rem;
  font-weight: 400;
  padding: 0 15px;
}
.blue-theme .mat-tab-page-sections.font-size-smaller .mat-tab-header .mat-tab-label {
  font-size: 1.1rem;
  font-weight: 200;
}
.blue-theme .mat-tab-group.mat-primary .mat-ink-bar,
.blue-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  height: 3px;
  background-color: #003566;
}
.blue-theme .mat-tab-label.text-red,
.blue-theme .mat-tab-label.mat-tab-label-active.text-red {
  color: #c10c0c;
}
.blue-theme .mat-item-card {
  margin-bottom: 20px;
  max-width: calc(50% - 20px);
}
.blue-theme .mat-item-card__title {
  font-size: 1rem;
  color: #26273b;
}
.blue-theme .mat-item-card__icons .mat-icon {
  padding-right: 24px;
  color: #003566;
}
.blue-theme .mat-item-card__icons .mat-icon:last-child {
  padding-right: 0;
}
.blue-theme .mat-table {
  background-color: transparent;
}
.blue-theme .mat-table.table-ellipsis {
  width: 100%;
  table-layout: fixed;
}
.blue-theme .mat-table.table-ellipsis th,
.blue-theme .mat-table.table-ellipsis td {
  overflow: hidden;
  width: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 10px;
}
.blue-theme .mat-table.table-ellipsis th.no-ellipsis,
.blue-theme .mat-table.table-ellipsis td.no-ellipsis {
  overflow: inherit;
}
.blue-theme .mat-table .mat-header-row .mat-header-cell {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  color: #000000;
}
.blue-theme .mat-table tr.mat-row {
  background-color: white;
  height: 65px;
}
.blue-theme .mat-table tr.mat-row .mat-cell {
  font-size: 16px;
  color: #26273b;
}
.blue-theme .mat-table tr.mat-row .mat-cell.action-buttons {
  color: #003566;
  font-size: 24px;
}
.blue-theme .mat-paginator {
  background-color: transparent;
}
.blue-theme .mat-checkbox-layout {
  white-space: normal !important;
}
.blue-theme mat-checkbox.light-border .mat-checkbox-frame {
  border-color: #ffffff;
}
.blue-theme .mat-tooltip {
  font-size: 0.9em;
  max-width: 350px !important;
}
.blue-theme .mat-menu-panel.dark-theme {
  min-width: 150px;
  background-color: #000000;
}
.blue-theme .mat-menu-panel.dark-theme .mat-menu-item {
  color: #ffffff;
}
.blue-theme .mat-menu-panel.dark-theme .mat-divider {
  background-color: #000000;
}
.blue-theme .draggable-item {
  border-radius: 5px;
  background-color: #f4f4f9;
  margin: 5px 0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.blue-theme .draggable-item .cdk-drag-handle {
  cursor: grab;
}
.blue-theme .draggable-item .cdk-drag-handle:active {
  cursor: grabbing;
}
.blue-theme .draggable-item.cdk-drag-preview {
  pointer-events: fill !important;
  box-shadow: 0px 0px 25px 0px rgb(0, 0, 0);
}
.blue-theme .draggable-item.cdk-drag-preview .cdk-drag-handle {
  cursor: grabbing;
}
.blue-theme .remove-padding mat-dialog-container {
  padding: 0px;
}
.blue-theme .mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Saira;
}
.blue-theme .mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.blue-theme .mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.blue-theme .mat-h1,
.blue-theme .mat-headline,
.blue-theme .mat-typography .mat-h1,
.blue-theme .mat-typography .mat-headline,
.blue-theme .mat-typography h1 {
  font: 900 24px / 32px Saira;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.blue-theme .mat-h2,
.blue-theme .mat-title,
.blue-theme .mat-typography .mat-h2,
.blue-theme .mat-typography .mat-title,
.blue-theme .mat-typography h2 {
  font: 600 20px / 28px Saira;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.blue-theme .mat-h3,
.blue-theme .mat-subheading-2,
.blue-theme .mat-typography .mat-h3,
.blue-theme .mat-typography .mat-subheading-2,
.blue-theme .mat-typography h3 {
  font: 400 16px / 28px Saira;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.blue-theme .mat-h4,
.blue-theme .mat-subheading-1,
.blue-theme .mat-typography .mat-h4,
.blue-theme .mat-typography .mat-subheading-1,
.blue-theme .mat-typography h4 {
  font: 600 14px / 1em Saira;
  letter-spacing: 3px;
  margin: 0 0 16px;
}
.blue-theme .mat-h5,
.blue-theme .mat-typography .mat-h5,
.blue-theme .mat-typography h5 {
  font: 400 calc(16px * 0.83) / 20px Saira;
  margin: 0 0 12px;
}
.blue-theme .mat-h6,
.blue-theme .mat-typography .mat-h6,
.blue-theme .mat-typography h6 {
  font: 400 calc(16px * 0.67) / 20px Saira;
  margin: 0 0 12px;
}
.blue-theme .mat-body-strong,
.blue-theme .mat-body-2,
.blue-theme .mat-typography .mat-body-strong,
.blue-theme .mat-typography .mat-body-2 {
  font: 400 18px / 26px Saira;
  letter-spacing: normal;
}
.blue-theme .mat-body,
.blue-theme .mat-body-1,
.blue-theme .mat-typography .mat-body,
.blue-theme .mat-typography .mat-body-1,
.blue-theme .mat-typography {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
}
.blue-theme .mat-body p,
.blue-theme .mat-body-1 p,
.blue-theme .mat-typography .mat-body p,
.blue-theme .mat-typography .mat-body-1 p,
.blue-theme .mat-typography p {
  margin: 0 0 12px;
}
.blue-theme .mat-small,
.blue-theme .mat-caption,
.blue-theme .mat-typography .mat-small,
.blue-theme .mat-typography .mat-caption {
  font: 400 12px / 20px Saira;
  letter-spacing: normal;
}
.blue-theme .mat-display-4,
.blue-theme .mat-typography .mat-display-4 {
  font: 400 112px / 112px Saira;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.blue-theme .mat-display-3,
.blue-theme .mat-typography .mat-display-3 {
  font: 900 56px / 56px Saira;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.blue-theme .mat-display-2,
.blue-theme .mat-typography .mat-display-2 {
  font: 900 40px / 1.2 Saira;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.blue-theme .mat-display-1,
.blue-theme .mat-typography .mat-display-1 {
  font: 600 32px / 1.13 Saira;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.blue-theme .mat-bottom-sheet-container {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
}
.blue-theme .mat-button, .blue-theme .mat-raised-button, .blue-theme .mat-icon-button, .blue-theme .mat-stroked-button,
.blue-theme .mat-flat-button, .blue-theme .mat-fab, .blue-theme .mat-mini-fab {
  font-family: Saira;
  font-size: 14px;
  font-weight: 600;
}
.blue-theme .mat-button-toggle {
  font-family: Saira;
}
.blue-theme .mat-card {
  font-family: Saira;
}
.blue-theme .mat-card-title {
  font-size: 24px;
  font-weight: 600;
}
.blue-theme .mat-card-header .mat-card-title {
  font-size: 20px;
}
.blue-theme .mat-card-subtitle,
.blue-theme .mat-card-content {
  font-size: 16px;
}
.blue-theme .mat-checkbox {
  font-family: Saira;
}
.blue-theme .mat-checkbox-layout .mat-checkbox-label {
  line-height: 26px;
}
.blue-theme .mat-chip {
  font-size: 18px;
  font-weight: 400;
}
.blue-theme .mat-chip .mat-chip-trailing-icon.mat-icon,
.blue-theme .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.blue-theme .mat-table {
  font-family: Saira;
}
.blue-theme .mat-header-cell {
  font-size: 12px;
  font-weight: 400;
}
.blue-theme .mat-cell, .blue-theme .mat-footer-cell {
  font-size: 16px;
}
.blue-theme .mat-calendar {
  font-family: Saira;
}
.blue-theme .mat-calendar-body {
  font-size: 13px;
}
.blue-theme .mat-calendar-body-label,
.blue-theme .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 600;
}
.blue-theme .mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.blue-theme .mat-dialog-title {
  font: 600 20px / 28px Saira;
  letter-spacing: normal;
}
.blue-theme .mat-expansion-panel-header {
  font-family: Saira;
  font-size: 14px;
  font-weight: 600;
}
.blue-theme .mat-expansion-panel-content {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
}
.blue-theme .mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Saira;
  letter-spacing: normal;
}
.blue-theme .mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.blue-theme .mat-form-field-prefix .mat-icon,
.blue-theme .mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.blue-theme .mat-form-field-prefix .mat-icon-button,
.blue-theme .mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.blue-theme .mat-form-field-prefix .mat-icon-button .mat-icon,
.blue-theme .mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.blue-theme .mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.blue-theme .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.blue-theme .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34371em) scale(0.75);
  width: 133.3333733333%;
}
.blue-theme .mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.3437em) scale(0.75);
  width: 133.3333833333%;
}
.blue-theme .mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.blue-theme .mat-form-field-label {
  top: 1.34375em;
}
.blue-theme .mat-form-field-underline {
  bottom: 1.34375em;
}
.blue-theme .mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.blue-theme .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.blue-theme .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.blue-theme .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.blue-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00112px);
  width: 133.3334533333%;
}
.blue-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00113px);
  width: 133.3334633333%;
}
.blue-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00114px);
  width: 133.3334733333%;
}
.blue-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.blue-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.blue-theme .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .blue-theme .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.blue-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2811em) scale(0.75);
  }
  .blue-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28109em) scale(0.75);
  }
  .blue-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28108em) scale(0.75);
  }
}
.blue-theme .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.blue-theme .mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.blue-theme .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.blue-theme .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59371em) scale(0.75);
  width: 133.3333733333%;
}
.blue-theme .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.5937em) scale(0.75);
  width: 133.3333833333%;
}
.blue-theme .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.blue-theme .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.blue-theme .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.blue-theme .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59371em) scale(0.75);
  width: 133.3333733333%;
}
.blue-theme .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.5937em) scale(0.75);
  width: 133.3333833333%;
}
.blue-theme .mat-grid-tile-header,
.blue-theme .mat-grid-tile-footer {
  font-size: 16px;
}
.blue-theme .mat-grid-tile-header .mat-line,
.blue-theme .mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.blue-theme .mat-grid-tile-header .mat-line:nth-child(n+2),
.blue-theme .mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.blue-theme input.mat-input-element {
  margin-top: -0.0625em;
}
.blue-theme .mat-menu-item {
  font-family: Saira;
  font-size: 16px;
  font-weight: 400;
}
.blue-theme .mat-paginator,
.blue-theme .mat-paginator-page-size .mat-select-trigger {
  font-family: Saira;
  font-size: 12px;
}
.blue-theme .mat-radio-button {
  font-family: Saira;
}
.blue-theme .mat-select {
  font-family: Saira;
}
.blue-theme .mat-select-trigger {
  height: 1.125em;
}
.blue-theme .mat-slide-toggle-content {
  font-family: Saira;
}
.blue-theme .mat-slider-thumb-label-text {
  font-family: Saira;
  font-size: 12px;
  font-weight: 400;
}
.blue-theme .mat-stepper-vertical, .blue-theme .mat-stepper-horizontal {
  font-family: Saira;
}
.blue-theme .mat-step-label {
  font-size: 16px;
  font-weight: 400;
}
.blue-theme .mat-step-sub-label-error {
  font-weight: normal;
}
.blue-theme .mat-step-label-error {
  font-size: 18px;
}
.blue-theme .mat-step-label-selected {
  font-size: 18px;
  font-weight: 400;
}
.blue-theme .mat-tab-group {
  font-family: Saira;
}
.blue-theme .mat-tab-label, .blue-theme .mat-tab-link {
  font-family: Saira;
  font-size: 14px;
  font-weight: 600;
}
.blue-theme .mat-toolbar,
.blue-theme .mat-toolbar h1,
.blue-theme .mat-toolbar h2,
.blue-theme .mat-toolbar h3,
.blue-theme .mat-toolbar h4,
.blue-theme .mat-toolbar h5,
.blue-theme .mat-toolbar h6 {
  font: 600 20px / 28px Saira;
  letter-spacing: normal;
  margin: 0;
}
.blue-theme .mat-tooltip {
  font-family: Saira;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.blue-theme .mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.blue-theme .mat-list-item {
  font-family: Saira;
}
.blue-theme .mat-list-option {
  font-family: Saira;
}
.blue-theme .mat-list-base .mat-list-item {
  font-size: 16px;
}
.blue-theme .mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.blue-theme .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.blue-theme .mat-list-base .mat-list-option {
  font-size: 16px;
}
.blue-theme .mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.blue-theme .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.blue-theme .mat-list-base .mat-subheader {
  font-family: Saira;
  font-size: 18px;
  font-weight: 400;
}
.blue-theme .mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.blue-theme .mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.blue-theme .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.blue-theme .mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.blue-theme .mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.blue-theme .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.blue-theme .mat-list-base[dense] .mat-subheader {
  font-family: Saira;
  font-size: 12px;
  font-weight: 400;
}
.blue-theme .mat-option {
  font-family: Saira;
  font-size: 16px;
}
.blue-theme .mat-optgroup-label {
  font: 400 18px / 26px Saira;
  letter-spacing: normal;
}
.blue-theme .mat-simple-snackbar {
  font-family: Saira;
  font-size: 16px;
}
.blue-theme .mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 600;
}
.blue-theme .mat-tree {
  font-family: Saira;
}
.blue-theme .mat-tree-node,
.blue-theme .mat-nested-tree-node {
  font-weight: 400;
  font-size: 16px;
}
.blue-theme .mat-ripple {
  overflow: hidden;
  position: relative;
}
.blue-theme .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.blue-theme .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.blue-theme .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .blue-theme .mat-ripple-element {
  display: none;
}

.blue-theme .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .blue-theme .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.blue-theme .cdk-overlay-container, .blue-theme .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.blue-theme .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.blue-theme .cdk-overlay-container:empty {
  display: none;
}
.blue-theme .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.blue-theme .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.blue-theme .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.blue-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .blue-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.blue-theme .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.blue-theme .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.blue-theme .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.blue-theme .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.blue-theme .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.blue-theme .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.blue-theme textarea.cdk-textarea-autosize {
  resize: none;
}
.blue-theme textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.blue-theme textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.blue-theme .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.blue-theme .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.blue-theme .mat-focus-indicator {
  position: relative;
}
.blue-theme .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.blue-theme .mat-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .blue-theme {
  --mat-focus-indicator-display: block;
}

.blue-theme .mat-mdc-focus-indicator {
  position: relative;
}
.blue-theme .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.blue-theme .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .blue-theme {
  --mat-mdc-focus-indicator-display: block;
}

.blue-theme .mat-ripple-element {
  background-color: rgba(18, 18, 18, 0.1);
}
.blue-theme .mat-option {
  color: #121212;
}
.blue-theme .mat-option:hover:not(.mat-option-disabled), .blue-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.blue-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.blue-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: #121212;
}
.blue-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.blue-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #003566;
}
.blue-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #9fa8da;
}
.blue-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e81253;
}
.blue-theme .mat-optgroup-label {
  color: #26273b;
}
.blue-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.blue-theme .mat-pseudo-checkbox {
  color: #26273b;
}
.blue-theme .mat-pseudo-checkbox::after {
  color: white;
}
.blue-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.blue-theme .mat-primary .mat-pseudo-checkbox-checked,
.blue-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #003566;
}
.blue-theme .mat-pseudo-checkbox-checked,
.blue-theme .mat-pseudo-checkbox-indeterminate,
.blue-theme .mat-accent .mat-pseudo-checkbox-checked,
.blue-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #9fa8da;
}
.blue-theme .mat-warn .mat-pseudo-checkbox-checked,
.blue-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e81253;
}
.blue-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.blue-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.blue-theme .mat-app-background, .blue-theme.mat-app-background {
  background-color: white;
  color: #121212;
}
.blue-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(18, 18, 18, 0.2), 0px 1px 1px 0px rgba(18, 18, 18, 0.14), 0px 1px 3px 0px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(18, 18, 18, 0.2), 0px 3px 4px 0px rgba(18, 18, 18, 0.14), 0px 1px 8px 0px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 5px 8px 0px rgba(18, 18, 18, 0.14), 0px 1px 14px 0px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 6px 10px 0px rgba(18, 18, 18, 0.14), 0px 1px 18px 0px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(18, 18, 18, 0.2), 0px 7px 10px 1px rgba(18, 18, 18, 0.14), 0px 2px 16px 1px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(18, 18, 18, 0.2), 0px 8px 10px 1px rgba(18, 18, 18, 0.14), 0px 3px 14px 2px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(18, 18, 18, 0.2), 0px 9px 12px 1px rgba(18, 18, 18, 0.14), 0px 3px 16px 2px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(18, 18, 18, 0.2), 0px 10px 14px 1px rgba(18, 18, 18, 0.14), 0px 4px 18px 3px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(18, 18, 18, 0.2), 0px 11px 15px 1px rgba(18, 18, 18, 0.14), 0px 4px 20px 3px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(18, 18, 18, 0.2), 0px 12px 17px 2px rgba(18, 18, 18, 0.14), 0px 5px 22px 4px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(18, 18, 18, 0.2), 0px 13px 19px 2px rgba(18, 18, 18, 0.14), 0px 5px 24px 4px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(18, 18, 18, 0.2), 0px 14px 21px 2px rgba(18, 18, 18, 0.14), 0px 5px 26px 4px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(18, 18, 18, 0.2), 0px 15px 22px 2px rgba(18, 18, 18, 0.14), 0px 6px 28px 5px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(18, 18, 18, 0.2), 0px 16px 24px 2px rgba(18, 18, 18, 0.14), 0px 6px 30px 5px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(18, 18, 18, 0.2), 0px 17px 26px 2px rgba(18, 18, 18, 0.14), 0px 6px 32px 5px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(18, 18, 18, 0.2), 0px 18px 28px 2px rgba(18, 18, 18, 0.14), 0px 7px 34px 6px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(18, 18, 18, 0.2), 0px 19px 29px 2px rgba(18, 18, 18, 0.14), 0px 7px 36px 6px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(18, 18, 18, 0.2), 0px 20px 31px 3px rgba(18, 18, 18, 0.14), 0px 8px 38px 7px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(18, 18, 18, 0.2), 0px 21px 33px 3px rgba(18, 18, 18, 0.14), 0px 8px 40px 7px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(18, 18, 18, 0.2), 0px 22px 35px 3px rgba(18, 18, 18, 0.14), 0px 8px 42px 7px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(18, 18, 18, 0.2), 0px 23px 36px 3px rgba(18, 18, 18, 0.14), 0px 9px 44px 8px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(18, 18, 18, 0.2), 0px 24px 38px 3px rgba(18, 18, 18, 0.14), 0px 9px 46px 8px rgba(18, 18, 18, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.blue-theme .mat-autocomplete-panel {
  background: white;
  color: #121212;
}
.blue-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.blue-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: #121212;
}
.blue-theme .mat-badge-content {
  color: white;
  background: #003566;
}
.cdk-high-contrast-active .blue-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.blue-theme .mat-badge-accent .mat-badge-content {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.blue-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e81253;
}
.blue-theme .mat-badge-disabled .mat-badge-content {
  background: #c1c1c1;
  color: rgba(0, 0, 0, 0.38);
}
.blue-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(18, 18, 18, 0.2), 0px 16px 24px 2px rgba(18, 18, 18, 0.14), 0px 6px 30px 5px rgba(18, 18, 18, 0.12);
  background: white;
  color: #121212;
}
.blue-theme .mat-button, .blue-theme .mat-icon-button, .blue-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.blue-theme .mat-button.mat-primary, .blue-theme .mat-icon-button.mat-primary, .blue-theme .mat-stroked-button.mat-primary {
  color: #003566;
}
.blue-theme .mat-button.mat-accent, .blue-theme .mat-icon-button.mat-accent, .blue-theme .mat-stroked-button.mat-accent {
  color: #9fa8da;
}
.blue-theme .mat-button.mat-warn, .blue-theme .mat-icon-button.mat-warn, .blue-theme .mat-stroked-button.mat-warn {
  color: #e81253;
}
.blue-theme .mat-button.mat-primary.mat-button-disabled, .blue-theme .mat-button.mat-accent.mat-button-disabled, .blue-theme .mat-button.mat-warn.mat-button-disabled, .blue-theme .mat-button.mat-button-disabled.mat-button-disabled, .blue-theme .mat-icon-button.mat-primary.mat-button-disabled, .blue-theme .mat-icon-button.mat-accent.mat-button-disabled, .blue-theme .mat-icon-button.mat-warn.mat-button-disabled, .blue-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .blue-theme .mat-stroked-button.mat-primary.mat-button-disabled, .blue-theme .mat-stroked-button.mat-accent.mat-button-disabled, .blue-theme .mat-stroked-button.mat-warn.mat-button-disabled, .blue-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(18, 18, 18, 0.26);
}
.blue-theme .mat-button.mat-primary .mat-button-focus-overlay, .blue-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .blue-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #003566;
}
.blue-theme .mat-button.mat-accent .mat-button-focus-overlay, .blue-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .blue-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #9fa8da;
}
.blue-theme .mat-button.mat-warn .mat-button-focus-overlay, .blue-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .blue-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e81253;
}
.blue-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .blue-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .blue-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.blue-theme .mat-button .mat-ripple-element, .blue-theme .mat-icon-button .mat-ripple-element, .blue-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.blue-theme .mat-button-focus-overlay {
  background: #121212;
}
.blue-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.blue-theme .mat-flat-button, .blue-theme .mat-raised-button, .blue-theme .mat-fab, .blue-theme .mat-mini-fab {
  color: #121212;
  background-color: white;
}
.blue-theme .mat-flat-button.mat-primary, .blue-theme .mat-raised-button.mat-primary, .blue-theme .mat-fab.mat-primary, .blue-theme .mat-mini-fab.mat-primary {
  color: white;
}
.blue-theme .mat-flat-button.mat-accent, .blue-theme .mat-raised-button.mat-accent, .blue-theme .mat-fab.mat-accent, .blue-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.blue-theme .mat-flat-button.mat-warn, .blue-theme .mat-raised-button.mat-warn, .blue-theme .mat-fab.mat-warn, .blue-theme .mat-mini-fab.mat-warn {
  color: white;
}
.blue-theme .mat-flat-button.mat-primary.mat-button-disabled, .blue-theme .mat-flat-button.mat-accent.mat-button-disabled, .blue-theme .mat-flat-button.mat-warn.mat-button-disabled, .blue-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .blue-theme .mat-raised-button.mat-primary.mat-button-disabled, .blue-theme .mat-raised-button.mat-accent.mat-button-disabled, .blue-theme .mat-raised-button.mat-warn.mat-button-disabled, .blue-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .blue-theme .mat-fab.mat-primary.mat-button-disabled, .blue-theme .mat-fab.mat-accent.mat-button-disabled, .blue-theme .mat-fab.mat-warn.mat-button-disabled, .blue-theme .mat-fab.mat-button-disabled.mat-button-disabled, .blue-theme .mat-mini-fab.mat-primary.mat-button-disabled, .blue-theme .mat-mini-fab.mat-accent.mat-button-disabled, .blue-theme .mat-mini-fab.mat-warn.mat-button-disabled, .blue-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(18, 18, 18, 0.26);
}
.blue-theme .mat-flat-button.mat-primary, .blue-theme .mat-raised-button.mat-primary, .blue-theme .mat-fab.mat-primary, .blue-theme .mat-mini-fab.mat-primary {
  background-color: #003566;
}
.blue-theme .mat-flat-button.mat-accent, .blue-theme .mat-raised-button.mat-accent, .blue-theme .mat-fab.mat-accent, .blue-theme .mat-mini-fab.mat-accent {
  background-color: #9fa8da;
}
.blue-theme .mat-flat-button.mat-warn, .blue-theme .mat-raised-button.mat-warn, .blue-theme .mat-fab.mat-warn, .blue-theme .mat-mini-fab.mat-warn {
  background-color: #e81253;
}
.blue-theme .mat-flat-button.mat-primary.mat-button-disabled, .blue-theme .mat-flat-button.mat-accent.mat-button-disabled, .blue-theme .mat-flat-button.mat-warn.mat-button-disabled, .blue-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .blue-theme .mat-raised-button.mat-primary.mat-button-disabled, .blue-theme .mat-raised-button.mat-accent.mat-button-disabled, .blue-theme .mat-raised-button.mat-warn.mat-button-disabled, .blue-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .blue-theme .mat-fab.mat-primary.mat-button-disabled, .blue-theme .mat-fab.mat-accent.mat-button-disabled, .blue-theme .mat-fab.mat-warn.mat-button-disabled, .blue-theme .mat-fab.mat-button-disabled.mat-button-disabled, .blue-theme .mat-mini-fab.mat-primary.mat-button-disabled, .blue-theme .mat-mini-fab.mat-accent.mat-button-disabled, .blue-theme .mat-mini-fab.mat-warn.mat-button-disabled, .blue-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: #f7f7f7;
}
.blue-theme .mat-flat-button.mat-primary .mat-ripple-element, .blue-theme .mat-raised-button.mat-primary .mat-ripple-element, .blue-theme .mat-fab.mat-primary .mat-ripple-element, .blue-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.blue-theme .mat-flat-button.mat-accent .mat-ripple-element, .blue-theme .mat-raised-button.mat-accent .mat-ripple-element, .blue-theme .mat-fab.mat-accent .mat-ripple-element, .blue-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.blue-theme .mat-flat-button.mat-warn .mat-ripple-element, .blue-theme .mat-raised-button.mat-warn .mat-ripple-element, .blue-theme .mat-fab.mat-warn .mat-ripple-element, .blue-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.blue-theme .mat-stroked-button:not([class*=mat-elevation-z]), .blue-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(18, 18, 18, 0.2), 0px 8px 10px 1px rgba(18, 18, 18, 0.14), 0px 3px 14px 2px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-fab:not([class*=mat-elevation-z]), .blue-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 6px 10px 0px rgba(18, 18, 18, 0.14), 0px 1px 18px 0px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .blue-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(18, 18, 18, 0.2), 0px 12px 17px 2px rgba(18, 18, 18, 0.14), 0px 5px 22px 4px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .blue-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.blue-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.blue-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.blue-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.blue-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.blue-theme .mat-button-toggle-appearance-standard {
  color: #121212;
  background: white;
}
.blue-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.blue-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.blue-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.blue-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.blue-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: #26273b;
}
.blue-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #121212;
}
.blue-theme .mat-button-toggle-disabled {
  color: rgba(18, 18, 18, 0.26);
  background-color: #eeeeee;
}
.blue-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.blue-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.blue-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.blue-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.blue-theme .mat-card {
  background: white;
  color: #121212;
}
.blue-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(18, 18, 18, 0.2), 0px 1px 1px 0px rgba(18, 18, 18, 0.14), 0px 1px 3px 0px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-card-subtitle {
  color: #26273b;
}
.blue-theme .mat-checkbox-frame {
  border-color: #26273b;
}
.blue-theme .mat-checkbox-checkmark {
  fill: white;
}
.blue-theme .mat-checkbox-checkmark-path {
  stroke: white !important;
}
.blue-theme .mat-checkbox-mixedmark {
  background-color: white;
}
.blue-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .blue-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #003566;
}
.blue-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .blue-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #9fa8da;
}
.blue-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .blue-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e81253;
}
.blue-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .blue-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.blue-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.blue-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.blue-theme .mat-checkbox .mat-ripple-element {
  background-color: #121212;
}
.blue-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.blue-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #003566;
}
.blue-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.blue-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #9fa8da;
}
.blue-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.blue-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e81253;
}
.blue-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: #121212;
}
.blue-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: #121212;
  opacity: 0.4;
}
.blue-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(18, 18, 18, 0.2), 0px 3px 4px 0px rgba(18, 18, 18, 0.14), 0px 1px 8px 0px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.blue-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.blue-theme .mat-chip.mat-standard-chip::after {
  background: #121212;
}
.blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #003566;
  color: white;
}
.blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e81253;
  color: white;
}
.blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.blue-theme .mat-table {
  background: white;
}
.blue-theme .mat-table thead, .blue-theme .mat-table tbody, .blue-theme .mat-table tfoot,
.blue-theme mat-header-row, .blue-theme mat-row, .blue-theme mat-footer-row,
.blue-theme [mat-header-row], .blue-theme [mat-row], .blue-theme [mat-footer-row],
.blue-theme .mat-table-sticky {
  background: inherit;
}
.blue-theme mat-row, .blue-theme mat-header-row, .blue-theme mat-footer-row,
.blue-theme th.mat-header-cell, .blue-theme td.mat-cell, .blue-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.blue-theme .mat-header-cell {
  color: #26273b;
}
.blue-theme .mat-cell, .blue-theme .mat-footer-cell {
  color: #121212;
}
.blue-theme .mat-calendar-arrow {
  fill: rgba(18, 18, 18, 0.54);
}
.blue-theme .mat-datepicker-toggle,
.blue-theme .mat-datepicker-content .mat-calendar-next-button,
.blue-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(18, 18, 18, 0.54);
}
.blue-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.blue-theme .mat-calendar-table-header,
.blue-theme .mat-calendar-body-label {
  color: #26273b;
}
.blue-theme .mat-calendar-body-cell-content,
.blue-theme .mat-date-range-input-separator {
  color: #121212;
  border-color: transparent;
}
.blue-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.blue-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.blue-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.blue-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.blue-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.blue-theme .mat-calendar-body-in-range::before {
  background: rgba(0, 53, 102, 0.2);
}
.blue-theme .mat-calendar-body-comparison-identical,
.blue-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.blue-theme .mat-calendar-body-comparison-bridge-start::before,
.blue-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 53, 102, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.blue-theme .mat-calendar-body-comparison-bridge-end::before,
.blue-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 53, 102, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.blue-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.blue-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.blue-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.blue-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.blue-theme .mat-calendar-body-selected {
  background-color: #003566;
  color: white;
}
.blue-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 53, 102, 0.4);
}
.blue-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.blue-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.blue-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 53, 102, 0.3);
}
@media (hover: hover) {
  .blue-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 53, 102, 0.3);
  }
}
.blue-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
  background-color: white;
  color: #121212;
}
.blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(159, 168, 218, 0.2);
}
.blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.blue-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(159, 168, 218, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.blue-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(159, 168, 218, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(159, 168, 218, 0.4);
}
.blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.blue-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.blue-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(159, 168, 218, 0.3);
}
@media (hover: hover) {
  .blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(159, 168, 218, 0.3);
  }
}
.blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(232, 18, 83, 0.2);
}
.blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.blue-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(232, 18, 83, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.blue-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(232, 18, 83, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e81253;
  color: white;
}
.blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(232, 18, 83, 0.4);
}
.blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.blue-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.blue-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(232, 18, 83, 0.3);
}
@media (hover: hover) {
  .blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(232, 18, 83, 0.3);
  }
}
.blue-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(18, 18, 18, 0.2), 0px 24px 38px 3px rgba(18, 18, 18, 0.14), 0px 9px 46px 8px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-datepicker-toggle-active {
  color: #003566;
}
.blue-theme .mat-datepicker-toggle-active.mat-accent {
  color: #9fa8da;
}
.blue-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e81253;
}
.blue-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.blue-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(18, 18, 18, 0.2), 0px 24px 38px 3px rgba(18, 18, 18, 0.14), 0px 9px 46px 8px rgba(18, 18, 18, 0.12);
  background: white;
  color: #121212;
}
.blue-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.blue-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.blue-theme .mat-expansion-panel {
  background: white;
  color: #121212;
}
.blue-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.blue-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .blue-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .blue-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .blue-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.blue-theme .mat-expansion-panel-header-title {
  color: #121212;
}
.blue-theme .mat-expansion-panel-header-description,
.blue-theme .mat-expansion-indicator::after {
  color: #26273b;
}
.blue-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(18, 18, 18, 0.26);
}
.blue-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.blue-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.blue-theme .mat-form-field-label {
  color: rgba(38, 39, 59, 0.6);
}
.blue-theme .mat-hint {
  color: rgba(38, 39, 59, 0.6);
}
.blue-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #003566;
}
.blue-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #9fa8da;
}
.blue-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e81253;
}
.blue-theme .mat-focused .mat-form-field-required-marker {
  color: #9fa8da;
}
.blue-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.blue-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #003566;
}
.blue-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #9fa8da;
}
.blue-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e81253;
}
.blue-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #003566;
}
.blue-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #9fa8da;
}
.blue-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e81253;
}
.blue-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e81253;
}
.blue-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.blue-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e81253;
}
.blue-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.blue-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e81253;
}
.blue-theme .mat-error {
  color: #e81253;
}
.blue-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: #26273b;
}
.blue-theme .mat-form-field-appearance-legacy .mat-hint {
  color: #26273b;
}
.blue-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.blue-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.blue-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.blue-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.blue-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(18, 18, 18, 0.04);
}
.blue-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(18, 18, 18, 0.02);
}
.blue-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.blue-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.blue-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.blue-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.blue-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.blue-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #003566;
}
.blue-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #9fa8da;
}
.blue-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e81253;
}
.blue-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e81253;
}
.blue-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.blue-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.blue-theme .mat-icon.mat-primary {
  color: #003566;
}
.blue-theme .mat-icon.mat-accent {
  color: #9fa8da;
}
.blue-theme .mat-icon.mat-warn {
  color: #e81253;
}
.blue-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: #26273b;
}
.blue-theme .mat-input-element:disabled,
.blue-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.blue-theme .mat-input-element {
  caret-color: #003566;
}
.blue-theme .mat-input-element::placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.blue-theme .mat-input-element::-moz-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.blue-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.blue-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.blue-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #9fa8da;
}
.blue-theme .mat-form-field.mat-warn .mat-input-element,
.blue-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e81253;
}
.blue-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e81253;
}
.blue-theme .mat-list-base .mat-list-item {
  color: #121212;
}
.blue-theme .mat-list-base .mat-list-option {
  color: #121212;
}
.blue-theme .mat-list-base .mat-subheader {
  color: #26273b;
}
.blue-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.blue-theme .mat-list-option:hover, .blue-theme .mat-list-option:focus,
.blue-theme .mat-nav-list .mat-list-item:hover,
.blue-theme .mat-nav-list .mat-list-item:focus,
.blue-theme .mat-action-list .mat-list-item:hover,
.blue-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.blue-theme .mat-list-single-selected-option, .blue-theme .mat-list-single-selected-option:hover, .blue-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.blue-theme .mat-menu-panel {
  background: white;
}
.blue-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-menu-item {
  background: transparent;
  color: #121212;
}
.blue-theme .mat-menu-item[disabled],
.blue-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.blue-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.blue-theme .mat-menu-item .mat-icon-no-color,
.blue-theme .mat-menu-submenu-icon {
  color: rgba(18, 18, 18, 0.54);
}
.blue-theme .mat-menu-item:hover:not([disabled]),
.blue-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.blue-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.blue-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.blue-theme .mat-paginator {
  background: white;
}
.blue-theme .mat-paginator,
.blue-theme .mat-paginator-page-size .mat-select-trigger {
  color: #26273b;
}
.blue-theme .mat-paginator-decrement,
.blue-theme .mat-paginator-increment {
  border-top: 2px solid rgba(18, 18, 18, 0.54);
  border-right: 2px solid rgba(18, 18, 18, 0.54);
}
.blue-theme .mat-paginator-first,
.blue-theme .mat-paginator-last {
  border-top: 2px solid rgba(18, 18, 18, 0.54);
}
.blue-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.blue-theme .mat-icon-button[disabled] .mat-paginator-increment,
.blue-theme .mat-icon-button[disabled] .mat-paginator-first,
.blue-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.blue-theme .mat-progress-bar-background {
  fill: #bfcdd9;
}
.blue-theme .mat-progress-bar-buffer {
  background-color: #bfcdd9;
}
.blue-theme .mat-progress-bar-fill::after {
  background-color: #003566;
}
.blue-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #e7e9f6;
}
.blue-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #e7e9f6;
}
.blue-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #9fa8da;
}
.blue-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9c4d4;
}
.blue-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9c4d4;
}
.blue-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e81253;
}
.blue-theme .mat-progress-spinner circle, .blue-theme .mat-spinner circle {
  stroke: #003566;
}
.blue-theme .mat-progress-spinner.mat-accent circle, .blue-theme .mat-spinner.mat-accent circle {
  stroke: #9fa8da;
}
.blue-theme .mat-progress-spinner.mat-warn circle, .blue-theme .mat-spinner.mat-warn circle {
  stroke: #e81253;
}
.blue-theme .mat-radio-outer-circle {
  border-color: #26273b;
}
.blue-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #003566;
}
.blue-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.blue-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .blue-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .blue-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #003566;
}
.blue-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #9fa8da;
}
.blue-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.blue-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .blue-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .blue-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #9fa8da;
}
.blue-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e81253;
}
.blue-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.blue-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .blue-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .blue-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e81253;
}
.blue-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.blue-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.blue-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.blue-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.blue-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.blue-theme .mat-radio-button .mat-ripple-element {
  background-color: #121212;
}
.blue-theme .mat-select-value {
  color: #121212;
}
.blue-theme .mat-select-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.blue-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.blue-theme .mat-select-arrow {
  color: #26273b;
}
.blue-theme .mat-select-panel {
  background: white;
}
.blue-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.blue-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #003566;
}
.blue-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #9fa8da;
}
.blue-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e81253;
}
.blue-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e81253;
}
.blue-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.blue-theme .mat-drawer-container {
  background-color: white;
  color: #121212;
}
.blue-theme .mat-drawer {
  background-color: white;
  color: #121212;
}
.blue-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.blue-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(18, 18, 18, 0.2), 0px 16px 24px 2px rgba(18, 18, 18, 0.14), 0px 6px 30px 5px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.blue-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.blue-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.blue-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.blue-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.blue-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #9fa8da;
}
.blue-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(159, 168, 218, 0.54);
}
.blue-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #9fa8da;
}
.blue-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #003566;
}
.blue-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 53, 102, 0.54);
}
.blue-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #003566;
}
.blue-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e81253;
}
.blue-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(232, 18, 83, 0.54);
}
.blue-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e81253;
}
.blue-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: #121212;
}
.blue-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(18, 18, 18, 0.2), 0px 1px 1px 0px rgba(18, 18, 18, 0.14), 0px 1px 3px 0px rgba(18, 18, 18, 0.12);
  background-color: #fafafa;
}
.blue-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.blue-theme .mat-slider-track-background {
  background-color: rgba(18, 18, 18, 0.26);
}
.blue-theme .mat-slider.mat-primary .mat-slider-track-fill,
.blue-theme .mat-slider.mat-primary .mat-slider-thumb,
.blue-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #003566;
}
.blue-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.blue-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 53, 102, 0.2);
}
.blue-theme .mat-slider.mat-accent .mat-slider-track-fill,
.blue-theme .mat-slider.mat-accent .mat-slider-thumb,
.blue-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #9fa8da;
}
.blue-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.blue-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(159, 168, 218, 0.2);
}
.blue-theme .mat-slider.mat-warn .mat-slider-track-fill,
.blue-theme .mat-slider.mat-warn .mat-slider-thumb,
.blue-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e81253;
}
.blue-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.blue-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(232, 18, 83, 0.2);
}
.blue-theme .mat-slider:hover .mat-slider-track-background,
.blue-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(18, 18, 18, 0.38);
}
.blue-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.blue-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.blue-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(18, 18, 18, 0.26);
}
.blue-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(18, 18, 18, 0.26);
}
.blue-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.blue-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(18, 18, 18, 0.87);
}
.blue-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.blue-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(18, 18, 18, 0.26);
}
.blue-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(18, 18, 18, 0.26);
  background-color: transparent;
}
.blue-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .blue-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(18, 18, 18, 0.38);
}
.blue-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .blue-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(18, 18, 18, 0.26);
}
.blue-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(18, 18, 18, 0.7);
}
.blue-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(18, 18, 18, 0.7), rgba(18, 18, 18, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(18, 18, 18, 0.7), rgba(18, 18, 18, 0.7) 2px, transparent 0, transparent);
}
.blue-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(18, 18, 18, 0.7), rgba(18, 18, 18, 0.7) 2px, transparent 0, transparent);
}
.blue-theme .mat-step-header.cdk-keyboard-focused, .blue-theme .mat-step-header.cdk-program-focused, .blue-theme .mat-step-header:hover:not([aria-disabled]), .blue-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.blue-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .blue-theme .mat-step-header:hover {
    background: none;
  }
}
.blue-theme .mat-step-header .mat-step-label,
.blue-theme .mat-step-header .mat-step-optional {
  color: #26273b;
}
.blue-theme .mat-step-header .mat-step-icon {
  background-color: #26273b;
  color: white;
}
.blue-theme .mat-step-header .mat-step-icon-selected,
.blue-theme .mat-step-header .mat-step-icon-state-done,
.blue-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #003566;
  color: white;
}
.blue-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.blue-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.blue-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.blue-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.blue-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.blue-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.blue-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.blue-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e81253;
  color: white;
}
.blue-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e81253;
}
.blue-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: #121212;
}
.blue-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e81253;
}
.blue-theme .mat-stepper-horizontal, .blue-theme .mat-stepper-vertical {
  background-color: white;
}
.blue-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.blue-theme .mat-horizontal-stepper-header::before,
.blue-theme .mat-horizontal-stepper-header::after,
.blue-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.blue-theme .mat-sort-header-arrow {
  color: #26273b;
}
.blue-theme .mat-tab-nav-bar,
.blue-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.blue-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.blue-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.blue-theme .mat-tab-label, .blue-theme .mat-tab-link {
  color: #121212;
}
.blue-theme .mat-tab-label.mat-tab-disabled, .blue-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.blue-theme .mat-tab-header-pagination-chevron {
  border-color: #121212;
}
.blue-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.blue-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.blue-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.blue-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .blue-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.blue-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.blue-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .blue-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .blue-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.blue-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.blue-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 194, 209, 0.3);
}
.blue-theme .mat-tab-group.mat-primary .mat-ink-bar, .blue-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #003566;
}
.blue-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .blue-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .blue-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .blue-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.blue-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .blue-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.blue-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.blue-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .blue-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .blue-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.blue-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.blue-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.blue-theme .mat-tab-group.mat-accent .mat-ink-bar, .blue-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #9fa8da;
}
.blue-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .blue-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .blue-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .blue-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.blue-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .blue-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.blue-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.blue-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .blue-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .blue-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.blue-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.blue-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 184, 203, 0.3);
}
.blue-theme .mat-tab-group.mat-warn .mat-ink-bar, .blue-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e81253;
}
.blue-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .blue-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .blue-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .blue-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.blue-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .blue-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.blue-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.blue-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .blue-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .blue-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.blue-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.blue-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 194, 209, 0.3);
}
.blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .blue-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #003566;
}
.blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .blue-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .blue-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.blue-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.blue-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.blue-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .blue-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.blue-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.blue-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .blue-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .blue-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.blue-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.blue-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .blue-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #9fa8da;
}
.blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .blue-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .blue-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.blue-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.blue-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.blue-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .blue-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.blue-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.blue-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .blue-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .blue-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.blue-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.blue-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 184, 203, 0.3);
}
.blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .blue-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e81253;
}
.blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .blue-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .blue-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.blue-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.blue-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.blue-theme .mat-toolbar {
  background: whitesmoke;
  color: #121212;
}
.blue-theme .mat-toolbar.mat-primary {
  background: #003566;
  color: white;
}
.blue-theme .mat-toolbar.mat-accent {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.blue-theme .mat-toolbar.mat-warn {
  background: #e81253;
  color: white;
}
.blue-theme .mat-toolbar .mat-form-field-underline,
.blue-theme .mat-toolbar .mat-form-field-ripple,
.blue-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.blue-theme .mat-toolbar .mat-form-field-label,
.blue-theme .mat-toolbar .mat-focused .mat-form-field-label,
.blue-theme .mat-toolbar .mat-select-value,
.blue-theme .mat-toolbar .mat-select-arrow,
.blue-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.blue-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.blue-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.blue-theme .mat-tree {
  background: white;
}
.blue-theme .mat-tree-node,
.blue-theme .mat-nested-tree-node {
  color: #121212;
}
.blue-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 6px 10px 0px rgba(18, 18, 18, 0.14), 0px 1px 18px 0px rgba(18, 18, 18, 0.12);
}
.blue-theme .mat-simple-snackbar-action {
  color: #9fa8da;
}

.red-theme .order {
  background-color: white;
  color: #000000;
}
.red-theme .order .line1 .detail-btn {
  color: #950207;
}
.red-theme .order .important-text {
  color: #6f0102;
  font-weight: 600;
}
.red-theme .order .status .status-chip {
  background-color: #ba4e52;
  color: #ffffff;
}
.red-theme .order .status .status-chip.closed {
  background-color: #42c778;
  color: #fff;
}
.red-theme .banner {
  background-color: white;
  color: #000000;
}
.red-theme .banner__content__title {
  color: #ff1f1f;
}
.red-theme .login {
  background-color: white;
  color: #000000;
}
.red-theme .login__header {
  background-color: #f3e1e1;
  color: #000000;
}
.red-theme .login-content {
  background-color: white;
  color: #000000;
}
.red-theme .login-content__link {
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  color: #8b0106;
  background-color: #ffffff;
}
.red-theme .login-content__link:hover {
  background-color: #8b0106;
  color: #ffffff !important;
}
.red-theme .login-content {
  background-color: white;
  color: #000000;
}
.red-theme .login-content .link-send {
  color: #8b0106;
}
.red-theme .login-content .link-send:hover {
  color: #000000;
}
.red-theme .login-content {
  background-color: white;
  color: #000000;
}
.red-theme .landing-container .sidenav-content {
  background-color: white;
  color: #000000;
}
.red-theme .landing-container .sidenav-content__linkContainer__link.static {
  background-color: #ffffff;
  color: #8b0106;
}
.red-theme .landing-container .sidenav-content__footer {
  background-color: rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 768px) {
  .red-theme .landing-container .landing-content {
    background-color: #f4f4f9;
    color: #9d0208;
  }
}
@media (max-height: 555px) {
  .red-theme .landing-container .landing-content {
    background-color: #f4f4f9;
    color: #9d0208;
  }
}
.red-theme .orders {
  background-color: rgba(255, 255, 255, 0);
  color: #000000;
}
.red-theme .orders__wrapper {
  background-color: #950207;
  color: #ffffff;
}
.red-theme .orders__see-all-btn .see-all-orders-btn {
  background-color: #950207;
  color: #ffffff;
  border: 1px solid #fe8d91;
}
.red-theme .orders__see-all-btn .see-all-orders-btn:hover {
  background-color: #be0108;
}
.red-theme .orders__banners {
  background-color: #f4f4f9;
  color: #000000;
}
.red-theme .orders__banners .see-more-banners-btn {
  background-color: white;
  color: #000000;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.red-theme .orders__banners .see-more-banners-btn:hover {
  background-color: #f3e1e1;
}
.red-theme .footer {
  background-color: #9595a8;
  color: white;
}
.red-theme .footer__facility__item__head {
  font-weight: bold;
}
.red-theme .footer__facility__item mat-divider {
  background-color: white;
}
.red-theme .all-orders {
  background-color: rgba(255, 255, 255, 0);
  color: #000000;
}
.red-theme .all-orders__wrapper {
  background-color: #f4f4f9;
  color: #000000;
}
.red-theme .all-orders__wrapper__header {
  background-color: white;
  color: #8b0106;
}
.red-theme .all-orders__wrapper__filter .filter-field .mat-form-field-outline {
  background-color: white;
}
.red-theme .order-detail__header {
  background-color: white;
  color: #000000;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.red-theme .order-detail__header .title {
  color: #950207;
}
.red-theme .order-detail__steps {
  background-color: white;
  color: #000000;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.red-theme .order-detail__steps mat-stepper {
  background-color: transparent;
  pointer-events: none;
  min-width: 100%;
}
.red-theme .order-detail__steps mat-stepper mat-step-header {
  padding: 0 !important;
  min-width: 130px !important;
  overflow: visible !important;
}
.red-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon.mat-step-icon-state-noicon {
  background-color: #bdbdbd;
}
.red-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon {
  height: 15px !important;
  width: 15px !important;
  margin-top: 5px;
}
.red-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon .mat-icon {
  font-size: 14px;
  height: 14px;
  width: 14px;
}
.red-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon .currentState {
  color: #42c778;
  font-size: 20px;
  height: 20px;
  width: 20px;
  margin-top: -27px;
}
.red-theme .order-detail__steps mat-stepper mat-step-header .mat-step-label {
  padding: 5px 5px 0 5px !important;
  font-size: 0.7rem;
}
.red-theme .order-detail__steps mat-stepper mat-step-header .mat-step-label .disabled {
  color: #bdbdbd;
}
.red-theme .order-detail__steps .mat-stepper-horizontal-line {
  display: none !important;
}
.red-theme .order-detail__steps .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:after,
.red-theme .order-detail__steps .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:before {
  top: 13px !important;
}
.red-theme .order-detail__steps .mat-horizontal-stepper-wrapper {
  width: fit-content !important;
  margin: auto !important;
}
.red-theme .order-detail__tabs {
  background-color: #ffffff;
  color: #000000;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.red-theme .order-detail__tabs mat-tab-group .mat-tab-body-wrapper {
  height: 100%;
}
.red-theme .order-detail__tabs mat-tab-group .newMessagesIcon {
  color: #e5104c;
}
.red-theme .order-detail__tabs mat-tab-group .mat-tab-label {
  opacity: 1 !important;
}
.red-theme .order-detail__tabs .desktopTabs .item {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.red-theme .order-detail__tabs .desktopTabs .item:first-child {
  background-color: #f4f4f9;
}
.red-theme .messages {
  background-color: white;
}
.red-theme .messages__list .message__content {
  background-color: #e4e9f2;
  color: #000000;
}
.red-theme .messages__list .message__content.budget {
  background-color: #42c778;
  color: #fff;
}
.red-theme .messages__list .message__content.remote-sign {
  background-color: #9d0208;
  color: #ffffff;
}
.red-theme .messages__list .message__content.payment {
  background-color: #ff6b6b;
  color: #ffffff;
}
.red-theme .messages__list .message__content .budget-icon {
  background-color: #69d394;
}
.red-theme .messages__list .message__content .remote-sign-icon {
  background-color: #cf030b;
}
.red-theme .messages__list .message__content .payment-icon {
  background-color: #ff9e9e;
}
.red-theme .messages__list .message__date {
  color: #1a1a1a;
}
.red-theme .messages__list .message.customer .message__content {
  background-color: #810104;
  color: #ffffff;
}
.red-theme .messages__input {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.red-theme .messages__input__wrapper {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.red-theme .messages__input__wrapper .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -6px;
}
.red-theme .messages__input__wrapper .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -4px;
}
.red-theme .messages__input__wrapper .send-icon {
  color: #000000;
}
.red-theme .messages__input__wrapper .attach-icon {
  color: #000000;
}
.red-theme .navbar {
  background-color: white !important;
  color: #000000 !important;
}
.red-theme .navbar mat-divider {
  background-color: #000000;
}
@media screen and (max-width: 768px) {
  .red-theme .navbar {
    background-color: #8b0106 !important;
    color: #ffffff !important;
  }
  .red-theme .navbar mat-divider {
    background-color: white;
  }
}
.red-theme .attachments-item-container {
  background-color: #e4e9f2;
}
.red-theme .attachments-item-container .file-name {
  color: #8b0106;
}
.red-theme .attachments-item-container .file-name mat-icon {
  color: #8b0106;
}
@media screen and (max-width: 992px) {
  .red-theme ngx-extended-pdf-viewer #mainContainer {
    min-width: unset !important;
    max-width: 100% !important;
  }
  .red-theme #toolbarViewerLeft pdf-find-button,
.red-theme #toolbarViewerLeft pdf-paging-area {
    display: none !important;
  }
  .red-theme #toolbarViewerRight {
    display: none !important;
  }
  .red-theme #toolbarViewerMiddle {
    display: flex !important;
  }
}
.red-theme .budget-viewer-wrapper .mat-dialog-container {
  padding: 0px;
}
.red-theme .budget-viewer__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.red-theme .budget-viewer__header h2 {
  color: #8b0106;
}
.red-theme .budget-viewer__content__lines .budget-line {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.red-theme .budget-viewer__content__lines .budget-line.accepted {
  border: 1px solid #56cd86;
  background-color: #56cd86;
  color: white;
}
.red-theme .budget-viewer__content__lines .budget-line.accepted.authorized {
  border: 1px solid #31a560;
  background-color: #31a560;
}
.red-theme .budget-viewer__content__lines .budget-line.accepted.authorized .mat-checkbox-disabled .mat-checkbox-label {
  color: white;
}
.red-theme .budget-viewer__content__lines .budget-line mat-checkbox {
  width: 100%;
  padding: 15px;
}
.red-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout {
  width: 100%;
}
.red-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
  width: 90%;
}
@media screen and (max-width: 768px) {
  .red-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
    width: 80%;
  }
}
.red-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.red-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label .checkbox-budget-label-1 {
  font-weight: 600;
}
.red-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label .checkbox-budget-label-2 {
  font-weight: 500;
  margin-right: 30px;
}
@media screen and (max-width: 768px) {
  .red-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label .checkbox-budget-label-2 {
    margin-right: 15px;
  }
}
.red-theme .budget-viewer__content__lines .budget-line mat-checkbox.mat-checkbox-checked .mat-checkbox-background {
  background-color: white;
}
.red-theme .budget-viewer__content__lines .budget-line mat-checkbox.mat-checkbox-checked .mat-checkbox-background .mat-checkbox-checkmark {
  fill: #42c778 !important;
}
.red-theme .budget-viewer__content__lines .budget-line mat-checkbox.mat-checkbox-checked .mat-checkbox-background .mat-checkbox-checkmark-path {
  stroke: #42c778 !important;
}
.red-theme .budget-viewer__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.red-theme .payments-viewer-wrapper .mat-dialog-container {
  padding: 0px;
}
.red-theme .payments-viewer__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.red-theme .payments-viewer__header h2 {
  color: #8b0106;
}
.red-theme .payments-viewer__content__lines .payments-line {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.red-theme .payments-viewer__content__lines .payments-line.pending {
  border: 1px solid #ff5252;
  background-color: #ff5252;
  color: white;
}
.red-theme .payments-viewer__content__lines .payments-line.accepted {
  border: 1px solid #2b9155;
  background-color: #2b9155;
  color: white;
}
.red-theme .payments-viewer__content__lines .payments-line.error {
  border: 1px solid #c10c0c;
  background-color: #c10c0c;
  color: white;
}
.red-theme .payments-viewer__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.red-theme .signature-viewer-wrapper .mat-dialog-container {
  padding: 0px;
}
.red-theme .signature-viewer__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.red-theme .signature-viewer__header h2 {
  color: #8b0106;
}
.red-theme .signature-viewer__content .mat-step-icon.mat-step-icon-state-edit mat-icon {
  border-radius: 20px;
  padding: 5px;
  background-color: #42c778;
}
.red-theme .signature-viewer__content .mat-step-icon.mat-step-icon-state-number {
  background-color: #810104;
}
.red-theme .signature-viewer__content mat-stepper {
  background-color: transparent;
  min-width: 100%;
}
.red-theme .signature-viewer__content mat-stepper mat-step-header {
  padding: 0 !important;
  overflow: visible !important;
}
.red-theme .signature-viewer__content mat-stepper mat-step-header .mat-step-label {
  padding: 5px 5px 0 5px !important;
  font-size: 0.7rem;
}
.red-theme .signature-viewer__content mat-stepper mat-step-header .mat-step-label .disabled {
  color: #bdbdbd;
}
.red-theme .signature-viewer__content .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:after,
.red-theme .signature-viewer__content .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:before,
.red-theme .signature-viewer__content .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 13px !important;
}
.red-theme .signature-viewer__content .mat-horizontal-content-container {
  padding: 5px 0 0 0;
}
.red-theme .signature-viewer__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.red-theme .remote-signature-viewer-wrapper .mat-dialog-container {
  padding: 0;
}
.red-theme .unverified-signature-warning {
  display: none !important;
}
.red-theme .card-docs__section__content__budget, .red-theme .card-docs__section__content__remote-sign, .red-theme .card-docs__section__content__payment {
  background-color: #42c778;
  color: white;
  font-size: bold;
}
.red-theme .card-docs__section__content__budget mat-icon, .red-theme .card-docs__section__content__remote-sign mat-icon, .red-theme .card-docs__section__content__payment mat-icon {
  background-color: #69d394;
}
.red-theme .card-docs__section__content__budget mat-icon.alert-icon, .red-theme .card-docs__section__content__remote-sign mat-icon.alert-icon, .red-theme .card-docs__section__content__payment mat-icon.alert-icon {
  color: yellow;
  background-color: transparent;
  margin-left: -7px;
}
.red-theme .card-docs__section__content__budget.pending, .red-theme .card-docs__section__content__remote-sign.pending, .red-theme .card-docs__section__content__payment.pending {
  background-color: #9d0208;
  color: white;
}
.red-theme .card-docs__section__content__budget.pending mat-icon, .red-theme .card-docs__section__content__remote-sign.pending mat-icon, .red-theme .card-docs__section__content__payment.pending mat-icon {
  background-color: #cf030b;
}
.red-theme .card-docs__section__content__budget.pending mat-icon.alert-icon, .red-theme .card-docs__section__content__remote-sign.pending mat-icon.alert-icon, .red-theme .card-docs__section__content__payment.pending mat-icon.alert-icon {
  background-color: transparent;
  margin-left: -7px;
}
.red-theme .card-docs__section__content__budget.payments, .red-theme .card-docs__section__content__remote-sign.payments, .red-theme .card-docs__section__content__payment.payments {
  background-color: #ff3838;
  color: #ffffff;
}
.red-theme .card-docs__section__content__budget.payments mat-icon, .red-theme .card-docs__section__content__remote-sign.payments mat-icon, .red-theme .card-docs__section__content__payment.payments mat-icon {
  background-color: #ff6b6b;
}
.red-theme .card-docs__section__content__budget.payments mat-icon.alert-icon, .red-theme .card-docs__section__content__remote-sign.payments mat-icon.alert-icon, .red-theme .card-docs__section__content__payment.payments mat-icon.alert-icon {
  background-color: transparent;
  margin-left: -7px;
}
.red-theme .card-docs__section__content__budget.payments.pendingPayments, .red-theme .card-docs__section__content__remote-sign.payments.pendingPayments, .red-theme .card-docs__section__content__payment.payments.pendingPayments {
  background-color: #ff6b6b;
  color: #ffffff;
}
.red-theme .card-docs__section__content__budget.payments.pendingPayments mat-icon, .red-theme .card-docs__section__content__remote-sign.payments.pendingPayments mat-icon, .red-theme .card-docs__section__content__payment.payments.pendingPayments mat-icon {
  background-color: #ff9e9e;
}
.red-theme .card-docs__section__content__budget.payments.pendingPayments mat-icon.alert-icon, .red-theme .card-docs__section__content__remote-sign.payments.pendingPayments mat-icon.alert-icon, .red-theme .card-docs__section__content__payment.payments.pendingPayments mat-icon.alert-icon {
  background-color: transparent;
  margin-left: -7px;
}
.red-theme .send-code-content {
  background-color: white;
  color: #000000;
}
.red-theme .send-code-content__link {
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  color: #8b0106;
  background-color: #ffffff;
}
.red-theme .send-code-content__link:hover {
  background-color: #8b0106;
  color: #ffffff !important;
}
.red-theme .ask-for-code-content {
  background-color: white;
  color: #000000;
}
.red-theme .global-message--success {
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;
}
.red-theme .global-message--success .mat-simple-snackbar-action {
  color: #155724 !important;
}
.red-theme .global-message--warning {
  color: #2c2c2c !important;
  background-color: rgb(206, 134, 0) !important;
  border-color: #be8f00 !important;
}
.red-theme .global-message--warning .mat-simple-snackbar-action {
  color: #2c2c2c !important;
}
.red-theme .global-message--error {
  color: rgb(245, 245, 245) !important;
  background-color: rgb(138, 0, 0) !important;
  border-color: rgb(114, 2, 2) !important;
}
.red-theme .global-message--error .mat-simple-snackbar-action {
  color: rgb(245, 219, 219) !important;
}
.red-theme html,
.red-theme body {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
  height: 100%;
}
.red-theme body {
  margin: 0;
}
.red-theme .ngx-summernote-view {
  display: none !important;
}
.red-theme .content-wrapper {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1120px;
}
.red-theme .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.red-theme .mat-app-background {
  height: 100%;
  background-color: #f4f4f9;
}
.red-theme .uppercase {
  text-transform: uppercase;
}
.red-theme .capitalize {
  text-transform: capitalize;
}
.red-theme .message-block {
  align-items: center;
  background-color: #848484;
  border-radius: 0.571rem;
  box-shadow: 0 10px 22px 0 rgba(0, 0, 0, 0.04);
  color: #fff;
  display: flex;
  font-size: 1.143rem;
  font-weight: 600;
  line-height: 1.5;
  overflow-wrap: anywhere;
  padding: 1.429rem 1.714rem;
  word-wrap: anywhere;
}
.red-theme .message-block .mat-icon {
  flex: 0 0 auto;
  margin-right: 1.143rem;
}
.red-theme .message-block.message-block__success {
  background-color: #9d0208;
  color: #ffffff;
}
.red-theme .message-block.message-block__warning {
  background-color: #ffb025;
  color: #fff;
}
.red-theme .message-block.message-block__error {
  background-color: #f34040;
  color: #fff;
}
.red-theme .hidden,
.red-theme *[hidden] {
  display: none !important;
  overflow: hidden !important;
}
.red-theme .hidden.block,
.red-theme *[hidden].block {
  display: hidden !important;
}
.red-theme .inlineBlock {
  display: inline-block;
}
.red-theme .underline {
  text-decoration: underline;
}
.red-theme .opacity-1 {
  opacity: 1;
}
.red-theme .opacity-08 {
  opacity: 0.8;
}
.red-theme .opacity-06 {
  opacity: 0.6;
}
.red-theme .opacity-04 {
  opacity: 0.4;
}
.red-theme .opacity-02 {
  opacity: 0.2;
}
.red-theme .opacity-0 {
  opacity: 0;
}
.red-theme .overlay-spinner {
  background: rgba(255, 255, 255, 0.4);
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.red-theme .overlay-spinner > .mat-spinner {
  margin: 3rem auto;
}
.red-theme .spin-icon {
  animation: spinner 0.6s linear infinite;
}
.red-theme .height-100 {
  height: 100%;
}
.red-theme .note-modal-backdrop {
  display: none !important;
}
.red-theme .note-editing-area {
  background-color: white;
}
.red-theme .note-editor .note-editing-area .note-editable table.table-no-bordered td,
.red-theme .note-editor .note-editing-area .note-editable table.table-no-bordered th {
  border: 0px;
}
.red-theme .autocomplete-panel-class,
.red-theme .notification-mention-menu {
  max-height: calc(var(--100vh) - 65px) !important;
  height: 550px !important;
}
.red-theme .notification-mention-menu .mat-menu-content {
  height: calc(100% - 16px);
}
.red-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #9d0208;
}
.red-theme .mat-form-field-appearance-outline .mat-form-field-label {
  color: #9d0208;
  font-weight: 600;
  text-transform: uppercase;
}
.red-theme .mat-form-field-appearance-outline input.mat-input-element {
  text-overflow: ellipsis;
}
.red-theme .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  z-index: 1;
  margin-top: 0.2em;
}
.red-theme .mat-form-field-appearance-outline.date-picker .mat-form-field-subscript-wrapper {
  margin-top: 2.2em;
}
.red-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
  color: #4c0104;
}
.red-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-label {
  color: #4c0104;
}
.red-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: transparent;
}
.red-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: #9d0208;
}
.red-theme .mat-form-field-appearance-outline.mat-form-field-disabled input.mat-input-element {
  color: #121212;
}
.red-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-form-field-infix,
.red-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-form-field-suffix {
  padding: 0.25em 0 0.75em 0;
  font-size: 1em;
}
.red-theme .smaller-mat-form-fields .mat-form-field-appearance-outline.date-picker .mat-form-field-infix,
.red-theme .smaller-mat-form-fields .mat-form-field-appearance-outline.date-picker .mat-form-field-suffix {
  padding: 0.25em 0 0.6em 0;
  margin-top: -0.3em;
}
.red-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-infix, .red-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-suffix {
  padding: 0.2em 0 0.25em 0;
}
.red-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-infix .mat-input-element, .red-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-suffix .mat-input-element {
  position: absolute;
  margin-top: -11px;
}
.red-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-form-field-infix .mat-select-arrow {
  margin-top: 10px;
}
.red-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-select-empty + .mat-form-field-label-wrapper .mat-form-field-label {
  margin-top: -0.75em;
}
.red-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-select + .mat-form-field-label-wrapper .mat-form-field-label {
  margin-top: -0.3em;
}
.mat-select .red-theme .smaller-mat-form-fields__no-margin.mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding: 0 0;
  margin: 0 0;
}
.red-theme .smaller-mat-form-fields__no-margin.mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding: 0 0;
  margin: 0 0;
}
.red-theme .smaller-mat-form-fields .mat-form-field__no-border .mat-form-field-outline-start,
.red-theme .smaller-mat-form-fields .mat-form-field__no-border .mat-form-field-outline-gap,
.red-theme .smaller-mat-form-fields .mat-form-field__no-border .mat-form-field-outline-end {
  border: none;
}
.red-theme .smaller-mat-form-fields .mat-form-field__border-more-radius .mat-form-field-outline-start, .red-theme .smaller-mat-form-fields.mat-form-field__border-more-radius .mat-form-field-outline-start {
  border-radius: 28px 0 0 28px !important;
  min-width: 28px;
}
.red-theme .smaller-mat-form-fields .mat-form-field__border-more-radius .mat-form-field-outline-end, .red-theme .smaller-mat-form-fields.mat-form-field__border-more-radius .mat-form-field-outline-end {
  border-radius: 0 28px 28px 0 !important;
}
.red-theme button.mat-button, .red-theme button.mat-flat-button {
  font-size: 1rem;
}
.red-theme button.mat-button.mat-button-fill, .red-theme button.mat-flat-button.mat-button-fill {
  width: 100%;
}
.red-theme button.mat-button-w-100-left {
  width: 100%;
  text-align: left;
}
.red-theme button.mat-flat-button:hover {
  opacity: 0.85;
}
.red-theme button.close-modal-button.mat-icon-button {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  background-color: rgba(255, 158, 158, 0.3);
  color: #6f0102;
}
.red-theme button.close-modal-button.mat-icon-button:hover {
  background-color: rgba(255, 158, 158, 0.4);
}
.red-theme button.mat-square-icon {
  border-radius: 5px;
  padding: 0 0;
  min-width: 36px;
  line-height: 36px;
}
.red-theme button.mat-square-icon.square-28 {
  min-width: 28px;
  line-height: 28px;
}
.red-theme button.mat-dark {
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
}
.red-theme button.mat-dark:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
.red-theme button .mat-icon {
  overflow: visible;
}
.red-theme .mat-flat-button.mat-primary.mat-button-disabled,
.red-theme .mat-flat-button.mat-button-disabled.mat-button-disabled,
.red-theme .mat-raised-button.mat-primary.mat-button-disabled,
.red-theme .mat-raised-button.mat-button-disabled.mat-button-disabled,
.red-theme .mat-fab.mat-primary.mat-button-disabled,
.red-theme .mat-fab.mat-button-disabled.mat-button-disabled,
.red-theme .mat-mini-fab.mat-primary.mat-button-disabled,
.red-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: #9d0208;
  color: #ffffff;
  opacity: 0.5;
}
.red-theme .mat-button.v-align-bottom .mat-button-wrapper > *,
.red-theme .mat-flat-button.v-align-bottom .mat-button-wrapper > *,
.red-theme .mat-stroked-button.v-align-bottom .mat-button-wrapper > *,
.red-theme .mat-raised-button.v-align-bottom .mat-button-wrapper > *,
.red-theme .mat-icon-button.v-align-bottom .mat-button-wrapper > *,
.red-theme .mat-fab.v-align-bottom .mat-button-wrapper > *,
.red-theme .mat-mini-fab.v-align-bottom .mat-button-wrapper > * {
  vertical-align: text-bottom;
}
.red-theme .mat-button.no-padding,
.red-theme .mat-flat-button.no-padding,
.red-theme .mat-stroked-button.no-padding,
.red-theme .mat-raised-button.no-padding,
.red-theme .mat-icon-button.no-padding,
.red-theme .mat-fab.no-padding,
.red-theme .mat-mini-fab.no-padding {
  padding: 0px;
}
.red-theme mat-expansion-panel.no-padding .mat-expansion-panel-header {
  padding: 0 !important;
}
.red-theme mat-expansion-panel.no-padding .mat-expansion-indicator {
  padding: 0 10px;
}
.red-theme .mat-expansion-panel-header {
  border-radius: initial;
  border-bottom: 1px solid #e2b3b5;
}
.red-theme .mat-expansion-panel-header.mat-expanded {
  height: 49px;
}
.red-theme .mat-expansion-panel-header .mat-expansion-indicator {
  border-width: 0 3px 3px 0;
  color: #000000;
}
.red-theme .mat-expansion-panel-header .mat-expansion-panel-header-title {
  color: #9d0208;
  font-size: 1rem;
  font-weight: 600;
}
.red-theme .header-color-primary .mat-expansion-panel-header {
  background-color: #9d0208 !important;
}
.red-theme .header-color-primary .mat-expansion-panel-header:hover {
  background-color: #950207 !important;
}
.red-theme .header-color-primary .mat-expansion-indicator::after {
  color: #ffffff;
}
.red-theme .header-color-primary .mat-expansion-panel-header-title {
  color: #ffffff;
}
.red-theme .mat-expanded > .mat-expansion-panel-content {
  padding-top: 16px;
}
.red-theme mat-expansion-panel.no-padding .mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0 0 16px 0;
}
.red-theme .mat-select-panel.bigger-selection-panel-class {
  max-height: calc(var(--100vh) - 100px);
}
.red-theme .mat-select-panel.bigger-selection-panel-class .mat-optgroup-label {
  overflow: visible !important;
}
.red-theme .mat-tab-page-sections .mat-tab-header {
  border-bottom: 0px;
}
.red-theme .mat-tab-page-sections .mat-tab-header .mat-tab-label {
  font-size: 1.5rem;
  font-weight: 400;
  padding: 0 15px;
}
.red-theme .mat-tab-page-sections.font-size-smaller .mat-tab-header .mat-tab-label {
  font-size: 1.1rem;
  font-weight: 200;
}
.red-theme .mat-tab-group.mat-primary .mat-ink-bar,
.red-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  height: 3px;
  background-color: #9d0208;
}
.red-theme .mat-tab-label.text-red,
.red-theme .mat-tab-label.mat-tab-label-active.text-red {
  color: #c10c0c;
}
.red-theme .mat-item-card {
  margin-bottom: 20px;
  max-width: calc(50% - 20px);
}
.red-theme .mat-item-card__title {
  font-size: 1rem;
  color: #26273b;
}
.red-theme .mat-item-card__icons .mat-icon {
  padding-right: 24px;
  color: #9d0208;
}
.red-theme .mat-item-card__icons .mat-icon:last-child {
  padding-right: 0;
}
.red-theme .mat-table {
  background-color: transparent;
}
.red-theme .mat-table.table-ellipsis {
  width: 100%;
  table-layout: fixed;
}
.red-theme .mat-table.table-ellipsis th,
.red-theme .mat-table.table-ellipsis td {
  overflow: hidden;
  width: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 10px;
}
.red-theme .mat-table.table-ellipsis th.no-ellipsis,
.red-theme .mat-table.table-ellipsis td.no-ellipsis {
  overflow: inherit;
}
.red-theme .mat-table .mat-header-row .mat-header-cell {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  color: #000000;
}
.red-theme .mat-table tr.mat-row {
  background-color: white;
  height: 65px;
}
.red-theme .mat-table tr.mat-row .mat-cell {
  font-size: 16px;
  color: #26273b;
}
.red-theme .mat-table tr.mat-row .mat-cell.action-buttons {
  color: #9d0208;
  font-size: 24px;
}
.red-theme .mat-paginator {
  background-color: transparent;
}
.red-theme .mat-checkbox-layout {
  white-space: normal !important;
}
.red-theme mat-checkbox.light-border .mat-checkbox-frame {
  border-color: #ffffff;
}
.red-theme .mat-tooltip {
  font-size: 0.9em;
  max-width: 350px !important;
}
.red-theme .mat-menu-panel.dark-theme {
  min-width: 150px;
  background-color: #000000;
}
.red-theme .mat-menu-panel.dark-theme .mat-menu-item {
  color: #ffffff;
}
.red-theme .mat-menu-panel.dark-theme .mat-divider {
  background-color: #000000;
}
.red-theme .draggable-item {
  border-radius: 5px;
  background-color: #f4f4f9;
  margin: 5px 0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.red-theme .draggable-item .cdk-drag-handle {
  cursor: grab;
}
.red-theme .draggable-item .cdk-drag-handle:active {
  cursor: grabbing;
}
.red-theme .draggable-item.cdk-drag-preview {
  pointer-events: fill !important;
  box-shadow: 0px 0px 25px 0px rgb(0, 0, 0);
}
.red-theme .draggable-item.cdk-drag-preview .cdk-drag-handle {
  cursor: grabbing;
}
.red-theme .remove-padding mat-dialog-container {
  padding: 0px;
}
.red-theme .mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Saira;
}
.red-theme .mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.red-theme .mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.red-theme .mat-h1,
.red-theme .mat-headline,
.red-theme .mat-typography .mat-h1,
.red-theme .mat-typography .mat-headline,
.red-theme .mat-typography h1 {
  font: 900 24px / 32px Saira;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.red-theme .mat-h2,
.red-theme .mat-title,
.red-theme .mat-typography .mat-h2,
.red-theme .mat-typography .mat-title,
.red-theme .mat-typography h2 {
  font: 600 20px / 28px Saira;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.red-theme .mat-h3,
.red-theme .mat-subheading-2,
.red-theme .mat-typography .mat-h3,
.red-theme .mat-typography .mat-subheading-2,
.red-theme .mat-typography h3 {
  font: 400 16px / 28px Saira;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.red-theme .mat-h4,
.red-theme .mat-subheading-1,
.red-theme .mat-typography .mat-h4,
.red-theme .mat-typography .mat-subheading-1,
.red-theme .mat-typography h4 {
  font: 600 14px / 1em Saira;
  letter-spacing: 3px;
  margin: 0 0 16px;
}
.red-theme .mat-h5,
.red-theme .mat-typography .mat-h5,
.red-theme .mat-typography h5 {
  font: 400 calc(16px * 0.83) / 20px Saira;
  margin: 0 0 12px;
}
.red-theme .mat-h6,
.red-theme .mat-typography .mat-h6,
.red-theme .mat-typography h6 {
  font: 400 calc(16px * 0.67) / 20px Saira;
  margin: 0 0 12px;
}
.red-theme .mat-body-strong,
.red-theme .mat-body-2,
.red-theme .mat-typography .mat-body-strong,
.red-theme .mat-typography .mat-body-2 {
  font: 400 18px / 26px Saira;
  letter-spacing: normal;
}
.red-theme .mat-body,
.red-theme .mat-body-1,
.red-theme .mat-typography .mat-body,
.red-theme .mat-typography .mat-body-1,
.red-theme .mat-typography {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
}
.red-theme .mat-body p,
.red-theme .mat-body-1 p,
.red-theme .mat-typography .mat-body p,
.red-theme .mat-typography .mat-body-1 p,
.red-theme .mat-typography p {
  margin: 0 0 12px;
}
.red-theme .mat-small,
.red-theme .mat-caption,
.red-theme .mat-typography .mat-small,
.red-theme .mat-typography .mat-caption {
  font: 400 12px / 20px Saira;
  letter-spacing: normal;
}
.red-theme .mat-display-4,
.red-theme .mat-typography .mat-display-4 {
  font: 400 112px / 112px Saira;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.red-theme .mat-display-3,
.red-theme .mat-typography .mat-display-3 {
  font: 900 56px / 56px Saira;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.red-theme .mat-display-2,
.red-theme .mat-typography .mat-display-2 {
  font: 900 40px / 1.2 Saira;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.red-theme .mat-display-1,
.red-theme .mat-typography .mat-display-1 {
  font: 600 32px / 1.13 Saira;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.red-theme .mat-bottom-sheet-container {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
}
.red-theme .mat-button, .red-theme .mat-raised-button, .red-theme .mat-icon-button, .red-theme .mat-stroked-button,
.red-theme .mat-flat-button, .red-theme .mat-fab, .red-theme .mat-mini-fab {
  font-family: Saira;
  font-size: 14px;
  font-weight: 600;
}
.red-theme .mat-button-toggle {
  font-family: Saira;
}
.red-theme .mat-card {
  font-family: Saira;
}
.red-theme .mat-card-title {
  font-size: 24px;
  font-weight: 600;
}
.red-theme .mat-card-header .mat-card-title {
  font-size: 20px;
}
.red-theme .mat-card-subtitle,
.red-theme .mat-card-content {
  font-size: 16px;
}
.red-theme .mat-checkbox {
  font-family: Saira;
}
.red-theme .mat-checkbox-layout .mat-checkbox-label {
  line-height: 26px;
}
.red-theme .mat-chip {
  font-size: 18px;
  font-weight: 400;
}
.red-theme .mat-chip .mat-chip-trailing-icon.mat-icon,
.red-theme .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.red-theme .mat-table {
  font-family: Saira;
}
.red-theme .mat-header-cell {
  font-size: 12px;
  font-weight: 400;
}
.red-theme .mat-cell, .red-theme .mat-footer-cell {
  font-size: 16px;
}
.red-theme .mat-calendar {
  font-family: Saira;
}
.red-theme .mat-calendar-body {
  font-size: 13px;
}
.red-theme .mat-calendar-body-label,
.red-theme .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 600;
}
.red-theme .mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.red-theme .mat-dialog-title {
  font: 600 20px / 28px Saira;
  letter-spacing: normal;
}
.red-theme .mat-expansion-panel-header {
  font-family: Saira;
  font-size: 14px;
  font-weight: 600;
}
.red-theme .mat-expansion-panel-content {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
}
.red-theme .mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Saira;
  letter-spacing: normal;
}
.red-theme .mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.red-theme .mat-form-field-prefix .mat-icon,
.red-theme .mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.red-theme .mat-form-field-prefix .mat-icon-button,
.red-theme .mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.red-theme .mat-form-field-prefix .mat-icon-button .mat-icon,
.red-theme .mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.red-theme .mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.red-theme .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.red-theme .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34369em) scale(0.75);
  width: 133.3333933333%;
}
.red-theme .mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34368em) scale(0.75);
  width: 133.3334033333%;
}
.red-theme .mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.red-theme .mat-form-field-label {
  top: 1.34375em;
}
.red-theme .mat-form-field-underline {
  bottom: 1.34375em;
}
.red-theme .mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.red-theme .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.red-theme .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.red-theme .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.red-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00118px);
  width: 133.3335133333%;
}
.red-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00119px);
  width: 133.3335233333%;
}
.red-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.0012px);
  width: 133.3335333333%;
}
.red-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.red-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.red-theme .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .red-theme .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.red-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28104em) scale(0.75);
  }
  .red-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28103em) scale(0.75);
  }
  .red-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28102em) scale(0.75);
  }
}
.red-theme .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.red-theme .mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.red-theme .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.red-theme .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59369em) scale(0.75);
  width: 133.3333933333%;
}
.red-theme .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59368em) scale(0.75);
  width: 133.3334033333%;
}
.red-theme .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.red-theme .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.red-theme .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.red-theme .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59369em) scale(0.75);
  width: 133.3333933333%;
}
.red-theme .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59368em) scale(0.75);
  width: 133.3334033333%;
}
.red-theme .mat-grid-tile-header,
.red-theme .mat-grid-tile-footer {
  font-size: 16px;
}
.red-theme .mat-grid-tile-header .mat-line,
.red-theme .mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.red-theme .mat-grid-tile-header .mat-line:nth-child(n+2),
.red-theme .mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.red-theme input.mat-input-element {
  margin-top: -0.0625em;
}
.red-theme .mat-menu-item {
  font-family: Saira;
  font-size: 16px;
  font-weight: 400;
}
.red-theme .mat-paginator,
.red-theme .mat-paginator-page-size .mat-select-trigger {
  font-family: Saira;
  font-size: 12px;
}
.red-theme .mat-radio-button {
  font-family: Saira;
}
.red-theme .mat-select {
  font-family: Saira;
}
.red-theme .mat-select-trigger {
  height: 1.125em;
}
.red-theme .mat-slide-toggle-content {
  font-family: Saira;
}
.red-theme .mat-slider-thumb-label-text {
  font-family: Saira;
  font-size: 12px;
  font-weight: 400;
}
.red-theme .mat-stepper-vertical, .red-theme .mat-stepper-horizontal {
  font-family: Saira;
}
.red-theme .mat-step-label {
  font-size: 16px;
  font-weight: 400;
}
.red-theme .mat-step-sub-label-error {
  font-weight: normal;
}
.red-theme .mat-step-label-error {
  font-size: 18px;
}
.red-theme .mat-step-label-selected {
  font-size: 18px;
  font-weight: 400;
}
.red-theme .mat-tab-group {
  font-family: Saira;
}
.red-theme .mat-tab-label, .red-theme .mat-tab-link {
  font-family: Saira;
  font-size: 14px;
  font-weight: 600;
}
.red-theme .mat-toolbar,
.red-theme .mat-toolbar h1,
.red-theme .mat-toolbar h2,
.red-theme .mat-toolbar h3,
.red-theme .mat-toolbar h4,
.red-theme .mat-toolbar h5,
.red-theme .mat-toolbar h6 {
  font: 600 20px / 28px Saira;
  letter-spacing: normal;
  margin: 0;
}
.red-theme .mat-tooltip {
  font-family: Saira;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.red-theme .mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.red-theme .mat-list-item {
  font-family: Saira;
}
.red-theme .mat-list-option {
  font-family: Saira;
}
.red-theme .mat-list-base .mat-list-item {
  font-size: 16px;
}
.red-theme .mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.red-theme .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.red-theme .mat-list-base .mat-list-option {
  font-size: 16px;
}
.red-theme .mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.red-theme .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.red-theme .mat-list-base .mat-subheader {
  font-family: Saira;
  font-size: 18px;
  font-weight: 400;
}
.red-theme .mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.red-theme .mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.red-theme .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.red-theme .mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.red-theme .mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.red-theme .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.red-theme .mat-list-base[dense] .mat-subheader {
  font-family: Saira;
  font-size: 12px;
  font-weight: 400;
}
.red-theme .mat-option {
  font-family: Saira;
  font-size: 16px;
}
.red-theme .mat-optgroup-label {
  font: 400 18px / 26px Saira;
  letter-spacing: normal;
}
.red-theme .mat-simple-snackbar {
  font-family: Saira;
  font-size: 16px;
}
.red-theme .mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 600;
}
.red-theme .mat-tree {
  font-family: Saira;
}
.red-theme .mat-tree-node,
.red-theme .mat-nested-tree-node {
  font-weight: 400;
  font-size: 16px;
}
.red-theme .mat-ripple {
  overflow: hidden;
  position: relative;
}
.red-theme .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.red-theme .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.red-theme .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .red-theme .mat-ripple-element {
  display: none;
}

.red-theme .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .red-theme .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.red-theme .cdk-overlay-container, .red-theme .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.red-theme .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.red-theme .cdk-overlay-container:empty {
  display: none;
}
.red-theme .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.red-theme .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.red-theme .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.red-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .red-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.red-theme .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.red-theme .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.red-theme .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.red-theme .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.red-theme .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.red-theme .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.red-theme textarea.cdk-textarea-autosize {
  resize: none;
}
.red-theme textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.red-theme textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.red-theme .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.red-theme .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.red-theme .mat-focus-indicator {
  position: relative;
}
.red-theme .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.red-theme .mat-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .red-theme {
  --mat-focus-indicator-display: block;
}

.red-theme .mat-mdc-focus-indicator {
  position: relative;
}
.red-theme .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.red-theme .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .red-theme {
  --mat-mdc-focus-indicator-display: block;
}

.red-theme .mat-ripple-element {
  background-color: rgba(18, 18, 18, 0.1);
}
.red-theme .mat-option {
  color: #121212;
}
.red-theme .mat-option:hover:not(.mat-option-disabled), .red-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.red-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.red-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: #121212;
}
.red-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.red-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #9d0208;
}
.red-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #9fa8da;
}
.red-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e81253;
}
.red-theme .mat-optgroup-label {
  color: #26273b;
}
.red-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.red-theme .mat-pseudo-checkbox {
  color: #26273b;
}
.red-theme .mat-pseudo-checkbox::after {
  color: white;
}
.red-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.red-theme .mat-primary .mat-pseudo-checkbox-checked,
.red-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #9d0208;
}
.red-theme .mat-pseudo-checkbox-checked,
.red-theme .mat-pseudo-checkbox-indeterminate,
.red-theme .mat-accent .mat-pseudo-checkbox-checked,
.red-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #9fa8da;
}
.red-theme .mat-warn .mat-pseudo-checkbox-checked,
.red-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e81253;
}
.red-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.red-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.red-theme .mat-app-background, .red-theme.mat-app-background {
  background-color: white;
  color: #121212;
}
.red-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(18, 18, 18, 0.2), 0px 1px 1px 0px rgba(18, 18, 18, 0.14), 0px 1px 3px 0px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(18, 18, 18, 0.2), 0px 3px 4px 0px rgba(18, 18, 18, 0.14), 0px 1px 8px 0px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 5px 8px 0px rgba(18, 18, 18, 0.14), 0px 1px 14px 0px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 6px 10px 0px rgba(18, 18, 18, 0.14), 0px 1px 18px 0px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(18, 18, 18, 0.2), 0px 7px 10px 1px rgba(18, 18, 18, 0.14), 0px 2px 16px 1px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(18, 18, 18, 0.2), 0px 8px 10px 1px rgba(18, 18, 18, 0.14), 0px 3px 14px 2px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(18, 18, 18, 0.2), 0px 9px 12px 1px rgba(18, 18, 18, 0.14), 0px 3px 16px 2px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(18, 18, 18, 0.2), 0px 10px 14px 1px rgba(18, 18, 18, 0.14), 0px 4px 18px 3px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(18, 18, 18, 0.2), 0px 11px 15px 1px rgba(18, 18, 18, 0.14), 0px 4px 20px 3px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(18, 18, 18, 0.2), 0px 12px 17px 2px rgba(18, 18, 18, 0.14), 0px 5px 22px 4px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(18, 18, 18, 0.2), 0px 13px 19px 2px rgba(18, 18, 18, 0.14), 0px 5px 24px 4px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(18, 18, 18, 0.2), 0px 14px 21px 2px rgba(18, 18, 18, 0.14), 0px 5px 26px 4px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(18, 18, 18, 0.2), 0px 15px 22px 2px rgba(18, 18, 18, 0.14), 0px 6px 28px 5px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(18, 18, 18, 0.2), 0px 16px 24px 2px rgba(18, 18, 18, 0.14), 0px 6px 30px 5px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(18, 18, 18, 0.2), 0px 17px 26px 2px rgba(18, 18, 18, 0.14), 0px 6px 32px 5px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(18, 18, 18, 0.2), 0px 18px 28px 2px rgba(18, 18, 18, 0.14), 0px 7px 34px 6px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(18, 18, 18, 0.2), 0px 19px 29px 2px rgba(18, 18, 18, 0.14), 0px 7px 36px 6px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(18, 18, 18, 0.2), 0px 20px 31px 3px rgba(18, 18, 18, 0.14), 0px 8px 38px 7px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(18, 18, 18, 0.2), 0px 21px 33px 3px rgba(18, 18, 18, 0.14), 0px 8px 40px 7px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(18, 18, 18, 0.2), 0px 22px 35px 3px rgba(18, 18, 18, 0.14), 0px 8px 42px 7px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(18, 18, 18, 0.2), 0px 23px 36px 3px rgba(18, 18, 18, 0.14), 0px 9px 44px 8px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(18, 18, 18, 0.2), 0px 24px 38px 3px rgba(18, 18, 18, 0.14), 0px 9px 46px 8px rgba(18, 18, 18, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.red-theme .mat-autocomplete-panel {
  background: white;
  color: #121212;
}
.red-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.red-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: #121212;
}
.red-theme .mat-badge-content {
  color: white;
  background: #9d0208;
}
.cdk-high-contrast-active .red-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.red-theme .mat-badge-accent .mat-badge-content {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.red-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e81253;
}
.red-theme .mat-badge-disabled .mat-badge-content {
  background: #c1c1c1;
  color: rgba(0, 0, 0, 0.38);
}
.red-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(18, 18, 18, 0.2), 0px 16px 24px 2px rgba(18, 18, 18, 0.14), 0px 6px 30px 5px rgba(18, 18, 18, 0.12);
  background: white;
  color: #121212;
}
.red-theme .mat-button, .red-theme .mat-icon-button, .red-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.red-theme .mat-button.mat-primary, .red-theme .mat-icon-button.mat-primary, .red-theme .mat-stroked-button.mat-primary {
  color: #9d0208;
}
.red-theme .mat-button.mat-accent, .red-theme .mat-icon-button.mat-accent, .red-theme .mat-stroked-button.mat-accent {
  color: #9fa8da;
}
.red-theme .mat-button.mat-warn, .red-theme .mat-icon-button.mat-warn, .red-theme .mat-stroked-button.mat-warn {
  color: #e81253;
}
.red-theme .mat-button.mat-primary.mat-button-disabled, .red-theme .mat-button.mat-accent.mat-button-disabled, .red-theme .mat-button.mat-warn.mat-button-disabled, .red-theme .mat-button.mat-button-disabled.mat-button-disabled, .red-theme .mat-icon-button.mat-primary.mat-button-disabled, .red-theme .mat-icon-button.mat-accent.mat-button-disabled, .red-theme .mat-icon-button.mat-warn.mat-button-disabled, .red-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .red-theme .mat-stroked-button.mat-primary.mat-button-disabled, .red-theme .mat-stroked-button.mat-accent.mat-button-disabled, .red-theme .mat-stroked-button.mat-warn.mat-button-disabled, .red-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(18, 18, 18, 0.26);
}
.red-theme .mat-button.mat-primary .mat-button-focus-overlay, .red-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .red-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #9d0208;
}
.red-theme .mat-button.mat-accent .mat-button-focus-overlay, .red-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .red-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #9fa8da;
}
.red-theme .mat-button.mat-warn .mat-button-focus-overlay, .red-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .red-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e81253;
}
.red-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .red-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .red-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.red-theme .mat-button .mat-ripple-element, .red-theme .mat-icon-button .mat-ripple-element, .red-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.red-theme .mat-button-focus-overlay {
  background: #121212;
}
.red-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.red-theme .mat-flat-button, .red-theme .mat-raised-button, .red-theme .mat-fab, .red-theme .mat-mini-fab {
  color: #121212;
  background-color: white;
}
.red-theme .mat-flat-button.mat-primary, .red-theme .mat-raised-button.mat-primary, .red-theme .mat-fab.mat-primary, .red-theme .mat-mini-fab.mat-primary {
  color: white;
}
.red-theme .mat-flat-button.mat-accent, .red-theme .mat-raised-button.mat-accent, .red-theme .mat-fab.mat-accent, .red-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.red-theme .mat-flat-button.mat-warn, .red-theme .mat-raised-button.mat-warn, .red-theme .mat-fab.mat-warn, .red-theme .mat-mini-fab.mat-warn {
  color: white;
}
.red-theme .mat-flat-button.mat-primary.mat-button-disabled, .red-theme .mat-flat-button.mat-accent.mat-button-disabled, .red-theme .mat-flat-button.mat-warn.mat-button-disabled, .red-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .red-theme .mat-raised-button.mat-primary.mat-button-disabled, .red-theme .mat-raised-button.mat-accent.mat-button-disabled, .red-theme .mat-raised-button.mat-warn.mat-button-disabled, .red-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .red-theme .mat-fab.mat-primary.mat-button-disabled, .red-theme .mat-fab.mat-accent.mat-button-disabled, .red-theme .mat-fab.mat-warn.mat-button-disabled, .red-theme .mat-fab.mat-button-disabled.mat-button-disabled, .red-theme .mat-mini-fab.mat-primary.mat-button-disabled, .red-theme .mat-mini-fab.mat-accent.mat-button-disabled, .red-theme .mat-mini-fab.mat-warn.mat-button-disabled, .red-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(18, 18, 18, 0.26);
}
.red-theme .mat-flat-button.mat-primary, .red-theme .mat-raised-button.mat-primary, .red-theme .mat-fab.mat-primary, .red-theme .mat-mini-fab.mat-primary {
  background-color: #9d0208;
}
.red-theme .mat-flat-button.mat-accent, .red-theme .mat-raised-button.mat-accent, .red-theme .mat-fab.mat-accent, .red-theme .mat-mini-fab.mat-accent {
  background-color: #9fa8da;
}
.red-theme .mat-flat-button.mat-warn, .red-theme .mat-raised-button.mat-warn, .red-theme .mat-fab.mat-warn, .red-theme .mat-mini-fab.mat-warn {
  background-color: #e81253;
}
.red-theme .mat-flat-button.mat-primary.mat-button-disabled, .red-theme .mat-flat-button.mat-accent.mat-button-disabled, .red-theme .mat-flat-button.mat-warn.mat-button-disabled, .red-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .red-theme .mat-raised-button.mat-primary.mat-button-disabled, .red-theme .mat-raised-button.mat-accent.mat-button-disabled, .red-theme .mat-raised-button.mat-warn.mat-button-disabled, .red-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .red-theme .mat-fab.mat-primary.mat-button-disabled, .red-theme .mat-fab.mat-accent.mat-button-disabled, .red-theme .mat-fab.mat-warn.mat-button-disabled, .red-theme .mat-fab.mat-button-disabled.mat-button-disabled, .red-theme .mat-mini-fab.mat-primary.mat-button-disabled, .red-theme .mat-mini-fab.mat-accent.mat-button-disabled, .red-theme .mat-mini-fab.mat-warn.mat-button-disabled, .red-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: #f7f7f7;
}
.red-theme .mat-flat-button.mat-primary .mat-ripple-element, .red-theme .mat-raised-button.mat-primary .mat-ripple-element, .red-theme .mat-fab.mat-primary .mat-ripple-element, .red-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.red-theme .mat-flat-button.mat-accent .mat-ripple-element, .red-theme .mat-raised-button.mat-accent .mat-ripple-element, .red-theme .mat-fab.mat-accent .mat-ripple-element, .red-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.red-theme .mat-flat-button.mat-warn .mat-ripple-element, .red-theme .mat-raised-button.mat-warn .mat-ripple-element, .red-theme .mat-fab.mat-warn .mat-ripple-element, .red-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.red-theme .mat-stroked-button:not([class*=mat-elevation-z]), .red-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(18, 18, 18, 0.2), 0px 8px 10px 1px rgba(18, 18, 18, 0.14), 0px 3px 14px 2px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-fab:not([class*=mat-elevation-z]), .red-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 6px 10px 0px rgba(18, 18, 18, 0.14), 0px 1px 18px 0px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .red-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(18, 18, 18, 0.2), 0px 12px 17px 2px rgba(18, 18, 18, 0.14), 0px 5px 22px 4px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .red-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.red-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.red-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.red-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.red-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.red-theme .mat-button-toggle-appearance-standard {
  color: #121212;
  background: white;
}
.red-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.red-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.red-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.red-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.red-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: #26273b;
}
.red-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #121212;
}
.red-theme .mat-button-toggle-disabled {
  color: rgba(18, 18, 18, 0.26);
  background-color: #eeeeee;
}
.red-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.red-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.red-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.red-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.red-theme .mat-card {
  background: white;
  color: #121212;
}
.red-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(18, 18, 18, 0.2), 0px 1px 1px 0px rgba(18, 18, 18, 0.14), 0px 1px 3px 0px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-card-subtitle {
  color: #26273b;
}
.red-theme .mat-checkbox-frame {
  border-color: #26273b;
}
.red-theme .mat-checkbox-checkmark {
  fill: white;
}
.red-theme .mat-checkbox-checkmark-path {
  stroke: white !important;
}
.red-theme .mat-checkbox-mixedmark {
  background-color: white;
}
.red-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .red-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #9d0208;
}
.red-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .red-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #9fa8da;
}
.red-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .red-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e81253;
}
.red-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .red-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.red-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.red-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.red-theme .mat-checkbox .mat-ripple-element {
  background-color: #121212;
}
.red-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.red-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #9d0208;
}
.red-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.red-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #9fa8da;
}
.red-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.red-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e81253;
}
.red-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: #121212;
}
.red-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: #121212;
  opacity: 0.4;
}
.red-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(18, 18, 18, 0.2), 0px 3px 4px 0px rgba(18, 18, 18, 0.14), 0px 1px 8px 0px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.red-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.red-theme .mat-chip.mat-standard-chip::after {
  background: #121212;
}
.red-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #9d0208;
  color: white;
}
.red-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.red-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.red-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e81253;
  color: white;
}
.red-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.red-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.red-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.red-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.red-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.red-theme .mat-table {
  background: white;
}
.red-theme .mat-table thead, .red-theme .mat-table tbody, .red-theme .mat-table tfoot,
.red-theme mat-header-row, .red-theme mat-row, .red-theme mat-footer-row,
.red-theme [mat-header-row], .red-theme [mat-row], .red-theme [mat-footer-row],
.red-theme .mat-table-sticky {
  background: inherit;
}
.red-theme mat-row, .red-theme mat-header-row, .red-theme mat-footer-row,
.red-theme th.mat-header-cell, .red-theme td.mat-cell, .red-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.red-theme .mat-header-cell {
  color: #26273b;
}
.red-theme .mat-cell, .red-theme .mat-footer-cell {
  color: #121212;
}
.red-theme .mat-calendar-arrow {
  fill: rgba(18, 18, 18, 0.54);
}
.red-theme .mat-datepicker-toggle,
.red-theme .mat-datepicker-content .mat-calendar-next-button,
.red-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(18, 18, 18, 0.54);
}
.red-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.red-theme .mat-calendar-table-header,
.red-theme .mat-calendar-body-label {
  color: #26273b;
}
.red-theme .mat-calendar-body-cell-content,
.red-theme .mat-date-range-input-separator {
  color: #121212;
  border-color: transparent;
}
.red-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.red-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.red-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.red-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.red-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.red-theme .mat-calendar-body-in-range::before {
  background: rgba(157, 2, 8, 0.2);
}
.red-theme .mat-calendar-body-comparison-identical,
.red-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.red-theme .mat-calendar-body-comparison-bridge-start::before,
.red-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(157, 2, 8, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.red-theme .mat-calendar-body-comparison-bridge-end::before,
.red-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(157, 2, 8, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.red-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.red-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.red-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.red-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.red-theme .mat-calendar-body-selected {
  background-color: #9d0208;
  color: white;
}
.red-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(157, 2, 8, 0.4);
}
.red-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.red-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.red-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(157, 2, 8, 0.3);
}
@media (hover: hover) {
  .red-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(157, 2, 8, 0.3);
  }
}
.red-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
  background-color: white;
  color: #121212;
}
.red-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(159, 168, 218, 0.2);
}
.red-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.red-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.red-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.red-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(159, 168, 218, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.red-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.red-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(159, 168, 218, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.red-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.red-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.red-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.red-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.red-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.red-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(159, 168, 218, 0.4);
}
.red-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.red-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.red-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(159, 168, 218, 0.3);
}
@media (hover: hover) {
  .red-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(159, 168, 218, 0.3);
  }
}
.red-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(232, 18, 83, 0.2);
}
.red-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.red-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.red-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.red-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(232, 18, 83, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.red-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.red-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(232, 18, 83, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.red-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.red-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.red-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.red-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.red-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e81253;
  color: white;
}
.red-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(232, 18, 83, 0.4);
}
.red-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.red-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.red-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(232, 18, 83, 0.3);
}
@media (hover: hover) {
  .red-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(232, 18, 83, 0.3);
  }
}
.red-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(18, 18, 18, 0.2), 0px 24px 38px 3px rgba(18, 18, 18, 0.14), 0px 9px 46px 8px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-datepicker-toggle-active {
  color: #9d0208;
}
.red-theme .mat-datepicker-toggle-active.mat-accent {
  color: #9fa8da;
}
.red-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e81253;
}
.red-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.red-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(18, 18, 18, 0.2), 0px 24px 38px 3px rgba(18, 18, 18, 0.14), 0px 9px 46px 8px rgba(18, 18, 18, 0.12);
  background: white;
  color: #121212;
}
.red-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.red-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.red-theme .mat-expansion-panel {
  background: white;
  color: #121212;
}
.red-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.red-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .red-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .red-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .red-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.red-theme .mat-expansion-panel-header-title {
  color: #121212;
}
.red-theme .mat-expansion-panel-header-description,
.red-theme .mat-expansion-indicator::after {
  color: #26273b;
}
.red-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(18, 18, 18, 0.26);
}
.red-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.red-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.red-theme .mat-form-field-label {
  color: rgba(38, 39, 59, 0.6);
}
.red-theme .mat-hint {
  color: rgba(38, 39, 59, 0.6);
}
.red-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #9d0208;
}
.red-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #9fa8da;
}
.red-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e81253;
}
.red-theme .mat-focused .mat-form-field-required-marker {
  color: #9fa8da;
}
.red-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.red-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #9d0208;
}
.red-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #9fa8da;
}
.red-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e81253;
}
.red-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #9d0208;
}
.red-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #9fa8da;
}
.red-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e81253;
}
.red-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e81253;
}
.red-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.red-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e81253;
}
.red-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.red-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e81253;
}
.red-theme .mat-error {
  color: #e81253;
}
.red-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: #26273b;
}
.red-theme .mat-form-field-appearance-legacy .mat-hint {
  color: #26273b;
}
.red-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.red-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.red-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.red-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.red-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(18, 18, 18, 0.04);
}
.red-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(18, 18, 18, 0.02);
}
.red-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.red-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.red-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.red-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.red-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.red-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #9d0208;
}
.red-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #9fa8da;
}
.red-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e81253;
}
.red-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e81253;
}
.red-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.red-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.red-theme .mat-icon.mat-primary {
  color: #9d0208;
}
.red-theme .mat-icon.mat-accent {
  color: #9fa8da;
}
.red-theme .mat-icon.mat-warn {
  color: #e81253;
}
.red-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: #26273b;
}
.red-theme .mat-input-element:disabled,
.red-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.red-theme .mat-input-element {
  caret-color: #9d0208;
}
.red-theme .mat-input-element::placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.red-theme .mat-input-element::-moz-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.red-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.red-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.red-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #9fa8da;
}
.red-theme .mat-form-field.mat-warn .mat-input-element,
.red-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e81253;
}
.red-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e81253;
}
.red-theme .mat-list-base .mat-list-item {
  color: #121212;
}
.red-theme .mat-list-base .mat-list-option {
  color: #121212;
}
.red-theme .mat-list-base .mat-subheader {
  color: #26273b;
}
.red-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.red-theme .mat-list-option:hover, .red-theme .mat-list-option:focus,
.red-theme .mat-nav-list .mat-list-item:hover,
.red-theme .mat-nav-list .mat-list-item:focus,
.red-theme .mat-action-list .mat-list-item:hover,
.red-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.red-theme .mat-list-single-selected-option, .red-theme .mat-list-single-selected-option:hover, .red-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.red-theme .mat-menu-panel {
  background: white;
}
.red-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-menu-item {
  background: transparent;
  color: #121212;
}
.red-theme .mat-menu-item[disabled],
.red-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.red-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.red-theme .mat-menu-item .mat-icon-no-color,
.red-theme .mat-menu-submenu-icon {
  color: rgba(18, 18, 18, 0.54);
}
.red-theme .mat-menu-item:hover:not([disabled]),
.red-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.red-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.red-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.red-theme .mat-paginator {
  background: white;
}
.red-theme .mat-paginator,
.red-theme .mat-paginator-page-size .mat-select-trigger {
  color: #26273b;
}
.red-theme .mat-paginator-decrement,
.red-theme .mat-paginator-increment {
  border-top: 2px solid rgba(18, 18, 18, 0.54);
  border-right: 2px solid rgba(18, 18, 18, 0.54);
}
.red-theme .mat-paginator-first,
.red-theme .mat-paginator-last {
  border-top: 2px solid rgba(18, 18, 18, 0.54);
}
.red-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.red-theme .mat-icon-button[disabled] .mat-paginator-increment,
.red-theme .mat-icon-button[disabled] .mat-paginator-first,
.red-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.red-theme .mat-progress-bar-background {
  fill: #e7c0c1;
}
.red-theme .mat-progress-bar-buffer {
  background-color: #e7c0c1;
}
.red-theme .mat-progress-bar-fill::after {
  background-color: #9d0208;
}
.red-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #e7e9f6;
}
.red-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #e7e9f6;
}
.red-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #9fa8da;
}
.red-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9c4d4;
}
.red-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9c4d4;
}
.red-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e81253;
}
.red-theme .mat-progress-spinner circle, .red-theme .mat-spinner circle {
  stroke: #9d0208;
}
.red-theme .mat-progress-spinner.mat-accent circle, .red-theme .mat-spinner.mat-accent circle {
  stroke: #9fa8da;
}
.red-theme .mat-progress-spinner.mat-warn circle, .red-theme .mat-spinner.mat-warn circle {
  stroke: #e81253;
}
.red-theme .mat-radio-outer-circle {
  border-color: #26273b;
}
.red-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #9d0208;
}
.red-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.red-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .red-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .red-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #9d0208;
}
.red-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #9fa8da;
}
.red-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.red-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .red-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .red-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #9fa8da;
}
.red-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e81253;
}
.red-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.red-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .red-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .red-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e81253;
}
.red-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.red-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.red-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.red-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.red-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.red-theme .mat-radio-button .mat-ripple-element {
  background-color: #121212;
}
.red-theme .mat-select-value {
  color: #121212;
}
.red-theme .mat-select-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.red-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.red-theme .mat-select-arrow {
  color: #26273b;
}
.red-theme .mat-select-panel {
  background: white;
}
.red-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.red-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #9d0208;
}
.red-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #9fa8da;
}
.red-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e81253;
}
.red-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e81253;
}
.red-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.red-theme .mat-drawer-container {
  background-color: white;
  color: #121212;
}
.red-theme .mat-drawer {
  background-color: white;
  color: #121212;
}
.red-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.red-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(18, 18, 18, 0.2), 0px 16px 24px 2px rgba(18, 18, 18, 0.14), 0px 6px 30px 5px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.red-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.red-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.red-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.red-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.red-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #9fa8da;
}
.red-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(159, 168, 218, 0.54);
}
.red-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #9fa8da;
}
.red-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #9d0208;
}
.red-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(157, 2, 8, 0.54);
}
.red-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #9d0208;
}
.red-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e81253;
}
.red-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(232, 18, 83, 0.54);
}
.red-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e81253;
}
.red-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: #121212;
}
.red-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(18, 18, 18, 0.2), 0px 1px 1px 0px rgba(18, 18, 18, 0.14), 0px 1px 3px 0px rgba(18, 18, 18, 0.12);
  background-color: #fafafa;
}
.red-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.red-theme .mat-slider-track-background {
  background-color: rgba(18, 18, 18, 0.26);
}
.red-theme .mat-slider.mat-primary .mat-slider-track-fill,
.red-theme .mat-slider.mat-primary .mat-slider-thumb,
.red-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #9d0208;
}
.red-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.red-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(157, 2, 8, 0.2);
}
.red-theme .mat-slider.mat-accent .mat-slider-track-fill,
.red-theme .mat-slider.mat-accent .mat-slider-thumb,
.red-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #9fa8da;
}
.red-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.red-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(159, 168, 218, 0.2);
}
.red-theme .mat-slider.mat-warn .mat-slider-track-fill,
.red-theme .mat-slider.mat-warn .mat-slider-thumb,
.red-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e81253;
}
.red-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.red-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(232, 18, 83, 0.2);
}
.red-theme .mat-slider:hover .mat-slider-track-background,
.red-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(18, 18, 18, 0.38);
}
.red-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.red-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.red-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(18, 18, 18, 0.26);
}
.red-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(18, 18, 18, 0.26);
}
.red-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(18, 18, 18, 0.12);
}
.red-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.red-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(18, 18, 18, 0.87);
}
.red-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.red-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(18, 18, 18, 0.26);
}
.red-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(18, 18, 18, 0.26);
  background-color: transparent;
}
.red-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .red-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(18, 18, 18, 0.38);
}
.red-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .red-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(18, 18, 18, 0.26);
}
.red-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(18, 18, 18, 0.7);
}
.red-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(18, 18, 18, 0.7), rgba(18, 18, 18, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(18, 18, 18, 0.7), rgba(18, 18, 18, 0.7) 2px, transparent 0, transparent);
}
.red-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(18, 18, 18, 0.7), rgba(18, 18, 18, 0.7) 2px, transparent 0, transparent);
}
.red-theme .mat-step-header.cdk-keyboard-focused, .red-theme .mat-step-header.cdk-program-focused, .red-theme .mat-step-header:hover:not([aria-disabled]), .red-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.red-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .red-theme .mat-step-header:hover {
    background: none;
  }
}
.red-theme .mat-step-header .mat-step-label,
.red-theme .mat-step-header .mat-step-optional {
  color: #26273b;
}
.red-theme .mat-step-header .mat-step-icon {
  background-color: #26273b;
  color: white;
}
.red-theme .mat-step-header .mat-step-icon-selected,
.red-theme .mat-step-header .mat-step-icon-state-done,
.red-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #9d0208;
  color: white;
}
.red-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.red-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.red-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.red-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.red-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.red-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.red-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.red-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e81253;
  color: white;
}
.red-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e81253;
}
.red-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: #121212;
}
.red-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e81253;
}
.red-theme .mat-stepper-horizontal, .red-theme .mat-stepper-vertical {
  background-color: white;
}
.red-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.red-theme .mat-horizontal-stepper-header::before,
.red-theme .mat-horizontal-stepper-header::after,
.red-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.red-theme .mat-sort-header-arrow {
  color: #26273b;
}
.red-theme .mat-tab-nav-bar,
.red-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.red-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.red-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.red-theme .mat-tab-label, .red-theme .mat-tab-link {
  color: #121212;
}
.red-theme .mat-tab-label.mat-tab-disabled, .red-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.red-theme .mat-tab-header-pagination-chevron {
  border-color: #121212;
}
.red-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.red-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.red-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.red-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .red-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.red-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.red-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .red-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .red-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.red-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.red-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(226, 179, 181, 0.3);
}
.red-theme .mat-tab-group.mat-primary .mat-ink-bar, .red-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #9d0208;
}
.red-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .red-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .red-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .red-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.red-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .red-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.red-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.red-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .red-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .red-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.red-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.red-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.red-theme .mat-tab-group.mat-accent .mat-ink-bar, .red-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #9fa8da;
}
.red-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .red-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .red-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .red-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.red-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .red-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.red-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.red-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .red-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .red-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.red-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.red-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 184, 203, 0.3);
}
.red-theme .mat-tab-group.mat-warn .mat-ink-bar, .red-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e81253;
}
.red-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .red-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .red-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .red-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.red-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .red-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.red-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.red-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .red-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .red-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.red-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.red-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(226, 179, 181, 0.3);
}
.red-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .red-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .red-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #9d0208;
}
.red-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .red-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.red-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .red-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.red-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.red-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.red-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.red-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.red-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.red-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.red-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.red-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.red-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.red-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .red-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.red-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.red-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .red-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .red-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.red-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.red-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.red-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .red-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .red-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #9fa8da;
}
.red-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .red-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.red-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .red-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.red-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.red-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.red-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.red-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.red-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.red-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.red-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.red-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.red-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.red-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .red-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.red-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.red-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .red-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .red-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.red-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.red-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 184, 203, 0.3);
}
.red-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .red-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .red-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e81253;
}
.red-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .red-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.red-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .red-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.red-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.red-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.red-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.red-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.red-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.red-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.red-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.red-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.red-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.red-theme .mat-toolbar {
  background: whitesmoke;
  color: #121212;
}
.red-theme .mat-toolbar.mat-primary {
  background: #9d0208;
  color: white;
}
.red-theme .mat-toolbar.mat-accent {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.red-theme .mat-toolbar.mat-warn {
  background: #e81253;
  color: white;
}
.red-theme .mat-toolbar .mat-form-field-underline,
.red-theme .mat-toolbar .mat-form-field-ripple,
.red-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.red-theme .mat-toolbar .mat-form-field-label,
.red-theme .mat-toolbar .mat-focused .mat-form-field-label,
.red-theme .mat-toolbar .mat-select-value,
.red-theme .mat-toolbar .mat-select-arrow,
.red-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.red-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.red-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.red-theme .mat-tree {
  background: white;
}
.red-theme .mat-tree-node,
.red-theme .mat-nested-tree-node {
  color: #121212;
}
.red-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 6px 10px 0px rgba(18, 18, 18, 0.14), 0px 1px 18px 0px rgba(18, 18, 18, 0.12);
}
.red-theme .mat-simple-snackbar-action {
  color: #9fa8da;
}

.purple-theme .order {
  background-color: white;
  color: #000000;
}
.purple-theme .order .line1 .detail-btn {
  color: #410aa0;
}
.purple-theme .order .important-text {
  color: #21037d;
  font-weight: 600;
}
.purple-theme .order .status .status-chip {
  background-color: #7f55c2;
  color: #ffffff;
}
.purple-theme .order .status .status-chip.closed {
  background-color: #42c778;
  color: #fff;
}
.purple-theme .banner {
  background-color: white;
  color: #000000;
}
.purple-theme .banner__content__title {
  color: #512aff;
}
.purple-theme .login {
  background-color: white;
  color: #000000;
}
.purple-theme .login__header {
  background-color: #e9e2f5;
  color: #000000;
}
.purple-theme .login-content {
  background-color: white;
  color: #000000;
}
.purple-theme .login-content__link {
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  color: #380897;
  background-color: #ffffff;
}
.purple-theme .login-content__link:hover {
  background-color: #380897;
  color: #ffffff !important;
}
.purple-theme .login-content {
  background-color: white;
  color: #000000;
}
.purple-theme .login-content .link-send {
  color: #380897;
}
.purple-theme .login-content .link-send:hover {
  color: #000000;
}
.purple-theme .login-content {
  background-color: white;
  color: #000000;
}
.purple-theme .landing-container .sidenav-content {
  background-color: white;
  color: #000000;
}
.purple-theme .landing-container .sidenav-content__linkContainer__link.static {
  background-color: #ffffff;
  color: #380897;
}
.purple-theme .landing-container .sidenav-content__footer {
  background-color: rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 768px) {
  .purple-theme .landing-container .landing-content {
    background-color: #f4f4f9;
    color: #480ca8;
  }
}
@media (max-height: 555px) {
  .purple-theme .landing-container .landing-content {
    background-color: #f4f4f9;
    color: #480ca8;
  }
}
.purple-theme .orders {
  background-color: rgba(255, 255, 255, 0);
  color: #000000;
}
.purple-theme .orders__wrapper {
  background-color: #410aa0;
  color: #ffffff;
}
.purple-theme .orders__see-all-btn .see-all-orders-btn {
  background-color: #410aa0;
  color: #ffffff;
  border: 1px solid #c1a4fa;
}
.purple-theme .orders__see-all-btn .see-all-orders-btn:hover {
  background-color: #4a0bc7;
}
.purple-theme .orders__banners {
  background-color: #f4f4f9;
  color: #000000;
}
.purple-theme .orders__banners .see-more-banners-btn {
  background-color: white;
  color: #000000;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.purple-theme .orders__banners .see-more-banners-btn:hover {
  background-color: #e9e2f5;
}
.purple-theme .footer {
  background-color: #9595a8;
  color: white;
}
.purple-theme .footer__facility__item__head {
  font-weight: bold;
}
.purple-theme .footer__facility__item mat-divider {
  background-color: white;
}
.purple-theme .all-orders {
  background-color: rgba(255, 255, 255, 0);
  color: #000000;
}
.purple-theme .all-orders__wrapper {
  background-color: #f4f4f9;
  color: #000000;
}
.purple-theme .all-orders__wrapper__header {
  background-color: white;
  color: #380897;
}
.purple-theme .all-orders__wrapper__filter .filter-field .mat-form-field-outline {
  background-color: white;
}
.purple-theme .order-detail__header {
  background-color: white;
  color: #000000;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.purple-theme .order-detail__header .title {
  color: #410aa0;
}
.purple-theme .order-detail__steps {
  background-color: white;
  color: #000000;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.purple-theme .order-detail__steps mat-stepper {
  background-color: transparent;
  pointer-events: none;
  min-width: 100%;
}
.purple-theme .order-detail__steps mat-stepper mat-step-header {
  padding: 0 !important;
  min-width: 130px !important;
  overflow: visible !important;
}
.purple-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon.mat-step-icon-state-noicon {
  background-color: #bdbdbd;
}
.purple-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon {
  height: 15px !important;
  width: 15px !important;
  margin-top: 5px;
}
.purple-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon .mat-icon {
  font-size: 14px;
  height: 14px;
  width: 14px;
}
.purple-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon .currentState {
  color: #42c778;
  font-size: 20px;
  height: 20px;
  width: 20px;
  margin-top: -27px;
}
.purple-theme .order-detail__steps mat-stepper mat-step-header .mat-step-label {
  padding: 5px 5px 0 5px !important;
  font-size: 0.7rem;
}
.purple-theme .order-detail__steps mat-stepper mat-step-header .mat-step-label .disabled {
  color: #bdbdbd;
}
.purple-theme .order-detail__steps .mat-stepper-horizontal-line {
  display: none !important;
}
.purple-theme .order-detail__steps .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:after,
.purple-theme .order-detail__steps .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:before {
  top: 13px !important;
}
.purple-theme .order-detail__steps .mat-horizontal-stepper-wrapper {
  width: fit-content !important;
  margin: auto !important;
}
.purple-theme .order-detail__tabs {
  background-color: #ffffff;
  color: #000000;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.purple-theme .order-detail__tabs mat-tab-group .mat-tab-body-wrapper {
  height: 100%;
}
.purple-theme .order-detail__tabs mat-tab-group .newMessagesIcon {
  color: #e5104c;
}
.purple-theme .order-detail__tabs mat-tab-group .mat-tab-label {
  opacity: 1 !important;
}
.purple-theme .order-detail__tabs .desktopTabs .item {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.purple-theme .order-detail__tabs .desktopTabs .item:first-child {
  background-color: #f4f4f9;
}
.purple-theme .messages {
  background-color: white;
}
.purple-theme .messages__list .message__content {
  background-color: #e4e9f2;
  color: #000000;
}
.purple-theme .messages__list .message__content.budget {
  background-color: #42c778;
  color: #fff;
}
.purple-theme .messages__list .message__content.remote-sign {
  background-color: #480ca8;
  color: #ffffff;
}
.purple-theme .messages__list .message__content.payment {
  background-color: #9077ff;
  color: #ffffff;
}
.purple-theme .messages__list .message__content .budget-icon {
  background-color: #69d394;
}
.purple-theme .messages__list .message__content .remote-sign-icon {
  background-color: #5c0fd8;
}
.purple-theme .messages__list .message__content .payment-icon {
  background-color: #baaaff;
}
.purple-theme .messages__list .message__date {
  color: #1a1a1a;
}
.purple-theme .messages__list .message.customer .message__content {
  background-color: #30068d;
  color: #ffffff;
}
.purple-theme .messages__input {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.purple-theme .messages__input__wrapper {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.purple-theme .messages__input__wrapper .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -6px;
}
.purple-theme .messages__input__wrapper .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -4px;
}
.purple-theme .messages__input__wrapper .send-icon {
  color: #000000;
}
.purple-theme .messages__input__wrapper .attach-icon {
  color: #000000;
}
.purple-theme .navbar {
  background-color: white !important;
  color: #000000 !important;
}
.purple-theme .navbar mat-divider {
  background-color: #000000;
}
@media screen and (max-width: 768px) {
  .purple-theme .navbar {
    background-color: #380897 !important;
    color: #ffffff !important;
  }
  .purple-theme .navbar mat-divider {
    background-color: white;
  }
}
.purple-theme .attachments-item-container {
  background-color: #e4e9f2;
}
.purple-theme .attachments-item-container .file-name {
  color: #380897;
}
.purple-theme .attachments-item-container .file-name mat-icon {
  color: #380897;
}
@media screen and (max-width: 992px) {
  .purple-theme ngx-extended-pdf-viewer #mainContainer {
    min-width: unset !important;
    max-width: 100% !important;
  }
  .purple-theme #toolbarViewerLeft pdf-find-button,
.purple-theme #toolbarViewerLeft pdf-paging-area {
    display: none !important;
  }
  .purple-theme #toolbarViewerRight {
    display: none !important;
  }
  .purple-theme #toolbarViewerMiddle {
    display: flex !important;
  }
}
.purple-theme .budget-viewer-wrapper .mat-dialog-container {
  padding: 0px;
}
.purple-theme .budget-viewer__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.purple-theme .budget-viewer__header h2 {
  color: #380897;
}
.purple-theme .budget-viewer__content__lines .budget-line {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.purple-theme .budget-viewer__content__lines .budget-line.accepted {
  border: 1px solid #56cd86;
  background-color: #56cd86;
  color: white;
}
.purple-theme .budget-viewer__content__lines .budget-line.accepted.authorized {
  border: 1px solid #31a560;
  background-color: #31a560;
}
.purple-theme .budget-viewer__content__lines .budget-line.accepted.authorized .mat-checkbox-disabled .mat-checkbox-label {
  color: white;
}
.purple-theme .budget-viewer__content__lines .budget-line mat-checkbox {
  width: 100%;
  padding: 15px;
}
.purple-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout {
  width: 100%;
}
.purple-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
  width: 90%;
}
@media screen and (max-width: 768px) {
  .purple-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
    width: 80%;
  }
}
.purple-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.purple-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label .checkbox-budget-label-1 {
  font-weight: 600;
}
.purple-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label .checkbox-budget-label-2 {
  font-weight: 500;
  margin-right: 30px;
}
@media screen and (max-width: 768px) {
  .purple-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label .checkbox-budget-label-2 {
    margin-right: 15px;
  }
}
.purple-theme .budget-viewer__content__lines .budget-line mat-checkbox.mat-checkbox-checked .mat-checkbox-background {
  background-color: white;
}
.purple-theme .budget-viewer__content__lines .budget-line mat-checkbox.mat-checkbox-checked .mat-checkbox-background .mat-checkbox-checkmark {
  fill: #42c778 !important;
}
.purple-theme .budget-viewer__content__lines .budget-line mat-checkbox.mat-checkbox-checked .mat-checkbox-background .mat-checkbox-checkmark-path {
  stroke: #42c778 !important;
}
.purple-theme .budget-viewer__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.purple-theme .payments-viewer-wrapper .mat-dialog-container {
  padding: 0px;
}
.purple-theme .payments-viewer__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.purple-theme .payments-viewer__header h2 {
  color: #380897;
}
.purple-theme .payments-viewer__content__lines .payments-line {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.purple-theme .payments-viewer__content__lines .payments-line.pending {
  border: 1px solid #7b5eff;
  background-color: #7b5eff;
  color: white;
}
.purple-theme .payments-viewer__content__lines .payments-line.accepted {
  border: 1px solid #2b9155;
  background-color: #2b9155;
  color: white;
}
.purple-theme .payments-viewer__content__lines .payments-line.error {
  border: 1px solid #c10c0c;
  background-color: #c10c0c;
  color: white;
}
.purple-theme .payments-viewer__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.purple-theme .signature-viewer-wrapper .mat-dialog-container {
  padding: 0px;
}
.purple-theme .signature-viewer__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.purple-theme .signature-viewer__header h2 {
  color: #380897;
}
.purple-theme .signature-viewer__content .mat-step-icon.mat-step-icon-state-edit mat-icon {
  border-radius: 20px;
  padding: 5px;
  background-color: #42c778;
}
.purple-theme .signature-viewer__content .mat-step-icon.mat-step-icon-state-number {
  background-color: #30068d;
}
.purple-theme .signature-viewer__content mat-stepper {
  background-color: transparent;
  min-width: 100%;
}
.purple-theme .signature-viewer__content mat-stepper mat-step-header {
  padding: 0 !important;
  overflow: visible !important;
}
.purple-theme .signature-viewer__content mat-stepper mat-step-header .mat-step-label {
  padding: 5px 5px 0 5px !important;
  font-size: 0.7rem;
}
.purple-theme .signature-viewer__content mat-stepper mat-step-header .mat-step-label .disabled {
  color: #bdbdbd;
}
.purple-theme .signature-viewer__content .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:after,
.purple-theme .signature-viewer__content .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:before,
.purple-theme .signature-viewer__content .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 13px !important;
}
.purple-theme .signature-viewer__content .mat-horizontal-content-container {
  padding: 5px 0 0 0;
}
.purple-theme .signature-viewer__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.purple-theme .remote-signature-viewer-wrapper .mat-dialog-container {
  padding: 0;
}
.purple-theme .unverified-signature-warning {
  display: none !important;
}
.purple-theme .card-docs__section__content__budget, .purple-theme .card-docs__section__content__remote-sign, .purple-theme .card-docs__section__content__payment {
  background-color: #42c778;
  color: white;
  font-size: bold;
}
.purple-theme .card-docs__section__content__budget mat-icon, .purple-theme .card-docs__section__content__remote-sign mat-icon, .purple-theme .card-docs__section__content__payment mat-icon {
  background-color: #69d394;
}
.purple-theme .card-docs__section__content__budget mat-icon.alert-icon, .purple-theme .card-docs__section__content__remote-sign mat-icon.alert-icon, .purple-theme .card-docs__section__content__payment mat-icon.alert-icon {
  color: yellow;
  background-color: transparent;
  margin-left: -7px;
}
.purple-theme .card-docs__section__content__budget.pending, .purple-theme .card-docs__section__content__remote-sign.pending, .purple-theme .card-docs__section__content__payment.pending {
  background-color: #480ca8;
  color: white;
}
.purple-theme .card-docs__section__content__budget.pending mat-icon, .purple-theme .card-docs__section__content__remote-sign.pending mat-icon, .purple-theme .card-docs__section__content__payment.pending mat-icon {
  background-color: #5c0fd8;
}
.purple-theme .card-docs__section__content__budget.pending mat-icon.alert-icon, .purple-theme .card-docs__section__content__remote-sign.pending mat-icon.alert-icon, .purple-theme .card-docs__section__content__payment.pending mat-icon.alert-icon {
  background-color: transparent;
  margin-left: -7px;
}
.purple-theme .card-docs__section__content__budget.payments, .purple-theme .card-docs__section__content__remote-sign.payments, .purple-theme .card-docs__section__content__payment.payments {
  background-color: #6644ff;
  color: #ffffff;
}
.purple-theme .card-docs__section__content__budget.payments mat-icon, .purple-theme .card-docs__section__content__remote-sign.payments mat-icon, .purple-theme .card-docs__section__content__payment.payments mat-icon {
  background-color: #9077ff;
}
.purple-theme .card-docs__section__content__budget.payments mat-icon.alert-icon, .purple-theme .card-docs__section__content__remote-sign.payments mat-icon.alert-icon, .purple-theme .card-docs__section__content__payment.payments mat-icon.alert-icon {
  background-color: transparent;
  margin-left: -7px;
}
.purple-theme .card-docs__section__content__budget.payments.pendingPayments, .purple-theme .card-docs__section__content__remote-sign.payments.pendingPayments, .purple-theme .card-docs__section__content__payment.payments.pendingPayments {
  background-color: #9077ff;
  color: #ffffff;
}
.purple-theme .card-docs__section__content__budget.payments.pendingPayments mat-icon, .purple-theme .card-docs__section__content__remote-sign.payments.pendingPayments mat-icon, .purple-theme .card-docs__section__content__payment.payments.pendingPayments mat-icon {
  background-color: #baaaff;
}
.purple-theme .card-docs__section__content__budget.payments.pendingPayments mat-icon.alert-icon, .purple-theme .card-docs__section__content__remote-sign.payments.pendingPayments mat-icon.alert-icon, .purple-theme .card-docs__section__content__payment.payments.pendingPayments mat-icon.alert-icon {
  background-color: transparent;
  margin-left: -7px;
}
.purple-theme .send-code-content {
  background-color: white;
  color: #000000;
}
.purple-theme .send-code-content__link {
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  color: #380897;
  background-color: #ffffff;
}
.purple-theme .send-code-content__link:hover {
  background-color: #380897;
  color: #ffffff !important;
}
.purple-theme .ask-for-code-content {
  background-color: white;
  color: #000000;
}
.purple-theme .global-message--success {
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;
}
.purple-theme .global-message--success .mat-simple-snackbar-action {
  color: #155724 !important;
}
.purple-theme .global-message--warning {
  color: #2c2c2c !important;
  background-color: rgb(206, 134, 0) !important;
  border-color: #be8f00 !important;
}
.purple-theme .global-message--warning .mat-simple-snackbar-action {
  color: #2c2c2c !important;
}
.purple-theme .global-message--error {
  color: rgb(245, 245, 245) !important;
  background-color: rgb(138, 0, 0) !important;
  border-color: rgb(114, 2, 2) !important;
}
.purple-theme .global-message--error .mat-simple-snackbar-action {
  color: rgb(245, 219, 219) !important;
}
.purple-theme html,
.purple-theme body {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
  height: 100%;
}
.purple-theme body {
  margin: 0;
}
.purple-theme .ngx-summernote-view {
  display: none !important;
}
.purple-theme .content-wrapper {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1120px;
}
.purple-theme .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.purple-theme .mat-app-background {
  height: 100%;
  background-color: #f4f4f9;
}
.purple-theme .uppercase {
  text-transform: uppercase;
}
.purple-theme .capitalize {
  text-transform: capitalize;
}
.purple-theme .message-block {
  align-items: center;
  background-color: #848484;
  border-radius: 0.571rem;
  box-shadow: 0 10px 22px 0 rgba(0, 0, 0, 0.04);
  color: #fff;
  display: flex;
  font-size: 1.143rem;
  font-weight: 600;
  line-height: 1.5;
  overflow-wrap: anywhere;
  padding: 1.429rem 1.714rem;
  word-wrap: anywhere;
}
.purple-theme .message-block .mat-icon {
  flex: 0 0 auto;
  margin-right: 1.143rem;
}
.purple-theme .message-block.message-block__success {
  background-color: #480ca8;
  color: #ffffff;
}
.purple-theme .message-block.message-block__warning {
  background-color: #ffb025;
  color: #fff;
}
.purple-theme .message-block.message-block__error {
  background-color: #f34040;
  color: #fff;
}
.purple-theme .hidden,
.purple-theme *[hidden] {
  display: none !important;
  overflow: hidden !important;
}
.purple-theme .hidden.block,
.purple-theme *[hidden].block {
  display: hidden !important;
}
.purple-theme .inlineBlock {
  display: inline-block;
}
.purple-theme .underline {
  text-decoration: underline;
}
.purple-theme .opacity-1 {
  opacity: 1;
}
.purple-theme .opacity-08 {
  opacity: 0.8;
}
.purple-theme .opacity-06 {
  opacity: 0.6;
}
.purple-theme .opacity-04 {
  opacity: 0.4;
}
.purple-theme .opacity-02 {
  opacity: 0.2;
}
.purple-theme .opacity-0 {
  opacity: 0;
}
.purple-theme .overlay-spinner {
  background: rgba(255, 255, 255, 0.4);
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.purple-theme .overlay-spinner > .mat-spinner {
  margin: 3rem auto;
}
.purple-theme .spin-icon {
  animation: spinner 0.6s linear infinite;
}
.purple-theme .height-100 {
  height: 100%;
}
.purple-theme .note-modal-backdrop {
  display: none !important;
}
.purple-theme .note-editing-area {
  background-color: white;
}
.purple-theme .note-editor .note-editing-area .note-editable table.table-no-bordered td,
.purple-theme .note-editor .note-editing-area .note-editable table.table-no-bordered th {
  border: 0px;
}
.purple-theme .autocomplete-panel-class,
.purple-theme .notification-mention-menu {
  max-height: calc(var(--100vh) - 65px) !important;
  height: 550px !important;
}
.purple-theme .notification-mention-menu .mat-menu-content {
  height: calc(100% - 16px);
}
.purple-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #480ca8;
}
.purple-theme .mat-form-field-appearance-outline .mat-form-field-label {
  color: #480ca8;
  font-weight: 600;
  text-transform: uppercase;
}
.purple-theme .mat-form-field-appearance-outline input.mat-input-element {
  text-overflow: ellipsis;
}
.purple-theme .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  z-index: 1;
  margin-top: 0.2em;
}
.purple-theme .mat-form-field-appearance-outline.date-picker .mat-form-field-subscript-wrapper {
  margin-top: 2.2em;
}
.purple-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
  color: #27075c;
}
.purple-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-label {
  color: #27075c;
}
.purple-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: transparent;
}
.purple-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: #480ca8;
}
.purple-theme .mat-form-field-appearance-outline.mat-form-field-disabled input.mat-input-element {
  color: #121212;
}
.purple-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-form-field-infix,
.purple-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-form-field-suffix {
  padding: 0.25em 0 0.75em 0;
  font-size: 1em;
}
.purple-theme .smaller-mat-form-fields .mat-form-field-appearance-outline.date-picker .mat-form-field-infix,
.purple-theme .smaller-mat-form-fields .mat-form-field-appearance-outline.date-picker .mat-form-field-suffix {
  padding: 0.25em 0 0.6em 0;
  margin-top: -0.3em;
}
.purple-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-infix, .purple-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-suffix {
  padding: 0.2em 0 0.25em 0;
}
.purple-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-infix .mat-input-element, .purple-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-suffix .mat-input-element {
  position: absolute;
  margin-top: -11px;
}
.purple-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-form-field-infix .mat-select-arrow {
  margin-top: 10px;
}
.purple-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-select-empty + .mat-form-field-label-wrapper .mat-form-field-label {
  margin-top: -0.75em;
}
.purple-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-select + .mat-form-field-label-wrapper .mat-form-field-label {
  margin-top: -0.3em;
}
.mat-select .purple-theme .smaller-mat-form-fields__no-margin.mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding: 0 0;
  margin: 0 0;
}
.purple-theme .smaller-mat-form-fields__no-margin.mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding: 0 0;
  margin: 0 0;
}
.purple-theme .smaller-mat-form-fields .mat-form-field__no-border .mat-form-field-outline-start,
.purple-theme .smaller-mat-form-fields .mat-form-field__no-border .mat-form-field-outline-gap,
.purple-theme .smaller-mat-form-fields .mat-form-field__no-border .mat-form-field-outline-end {
  border: none;
}
.purple-theme .smaller-mat-form-fields .mat-form-field__border-more-radius .mat-form-field-outline-start, .purple-theme .smaller-mat-form-fields.mat-form-field__border-more-radius .mat-form-field-outline-start {
  border-radius: 28px 0 0 28px !important;
  min-width: 28px;
}
.purple-theme .smaller-mat-form-fields .mat-form-field__border-more-radius .mat-form-field-outline-end, .purple-theme .smaller-mat-form-fields.mat-form-field__border-more-radius .mat-form-field-outline-end {
  border-radius: 0 28px 28px 0 !important;
}
.purple-theme button.mat-button, .purple-theme button.mat-flat-button {
  font-size: 1rem;
}
.purple-theme button.mat-button.mat-button-fill, .purple-theme button.mat-flat-button.mat-button-fill {
  width: 100%;
}
.purple-theme button.mat-button-w-100-left {
  width: 100%;
  text-align: left;
}
.purple-theme button.mat-flat-button:hover {
  opacity: 0.85;
}
.purple-theme button.close-modal-button.mat-icon-button {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  background-color: rgba(185, 170, 255, 0.3);
  color: #21037d;
}
.purple-theme button.close-modal-button.mat-icon-button:hover {
  background-color: rgba(185, 170, 255, 0.4);
}
.purple-theme button.mat-square-icon {
  border-radius: 5px;
  padding: 0 0;
  min-width: 36px;
  line-height: 36px;
}
.purple-theme button.mat-square-icon.square-28 {
  min-width: 28px;
  line-height: 28px;
}
.purple-theme button.mat-dark {
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
}
.purple-theme button.mat-dark:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
.purple-theme button .mat-icon {
  overflow: visible;
}
.purple-theme .mat-flat-button.mat-primary.mat-button-disabled,
.purple-theme .mat-flat-button.mat-button-disabled.mat-button-disabled,
.purple-theme .mat-raised-button.mat-primary.mat-button-disabled,
.purple-theme .mat-raised-button.mat-button-disabled.mat-button-disabled,
.purple-theme .mat-fab.mat-primary.mat-button-disabled,
.purple-theme .mat-fab.mat-button-disabled.mat-button-disabled,
.purple-theme .mat-mini-fab.mat-primary.mat-button-disabled,
.purple-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: #480ca8;
  color: #ffffff;
  opacity: 0.5;
}
.purple-theme .mat-button.v-align-bottom .mat-button-wrapper > *,
.purple-theme .mat-flat-button.v-align-bottom .mat-button-wrapper > *,
.purple-theme .mat-stroked-button.v-align-bottom .mat-button-wrapper > *,
.purple-theme .mat-raised-button.v-align-bottom .mat-button-wrapper > *,
.purple-theme .mat-icon-button.v-align-bottom .mat-button-wrapper > *,
.purple-theme .mat-fab.v-align-bottom .mat-button-wrapper > *,
.purple-theme .mat-mini-fab.v-align-bottom .mat-button-wrapper > * {
  vertical-align: text-bottom;
}
.purple-theme .mat-button.no-padding,
.purple-theme .mat-flat-button.no-padding,
.purple-theme .mat-stroked-button.no-padding,
.purple-theme .mat-raised-button.no-padding,
.purple-theme .mat-icon-button.no-padding,
.purple-theme .mat-fab.no-padding,
.purple-theme .mat-mini-fab.no-padding {
  padding: 0px;
}
.purple-theme mat-expansion-panel.no-padding .mat-expansion-panel-header {
  padding: 0 !important;
}
.purple-theme mat-expansion-panel.no-padding .mat-expansion-indicator {
  padding: 0 10px;
}
.purple-theme .mat-expansion-panel-header {
  border-radius: initial;
  border-bottom: 1px solid #c8b6e5;
}
.purple-theme .mat-expansion-panel-header.mat-expanded {
  height: 49px;
}
.purple-theme .mat-expansion-panel-header .mat-expansion-indicator {
  border-width: 0 3px 3px 0;
  color: #000000;
}
.purple-theme .mat-expansion-panel-header .mat-expansion-panel-header-title {
  color: #480ca8;
  font-size: 1rem;
  font-weight: 600;
}
.purple-theme .header-color-primary .mat-expansion-panel-header {
  background-color: #480ca8 !important;
}
.purple-theme .header-color-primary .mat-expansion-panel-header:hover {
  background-color: #410aa0 !important;
}
.purple-theme .header-color-primary .mat-expansion-indicator::after {
  color: #ffffff;
}
.purple-theme .header-color-primary .mat-expansion-panel-header-title {
  color: #ffffff;
}
.purple-theme .mat-expanded > .mat-expansion-panel-content {
  padding-top: 16px;
}
.purple-theme mat-expansion-panel.no-padding .mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0 0 16px 0;
}
.purple-theme .mat-select-panel.bigger-selection-panel-class {
  max-height: calc(var(--100vh) - 100px);
}
.purple-theme .mat-select-panel.bigger-selection-panel-class .mat-optgroup-label {
  overflow: visible !important;
}
.purple-theme .mat-tab-page-sections .mat-tab-header {
  border-bottom: 0px;
}
.purple-theme .mat-tab-page-sections .mat-tab-header .mat-tab-label {
  font-size: 1.5rem;
  font-weight: 400;
  padding: 0 15px;
}
.purple-theme .mat-tab-page-sections.font-size-smaller .mat-tab-header .mat-tab-label {
  font-size: 1.1rem;
  font-weight: 200;
}
.purple-theme .mat-tab-group.mat-primary .mat-ink-bar,
.purple-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  height: 3px;
  background-color: #480ca8;
}
.purple-theme .mat-tab-label.text-red,
.purple-theme .mat-tab-label.mat-tab-label-active.text-red {
  color: #c10c0c;
}
.purple-theme .mat-item-card {
  margin-bottom: 20px;
  max-width: calc(50% - 20px);
}
.purple-theme .mat-item-card__title {
  font-size: 1rem;
  color: #26273b;
}
.purple-theme .mat-item-card__icons .mat-icon {
  padding-right: 24px;
  color: #480ca8;
}
.purple-theme .mat-item-card__icons .mat-icon:last-child {
  padding-right: 0;
}
.purple-theme .mat-table {
  background-color: transparent;
}
.purple-theme .mat-table.table-ellipsis {
  width: 100%;
  table-layout: fixed;
}
.purple-theme .mat-table.table-ellipsis th,
.purple-theme .mat-table.table-ellipsis td {
  overflow: hidden;
  width: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 10px;
}
.purple-theme .mat-table.table-ellipsis th.no-ellipsis,
.purple-theme .mat-table.table-ellipsis td.no-ellipsis {
  overflow: inherit;
}
.purple-theme .mat-table .mat-header-row .mat-header-cell {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  color: #000000;
}
.purple-theme .mat-table tr.mat-row {
  background-color: white;
  height: 65px;
}
.purple-theme .mat-table tr.mat-row .mat-cell {
  font-size: 16px;
  color: #26273b;
}
.purple-theme .mat-table tr.mat-row .mat-cell.action-buttons {
  color: #480ca8;
  font-size: 24px;
}
.purple-theme .mat-paginator {
  background-color: transparent;
}
.purple-theme .mat-checkbox-layout {
  white-space: normal !important;
}
.purple-theme mat-checkbox.light-border .mat-checkbox-frame {
  border-color: #ffffff;
}
.purple-theme .mat-tooltip {
  font-size: 0.9em;
  max-width: 350px !important;
}
.purple-theme .mat-menu-panel.dark-theme {
  min-width: 150px;
  background-color: #000000;
}
.purple-theme .mat-menu-panel.dark-theme .mat-menu-item {
  color: #ffffff;
}
.purple-theme .mat-menu-panel.dark-theme .mat-divider {
  background-color: #000000;
}
.purple-theme .draggable-item {
  border-radius: 5px;
  background-color: #f4f4f9;
  margin: 5px 0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.purple-theme .draggable-item .cdk-drag-handle {
  cursor: grab;
}
.purple-theme .draggable-item .cdk-drag-handle:active {
  cursor: grabbing;
}
.purple-theme .draggable-item.cdk-drag-preview {
  pointer-events: fill !important;
  box-shadow: 0px 0px 25px 0px rgb(0, 0, 0);
}
.purple-theme .draggable-item.cdk-drag-preview .cdk-drag-handle {
  cursor: grabbing;
}
.purple-theme .remove-padding mat-dialog-container {
  padding: 0px;
}
.purple-theme .mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Saira;
}
.purple-theme .mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.purple-theme .mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.purple-theme .mat-h1,
.purple-theme .mat-headline,
.purple-theme .mat-typography .mat-h1,
.purple-theme .mat-typography .mat-headline,
.purple-theme .mat-typography h1 {
  font: 900 24px / 32px Saira;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.purple-theme .mat-h2,
.purple-theme .mat-title,
.purple-theme .mat-typography .mat-h2,
.purple-theme .mat-typography .mat-title,
.purple-theme .mat-typography h2 {
  font: 600 20px / 28px Saira;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.purple-theme .mat-h3,
.purple-theme .mat-subheading-2,
.purple-theme .mat-typography .mat-h3,
.purple-theme .mat-typography .mat-subheading-2,
.purple-theme .mat-typography h3 {
  font: 400 16px / 28px Saira;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.purple-theme .mat-h4,
.purple-theme .mat-subheading-1,
.purple-theme .mat-typography .mat-h4,
.purple-theme .mat-typography .mat-subheading-1,
.purple-theme .mat-typography h4 {
  font: 600 14px / 1em Saira;
  letter-spacing: 3px;
  margin: 0 0 16px;
}
.purple-theme .mat-h5,
.purple-theme .mat-typography .mat-h5,
.purple-theme .mat-typography h5 {
  font: 400 calc(16px * 0.83) / 20px Saira;
  margin: 0 0 12px;
}
.purple-theme .mat-h6,
.purple-theme .mat-typography .mat-h6,
.purple-theme .mat-typography h6 {
  font: 400 calc(16px * 0.67) / 20px Saira;
  margin: 0 0 12px;
}
.purple-theme .mat-body-strong,
.purple-theme .mat-body-2,
.purple-theme .mat-typography .mat-body-strong,
.purple-theme .mat-typography .mat-body-2 {
  font: 400 18px / 26px Saira;
  letter-spacing: normal;
}
.purple-theme .mat-body,
.purple-theme .mat-body-1,
.purple-theme .mat-typography .mat-body,
.purple-theme .mat-typography .mat-body-1,
.purple-theme .mat-typography {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
}
.purple-theme .mat-body p,
.purple-theme .mat-body-1 p,
.purple-theme .mat-typography .mat-body p,
.purple-theme .mat-typography .mat-body-1 p,
.purple-theme .mat-typography p {
  margin: 0 0 12px;
}
.purple-theme .mat-small,
.purple-theme .mat-caption,
.purple-theme .mat-typography .mat-small,
.purple-theme .mat-typography .mat-caption {
  font: 400 12px / 20px Saira;
  letter-spacing: normal;
}
.purple-theme .mat-display-4,
.purple-theme .mat-typography .mat-display-4 {
  font: 400 112px / 112px Saira;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.purple-theme .mat-display-3,
.purple-theme .mat-typography .mat-display-3 {
  font: 900 56px / 56px Saira;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.purple-theme .mat-display-2,
.purple-theme .mat-typography .mat-display-2 {
  font: 900 40px / 1.2 Saira;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.purple-theme .mat-display-1,
.purple-theme .mat-typography .mat-display-1 {
  font: 600 32px / 1.13 Saira;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.purple-theme .mat-bottom-sheet-container {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
}
.purple-theme .mat-button, .purple-theme .mat-raised-button, .purple-theme .mat-icon-button, .purple-theme .mat-stroked-button,
.purple-theme .mat-flat-button, .purple-theme .mat-fab, .purple-theme .mat-mini-fab {
  font-family: Saira;
  font-size: 14px;
  font-weight: 600;
}
.purple-theme .mat-button-toggle {
  font-family: Saira;
}
.purple-theme .mat-card {
  font-family: Saira;
}
.purple-theme .mat-card-title {
  font-size: 24px;
  font-weight: 600;
}
.purple-theme .mat-card-header .mat-card-title {
  font-size: 20px;
}
.purple-theme .mat-card-subtitle,
.purple-theme .mat-card-content {
  font-size: 16px;
}
.purple-theme .mat-checkbox {
  font-family: Saira;
}
.purple-theme .mat-checkbox-layout .mat-checkbox-label {
  line-height: 26px;
}
.purple-theme .mat-chip {
  font-size: 18px;
  font-weight: 400;
}
.purple-theme .mat-chip .mat-chip-trailing-icon.mat-icon,
.purple-theme .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.purple-theme .mat-table {
  font-family: Saira;
}
.purple-theme .mat-header-cell {
  font-size: 12px;
  font-weight: 400;
}
.purple-theme .mat-cell, .purple-theme .mat-footer-cell {
  font-size: 16px;
}
.purple-theme .mat-calendar {
  font-family: Saira;
}
.purple-theme .mat-calendar-body {
  font-size: 13px;
}
.purple-theme .mat-calendar-body-label,
.purple-theme .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 600;
}
.purple-theme .mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.purple-theme .mat-dialog-title {
  font: 600 20px / 28px Saira;
  letter-spacing: normal;
}
.purple-theme .mat-expansion-panel-header {
  font-family: Saira;
  font-size: 14px;
  font-weight: 600;
}
.purple-theme .mat-expansion-panel-content {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
}
.purple-theme .mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Saira;
  letter-spacing: normal;
}
.purple-theme .mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.purple-theme .mat-form-field-prefix .mat-icon,
.purple-theme .mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.purple-theme .mat-form-field-prefix .mat-icon-button,
.purple-theme .mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.purple-theme .mat-form-field-prefix .mat-icon-button .mat-icon,
.purple-theme .mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.purple-theme .mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.purple-theme .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.purple-theme .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34367em) scale(0.75);
  width: 133.3334133333%;
}
.purple-theme .mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34366em) scale(0.75);
  width: 133.3334233333%;
}
.purple-theme .mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.purple-theme .mat-form-field-label {
  top: 1.34375em;
}
.purple-theme .mat-form-field-underline {
  bottom: 1.34375em;
}
.purple-theme .mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.purple-theme .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.purple-theme .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.purple-theme .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.purple-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00124px);
  width: 133.3335733333%;
}
.purple-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00125px);
  width: 133.3335833333%;
}
.purple-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00126px);
  width: 133.3335933333%;
}
.purple-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.purple-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.purple-theme .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .purple-theme .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.purple-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28098em) scale(0.75);
  }
  .purple-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28097em) scale(0.75);
  }
  .purple-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28096em) scale(0.75);
  }
}
.purple-theme .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.purple-theme .mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.purple-theme .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.purple-theme .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59367em) scale(0.75);
  width: 133.3334133333%;
}
.purple-theme .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59366em) scale(0.75);
  width: 133.3334233333%;
}
.purple-theme .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.purple-theme .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.purple-theme .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.purple-theme .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59367em) scale(0.75);
  width: 133.3334133333%;
}
.purple-theme .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59366em) scale(0.75);
  width: 133.3334233333%;
}
.purple-theme .mat-grid-tile-header,
.purple-theme .mat-grid-tile-footer {
  font-size: 16px;
}
.purple-theme .mat-grid-tile-header .mat-line,
.purple-theme .mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.purple-theme .mat-grid-tile-header .mat-line:nth-child(n+2),
.purple-theme .mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.purple-theme input.mat-input-element {
  margin-top: -0.0625em;
}
.purple-theme .mat-menu-item {
  font-family: Saira;
  font-size: 16px;
  font-weight: 400;
}
.purple-theme .mat-paginator,
.purple-theme .mat-paginator-page-size .mat-select-trigger {
  font-family: Saira;
  font-size: 12px;
}
.purple-theme .mat-radio-button {
  font-family: Saira;
}
.purple-theme .mat-select {
  font-family: Saira;
}
.purple-theme .mat-select-trigger {
  height: 1.125em;
}
.purple-theme .mat-slide-toggle-content {
  font-family: Saira;
}
.purple-theme .mat-slider-thumb-label-text {
  font-family: Saira;
  font-size: 12px;
  font-weight: 400;
}
.purple-theme .mat-stepper-vertical, .purple-theme .mat-stepper-horizontal {
  font-family: Saira;
}
.purple-theme .mat-step-label {
  font-size: 16px;
  font-weight: 400;
}
.purple-theme .mat-step-sub-label-error {
  font-weight: normal;
}
.purple-theme .mat-step-label-error {
  font-size: 18px;
}
.purple-theme .mat-step-label-selected {
  font-size: 18px;
  font-weight: 400;
}
.purple-theme .mat-tab-group {
  font-family: Saira;
}
.purple-theme .mat-tab-label, .purple-theme .mat-tab-link {
  font-family: Saira;
  font-size: 14px;
  font-weight: 600;
}
.purple-theme .mat-toolbar,
.purple-theme .mat-toolbar h1,
.purple-theme .mat-toolbar h2,
.purple-theme .mat-toolbar h3,
.purple-theme .mat-toolbar h4,
.purple-theme .mat-toolbar h5,
.purple-theme .mat-toolbar h6 {
  font: 600 20px / 28px Saira;
  letter-spacing: normal;
  margin: 0;
}
.purple-theme .mat-tooltip {
  font-family: Saira;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.purple-theme .mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.purple-theme .mat-list-item {
  font-family: Saira;
}
.purple-theme .mat-list-option {
  font-family: Saira;
}
.purple-theme .mat-list-base .mat-list-item {
  font-size: 16px;
}
.purple-theme .mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.purple-theme .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.purple-theme .mat-list-base .mat-list-option {
  font-size: 16px;
}
.purple-theme .mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.purple-theme .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.purple-theme .mat-list-base .mat-subheader {
  font-family: Saira;
  font-size: 18px;
  font-weight: 400;
}
.purple-theme .mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.purple-theme .mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.purple-theme .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.purple-theme .mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.purple-theme .mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.purple-theme .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.purple-theme .mat-list-base[dense] .mat-subheader {
  font-family: Saira;
  font-size: 12px;
  font-weight: 400;
}
.purple-theme .mat-option {
  font-family: Saira;
  font-size: 16px;
}
.purple-theme .mat-optgroup-label {
  font: 400 18px / 26px Saira;
  letter-spacing: normal;
}
.purple-theme .mat-simple-snackbar {
  font-family: Saira;
  font-size: 16px;
}
.purple-theme .mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 600;
}
.purple-theme .mat-tree {
  font-family: Saira;
}
.purple-theme .mat-tree-node,
.purple-theme .mat-nested-tree-node {
  font-weight: 400;
  font-size: 16px;
}
.purple-theme .mat-ripple {
  overflow: hidden;
  position: relative;
}
.purple-theme .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.purple-theme .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.purple-theme .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .purple-theme .mat-ripple-element {
  display: none;
}

.purple-theme .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .purple-theme .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.purple-theme .cdk-overlay-container, .purple-theme .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.purple-theme .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.purple-theme .cdk-overlay-container:empty {
  display: none;
}
.purple-theme .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.purple-theme .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.purple-theme .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.purple-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .purple-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.purple-theme .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.purple-theme .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.purple-theme .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.purple-theme .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.purple-theme .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.purple-theme .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.purple-theme textarea.cdk-textarea-autosize {
  resize: none;
}
.purple-theme textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.purple-theme textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.purple-theme .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.purple-theme .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.purple-theme .mat-focus-indicator {
  position: relative;
}
.purple-theme .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.purple-theme .mat-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .purple-theme {
  --mat-focus-indicator-display: block;
}

.purple-theme .mat-mdc-focus-indicator {
  position: relative;
}
.purple-theme .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.purple-theme .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .purple-theme {
  --mat-mdc-focus-indicator-display: block;
}

.purple-theme .mat-ripple-element {
  background-color: rgba(18, 18, 18, 0.1);
}
.purple-theme .mat-option {
  color: #121212;
}
.purple-theme .mat-option:hover:not(.mat-option-disabled), .purple-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.purple-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.purple-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: #121212;
}
.purple-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.purple-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #480ca8;
}
.purple-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #9fa8da;
}
.purple-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e81253;
}
.purple-theme .mat-optgroup-label {
  color: #26273b;
}
.purple-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.purple-theme .mat-pseudo-checkbox {
  color: #26273b;
}
.purple-theme .mat-pseudo-checkbox::after {
  color: white;
}
.purple-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.purple-theme .mat-primary .mat-pseudo-checkbox-checked,
.purple-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #480ca8;
}
.purple-theme .mat-pseudo-checkbox-checked,
.purple-theme .mat-pseudo-checkbox-indeterminate,
.purple-theme .mat-accent .mat-pseudo-checkbox-checked,
.purple-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #9fa8da;
}
.purple-theme .mat-warn .mat-pseudo-checkbox-checked,
.purple-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e81253;
}
.purple-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.purple-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.purple-theme .mat-app-background, .purple-theme.mat-app-background {
  background-color: white;
  color: #121212;
}
.purple-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(18, 18, 18, 0.2), 0px 1px 1px 0px rgba(18, 18, 18, 0.14), 0px 1px 3px 0px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(18, 18, 18, 0.2), 0px 3px 4px 0px rgba(18, 18, 18, 0.14), 0px 1px 8px 0px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 5px 8px 0px rgba(18, 18, 18, 0.14), 0px 1px 14px 0px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 6px 10px 0px rgba(18, 18, 18, 0.14), 0px 1px 18px 0px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(18, 18, 18, 0.2), 0px 7px 10px 1px rgba(18, 18, 18, 0.14), 0px 2px 16px 1px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(18, 18, 18, 0.2), 0px 8px 10px 1px rgba(18, 18, 18, 0.14), 0px 3px 14px 2px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(18, 18, 18, 0.2), 0px 9px 12px 1px rgba(18, 18, 18, 0.14), 0px 3px 16px 2px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(18, 18, 18, 0.2), 0px 10px 14px 1px rgba(18, 18, 18, 0.14), 0px 4px 18px 3px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(18, 18, 18, 0.2), 0px 11px 15px 1px rgba(18, 18, 18, 0.14), 0px 4px 20px 3px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(18, 18, 18, 0.2), 0px 12px 17px 2px rgba(18, 18, 18, 0.14), 0px 5px 22px 4px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(18, 18, 18, 0.2), 0px 13px 19px 2px rgba(18, 18, 18, 0.14), 0px 5px 24px 4px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(18, 18, 18, 0.2), 0px 14px 21px 2px rgba(18, 18, 18, 0.14), 0px 5px 26px 4px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(18, 18, 18, 0.2), 0px 15px 22px 2px rgba(18, 18, 18, 0.14), 0px 6px 28px 5px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(18, 18, 18, 0.2), 0px 16px 24px 2px rgba(18, 18, 18, 0.14), 0px 6px 30px 5px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(18, 18, 18, 0.2), 0px 17px 26px 2px rgba(18, 18, 18, 0.14), 0px 6px 32px 5px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(18, 18, 18, 0.2), 0px 18px 28px 2px rgba(18, 18, 18, 0.14), 0px 7px 34px 6px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(18, 18, 18, 0.2), 0px 19px 29px 2px rgba(18, 18, 18, 0.14), 0px 7px 36px 6px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(18, 18, 18, 0.2), 0px 20px 31px 3px rgba(18, 18, 18, 0.14), 0px 8px 38px 7px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(18, 18, 18, 0.2), 0px 21px 33px 3px rgba(18, 18, 18, 0.14), 0px 8px 40px 7px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(18, 18, 18, 0.2), 0px 22px 35px 3px rgba(18, 18, 18, 0.14), 0px 8px 42px 7px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(18, 18, 18, 0.2), 0px 23px 36px 3px rgba(18, 18, 18, 0.14), 0px 9px 44px 8px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(18, 18, 18, 0.2), 0px 24px 38px 3px rgba(18, 18, 18, 0.14), 0px 9px 46px 8px rgba(18, 18, 18, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.purple-theme .mat-autocomplete-panel {
  background: white;
  color: #121212;
}
.purple-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.purple-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: #121212;
}
.purple-theme .mat-badge-content {
  color: white;
  background: #480ca8;
}
.cdk-high-contrast-active .purple-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.purple-theme .mat-badge-accent .mat-badge-content {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.purple-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e81253;
}
.purple-theme .mat-badge-disabled .mat-badge-content {
  background: #c1c1c1;
  color: rgba(0, 0, 0, 0.38);
}
.purple-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(18, 18, 18, 0.2), 0px 16px 24px 2px rgba(18, 18, 18, 0.14), 0px 6px 30px 5px rgba(18, 18, 18, 0.12);
  background: white;
  color: #121212;
}
.purple-theme .mat-button, .purple-theme .mat-icon-button, .purple-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.purple-theme .mat-button.mat-primary, .purple-theme .mat-icon-button.mat-primary, .purple-theme .mat-stroked-button.mat-primary {
  color: #480ca8;
}
.purple-theme .mat-button.mat-accent, .purple-theme .mat-icon-button.mat-accent, .purple-theme .mat-stroked-button.mat-accent {
  color: #9fa8da;
}
.purple-theme .mat-button.mat-warn, .purple-theme .mat-icon-button.mat-warn, .purple-theme .mat-stroked-button.mat-warn {
  color: #e81253;
}
.purple-theme .mat-button.mat-primary.mat-button-disabled, .purple-theme .mat-button.mat-accent.mat-button-disabled, .purple-theme .mat-button.mat-warn.mat-button-disabled, .purple-theme .mat-button.mat-button-disabled.mat-button-disabled, .purple-theme .mat-icon-button.mat-primary.mat-button-disabled, .purple-theme .mat-icon-button.mat-accent.mat-button-disabled, .purple-theme .mat-icon-button.mat-warn.mat-button-disabled, .purple-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .purple-theme .mat-stroked-button.mat-primary.mat-button-disabled, .purple-theme .mat-stroked-button.mat-accent.mat-button-disabled, .purple-theme .mat-stroked-button.mat-warn.mat-button-disabled, .purple-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(18, 18, 18, 0.26);
}
.purple-theme .mat-button.mat-primary .mat-button-focus-overlay, .purple-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .purple-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #480ca8;
}
.purple-theme .mat-button.mat-accent .mat-button-focus-overlay, .purple-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .purple-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #9fa8da;
}
.purple-theme .mat-button.mat-warn .mat-button-focus-overlay, .purple-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .purple-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e81253;
}
.purple-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .purple-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .purple-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.purple-theme .mat-button .mat-ripple-element, .purple-theme .mat-icon-button .mat-ripple-element, .purple-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.purple-theme .mat-button-focus-overlay {
  background: #121212;
}
.purple-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.purple-theme .mat-flat-button, .purple-theme .mat-raised-button, .purple-theme .mat-fab, .purple-theme .mat-mini-fab {
  color: #121212;
  background-color: white;
}
.purple-theme .mat-flat-button.mat-primary, .purple-theme .mat-raised-button.mat-primary, .purple-theme .mat-fab.mat-primary, .purple-theme .mat-mini-fab.mat-primary {
  color: white;
}
.purple-theme .mat-flat-button.mat-accent, .purple-theme .mat-raised-button.mat-accent, .purple-theme .mat-fab.mat-accent, .purple-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.purple-theme .mat-flat-button.mat-warn, .purple-theme .mat-raised-button.mat-warn, .purple-theme .mat-fab.mat-warn, .purple-theme .mat-mini-fab.mat-warn {
  color: white;
}
.purple-theme .mat-flat-button.mat-primary.mat-button-disabled, .purple-theme .mat-flat-button.mat-accent.mat-button-disabled, .purple-theme .mat-flat-button.mat-warn.mat-button-disabled, .purple-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .purple-theme .mat-raised-button.mat-primary.mat-button-disabled, .purple-theme .mat-raised-button.mat-accent.mat-button-disabled, .purple-theme .mat-raised-button.mat-warn.mat-button-disabled, .purple-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .purple-theme .mat-fab.mat-primary.mat-button-disabled, .purple-theme .mat-fab.mat-accent.mat-button-disabled, .purple-theme .mat-fab.mat-warn.mat-button-disabled, .purple-theme .mat-fab.mat-button-disabled.mat-button-disabled, .purple-theme .mat-mini-fab.mat-primary.mat-button-disabled, .purple-theme .mat-mini-fab.mat-accent.mat-button-disabled, .purple-theme .mat-mini-fab.mat-warn.mat-button-disabled, .purple-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(18, 18, 18, 0.26);
}
.purple-theme .mat-flat-button.mat-primary, .purple-theme .mat-raised-button.mat-primary, .purple-theme .mat-fab.mat-primary, .purple-theme .mat-mini-fab.mat-primary {
  background-color: #480ca8;
}
.purple-theme .mat-flat-button.mat-accent, .purple-theme .mat-raised-button.mat-accent, .purple-theme .mat-fab.mat-accent, .purple-theme .mat-mini-fab.mat-accent {
  background-color: #9fa8da;
}
.purple-theme .mat-flat-button.mat-warn, .purple-theme .mat-raised-button.mat-warn, .purple-theme .mat-fab.mat-warn, .purple-theme .mat-mini-fab.mat-warn {
  background-color: #e81253;
}
.purple-theme .mat-flat-button.mat-primary.mat-button-disabled, .purple-theme .mat-flat-button.mat-accent.mat-button-disabled, .purple-theme .mat-flat-button.mat-warn.mat-button-disabled, .purple-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .purple-theme .mat-raised-button.mat-primary.mat-button-disabled, .purple-theme .mat-raised-button.mat-accent.mat-button-disabled, .purple-theme .mat-raised-button.mat-warn.mat-button-disabled, .purple-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .purple-theme .mat-fab.mat-primary.mat-button-disabled, .purple-theme .mat-fab.mat-accent.mat-button-disabled, .purple-theme .mat-fab.mat-warn.mat-button-disabled, .purple-theme .mat-fab.mat-button-disabled.mat-button-disabled, .purple-theme .mat-mini-fab.mat-primary.mat-button-disabled, .purple-theme .mat-mini-fab.mat-accent.mat-button-disabled, .purple-theme .mat-mini-fab.mat-warn.mat-button-disabled, .purple-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: #f7f7f7;
}
.purple-theme .mat-flat-button.mat-primary .mat-ripple-element, .purple-theme .mat-raised-button.mat-primary .mat-ripple-element, .purple-theme .mat-fab.mat-primary .mat-ripple-element, .purple-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.purple-theme .mat-flat-button.mat-accent .mat-ripple-element, .purple-theme .mat-raised-button.mat-accent .mat-ripple-element, .purple-theme .mat-fab.mat-accent .mat-ripple-element, .purple-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.purple-theme .mat-flat-button.mat-warn .mat-ripple-element, .purple-theme .mat-raised-button.mat-warn .mat-ripple-element, .purple-theme .mat-fab.mat-warn .mat-ripple-element, .purple-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.purple-theme .mat-stroked-button:not([class*=mat-elevation-z]), .purple-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(18, 18, 18, 0.2), 0px 8px 10px 1px rgba(18, 18, 18, 0.14), 0px 3px 14px 2px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-fab:not([class*=mat-elevation-z]), .purple-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 6px 10px 0px rgba(18, 18, 18, 0.14), 0px 1px 18px 0px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .purple-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(18, 18, 18, 0.2), 0px 12px 17px 2px rgba(18, 18, 18, 0.14), 0px 5px 22px 4px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .purple-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.purple-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.purple-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.purple-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.purple-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.purple-theme .mat-button-toggle-appearance-standard {
  color: #121212;
  background: white;
}
.purple-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.purple-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.purple-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.purple-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.purple-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: #26273b;
}
.purple-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #121212;
}
.purple-theme .mat-button-toggle-disabled {
  color: rgba(18, 18, 18, 0.26);
  background-color: #eeeeee;
}
.purple-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.purple-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.purple-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.purple-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.purple-theme .mat-card {
  background: white;
  color: #121212;
}
.purple-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(18, 18, 18, 0.2), 0px 1px 1px 0px rgba(18, 18, 18, 0.14), 0px 1px 3px 0px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-card-subtitle {
  color: #26273b;
}
.purple-theme .mat-checkbox-frame {
  border-color: #26273b;
}
.purple-theme .mat-checkbox-checkmark {
  fill: white;
}
.purple-theme .mat-checkbox-checkmark-path {
  stroke: white !important;
}
.purple-theme .mat-checkbox-mixedmark {
  background-color: white;
}
.purple-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .purple-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #480ca8;
}
.purple-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .purple-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #9fa8da;
}
.purple-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .purple-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e81253;
}
.purple-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .purple-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.purple-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.purple-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.purple-theme .mat-checkbox .mat-ripple-element {
  background-color: #121212;
}
.purple-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.purple-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #480ca8;
}
.purple-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.purple-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #9fa8da;
}
.purple-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.purple-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e81253;
}
.purple-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: #121212;
}
.purple-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: #121212;
  opacity: 0.4;
}
.purple-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(18, 18, 18, 0.2), 0px 3px 4px 0px rgba(18, 18, 18, 0.14), 0px 1px 8px 0px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.purple-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.purple-theme .mat-chip.mat-standard-chip::after {
  background: #121212;
}
.purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #480ca8;
  color: white;
}
.purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e81253;
  color: white;
}
.purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.purple-theme .mat-table {
  background: white;
}
.purple-theme .mat-table thead, .purple-theme .mat-table tbody, .purple-theme .mat-table tfoot,
.purple-theme mat-header-row, .purple-theme mat-row, .purple-theme mat-footer-row,
.purple-theme [mat-header-row], .purple-theme [mat-row], .purple-theme [mat-footer-row],
.purple-theme .mat-table-sticky {
  background: inherit;
}
.purple-theme mat-row, .purple-theme mat-header-row, .purple-theme mat-footer-row,
.purple-theme th.mat-header-cell, .purple-theme td.mat-cell, .purple-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.purple-theme .mat-header-cell {
  color: #26273b;
}
.purple-theme .mat-cell, .purple-theme .mat-footer-cell {
  color: #121212;
}
.purple-theme .mat-calendar-arrow {
  fill: rgba(18, 18, 18, 0.54);
}
.purple-theme .mat-datepicker-toggle,
.purple-theme .mat-datepicker-content .mat-calendar-next-button,
.purple-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(18, 18, 18, 0.54);
}
.purple-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.purple-theme .mat-calendar-table-header,
.purple-theme .mat-calendar-body-label {
  color: #26273b;
}
.purple-theme .mat-calendar-body-cell-content,
.purple-theme .mat-date-range-input-separator {
  color: #121212;
  border-color: transparent;
}
.purple-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.purple-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.purple-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.purple-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.purple-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.purple-theme .mat-calendar-body-in-range::before {
  background: rgba(72, 12, 168, 0.2);
}
.purple-theme .mat-calendar-body-comparison-identical,
.purple-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.purple-theme .mat-calendar-body-comparison-bridge-start::before,
.purple-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(72, 12, 168, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.purple-theme .mat-calendar-body-comparison-bridge-end::before,
.purple-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(72, 12, 168, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.purple-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.purple-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.purple-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.purple-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.purple-theme .mat-calendar-body-selected {
  background-color: #480ca8;
  color: white;
}
.purple-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(72, 12, 168, 0.4);
}
.purple-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.purple-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.purple-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(72, 12, 168, 0.3);
}
@media (hover: hover) {
  .purple-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(72, 12, 168, 0.3);
  }
}
.purple-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
  background-color: white;
  color: #121212;
}
.purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(159, 168, 218, 0.2);
}
.purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.purple-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(159, 168, 218, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.purple-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(159, 168, 218, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(159, 168, 218, 0.4);
}
.purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.purple-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.purple-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(159, 168, 218, 0.3);
}
@media (hover: hover) {
  .purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(159, 168, 218, 0.3);
  }
}
.purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(232, 18, 83, 0.2);
}
.purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.purple-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(232, 18, 83, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.purple-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(232, 18, 83, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e81253;
  color: white;
}
.purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(232, 18, 83, 0.4);
}
.purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.purple-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.purple-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(232, 18, 83, 0.3);
}
@media (hover: hover) {
  .purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(232, 18, 83, 0.3);
  }
}
.purple-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(18, 18, 18, 0.2), 0px 24px 38px 3px rgba(18, 18, 18, 0.14), 0px 9px 46px 8px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-datepicker-toggle-active {
  color: #480ca8;
}
.purple-theme .mat-datepicker-toggle-active.mat-accent {
  color: #9fa8da;
}
.purple-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e81253;
}
.purple-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.purple-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(18, 18, 18, 0.2), 0px 24px 38px 3px rgba(18, 18, 18, 0.14), 0px 9px 46px 8px rgba(18, 18, 18, 0.12);
  background: white;
  color: #121212;
}
.purple-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.purple-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.purple-theme .mat-expansion-panel {
  background: white;
  color: #121212;
}
.purple-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.purple-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .purple-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .purple-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .purple-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.purple-theme .mat-expansion-panel-header-title {
  color: #121212;
}
.purple-theme .mat-expansion-panel-header-description,
.purple-theme .mat-expansion-indicator::after {
  color: #26273b;
}
.purple-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(18, 18, 18, 0.26);
}
.purple-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.purple-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.purple-theme .mat-form-field-label {
  color: rgba(38, 39, 59, 0.6);
}
.purple-theme .mat-hint {
  color: rgba(38, 39, 59, 0.6);
}
.purple-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #480ca8;
}
.purple-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #9fa8da;
}
.purple-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e81253;
}
.purple-theme .mat-focused .mat-form-field-required-marker {
  color: #9fa8da;
}
.purple-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.purple-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #480ca8;
}
.purple-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #9fa8da;
}
.purple-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e81253;
}
.purple-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #480ca8;
}
.purple-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #9fa8da;
}
.purple-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e81253;
}
.purple-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e81253;
}
.purple-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.purple-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e81253;
}
.purple-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.purple-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e81253;
}
.purple-theme .mat-error {
  color: #e81253;
}
.purple-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: #26273b;
}
.purple-theme .mat-form-field-appearance-legacy .mat-hint {
  color: #26273b;
}
.purple-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.purple-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.purple-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.purple-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.purple-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(18, 18, 18, 0.04);
}
.purple-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(18, 18, 18, 0.02);
}
.purple-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.purple-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.purple-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.purple-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.purple-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.purple-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #480ca8;
}
.purple-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #9fa8da;
}
.purple-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e81253;
}
.purple-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e81253;
}
.purple-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.purple-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.purple-theme .mat-icon.mat-primary {
  color: #480ca8;
}
.purple-theme .mat-icon.mat-accent {
  color: #9fa8da;
}
.purple-theme .mat-icon.mat-warn {
  color: #e81253;
}
.purple-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: #26273b;
}
.purple-theme .mat-input-element:disabled,
.purple-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.purple-theme .mat-input-element {
  caret-color: #480ca8;
}
.purple-theme .mat-input-element::placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.purple-theme .mat-input-element::-moz-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.purple-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.purple-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.purple-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #9fa8da;
}
.purple-theme .mat-form-field.mat-warn .mat-input-element,
.purple-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e81253;
}
.purple-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e81253;
}
.purple-theme .mat-list-base .mat-list-item {
  color: #121212;
}
.purple-theme .mat-list-base .mat-list-option {
  color: #121212;
}
.purple-theme .mat-list-base .mat-subheader {
  color: #26273b;
}
.purple-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.purple-theme .mat-list-option:hover, .purple-theme .mat-list-option:focus,
.purple-theme .mat-nav-list .mat-list-item:hover,
.purple-theme .mat-nav-list .mat-list-item:focus,
.purple-theme .mat-action-list .mat-list-item:hover,
.purple-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.purple-theme .mat-list-single-selected-option, .purple-theme .mat-list-single-selected-option:hover, .purple-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.purple-theme .mat-menu-panel {
  background: white;
}
.purple-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-menu-item {
  background: transparent;
  color: #121212;
}
.purple-theme .mat-menu-item[disabled],
.purple-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.purple-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.purple-theme .mat-menu-item .mat-icon-no-color,
.purple-theme .mat-menu-submenu-icon {
  color: rgba(18, 18, 18, 0.54);
}
.purple-theme .mat-menu-item:hover:not([disabled]),
.purple-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.purple-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.purple-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.purple-theme .mat-paginator {
  background: white;
}
.purple-theme .mat-paginator,
.purple-theme .mat-paginator-page-size .mat-select-trigger {
  color: #26273b;
}
.purple-theme .mat-paginator-decrement,
.purple-theme .mat-paginator-increment {
  border-top: 2px solid rgba(18, 18, 18, 0.54);
  border-right: 2px solid rgba(18, 18, 18, 0.54);
}
.purple-theme .mat-paginator-first,
.purple-theme .mat-paginator-last {
  border-top: 2px solid rgba(18, 18, 18, 0.54);
}
.purple-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.purple-theme .mat-icon-button[disabled] .mat-paginator-increment,
.purple-theme .mat-icon-button[disabled] .mat-paginator-first,
.purple-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.purple-theme .mat-progress-bar-background {
  fill: #d1c2e9;
}
.purple-theme .mat-progress-bar-buffer {
  background-color: #d1c2e9;
}
.purple-theme .mat-progress-bar-fill::after {
  background-color: #480ca8;
}
.purple-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #e7e9f6;
}
.purple-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #e7e9f6;
}
.purple-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #9fa8da;
}
.purple-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9c4d4;
}
.purple-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9c4d4;
}
.purple-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e81253;
}
.purple-theme .mat-progress-spinner circle, .purple-theme .mat-spinner circle {
  stroke: #480ca8;
}
.purple-theme .mat-progress-spinner.mat-accent circle, .purple-theme .mat-spinner.mat-accent circle {
  stroke: #9fa8da;
}
.purple-theme .mat-progress-spinner.mat-warn circle, .purple-theme .mat-spinner.mat-warn circle {
  stroke: #e81253;
}
.purple-theme .mat-radio-outer-circle {
  border-color: #26273b;
}
.purple-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #480ca8;
}
.purple-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.purple-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .purple-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .purple-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #480ca8;
}
.purple-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #9fa8da;
}
.purple-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.purple-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .purple-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .purple-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #9fa8da;
}
.purple-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e81253;
}
.purple-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.purple-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .purple-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .purple-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e81253;
}
.purple-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.purple-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.purple-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.purple-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.purple-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.purple-theme .mat-radio-button .mat-ripple-element {
  background-color: #121212;
}
.purple-theme .mat-select-value {
  color: #121212;
}
.purple-theme .mat-select-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.purple-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.purple-theme .mat-select-arrow {
  color: #26273b;
}
.purple-theme .mat-select-panel {
  background: white;
}
.purple-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.purple-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #480ca8;
}
.purple-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #9fa8da;
}
.purple-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e81253;
}
.purple-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e81253;
}
.purple-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.purple-theme .mat-drawer-container {
  background-color: white;
  color: #121212;
}
.purple-theme .mat-drawer {
  background-color: white;
  color: #121212;
}
.purple-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.purple-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(18, 18, 18, 0.2), 0px 16px 24px 2px rgba(18, 18, 18, 0.14), 0px 6px 30px 5px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.purple-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.purple-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.purple-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.purple-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.purple-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #9fa8da;
}
.purple-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(159, 168, 218, 0.54);
}
.purple-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #9fa8da;
}
.purple-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #480ca8;
}
.purple-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(72, 12, 168, 0.54);
}
.purple-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #480ca8;
}
.purple-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e81253;
}
.purple-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(232, 18, 83, 0.54);
}
.purple-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e81253;
}
.purple-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: #121212;
}
.purple-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(18, 18, 18, 0.2), 0px 1px 1px 0px rgba(18, 18, 18, 0.14), 0px 1px 3px 0px rgba(18, 18, 18, 0.12);
  background-color: #fafafa;
}
.purple-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.purple-theme .mat-slider-track-background {
  background-color: rgba(18, 18, 18, 0.26);
}
.purple-theme .mat-slider.mat-primary .mat-slider-track-fill,
.purple-theme .mat-slider.mat-primary .mat-slider-thumb,
.purple-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #480ca8;
}
.purple-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.purple-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(72, 12, 168, 0.2);
}
.purple-theme .mat-slider.mat-accent .mat-slider-track-fill,
.purple-theme .mat-slider.mat-accent .mat-slider-thumb,
.purple-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #9fa8da;
}
.purple-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.purple-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(159, 168, 218, 0.2);
}
.purple-theme .mat-slider.mat-warn .mat-slider-track-fill,
.purple-theme .mat-slider.mat-warn .mat-slider-thumb,
.purple-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e81253;
}
.purple-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.purple-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(232, 18, 83, 0.2);
}
.purple-theme .mat-slider:hover .mat-slider-track-background,
.purple-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(18, 18, 18, 0.38);
}
.purple-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.purple-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.purple-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(18, 18, 18, 0.26);
}
.purple-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(18, 18, 18, 0.26);
}
.purple-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.purple-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(18, 18, 18, 0.87);
}
.purple-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.purple-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(18, 18, 18, 0.26);
}
.purple-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(18, 18, 18, 0.26);
  background-color: transparent;
}
.purple-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .purple-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(18, 18, 18, 0.38);
}
.purple-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .purple-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(18, 18, 18, 0.26);
}
.purple-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(18, 18, 18, 0.7);
}
.purple-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(18, 18, 18, 0.7), rgba(18, 18, 18, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(18, 18, 18, 0.7), rgba(18, 18, 18, 0.7) 2px, transparent 0, transparent);
}
.purple-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(18, 18, 18, 0.7), rgba(18, 18, 18, 0.7) 2px, transparent 0, transparent);
}
.purple-theme .mat-step-header.cdk-keyboard-focused, .purple-theme .mat-step-header.cdk-program-focused, .purple-theme .mat-step-header:hover:not([aria-disabled]), .purple-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.purple-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .purple-theme .mat-step-header:hover {
    background: none;
  }
}
.purple-theme .mat-step-header .mat-step-label,
.purple-theme .mat-step-header .mat-step-optional {
  color: #26273b;
}
.purple-theme .mat-step-header .mat-step-icon {
  background-color: #26273b;
  color: white;
}
.purple-theme .mat-step-header .mat-step-icon-selected,
.purple-theme .mat-step-header .mat-step-icon-state-done,
.purple-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #480ca8;
  color: white;
}
.purple-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.purple-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.purple-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.purple-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.purple-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.purple-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.purple-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.purple-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e81253;
  color: white;
}
.purple-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e81253;
}
.purple-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: #121212;
}
.purple-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e81253;
}
.purple-theme .mat-stepper-horizontal, .purple-theme .mat-stepper-vertical {
  background-color: white;
}
.purple-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.purple-theme .mat-horizontal-stepper-header::before,
.purple-theme .mat-horizontal-stepper-header::after,
.purple-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.purple-theme .mat-sort-header-arrow {
  color: #26273b;
}
.purple-theme .mat-tab-nav-bar,
.purple-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.purple-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.purple-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.purple-theme .mat-tab-label, .purple-theme .mat-tab-link {
  color: #121212;
}
.purple-theme .mat-tab-label.mat-tab-disabled, .purple-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.purple-theme .mat-tab-header-pagination-chevron {
  border-color: #121212;
}
.purple-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.purple-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.purple-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.purple-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .purple-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.purple-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.purple-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .purple-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .purple-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.purple-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.purple-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(200, 182, 229, 0.3);
}
.purple-theme .mat-tab-group.mat-primary .mat-ink-bar, .purple-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #480ca8;
}
.purple-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .purple-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .purple-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .purple-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.purple-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .purple-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.purple-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.purple-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .purple-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .purple-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.purple-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.purple-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.purple-theme .mat-tab-group.mat-accent .mat-ink-bar, .purple-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #9fa8da;
}
.purple-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .purple-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .purple-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .purple-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.purple-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .purple-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.purple-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.purple-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .purple-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .purple-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.purple-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.purple-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 184, 203, 0.3);
}
.purple-theme .mat-tab-group.mat-warn .mat-ink-bar, .purple-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e81253;
}
.purple-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .purple-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .purple-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .purple-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.purple-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .purple-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.purple-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.purple-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .purple-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .purple-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.purple-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.purple-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(200, 182, 229, 0.3);
}
.purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .purple-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #480ca8;
}
.purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .purple-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .purple-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.purple-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.purple-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.purple-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .purple-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.purple-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.purple-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .purple-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .purple-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.purple-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.purple-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .purple-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #9fa8da;
}
.purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .purple-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .purple-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.purple-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.purple-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.purple-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .purple-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.purple-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.purple-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .purple-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .purple-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.purple-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.purple-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 184, 203, 0.3);
}
.purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .purple-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e81253;
}
.purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .purple-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .purple-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.purple-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.purple-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.purple-theme .mat-toolbar {
  background: whitesmoke;
  color: #121212;
}
.purple-theme .mat-toolbar.mat-primary {
  background: #480ca8;
  color: white;
}
.purple-theme .mat-toolbar.mat-accent {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.purple-theme .mat-toolbar.mat-warn {
  background: #e81253;
  color: white;
}
.purple-theme .mat-toolbar .mat-form-field-underline,
.purple-theme .mat-toolbar .mat-form-field-ripple,
.purple-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.purple-theme .mat-toolbar .mat-form-field-label,
.purple-theme .mat-toolbar .mat-focused .mat-form-field-label,
.purple-theme .mat-toolbar .mat-select-value,
.purple-theme .mat-toolbar .mat-select-arrow,
.purple-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.purple-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.purple-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.purple-theme .mat-tree {
  background: white;
}
.purple-theme .mat-tree-node,
.purple-theme .mat-nested-tree-node {
  color: #121212;
}
.purple-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 6px 10px 0px rgba(18, 18, 18, 0.14), 0px 1px 18px 0px rgba(18, 18, 18, 0.12);
}
.purple-theme .mat-simple-snackbar-action {
  color: #9fa8da;
}

.orange-theme .order {
  background-color: white;
  color: #000000;
}
.orange-theme .order .line1 .detail-btn {
  color: #c75003;
}
.orange-theme .order .important-text {
  color: #ad2d01;
  font-weight: 600;
}
.orange-theme .order .status .status-chip {
  background-color: #db8a4f;
  color: #ffffff;
}
.orange-theme .order .status .status-chip.closed {
  background-color: #42c778;
  color: #fff;
}
.orange-theme .banner {
  background-color: white;
  color: #000000;
}
.orange-theme .banner__content__title {
  color: #ff7658;
}
.orange-theme .login {
  background-color: white;
  color: #000000;
}
.orange-theme .login__header {
  background-color: #f9ebe1;
  color: #000000;
}
.orange-theme .login-content {
  background-color: white;
  color: #000000;
}
.orange-theme .login-content__link {
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  color: #c04702;
  background-color: #ffffff;
}
.orange-theme .login-content__link:hover {
  background-color: #c04702;
  color: #ffffff !important;
}
.orange-theme .login-content {
  background-color: white;
  color: #000000;
}
.orange-theme .login-content .link-send {
  color: #c04702;
}
.orange-theme .login-content .link-send:hover {
  color: #000000;
}
.orange-theme .login-content {
  background-color: white;
  color: #000000;
}
.orange-theme .landing-container .sidenav-content {
  background-color: white;
  color: #000000;
}
.orange-theme .landing-container .sidenav-content__linkContainer__link.static {
  background-color: #ffffff;
  color: #c04702;
}
.orange-theme .landing-container .sidenav-content__footer {
  background-color: rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 768px) {
  .orange-theme .landing-container .landing-content {
    background-color: #f4f4f9;
    color: #cc5803;
  }
}
@media (max-height: 555px) {
  .orange-theme .landing-container .landing-content {
    background-color: #f4f4f9;
    color: #cc5803;
  }
}
.orange-theme .orders {
  background-color: rgba(255, 255, 255, 0);
  color: #000000;
}
.orange-theme .orders__wrapper {
  background-color: #c75003;
  color: #ffffff;
}
.orange-theme .orders__see-all-btn .see-all-orders-btn {
  background-color: #c75003;
  color: #ffffff;
  border: 1px solid #fed8c3;
}
.orange-theme .orders__see-all-btn .see-all-orders-btn:hover {
  background-color: #f25a03;
}
.orange-theme .orders__banners {
  background-color: #f4f4f9;
  color: #000000;
}
.orange-theme .orders__banners .see-more-banners-btn {
  background-color: white;
  color: #000000;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.orange-theme .orders__banners .see-more-banners-btn:hover {
  background-color: #f9ebe1;
}
.orange-theme .footer {
  background-color: #9595a8;
  color: white;
}
.orange-theme .footer__facility__item__head {
  font-weight: bold;
}
.orange-theme .footer__facility__item mat-divider {
  background-color: white;
}
.orange-theme .all-orders {
  background-color: rgba(255, 255, 255, 0);
  color: #000000;
}
.orange-theme .all-orders__wrapper {
  background-color: #f4f4f9;
  color: #000000;
}
.orange-theme .all-orders__wrapper__header {
  background-color: white;
  color: #c04702;
}
.orange-theme .all-orders__wrapper__filter .filter-field .mat-form-field-outline {
  background-color: white;
}
.orange-theme .order-detail__header {
  background-color: white;
  color: #000000;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.orange-theme .order-detail__header .title {
  color: #c75003;
}
.orange-theme .order-detail__steps {
  background-color: white;
  color: #000000;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.orange-theme .order-detail__steps mat-stepper {
  background-color: transparent;
  pointer-events: none;
  min-width: 100%;
}
.orange-theme .order-detail__steps mat-stepper mat-step-header {
  padding: 0 !important;
  min-width: 130px !important;
  overflow: visible !important;
}
.orange-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon.mat-step-icon-state-noicon {
  background-color: #bdbdbd;
}
.orange-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon {
  height: 15px !important;
  width: 15px !important;
  margin-top: 5px;
}
.orange-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon .mat-icon {
  font-size: 14px;
  height: 14px;
  width: 14px;
}
.orange-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon .currentState {
  color: #42c778;
  font-size: 20px;
  height: 20px;
  width: 20px;
  margin-top: -27px;
}
.orange-theme .order-detail__steps mat-stepper mat-step-header .mat-step-label {
  padding: 5px 5px 0 5px !important;
  font-size: 0.7rem;
}
.orange-theme .order-detail__steps mat-stepper mat-step-header .mat-step-label .disabled {
  color: #bdbdbd;
}
.orange-theme .order-detail__steps .mat-stepper-horizontal-line {
  display: none !important;
}
.orange-theme .order-detail__steps .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:after,
.orange-theme .order-detail__steps .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:before {
  top: 13px !important;
}
.orange-theme .order-detail__steps .mat-horizontal-stepper-wrapper {
  width: fit-content !important;
  margin: auto !important;
}
.orange-theme .order-detail__tabs {
  background-color: #ffffff;
  color: #000000;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.orange-theme .order-detail__tabs mat-tab-group .mat-tab-body-wrapper {
  height: 100%;
}
.orange-theme .order-detail__tabs mat-tab-group .newMessagesIcon {
  color: #e5104c;
}
.orange-theme .order-detail__tabs mat-tab-group .mat-tab-label {
  opacity: 1 !important;
}
.orange-theme .order-detail__tabs .desktopTabs .item {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.orange-theme .order-detail__tabs .desktopTabs .item:first-child {
  background-color: #f4f4f9;
}
.orange-theme .messages {
  background-color: white;
}
.orange-theme .messages__list .message__content {
  background-color: #e4e9f2;
  color: #000000;
}
.orange-theme .messages__list .message__content.budget {
  background-color: #42c778;
  color: #fff;
}
.orange-theme .messages__list .message__content.remote-sign {
  background-color: #cc5803;
  color: #ffffff;
}
.orange-theme .messages__list .message__content.payment {
  background-color: #ffb5a4;
  color: #ffffff;
}
.orange-theme .messages__list .message__content .budget-icon {
  background-color: #69d394;
}
.orange-theme .messages__list .message__content .remote-sign-icon {
  background-color: #fb6e07;
}
.orange-theme .messages__list .message__content .payment-icon {
  background-color: #ffded7;
}
.orange-theme .messages__list .message__date {
  color: #1a1a1a;
}
.orange-theme .messages__list .message.customer .message__content {
  background-color: #b93d02;
  color: #ffffff;
}
.orange-theme .messages__input {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.orange-theme .messages__input__wrapper {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.orange-theme .messages__input__wrapper .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -6px;
}
.orange-theme .messages__input__wrapper .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -4px;
}
.orange-theme .messages__input__wrapper .send-icon {
  color: #000000;
}
.orange-theme .messages__input__wrapper .attach-icon {
  color: #000000;
}
.orange-theme .navbar {
  background-color: white !important;
  color: #000000 !important;
}
.orange-theme .navbar mat-divider {
  background-color: #000000;
}
@media screen and (max-width: 768px) {
  .orange-theme .navbar {
    background-color: #c04702 !important;
    color: #ffffff !important;
  }
  .orange-theme .navbar mat-divider {
    background-color: white;
  }
}
.orange-theme .attachments-item-container {
  background-color: #e4e9f2;
}
.orange-theme .attachments-item-container .file-name {
  color: #c04702;
}
.orange-theme .attachments-item-container .file-name mat-icon {
  color: #c04702;
}
@media screen and (max-width: 992px) {
  .orange-theme ngx-extended-pdf-viewer #mainContainer {
    min-width: unset !important;
    max-width: 100% !important;
  }
  .orange-theme #toolbarViewerLeft pdf-find-button,
.orange-theme #toolbarViewerLeft pdf-paging-area {
    display: none !important;
  }
  .orange-theme #toolbarViewerRight {
    display: none !important;
  }
  .orange-theme #toolbarViewerMiddle {
    display: flex !important;
  }
}
.orange-theme .budget-viewer-wrapper .mat-dialog-container {
  padding: 0px;
}
.orange-theme .budget-viewer__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.orange-theme .budget-viewer__header h2 {
  color: #c04702;
}
.orange-theme .budget-viewer__content__lines .budget-line {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.orange-theme .budget-viewer__content__lines .budget-line.accepted {
  border: 1px solid #56cd86;
  background-color: #56cd86;
  color: white;
}
.orange-theme .budget-viewer__content__lines .budget-line.accepted.authorized {
  border: 1px solid #31a560;
  background-color: #31a560;
}
.orange-theme .budget-viewer__content__lines .budget-line.accepted.authorized .mat-checkbox-disabled .mat-checkbox-label {
  color: white;
}
.orange-theme .budget-viewer__content__lines .budget-line mat-checkbox {
  width: 100%;
  padding: 15px;
}
.orange-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout {
  width: 100%;
}
.orange-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
  width: 90%;
}
@media screen and (max-width: 768px) {
  .orange-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
    width: 80%;
  }
}
.orange-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.orange-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label .checkbox-budget-label-1 {
  font-weight: 600;
}
.orange-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label .checkbox-budget-label-2 {
  font-weight: 500;
  margin-right: 30px;
}
@media screen and (max-width: 768px) {
  .orange-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label .checkbox-budget-label-2 {
    margin-right: 15px;
  }
}
.orange-theme .budget-viewer__content__lines .budget-line mat-checkbox.mat-checkbox-checked .mat-checkbox-background {
  background-color: white;
}
.orange-theme .budget-viewer__content__lines .budget-line mat-checkbox.mat-checkbox-checked .mat-checkbox-background .mat-checkbox-checkmark {
  fill: #42c778 !important;
}
.orange-theme .budget-viewer__content__lines .budget-line mat-checkbox.mat-checkbox-checked .mat-checkbox-background .mat-checkbox-checkmark-path {
  stroke: #42c778 !important;
}
.orange-theme .budget-viewer__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.orange-theme .payments-viewer-wrapper .mat-dialog-container {
  padding: 0px;
}
.orange-theme .payments-viewer__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.orange-theme .payments-viewer__header h2 {
  color: #c04702;
}
.orange-theme .payments-viewer__content__lines .payments-line {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.orange-theme .payments-viewer__content__lines .payments-line.pending {
  border: 1px solid #ffa08b;
  background-color: #ffa08b;
  color: white;
}
.orange-theme .payments-viewer__content__lines .payments-line.accepted {
  border: 1px solid #2b9155;
  background-color: #2b9155;
  color: white;
}
.orange-theme .payments-viewer__content__lines .payments-line.error {
  border: 1px solid #c10c0c;
  background-color: #c10c0c;
  color: white;
}
.orange-theme .payments-viewer__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.orange-theme .signature-viewer-wrapper .mat-dialog-container {
  padding: 0px;
}
.orange-theme .signature-viewer__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.orange-theme .signature-viewer__header h2 {
  color: #c04702;
}
.orange-theme .signature-viewer__content .mat-step-icon.mat-step-icon-state-edit mat-icon {
  border-radius: 20px;
  padding: 5px;
  background-color: #42c778;
}
.orange-theme .signature-viewer__content .mat-step-icon.mat-step-icon-state-number {
  background-color: #b93d02;
}
.orange-theme .signature-viewer__content mat-stepper {
  background-color: transparent;
  min-width: 100%;
}
.orange-theme .signature-viewer__content mat-stepper mat-step-header {
  padding: 0 !important;
  overflow: visible !important;
}
.orange-theme .signature-viewer__content mat-stepper mat-step-header .mat-step-label {
  padding: 5px 5px 0 5px !important;
  font-size: 0.7rem;
}
.orange-theme .signature-viewer__content mat-stepper mat-step-header .mat-step-label .disabled {
  color: #bdbdbd;
}
.orange-theme .signature-viewer__content .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:after,
.orange-theme .signature-viewer__content .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:before,
.orange-theme .signature-viewer__content .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 13px !important;
}
.orange-theme .signature-viewer__content .mat-horizontal-content-container {
  padding: 5px 0 0 0;
}
.orange-theme .signature-viewer__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.orange-theme .remote-signature-viewer-wrapper .mat-dialog-container {
  padding: 0;
}
.orange-theme .unverified-signature-warning {
  display: none !important;
}
.orange-theme .card-docs__section__content__budget, .orange-theme .card-docs__section__content__remote-sign, .orange-theme .card-docs__section__content__payment {
  background-color: #42c778;
  color: white;
  font-size: bold;
}
.orange-theme .card-docs__section__content__budget mat-icon, .orange-theme .card-docs__section__content__remote-sign mat-icon, .orange-theme .card-docs__section__content__payment mat-icon {
  background-color: #69d394;
}
.orange-theme .card-docs__section__content__budget mat-icon.alert-icon, .orange-theme .card-docs__section__content__remote-sign mat-icon.alert-icon, .orange-theme .card-docs__section__content__payment mat-icon.alert-icon {
  color: yellow;
  background-color: transparent;
  margin-left: -7px;
}
.orange-theme .card-docs__section__content__budget.pending, .orange-theme .card-docs__section__content__remote-sign.pending, .orange-theme .card-docs__section__content__payment.pending {
  background-color: #cc5803;
  color: white;
}
.orange-theme .card-docs__section__content__budget.pending mat-icon, .orange-theme .card-docs__section__content__remote-sign.pending mat-icon, .orange-theme .card-docs__section__content__payment.pending mat-icon {
  background-color: #fb6e07;
}
.orange-theme .card-docs__section__content__budget.pending mat-icon.alert-icon, .orange-theme .card-docs__section__content__remote-sign.pending mat-icon.alert-icon, .orange-theme .card-docs__section__content__payment.pending mat-icon.alert-icon {
  background-color: transparent;
  margin-left: -7px;
}
.orange-theme .card-docs__section__content__budget.payments, .orange-theme .card-docs__section__content__remote-sign.payments, .orange-theme .card-docs__section__content__payment.payments {
  background-color: #ff8b71;
  color: #ffffff;
}
.orange-theme .card-docs__section__content__budget.payments mat-icon, .orange-theme .card-docs__section__content__remote-sign.payments mat-icon, .orange-theme .card-docs__section__content__payment.payments mat-icon {
  background-color: #ffb5a4;
}
.orange-theme .card-docs__section__content__budget.payments mat-icon.alert-icon, .orange-theme .card-docs__section__content__remote-sign.payments mat-icon.alert-icon, .orange-theme .card-docs__section__content__payment.payments mat-icon.alert-icon {
  background-color: transparent;
  margin-left: -7px;
}
.orange-theme .card-docs__section__content__budget.payments.pendingPayments, .orange-theme .card-docs__section__content__remote-sign.payments.pendingPayments, .orange-theme .card-docs__section__content__payment.payments.pendingPayments {
  background-color: #ffb5a4;
  color: #ffffff;
}
.orange-theme .card-docs__section__content__budget.payments.pendingPayments mat-icon, .orange-theme .card-docs__section__content__remote-sign.payments.pendingPayments mat-icon, .orange-theme .card-docs__section__content__payment.payments.pendingPayments mat-icon {
  background-color: #ffded7;
}
.orange-theme .card-docs__section__content__budget.payments.pendingPayments mat-icon.alert-icon, .orange-theme .card-docs__section__content__remote-sign.payments.pendingPayments mat-icon.alert-icon, .orange-theme .card-docs__section__content__payment.payments.pendingPayments mat-icon.alert-icon {
  background-color: transparent;
  margin-left: -7px;
}
.orange-theme .send-code-content {
  background-color: white;
  color: #000000;
}
.orange-theme .send-code-content__link {
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  color: #c04702;
  background-color: #ffffff;
}
.orange-theme .send-code-content__link:hover {
  background-color: #c04702;
  color: #ffffff !important;
}
.orange-theme .ask-for-code-content {
  background-color: white;
  color: #000000;
}
.orange-theme .global-message--success {
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;
}
.orange-theme .global-message--success .mat-simple-snackbar-action {
  color: #155724 !important;
}
.orange-theme .global-message--warning {
  color: #2c2c2c !important;
  background-color: rgb(206, 134, 0) !important;
  border-color: #be8f00 !important;
}
.orange-theme .global-message--warning .mat-simple-snackbar-action {
  color: #2c2c2c !important;
}
.orange-theme .global-message--error {
  color: rgb(245, 245, 245) !important;
  background-color: rgb(138, 0, 0) !important;
  border-color: rgb(114, 2, 2) !important;
}
.orange-theme .global-message--error .mat-simple-snackbar-action {
  color: rgb(245, 219, 219) !important;
}
.orange-theme html,
.orange-theme body {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
  height: 100%;
}
.orange-theme body {
  margin: 0;
}
.orange-theme .ngx-summernote-view {
  display: none !important;
}
.orange-theme .content-wrapper {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1120px;
}
.orange-theme .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.orange-theme .mat-app-background {
  height: 100%;
  background-color: #f4f4f9;
}
.orange-theme .uppercase {
  text-transform: uppercase;
}
.orange-theme .capitalize {
  text-transform: capitalize;
}
.orange-theme .message-block {
  align-items: center;
  background-color: #848484;
  border-radius: 0.571rem;
  box-shadow: 0 10px 22px 0 rgba(0, 0, 0, 0.04);
  color: #fff;
  display: flex;
  font-size: 1.143rem;
  font-weight: 600;
  line-height: 1.5;
  overflow-wrap: anywhere;
  padding: 1.429rem 1.714rem;
  word-wrap: anywhere;
}
.orange-theme .message-block .mat-icon {
  flex: 0 0 auto;
  margin-right: 1.143rem;
}
.orange-theme .message-block.message-block__success {
  background-color: #cc5803;
  color: #ffffff;
}
.orange-theme .message-block.message-block__warning {
  background-color: #ffb025;
  color: #fff;
}
.orange-theme .message-block.message-block__error {
  background-color: #f34040;
  color: #fff;
}
.orange-theme .hidden,
.orange-theme *[hidden] {
  display: none !important;
  overflow: hidden !important;
}
.orange-theme .hidden.block,
.orange-theme *[hidden].block {
  display: hidden !important;
}
.orange-theme .inlineBlock {
  display: inline-block;
}
.orange-theme .underline {
  text-decoration: underline;
}
.orange-theme .opacity-1 {
  opacity: 1;
}
.orange-theme .opacity-08 {
  opacity: 0.8;
}
.orange-theme .opacity-06 {
  opacity: 0.6;
}
.orange-theme .opacity-04 {
  opacity: 0.4;
}
.orange-theme .opacity-02 {
  opacity: 0.2;
}
.orange-theme .opacity-0 {
  opacity: 0;
}
.orange-theme .overlay-spinner {
  background: rgba(255, 255, 255, 0.4);
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.orange-theme .overlay-spinner > .mat-spinner {
  margin: 3rem auto;
}
.orange-theme .spin-icon {
  animation: spinner 0.6s linear infinite;
}
.orange-theme .height-100 {
  height: 100%;
}
.orange-theme .note-modal-backdrop {
  display: none !important;
}
.orange-theme .note-editing-area {
  background-color: white;
}
.orange-theme .note-editor .note-editing-area .note-editable table.table-no-bordered td,
.orange-theme .note-editor .note-editing-area .note-editable table.table-no-bordered th {
  border: 0px;
}
.orange-theme .autocomplete-panel-class,
.orange-theme .notification-mention-menu {
  max-height: calc(var(--100vh) - 65px) !important;
  height: 550px !important;
}
.orange-theme .notification-mention-menu .mat-menu-content {
  height: calc(100% - 16px);
}
.orange-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #cc5803;
}
.orange-theme .mat-form-field-appearance-outline .mat-form-field-label {
  color: #cc5803;
  font-weight: 600;
  text-transform: uppercase;
}
.orange-theme .mat-form-field-appearance-outline input.mat-input-element {
  text-overflow: ellipsis;
}
.orange-theme .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  z-index: 1;
  margin-top: 0.2em;
}
.orange-theme .mat-form-field-appearance-outline.date-picker .mat-form-field-subscript-wrapper {
  margin-top: 2.2em;
}
.orange-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
  color: #7c3502;
}
.orange-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-label {
  color: #7c3502;
}
.orange-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: transparent;
}
.orange-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: #cc5803;
}
.orange-theme .mat-form-field-appearance-outline.mat-form-field-disabled input.mat-input-element {
  color: #121212;
}
.orange-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-form-field-infix,
.orange-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-form-field-suffix {
  padding: 0.25em 0 0.75em 0;
  font-size: 1em;
}
.orange-theme .smaller-mat-form-fields .mat-form-field-appearance-outline.date-picker .mat-form-field-infix,
.orange-theme .smaller-mat-form-fields .mat-form-field-appearance-outline.date-picker .mat-form-field-suffix {
  padding: 0.25em 0 0.6em 0;
  margin-top: -0.3em;
}
.orange-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-infix, .orange-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-suffix {
  padding: 0.2em 0 0.25em 0;
}
.orange-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-infix .mat-input-element, .orange-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-suffix .mat-input-element {
  position: absolute;
  margin-top: -11px;
}
.orange-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-form-field-infix .mat-select-arrow {
  margin-top: 10px;
}
.orange-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-select-empty + .mat-form-field-label-wrapper .mat-form-field-label {
  margin-top: -0.75em;
}
.orange-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-select + .mat-form-field-label-wrapper .mat-form-field-label {
  margin-top: -0.3em;
}
.mat-select .orange-theme .smaller-mat-form-fields__no-margin.mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding: 0 0;
  margin: 0 0;
}
.orange-theme .smaller-mat-form-fields__no-margin.mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding: 0 0;
  margin: 0 0;
}
.orange-theme .smaller-mat-form-fields .mat-form-field__no-border .mat-form-field-outline-start,
.orange-theme .smaller-mat-form-fields .mat-form-field__no-border .mat-form-field-outline-gap,
.orange-theme .smaller-mat-form-fields .mat-form-field__no-border .mat-form-field-outline-end {
  border: none;
}
.orange-theme .smaller-mat-form-fields .mat-form-field__border-more-radius .mat-form-field-outline-start, .orange-theme .smaller-mat-form-fields.mat-form-field__border-more-radius .mat-form-field-outline-start {
  border-radius: 28px 0 0 28px !important;
  min-width: 28px;
}
.orange-theme .smaller-mat-form-fields .mat-form-field__border-more-radius .mat-form-field-outline-end, .orange-theme .smaller-mat-form-fields.mat-form-field__border-more-radius .mat-form-field-outline-end {
  border-radius: 0 28px 28px 0 !important;
}
.orange-theme button.mat-button, .orange-theme button.mat-flat-button {
  font-size: 1rem;
}
.orange-theme button.mat-button.mat-button-fill, .orange-theme button.mat-flat-button.mat-button-fill {
  width: 100%;
}
.orange-theme button.mat-button-w-100-left {
  width: 100%;
  text-align: left;
}
.orange-theme button.mat-flat-button:hover {
  opacity: 0.85;
}
.orange-theme button.close-modal-button.mat-icon-button {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  background-color: rgba(255, 222, 215, 0.3);
  color: #ad2d01;
}
.orange-theme button.close-modal-button.mat-icon-button:hover {
  background-color: rgba(255, 222, 215, 0.4);
}
.orange-theme button.mat-square-icon {
  border-radius: 5px;
  padding: 0 0;
  min-width: 36px;
  line-height: 36px;
}
.orange-theme button.mat-square-icon.square-28 {
  min-width: 28px;
  line-height: 28px;
}
.orange-theme button.mat-dark {
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
}
.orange-theme button.mat-dark:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
.orange-theme button .mat-icon {
  overflow: visible;
}
.orange-theme .mat-flat-button.mat-primary.mat-button-disabled,
.orange-theme .mat-flat-button.mat-button-disabled.mat-button-disabled,
.orange-theme .mat-raised-button.mat-primary.mat-button-disabled,
.orange-theme .mat-raised-button.mat-button-disabled.mat-button-disabled,
.orange-theme .mat-fab.mat-primary.mat-button-disabled,
.orange-theme .mat-fab.mat-button-disabled.mat-button-disabled,
.orange-theme .mat-mini-fab.mat-primary.mat-button-disabled,
.orange-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: #cc5803;
  color: #ffffff;
  opacity: 0.5;
}
.orange-theme .mat-button.v-align-bottom .mat-button-wrapper > *,
.orange-theme .mat-flat-button.v-align-bottom .mat-button-wrapper > *,
.orange-theme .mat-stroked-button.v-align-bottom .mat-button-wrapper > *,
.orange-theme .mat-raised-button.v-align-bottom .mat-button-wrapper > *,
.orange-theme .mat-icon-button.v-align-bottom .mat-button-wrapper > *,
.orange-theme .mat-fab.v-align-bottom .mat-button-wrapper > *,
.orange-theme .mat-mini-fab.v-align-bottom .mat-button-wrapper > * {
  vertical-align: text-bottom;
}
.orange-theme .mat-button.no-padding,
.orange-theme .mat-flat-button.no-padding,
.orange-theme .mat-stroked-button.no-padding,
.orange-theme .mat-raised-button.no-padding,
.orange-theme .mat-icon-button.no-padding,
.orange-theme .mat-fab.no-padding,
.orange-theme .mat-mini-fab.no-padding {
  padding: 0px;
}
.orange-theme mat-expansion-panel.no-padding .mat-expansion-panel-header {
  padding: 0 !important;
}
.orange-theme mat-expansion-panel.no-padding .mat-expansion-indicator {
  padding: 0 10px;
}
.orange-theme .mat-expansion-panel-header {
  border-radius: initial;
  border-bottom: 1px solid #f0cdb3;
}
.orange-theme .mat-expansion-panel-header.mat-expanded {
  height: 49px;
}
.orange-theme .mat-expansion-panel-header .mat-expansion-indicator {
  border-width: 0 3px 3px 0;
  color: #000000;
}
.orange-theme .mat-expansion-panel-header .mat-expansion-panel-header-title {
  color: #cc5803;
  font-size: 1rem;
  font-weight: 600;
}
.orange-theme .header-color-primary .mat-expansion-panel-header {
  background-color: #cc5803 !important;
}
.orange-theme .header-color-primary .mat-expansion-panel-header:hover {
  background-color: #c75003 !important;
}
.orange-theme .header-color-primary .mat-expansion-indicator::after {
  color: #ffffff;
}
.orange-theme .header-color-primary .mat-expansion-panel-header-title {
  color: #ffffff;
}
.orange-theme .mat-expanded > .mat-expansion-panel-content {
  padding-top: 16px;
}
.orange-theme mat-expansion-panel.no-padding .mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0 0 16px 0;
}
.orange-theme .mat-select-panel.bigger-selection-panel-class {
  max-height: calc(var(--100vh) - 100px);
}
.orange-theme .mat-select-panel.bigger-selection-panel-class .mat-optgroup-label {
  overflow: visible !important;
}
.orange-theme .mat-tab-page-sections .mat-tab-header {
  border-bottom: 0px;
}
.orange-theme .mat-tab-page-sections .mat-tab-header .mat-tab-label {
  font-size: 1.5rem;
  font-weight: 400;
  padding: 0 15px;
}
.orange-theme .mat-tab-page-sections.font-size-smaller .mat-tab-header .mat-tab-label {
  font-size: 1.1rem;
  font-weight: 200;
}
.orange-theme .mat-tab-group.mat-primary .mat-ink-bar,
.orange-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  height: 3px;
  background-color: #cc5803;
}
.orange-theme .mat-tab-label.text-red,
.orange-theme .mat-tab-label.mat-tab-label-active.text-red {
  color: #c10c0c;
}
.orange-theme .mat-item-card {
  margin-bottom: 20px;
  max-width: calc(50% - 20px);
}
.orange-theme .mat-item-card__title {
  font-size: 1rem;
  color: #26273b;
}
.orange-theme .mat-item-card__icons .mat-icon {
  padding-right: 24px;
  color: #cc5803;
}
.orange-theme .mat-item-card__icons .mat-icon:last-child {
  padding-right: 0;
}
.orange-theme .mat-table {
  background-color: transparent;
}
.orange-theme .mat-table.table-ellipsis {
  width: 100%;
  table-layout: fixed;
}
.orange-theme .mat-table.table-ellipsis th,
.orange-theme .mat-table.table-ellipsis td {
  overflow: hidden;
  width: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 10px;
}
.orange-theme .mat-table.table-ellipsis th.no-ellipsis,
.orange-theme .mat-table.table-ellipsis td.no-ellipsis {
  overflow: inherit;
}
.orange-theme .mat-table .mat-header-row .mat-header-cell {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  color: #000000;
}
.orange-theme .mat-table tr.mat-row {
  background-color: white;
  height: 65px;
}
.orange-theme .mat-table tr.mat-row .mat-cell {
  font-size: 16px;
  color: #26273b;
}
.orange-theme .mat-table tr.mat-row .mat-cell.action-buttons {
  color: #cc5803;
  font-size: 24px;
}
.orange-theme .mat-paginator {
  background-color: transparent;
}
.orange-theme .mat-checkbox-layout {
  white-space: normal !important;
}
.orange-theme mat-checkbox.light-border .mat-checkbox-frame {
  border-color: #ffffff;
}
.orange-theme .mat-tooltip {
  font-size: 0.9em;
  max-width: 350px !important;
}
.orange-theme .mat-menu-panel.dark-theme {
  min-width: 150px;
  background-color: #000000;
}
.orange-theme .mat-menu-panel.dark-theme .mat-menu-item {
  color: #ffffff;
}
.orange-theme .mat-menu-panel.dark-theme .mat-divider {
  background-color: #000000;
}
.orange-theme .draggable-item {
  border-radius: 5px;
  background-color: #f4f4f9;
  margin: 5px 0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.orange-theme .draggable-item .cdk-drag-handle {
  cursor: grab;
}
.orange-theme .draggable-item .cdk-drag-handle:active {
  cursor: grabbing;
}
.orange-theme .draggable-item.cdk-drag-preview {
  pointer-events: fill !important;
  box-shadow: 0px 0px 25px 0px rgb(0, 0, 0);
}
.orange-theme .draggable-item.cdk-drag-preview .cdk-drag-handle {
  cursor: grabbing;
}
.orange-theme .remove-padding mat-dialog-container {
  padding: 0px;
}
.orange-theme .mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Saira;
}
.orange-theme .mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.orange-theme .mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.orange-theme .mat-h1,
.orange-theme .mat-headline,
.orange-theme .mat-typography .mat-h1,
.orange-theme .mat-typography .mat-headline,
.orange-theme .mat-typography h1 {
  font: 900 24px / 32px Saira;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.orange-theme .mat-h2,
.orange-theme .mat-title,
.orange-theme .mat-typography .mat-h2,
.orange-theme .mat-typography .mat-title,
.orange-theme .mat-typography h2 {
  font: 600 20px / 28px Saira;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.orange-theme .mat-h3,
.orange-theme .mat-subheading-2,
.orange-theme .mat-typography .mat-h3,
.orange-theme .mat-typography .mat-subheading-2,
.orange-theme .mat-typography h3 {
  font: 400 16px / 28px Saira;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.orange-theme .mat-h4,
.orange-theme .mat-subheading-1,
.orange-theme .mat-typography .mat-h4,
.orange-theme .mat-typography .mat-subheading-1,
.orange-theme .mat-typography h4 {
  font: 600 14px / 1em Saira;
  letter-spacing: 3px;
  margin: 0 0 16px;
}
.orange-theme .mat-h5,
.orange-theme .mat-typography .mat-h5,
.orange-theme .mat-typography h5 {
  font: 400 calc(16px * 0.83) / 20px Saira;
  margin: 0 0 12px;
}
.orange-theme .mat-h6,
.orange-theme .mat-typography .mat-h6,
.orange-theme .mat-typography h6 {
  font: 400 calc(16px * 0.67) / 20px Saira;
  margin: 0 0 12px;
}
.orange-theme .mat-body-strong,
.orange-theme .mat-body-2,
.orange-theme .mat-typography .mat-body-strong,
.orange-theme .mat-typography .mat-body-2 {
  font: 400 18px / 26px Saira;
  letter-spacing: normal;
}
.orange-theme .mat-body,
.orange-theme .mat-body-1,
.orange-theme .mat-typography .mat-body,
.orange-theme .mat-typography .mat-body-1,
.orange-theme .mat-typography {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
}
.orange-theme .mat-body p,
.orange-theme .mat-body-1 p,
.orange-theme .mat-typography .mat-body p,
.orange-theme .mat-typography .mat-body-1 p,
.orange-theme .mat-typography p {
  margin: 0 0 12px;
}
.orange-theme .mat-small,
.orange-theme .mat-caption,
.orange-theme .mat-typography .mat-small,
.orange-theme .mat-typography .mat-caption {
  font: 400 12px / 20px Saira;
  letter-spacing: normal;
}
.orange-theme .mat-display-4,
.orange-theme .mat-typography .mat-display-4 {
  font: 400 112px / 112px Saira;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.orange-theme .mat-display-3,
.orange-theme .mat-typography .mat-display-3 {
  font: 900 56px / 56px Saira;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.orange-theme .mat-display-2,
.orange-theme .mat-typography .mat-display-2 {
  font: 900 40px / 1.2 Saira;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.orange-theme .mat-display-1,
.orange-theme .mat-typography .mat-display-1 {
  font: 600 32px / 1.13 Saira;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.orange-theme .mat-bottom-sheet-container {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
}
.orange-theme .mat-button, .orange-theme .mat-raised-button, .orange-theme .mat-icon-button, .orange-theme .mat-stroked-button,
.orange-theme .mat-flat-button, .orange-theme .mat-fab, .orange-theme .mat-mini-fab {
  font-family: Saira;
  font-size: 14px;
  font-weight: 600;
}
.orange-theme .mat-button-toggle {
  font-family: Saira;
}
.orange-theme .mat-card {
  font-family: Saira;
}
.orange-theme .mat-card-title {
  font-size: 24px;
  font-weight: 600;
}
.orange-theme .mat-card-header .mat-card-title {
  font-size: 20px;
}
.orange-theme .mat-card-subtitle,
.orange-theme .mat-card-content {
  font-size: 16px;
}
.orange-theme .mat-checkbox {
  font-family: Saira;
}
.orange-theme .mat-checkbox-layout .mat-checkbox-label {
  line-height: 26px;
}
.orange-theme .mat-chip {
  font-size: 18px;
  font-weight: 400;
}
.orange-theme .mat-chip .mat-chip-trailing-icon.mat-icon,
.orange-theme .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.orange-theme .mat-table {
  font-family: Saira;
}
.orange-theme .mat-header-cell {
  font-size: 12px;
  font-weight: 400;
}
.orange-theme .mat-cell, .orange-theme .mat-footer-cell {
  font-size: 16px;
}
.orange-theme .mat-calendar {
  font-family: Saira;
}
.orange-theme .mat-calendar-body {
  font-size: 13px;
}
.orange-theme .mat-calendar-body-label,
.orange-theme .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 600;
}
.orange-theme .mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.orange-theme .mat-dialog-title {
  font: 600 20px / 28px Saira;
  letter-spacing: normal;
}
.orange-theme .mat-expansion-panel-header {
  font-family: Saira;
  font-size: 14px;
  font-weight: 600;
}
.orange-theme .mat-expansion-panel-content {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
}
.orange-theme .mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Saira;
  letter-spacing: normal;
}
.orange-theme .mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.orange-theme .mat-form-field-prefix .mat-icon,
.orange-theme .mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.orange-theme .mat-form-field-prefix .mat-icon-button,
.orange-theme .mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.orange-theme .mat-form-field-prefix .mat-icon-button .mat-icon,
.orange-theme .mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.orange-theme .mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.orange-theme .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.orange-theme .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34365em) scale(0.75);
  width: 133.3334333333%;
}
.orange-theme .mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34364em) scale(0.75);
  width: 133.3334433333%;
}
.orange-theme .mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.orange-theme .mat-form-field-label {
  top: 1.34375em;
}
.orange-theme .mat-form-field-underline {
  bottom: 1.34375em;
}
.orange-theme .mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.orange-theme .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.orange-theme .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.orange-theme .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.orange-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.0013px);
  width: 133.3336333333%;
}
.orange-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00131px);
  width: 133.3336433333%;
}
.orange-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00132px);
  width: 133.3336533333%;
}
.orange-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.orange-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.orange-theme .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .orange-theme .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.orange-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28092em) scale(0.75);
  }
  .orange-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28091em) scale(0.75);
  }
  .orange-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2809em) scale(0.75);
  }
}
.orange-theme .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.orange-theme .mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.orange-theme .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.orange-theme .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59365em) scale(0.75);
  width: 133.3334333333%;
}
.orange-theme .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59364em) scale(0.75);
  width: 133.3334433333%;
}
.orange-theme .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.orange-theme .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.orange-theme .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.orange-theme .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59365em) scale(0.75);
  width: 133.3334333333%;
}
.orange-theme .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59364em) scale(0.75);
  width: 133.3334433333%;
}
.orange-theme .mat-grid-tile-header,
.orange-theme .mat-grid-tile-footer {
  font-size: 16px;
}
.orange-theme .mat-grid-tile-header .mat-line,
.orange-theme .mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.orange-theme .mat-grid-tile-header .mat-line:nth-child(n+2),
.orange-theme .mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.orange-theme input.mat-input-element {
  margin-top: -0.0625em;
}
.orange-theme .mat-menu-item {
  font-family: Saira;
  font-size: 16px;
  font-weight: 400;
}
.orange-theme .mat-paginator,
.orange-theme .mat-paginator-page-size .mat-select-trigger {
  font-family: Saira;
  font-size: 12px;
}
.orange-theme .mat-radio-button {
  font-family: Saira;
}
.orange-theme .mat-select {
  font-family: Saira;
}
.orange-theme .mat-select-trigger {
  height: 1.125em;
}
.orange-theme .mat-slide-toggle-content {
  font-family: Saira;
}
.orange-theme .mat-slider-thumb-label-text {
  font-family: Saira;
  font-size: 12px;
  font-weight: 400;
}
.orange-theme .mat-stepper-vertical, .orange-theme .mat-stepper-horizontal {
  font-family: Saira;
}
.orange-theme .mat-step-label {
  font-size: 16px;
  font-weight: 400;
}
.orange-theme .mat-step-sub-label-error {
  font-weight: normal;
}
.orange-theme .mat-step-label-error {
  font-size: 18px;
}
.orange-theme .mat-step-label-selected {
  font-size: 18px;
  font-weight: 400;
}
.orange-theme .mat-tab-group {
  font-family: Saira;
}
.orange-theme .mat-tab-label, .orange-theme .mat-tab-link {
  font-family: Saira;
  font-size: 14px;
  font-weight: 600;
}
.orange-theme .mat-toolbar,
.orange-theme .mat-toolbar h1,
.orange-theme .mat-toolbar h2,
.orange-theme .mat-toolbar h3,
.orange-theme .mat-toolbar h4,
.orange-theme .mat-toolbar h5,
.orange-theme .mat-toolbar h6 {
  font: 600 20px / 28px Saira;
  letter-spacing: normal;
  margin: 0;
}
.orange-theme .mat-tooltip {
  font-family: Saira;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.orange-theme .mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.orange-theme .mat-list-item {
  font-family: Saira;
}
.orange-theme .mat-list-option {
  font-family: Saira;
}
.orange-theme .mat-list-base .mat-list-item {
  font-size: 16px;
}
.orange-theme .mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.orange-theme .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.orange-theme .mat-list-base .mat-list-option {
  font-size: 16px;
}
.orange-theme .mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.orange-theme .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.orange-theme .mat-list-base .mat-subheader {
  font-family: Saira;
  font-size: 18px;
  font-weight: 400;
}
.orange-theme .mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.orange-theme .mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.orange-theme .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.orange-theme .mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.orange-theme .mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.orange-theme .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.orange-theme .mat-list-base[dense] .mat-subheader {
  font-family: Saira;
  font-size: 12px;
  font-weight: 400;
}
.orange-theme .mat-option {
  font-family: Saira;
  font-size: 16px;
}
.orange-theme .mat-optgroup-label {
  font: 400 18px / 26px Saira;
  letter-spacing: normal;
}
.orange-theme .mat-simple-snackbar {
  font-family: Saira;
  font-size: 16px;
}
.orange-theme .mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 600;
}
.orange-theme .mat-tree {
  font-family: Saira;
}
.orange-theme .mat-tree-node,
.orange-theme .mat-nested-tree-node {
  font-weight: 400;
  font-size: 16px;
}
.orange-theme .mat-ripple {
  overflow: hidden;
  position: relative;
}
.orange-theme .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.orange-theme .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.orange-theme .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .orange-theme .mat-ripple-element {
  display: none;
}

.orange-theme .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .orange-theme .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.orange-theme .cdk-overlay-container, .orange-theme .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.orange-theme .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.orange-theme .cdk-overlay-container:empty {
  display: none;
}
.orange-theme .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.orange-theme .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.orange-theme .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.orange-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .orange-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.orange-theme .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.orange-theme .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.orange-theme .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.orange-theme .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.orange-theme .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.orange-theme .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.orange-theme textarea.cdk-textarea-autosize {
  resize: none;
}
.orange-theme textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.orange-theme textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.orange-theme .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.orange-theme .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.orange-theme .mat-focus-indicator {
  position: relative;
}
.orange-theme .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.orange-theme .mat-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .orange-theme {
  --mat-focus-indicator-display: block;
}

.orange-theme .mat-mdc-focus-indicator {
  position: relative;
}
.orange-theme .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.orange-theme .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .orange-theme {
  --mat-mdc-focus-indicator-display: block;
}

.orange-theme .mat-ripple-element {
  background-color: rgba(18, 18, 18, 0.1);
}
.orange-theme .mat-option {
  color: #121212;
}
.orange-theme .mat-option:hover:not(.mat-option-disabled), .orange-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.orange-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.orange-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: #121212;
}
.orange-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.orange-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #cc5803;
}
.orange-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #9fa8da;
}
.orange-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e81253;
}
.orange-theme .mat-optgroup-label {
  color: #26273b;
}
.orange-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.orange-theme .mat-pseudo-checkbox {
  color: #26273b;
}
.orange-theme .mat-pseudo-checkbox::after {
  color: white;
}
.orange-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.orange-theme .mat-primary .mat-pseudo-checkbox-checked,
.orange-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #cc5803;
}
.orange-theme .mat-pseudo-checkbox-checked,
.orange-theme .mat-pseudo-checkbox-indeterminate,
.orange-theme .mat-accent .mat-pseudo-checkbox-checked,
.orange-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #9fa8da;
}
.orange-theme .mat-warn .mat-pseudo-checkbox-checked,
.orange-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e81253;
}
.orange-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.orange-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.orange-theme .mat-app-background, .orange-theme.mat-app-background {
  background-color: white;
  color: #121212;
}
.orange-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(18, 18, 18, 0.2), 0px 1px 1px 0px rgba(18, 18, 18, 0.14), 0px 1px 3px 0px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(18, 18, 18, 0.2), 0px 3px 4px 0px rgba(18, 18, 18, 0.14), 0px 1px 8px 0px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 5px 8px 0px rgba(18, 18, 18, 0.14), 0px 1px 14px 0px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 6px 10px 0px rgba(18, 18, 18, 0.14), 0px 1px 18px 0px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(18, 18, 18, 0.2), 0px 7px 10px 1px rgba(18, 18, 18, 0.14), 0px 2px 16px 1px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(18, 18, 18, 0.2), 0px 8px 10px 1px rgba(18, 18, 18, 0.14), 0px 3px 14px 2px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(18, 18, 18, 0.2), 0px 9px 12px 1px rgba(18, 18, 18, 0.14), 0px 3px 16px 2px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(18, 18, 18, 0.2), 0px 10px 14px 1px rgba(18, 18, 18, 0.14), 0px 4px 18px 3px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(18, 18, 18, 0.2), 0px 11px 15px 1px rgba(18, 18, 18, 0.14), 0px 4px 20px 3px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(18, 18, 18, 0.2), 0px 12px 17px 2px rgba(18, 18, 18, 0.14), 0px 5px 22px 4px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(18, 18, 18, 0.2), 0px 13px 19px 2px rgba(18, 18, 18, 0.14), 0px 5px 24px 4px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(18, 18, 18, 0.2), 0px 14px 21px 2px rgba(18, 18, 18, 0.14), 0px 5px 26px 4px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(18, 18, 18, 0.2), 0px 15px 22px 2px rgba(18, 18, 18, 0.14), 0px 6px 28px 5px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(18, 18, 18, 0.2), 0px 16px 24px 2px rgba(18, 18, 18, 0.14), 0px 6px 30px 5px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(18, 18, 18, 0.2), 0px 17px 26px 2px rgba(18, 18, 18, 0.14), 0px 6px 32px 5px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(18, 18, 18, 0.2), 0px 18px 28px 2px rgba(18, 18, 18, 0.14), 0px 7px 34px 6px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(18, 18, 18, 0.2), 0px 19px 29px 2px rgba(18, 18, 18, 0.14), 0px 7px 36px 6px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(18, 18, 18, 0.2), 0px 20px 31px 3px rgba(18, 18, 18, 0.14), 0px 8px 38px 7px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(18, 18, 18, 0.2), 0px 21px 33px 3px rgba(18, 18, 18, 0.14), 0px 8px 40px 7px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(18, 18, 18, 0.2), 0px 22px 35px 3px rgba(18, 18, 18, 0.14), 0px 8px 42px 7px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(18, 18, 18, 0.2), 0px 23px 36px 3px rgba(18, 18, 18, 0.14), 0px 9px 44px 8px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(18, 18, 18, 0.2), 0px 24px 38px 3px rgba(18, 18, 18, 0.14), 0px 9px 46px 8px rgba(18, 18, 18, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.orange-theme .mat-autocomplete-panel {
  background: white;
  color: #121212;
}
.orange-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.orange-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: #121212;
}
.orange-theme .mat-badge-content {
  color: white;
  background: #cc5803;
}
.cdk-high-contrast-active .orange-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.orange-theme .mat-badge-accent .mat-badge-content {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.orange-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e81253;
}
.orange-theme .mat-badge-disabled .mat-badge-content {
  background: #c1c1c1;
  color: rgba(0, 0, 0, 0.38);
}
.orange-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(18, 18, 18, 0.2), 0px 16px 24px 2px rgba(18, 18, 18, 0.14), 0px 6px 30px 5px rgba(18, 18, 18, 0.12);
  background: white;
  color: #121212;
}
.orange-theme .mat-button, .orange-theme .mat-icon-button, .orange-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.orange-theme .mat-button.mat-primary, .orange-theme .mat-icon-button.mat-primary, .orange-theme .mat-stroked-button.mat-primary {
  color: #cc5803;
}
.orange-theme .mat-button.mat-accent, .orange-theme .mat-icon-button.mat-accent, .orange-theme .mat-stroked-button.mat-accent {
  color: #9fa8da;
}
.orange-theme .mat-button.mat-warn, .orange-theme .mat-icon-button.mat-warn, .orange-theme .mat-stroked-button.mat-warn {
  color: #e81253;
}
.orange-theme .mat-button.mat-primary.mat-button-disabled, .orange-theme .mat-button.mat-accent.mat-button-disabled, .orange-theme .mat-button.mat-warn.mat-button-disabled, .orange-theme .mat-button.mat-button-disabled.mat-button-disabled, .orange-theme .mat-icon-button.mat-primary.mat-button-disabled, .orange-theme .mat-icon-button.mat-accent.mat-button-disabled, .orange-theme .mat-icon-button.mat-warn.mat-button-disabled, .orange-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .orange-theme .mat-stroked-button.mat-primary.mat-button-disabled, .orange-theme .mat-stroked-button.mat-accent.mat-button-disabled, .orange-theme .mat-stroked-button.mat-warn.mat-button-disabled, .orange-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(18, 18, 18, 0.26);
}
.orange-theme .mat-button.mat-primary .mat-button-focus-overlay, .orange-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .orange-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #cc5803;
}
.orange-theme .mat-button.mat-accent .mat-button-focus-overlay, .orange-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .orange-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #9fa8da;
}
.orange-theme .mat-button.mat-warn .mat-button-focus-overlay, .orange-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .orange-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e81253;
}
.orange-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .orange-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .orange-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.orange-theme .mat-button .mat-ripple-element, .orange-theme .mat-icon-button .mat-ripple-element, .orange-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.orange-theme .mat-button-focus-overlay {
  background: #121212;
}
.orange-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.orange-theme .mat-flat-button, .orange-theme .mat-raised-button, .orange-theme .mat-fab, .orange-theme .mat-mini-fab {
  color: #121212;
  background-color: white;
}
.orange-theme .mat-flat-button.mat-primary, .orange-theme .mat-raised-button.mat-primary, .orange-theme .mat-fab.mat-primary, .orange-theme .mat-mini-fab.mat-primary {
  color: white;
}
.orange-theme .mat-flat-button.mat-accent, .orange-theme .mat-raised-button.mat-accent, .orange-theme .mat-fab.mat-accent, .orange-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.orange-theme .mat-flat-button.mat-warn, .orange-theme .mat-raised-button.mat-warn, .orange-theme .mat-fab.mat-warn, .orange-theme .mat-mini-fab.mat-warn {
  color: white;
}
.orange-theme .mat-flat-button.mat-primary.mat-button-disabled, .orange-theme .mat-flat-button.mat-accent.mat-button-disabled, .orange-theme .mat-flat-button.mat-warn.mat-button-disabled, .orange-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .orange-theme .mat-raised-button.mat-primary.mat-button-disabled, .orange-theme .mat-raised-button.mat-accent.mat-button-disabled, .orange-theme .mat-raised-button.mat-warn.mat-button-disabled, .orange-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .orange-theme .mat-fab.mat-primary.mat-button-disabled, .orange-theme .mat-fab.mat-accent.mat-button-disabled, .orange-theme .mat-fab.mat-warn.mat-button-disabled, .orange-theme .mat-fab.mat-button-disabled.mat-button-disabled, .orange-theme .mat-mini-fab.mat-primary.mat-button-disabled, .orange-theme .mat-mini-fab.mat-accent.mat-button-disabled, .orange-theme .mat-mini-fab.mat-warn.mat-button-disabled, .orange-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(18, 18, 18, 0.26);
}
.orange-theme .mat-flat-button.mat-primary, .orange-theme .mat-raised-button.mat-primary, .orange-theme .mat-fab.mat-primary, .orange-theme .mat-mini-fab.mat-primary {
  background-color: #cc5803;
}
.orange-theme .mat-flat-button.mat-accent, .orange-theme .mat-raised-button.mat-accent, .orange-theme .mat-fab.mat-accent, .orange-theme .mat-mini-fab.mat-accent {
  background-color: #9fa8da;
}
.orange-theme .mat-flat-button.mat-warn, .orange-theme .mat-raised-button.mat-warn, .orange-theme .mat-fab.mat-warn, .orange-theme .mat-mini-fab.mat-warn {
  background-color: #e81253;
}
.orange-theme .mat-flat-button.mat-primary.mat-button-disabled, .orange-theme .mat-flat-button.mat-accent.mat-button-disabled, .orange-theme .mat-flat-button.mat-warn.mat-button-disabled, .orange-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .orange-theme .mat-raised-button.mat-primary.mat-button-disabled, .orange-theme .mat-raised-button.mat-accent.mat-button-disabled, .orange-theme .mat-raised-button.mat-warn.mat-button-disabled, .orange-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .orange-theme .mat-fab.mat-primary.mat-button-disabled, .orange-theme .mat-fab.mat-accent.mat-button-disabled, .orange-theme .mat-fab.mat-warn.mat-button-disabled, .orange-theme .mat-fab.mat-button-disabled.mat-button-disabled, .orange-theme .mat-mini-fab.mat-primary.mat-button-disabled, .orange-theme .mat-mini-fab.mat-accent.mat-button-disabled, .orange-theme .mat-mini-fab.mat-warn.mat-button-disabled, .orange-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: #f7f7f7;
}
.orange-theme .mat-flat-button.mat-primary .mat-ripple-element, .orange-theme .mat-raised-button.mat-primary .mat-ripple-element, .orange-theme .mat-fab.mat-primary .mat-ripple-element, .orange-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.orange-theme .mat-flat-button.mat-accent .mat-ripple-element, .orange-theme .mat-raised-button.mat-accent .mat-ripple-element, .orange-theme .mat-fab.mat-accent .mat-ripple-element, .orange-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.orange-theme .mat-flat-button.mat-warn .mat-ripple-element, .orange-theme .mat-raised-button.mat-warn .mat-ripple-element, .orange-theme .mat-fab.mat-warn .mat-ripple-element, .orange-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.orange-theme .mat-stroked-button:not([class*=mat-elevation-z]), .orange-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(18, 18, 18, 0.2), 0px 8px 10px 1px rgba(18, 18, 18, 0.14), 0px 3px 14px 2px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-fab:not([class*=mat-elevation-z]), .orange-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 6px 10px 0px rgba(18, 18, 18, 0.14), 0px 1px 18px 0px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .orange-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(18, 18, 18, 0.2), 0px 12px 17px 2px rgba(18, 18, 18, 0.14), 0px 5px 22px 4px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .orange-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.orange-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.orange-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.orange-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.orange-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.orange-theme .mat-button-toggle-appearance-standard {
  color: #121212;
  background: white;
}
.orange-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.orange-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.orange-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.orange-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.orange-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: #26273b;
}
.orange-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #121212;
}
.orange-theme .mat-button-toggle-disabled {
  color: rgba(18, 18, 18, 0.26);
  background-color: #eeeeee;
}
.orange-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.orange-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.orange-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.orange-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.orange-theme .mat-card {
  background: white;
  color: #121212;
}
.orange-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(18, 18, 18, 0.2), 0px 1px 1px 0px rgba(18, 18, 18, 0.14), 0px 1px 3px 0px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-card-subtitle {
  color: #26273b;
}
.orange-theme .mat-checkbox-frame {
  border-color: #26273b;
}
.orange-theme .mat-checkbox-checkmark {
  fill: white;
}
.orange-theme .mat-checkbox-checkmark-path {
  stroke: white !important;
}
.orange-theme .mat-checkbox-mixedmark {
  background-color: white;
}
.orange-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .orange-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #cc5803;
}
.orange-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .orange-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #9fa8da;
}
.orange-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .orange-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e81253;
}
.orange-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .orange-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.orange-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.orange-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.orange-theme .mat-checkbox .mat-ripple-element {
  background-color: #121212;
}
.orange-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.orange-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #cc5803;
}
.orange-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.orange-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #9fa8da;
}
.orange-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.orange-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e81253;
}
.orange-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: #121212;
}
.orange-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: #121212;
  opacity: 0.4;
}
.orange-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(18, 18, 18, 0.2), 0px 3px 4px 0px rgba(18, 18, 18, 0.14), 0px 1px 8px 0px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.orange-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.orange-theme .mat-chip.mat-standard-chip::after {
  background: #121212;
}
.orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #cc5803;
  color: white;
}
.orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e81253;
  color: white;
}
.orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.orange-theme .mat-table {
  background: white;
}
.orange-theme .mat-table thead, .orange-theme .mat-table tbody, .orange-theme .mat-table tfoot,
.orange-theme mat-header-row, .orange-theme mat-row, .orange-theme mat-footer-row,
.orange-theme [mat-header-row], .orange-theme [mat-row], .orange-theme [mat-footer-row],
.orange-theme .mat-table-sticky {
  background: inherit;
}
.orange-theme mat-row, .orange-theme mat-header-row, .orange-theme mat-footer-row,
.orange-theme th.mat-header-cell, .orange-theme td.mat-cell, .orange-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.orange-theme .mat-header-cell {
  color: #26273b;
}
.orange-theme .mat-cell, .orange-theme .mat-footer-cell {
  color: #121212;
}
.orange-theme .mat-calendar-arrow {
  fill: rgba(18, 18, 18, 0.54);
}
.orange-theme .mat-datepicker-toggle,
.orange-theme .mat-datepicker-content .mat-calendar-next-button,
.orange-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(18, 18, 18, 0.54);
}
.orange-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.orange-theme .mat-calendar-table-header,
.orange-theme .mat-calendar-body-label {
  color: #26273b;
}
.orange-theme .mat-calendar-body-cell-content,
.orange-theme .mat-date-range-input-separator {
  color: #121212;
  border-color: transparent;
}
.orange-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.orange-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.orange-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.orange-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.orange-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.orange-theme .mat-calendar-body-in-range::before {
  background: rgba(204, 88, 3, 0.2);
}
.orange-theme .mat-calendar-body-comparison-identical,
.orange-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.orange-theme .mat-calendar-body-comparison-bridge-start::before,
.orange-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(204, 88, 3, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.orange-theme .mat-calendar-body-comparison-bridge-end::before,
.orange-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(204, 88, 3, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.orange-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.orange-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.orange-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.orange-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.orange-theme .mat-calendar-body-selected {
  background-color: #cc5803;
  color: white;
}
.orange-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(204, 88, 3, 0.4);
}
.orange-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.orange-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.orange-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(204, 88, 3, 0.3);
}
@media (hover: hover) {
  .orange-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(204, 88, 3, 0.3);
  }
}
.orange-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
  background-color: white;
  color: #121212;
}
.orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(159, 168, 218, 0.2);
}
.orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.orange-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(159, 168, 218, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.orange-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(159, 168, 218, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(159, 168, 218, 0.4);
}
.orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.orange-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.orange-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(159, 168, 218, 0.3);
}
@media (hover: hover) {
  .orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(159, 168, 218, 0.3);
  }
}
.orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(232, 18, 83, 0.2);
}
.orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.orange-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(232, 18, 83, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.orange-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(232, 18, 83, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e81253;
  color: white;
}
.orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(232, 18, 83, 0.4);
}
.orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.orange-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.orange-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(232, 18, 83, 0.3);
}
@media (hover: hover) {
  .orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(232, 18, 83, 0.3);
  }
}
.orange-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(18, 18, 18, 0.2), 0px 24px 38px 3px rgba(18, 18, 18, 0.14), 0px 9px 46px 8px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-datepicker-toggle-active {
  color: #cc5803;
}
.orange-theme .mat-datepicker-toggle-active.mat-accent {
  color: #9fa8da;
}
.orange-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e81253;
}
.orange-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.orange-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(18, 18, 18, 0.2), 0px 24px 38px 3px rgba(18, 18, 18, 0.14), 0px 9px 46px 8px rgba(18, 18, 18, 0.12);
  background: white;
  color: #121212;
}
.orange-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.orange-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.orange-theme .mat-expansion-panel {
  background: white;
  color: #121212;
}
.orange-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.orange-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .orange-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .orange-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .orange-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.orange-theme .mat-expansion-panel-header-title {
  color: #121212;
}
.orange-theme .mat-expansion-panel-header-description,
.orange-theme .mat-expansion-indicator::after {
  color: #26273b;
}
.orange-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(18, 18, 18, 0.26);
}
.orange-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.orange-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.orange-theme .mat-form-field-label {
  color: rgba(38, 39, 59, 0.6);
}
.orange-theme .mat-hint {
  color: rgba(38, 39, 59, 0.6);
}
.orange-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #cc5803;
}
.orange-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #9fa8da;
}
.orange-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e81253;
}
.orange-theme .mat-focused .mat-form-field-required-marker {
  color: #9fa8da;
}
.orange-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.orange-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #cc5803;
}
.orange-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #9fa8da;
}
.orange-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e81253;
}
.orange-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #cc5803;
}
.orange-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #9fa8da;
}
.orange-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e81253;
}
.orange-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e81253;
}
.orange-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.orange-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e81253;
}
.orange-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.orange-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e81253;
}
.orange-theme .mat-error {
  color: #e81253;
}
.orange-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: #26273b;
}
.orange-theme .mat-form-field-appearance-legacy .mat-hint {
  color: #26273b;
}
.orange-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.orange-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.orange-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.orange-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.orange-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(18, 18, 18, 0.04);
}
.orange-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(18, 18, 18, 0.02);
}
.orange-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.orange-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.orange-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.orange-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.orange-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.orange-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #cc5803;
}
.orange-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #9fa8da;
}
.orange-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e81253;
}
.orange-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e81253;
}
.orange-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.orange-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.orange-theme .mat-icon.mat-primary {
  color: #cc5803;
}
.orange-theme .mat-icon.mat-accent {
  color: #9fa8da;
}
.orange-theme .mat-icon.mat-warn {
  color: #e81253;
}
.orange-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: #26273b;
}
.orange-theme .mat-input-element:disabled,
.orange-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.orange-theme .mat-input-element {
  caret-color: #cc5803;
}
.orange-theme .mat-input-element::placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.orange-theme .mat-input-element::-moz-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.orange-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.orange-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.orange-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #9fa8da;
}
.orange-theme .mat-form-field.mat-warn .mat-input-element,
.orange-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e81253;
}
.orange-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e81253;
}
.orange-theme .mat-list-base .mat-list-item {
  color: #121212;
}
.orange-theme .mat-list-base .mat-list-option {
  color: #121212;
}
.orange-theme .mat-list-base .mat-subheader {
  color: #26273b;
}
.orange-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.orange-theme .mat-list-option:hover, .orange-theme .mat-list-option:focus,
.orange-theme .mat-nav-list .mat-list-item:hover,
.orange-theme .mat-nav-list .mat-list-item:focus,
.orange-theme .mat-action-list .mat-list-item:hover,
.orange-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.orange-theme .mat-list-single-selected-option, .orange-theme .mat-list-single-selected-option:hover, .orange-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.orange-theme .mat-menu-panel {
  background: white;
}
.orange-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-menu-item {
  background: transparent;
  color: #121212;
}
.orange-theme .mat-menu-item[disabled],
.orange-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.orange-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.orange-theme .mat-menu-item .mat-icon-no-color,
.orange-theme .mat-menu-submenu-icon {
  color: rgba(18, 18, 18, 0.54);
}
.orange-theme .mat-menu-item:hover:not([disabled]),
.orange-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.orange-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.orange-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.orange-theme .mat-paginator {
  background: white;
}
.orange-theme .mat-paginator,
.orange-theme .mat-paginator-page-size .mat-select-trigger {
  color: #26273b;
}
.orange-theme .mat-paginator-decrement,
.orange-theme .mat-paginator-increment {
  border-top: 2px solid rgba(18, 18, 18, 0.54);
  border-right: 2px solid rgba(18, 18, 18, 0.54);
}
.orange-theme .mat-paginator-first,
.orange-theme .mat-paginator-last {
  border-top: 2px solid rgba(18, 18, 18, 0.54);
}
.orange-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.orange-theme .mat-icon-button[disabled] .mat-paginator-increment,
.orange-theme .mat-icon-button[disabled] .mat-paginator-first,
.orange-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.orange-theme .mat-progress-bar-background {
  fill: #f2d5c0;
}
.orange-theme .mat-progress-bar-buffer {
  background-color: #f2d5c0;
}
.orange-theme .mat-progress-bar-fill::after {
  background-color: #cc5803;
}
.orange-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #e7e9f6;
}
.orange-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #e7e9f6;
}
.orange-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #9fa8da;
}
.orange-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9c4d4;
}
.orange-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9c4d4;
}
.orange-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e81253;
}
.orange-theme .mat-progress-spinner circle, .orange-theme .mat-spinner circle {
  stroke: #cc5803;
}
.orange-theme .mat-progress-spinner.mat-accent circle, .orange-theme .mat-spinner.mat-accent circle {
  stroke: #9fa8da;
}
.orange-theme .mat-progress-spinner.mat-warn circle, .orange-theme .mat-spinner.mat-warn circle {
  stroke: #e81253;
}
.orange-theme .mat-radio-outer-circle {
  border-color: #26273b;
}
.orange-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cc5803;
}
.orange-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.orange-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .orange-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .orange-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #cc5803;
}
.orange-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #9fa8da;
}
.orange-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.orange-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .orange-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .orange-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #9fa8da;
}
.orange-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e81253;
}
.orange-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.orange-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .orange-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .orange-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e81253;
}
.orange-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.orange-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.orange-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.orange-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.orange-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.orange-theme .mat-radio-button .mat-ripple-element {
  background-color: #121212;
}
.orange-theme .mat-select-value {
  color: #121212;
}
.orange-theme .mat-select-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.orange-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.orange-theme .mat-select-arrow {
  color: #26273b;
}
.orange-theme .mat-select-panel {
  background: white;
}
.orange-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.orange-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #cc5803;
}
.orange-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #9fa8da;
}
.orange-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e81253;
}
.orange-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e81253;
}
.orange-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.orange-theme .mat-drawer-container {
  background-color: white;
  color: #121212;
}
.orange-theme .mat-drawer {
  background-color: white;
  color: #121212;
}
.orange-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.orange-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(18, 18, 18, 0.2), 0px 16px 24px 2px rgba(18, 18, 18, 0.14), 0px 6px 30px 5px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.orange-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.orange-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.orange-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.orange-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.orange-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #9fa8da;
}
.orange-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(159, 168, 218, 0.54);
}
.orange-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #9fa8da;
}
.orange-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #cc5803;
}
.orange-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(204, 88, 3, 0.54);
}
.orange-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #cc5803;
}
.orange-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e81253;
}
.orange-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(232, 18, 83, 0.54);
}
.orange-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e81253;
}
.orange-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: #121212;
}
.orange-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(18, 18, 18, 0.2), 0px 1px 1px 0px rgba(18, 18, 18, 0.14), 0px 1px 3px 0px rgba(18, 18, 18, 0.12);
  background-color: #fafafa;
}
.orange-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.orange-theme .mat-slider-track-background {
  background-color: rgba(18, 18, 18, 0.26);
}
.orange-theme .mat-slider.mat-primary .mat-slider-track-fill,
.orange-theme .mat-slider.mat-primary .mat-slider-thumb,
.orange-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #cc5803;
}
.orange-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.orange-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(204, 88, 3, 0.2);
}
.orange-theme .mat-slider.mat-accent .mat-slider-track-fill,
.orange-theme .mat-slider.mat-accent .mat-slider-thumb,
.orange-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #9fa8da;
}
.orange-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.orange-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(159, 168, 218, 0.2);
}
.orange-theme .mat-slider.mat-warn .mat-slider-track-fill,
.orange-theme .mat-slider.mat-warn .mat-slider-thumb,
.orange-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e81253;
}
.orange-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.orange-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(232, 18, 83, 0.2);
}
.orange-theme .mat-slider:hover .mat-slider-track-background,
.orange-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(18, 18, 18, 0.38);
}
.orange-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.orange-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.orange-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(18, 18, 18, 0.26);
}
.orange-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(18, 18, 18, 0.26);
}
.orange-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.orange-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(18, 18, 18, 0.87);
}
.orange-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.orange-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(18, 18, 18, 0.26);
}
.orange-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(18, 18, 18, 0.26);
  background-color: transparent;
}
.orange-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .orange-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(18, 18, 18, 0.38);
}
.orange-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .orange-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(18, 18, 18, 0.26);
}
.orange-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(18, 18, 18, 0.7);
}
.orange-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(18, 18, 18, 0.7), rgba(18, 18, 18, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(18, 18, 18, 0.7), rgba(18, 18, 18, 0.7) 2px, transparent 0, transparent);
}
.orange-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(18, 18, 18, 0.7), rgba(18, 18, 18, 0.7) 2px, transparent 0, transparent);
}
.orange-theme .mat-step-header.cdk-keyboard-focused, .orange-theme .mat-step-header.cdk-program-focused, .orange-theme .mat-step-header:hover:not([aria-disabled]), .orange-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.orange-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .orange-theme .mat-step-header:hover {
    background: none;
  }
}
.orange-theme .mat-step-header .mat-step-label,
.orange-theme .mat-step-header .mat-step-optional {
  color: #26273b;
}
.orange-theme .mat-step-header .mat-step-icon {
  background-color: #26273b;
  color: white;
}
.orange-theme .mat-step-header .mat-step-icon-selected,
.orange-theme .mat-step-header .mat-step-icon-state-done,
.orange-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #cc5803;
  color: white;
}
.orange-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.orange-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.orange-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.orange-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.orange-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.orange-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.orange-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.orange-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e81253;
  color: white;
}
.orange-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e81253;
}
.orange-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: #121212;
}
.orange-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e81253;
}
.orange-theme .mat-stepper-horizontal, .orange-theme .mat-stepper-vertical {
  background-color: white;
}
.orange-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.orange-theme .mat-horizontal-stepper-header::before,
.orange-theme .mat-horizontal-stepper-header::after,
.orange-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.orange-theme .mat-sort-header-arrow {
  color: #26273b;
}
.orange-theme .mat-tab-nav-bar,
.orange-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.orange-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.orange-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.orange-theme .mat-tab-label, .orange-theme .mat-tab-link {
  color: #121212;
}
.orange-theme .mat-tab-label.mat-tab-disabled, .orange-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.orange-theme .mat-tab-header-pagination-chevron {
  border-color: #121212;
}
.orange-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.orange-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.orange-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.orange-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .orange-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.orange-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.orange-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .orange-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .orange-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.orange-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.orange-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 205, 179, 0.3);
}
.orange-theme .mat-tab-group.mat-primary .mat-ink-bar, .orange-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #cc5803;
}
.orange-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .orange-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .orange-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .orange-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.orange-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .orange-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.orange-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.orange-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .orange-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .orange-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.orange-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.orange-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.orange-theme .mat-tab-group.mat-accent .mat-ink-bar, .orange-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #9fa8da;
}
.orange-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .orange-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .orange-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .orange-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.orange-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .orange-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.orange-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.orange-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .orange-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .orange-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.orange-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.orange-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 184, 203, 0.3);
}
.orange-theme .mat-tab-group.mat-warn .mat-ink-bar, .orange-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e81253;
}
.orange-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .orange-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .orange-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .orange-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.orange-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .orange-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.orange-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.orange-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .orange-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .orange-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.orange-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.orange-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 205, 179, 0.3);
}
.orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .orange-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #cc5803;
}
.orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .orange-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .orange-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.orange-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.orange-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.orange-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .orange-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.orange-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.orange-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .orange-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .orange-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.orange-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.orange-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .orange-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #9fa8da;
}
.orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .orange-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .orange-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.orange-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.orange-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.orange-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .orange-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.orange-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.orange-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .orange-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .orange-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.orange-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.orange-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 184, 203, 0.3);
}
.orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .orange-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e81253;
}
.orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .orange-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .orange-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.orange-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.orange-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.orange-theme .mat-toolbar {
  background: whitesmoke;
  color: #121212;
}
.orange-theme .mat-toolbar.mat-primary {
  background: #cc5803;
  color: white;
}
.orange-theme .mat-toolbar.mat-accent {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.orange-theme .mat-toolbar.mat-warn {
  background: #e81253;
  color: white;
}
.orange-theme .mat-toolbar .mat-form-field-underline,
.orange-theme .mat-toolbar .mat-form-field-ripple,
.orange-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.orange-theme .mat-toolbar .mat-form-field-label,
.orange-theme .mat-toolbar .mat-focused .mat-form-field-label,
.orange-theme .mat-toolbar .mat-select-value,
.orange-theme .mat-toolbar .mat-select-arrow,
.orange-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.orange-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.orange-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.orange-theme .mat-tree {
  background: white;
}
.orange-theme .mat-tree-node,
.orange-theme .mat-nested-tree-node {
  color: #121212;
}
.orange-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 6px 10px 0px rgba(18, 18, 18, 0.14), 0px 1px 18px 0px rgba(18, 18, 18, 0.12);
}
.orange-theme .mat-simple-snackbar-action {
  color: #9fa8da;
}

.yellow-theme .order {
  background-color: white;
  color: #000000;
}
.yellow-theme .order .line1 .detail-btn {
  color: #ffcf42;
}
.yellow-theme .order .important-text {
  color: #ffb921;
  font-weight: 600;
}
.yellow-theme .order .status .status-chip {
  background-color: #ffe180;
  color: #ffffff;
}
.yellow-theme .order .status .status-chip.closed {
  background-color: #42c778;
  color: #fff;
}
.yellow-theme .banner {
  background-color: white;
  color: #000000;
}
.yellow-theme .banner__content__title {
  color: #ffe7b7;
}
.yellow-theme .login {
  background-color: white;
  color: #000000;
}
.yellow-theme .login__header {
  background-color: #fffae9;
  color: #000000;
}
.yellow-theme .login-content {
  background-color: white;
  color: #000000;
}
.yellow-theme .login-content__link {
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  color: #ffc939;
  background-color: #ffffff;
}
.yellow-theme .login-content__link:hover {
  background-color: #ffc939;
  color: #ffffff !important;
}
.yellow-theme .login-content {
  background-color: white;
  color: #000000;
}
.yellow-theme .login-content .link-send {
  color: #ffc939;
}
.yellow-theme .login-content .link-send:hover {
  color: #000000;
}
.yellow-theme .login-content {
  background-color: white;
  color: #000000;
}
.yellow-theme .landing-container .sidenav-content {
  background-color: white;
  color: #000000;
}
.yellow-theme .landing-container .sidenav-content__linkContainer__link.static {
  background-color: #ffffff;
  color: #ffc939;
}
.yellow-theme .landing-container .sidenav-content__footer {
  background-color: rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 768px) {
  .yellow-theme .landing-container .landing-content {
    background-color: #f4f4f9;
    color: #ffd449;
  }
}
@media (max-height: 555px) {
  .yellow-theme .landing-container .landing-content {
    background-color: #f4f4f9;
    color: #ffd449;
  }
}
.yellow-theme .orders {
  background-color: rgba(255, 255, 255, 0);
  color: #000000;
}
.yellow-theme .orders__wrapper {
  background-color: #ffcf42;
  color: #ffffff;
}
.yellow-theme .orders__see-all-btn .see-all-orders-btn {
  background-color: #ffcf42;
  color: #ffffff;
  border: 1px solid white;
}
.yellow-theme .orders__see-all-btn .see-all-orders-btn:hover {
  background-color: #ffd76c;
}
.yellow-theme .orders__banners {
  background-color: #f4f4f9;
  color: #000000;
}
.yellow-theme .orders__banners .see-more-banners-btn {
  background-color: white;
  color: #000000;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.yellow-theme .orders__banners .see-more-banners-btn:hover {
  background-color: #fffae9;
}
.yellow-theme .footer {
  background-color: #9595a8;
  color: white;
}
.yellow-theme .footer__facility__item__head {
  font-weight: bold;
}
.yellow-theme .footer__facility__item mat-divider {
  background-color: white;
}
.yellow-theme .all-orders {
  background-color: rgba(255, 255, 255, 0);
  color: #000000;
}
.yellow-theme .all-orders__wrapper {
  background-color: #f4f4f9;
  color: #000000;
}
.yellow-theme .all-orders__wrapper__header {
  background-color: white;
  color: #ffc939;
}
.yellow-theme .all-orders__wrapper__filter .filter-field .mat-form-field-outline {
  background-color: white;
}
.yellow-theme .order-detail__header {
  background-color: white;
  color: #000000;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.yellow-theme .order-detail__header .title {
  color: #ffcf42;
}
.yellow-theme .order-detail__steps {
  background-color: white;
  color: #000000;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.yellow-theme .order-detail__steps mat-stepper {
  background-color: transparent;
  pointer-events: none;
  min-width: 100%;
}
.yellow-theme .order-detail__steps mat-stepper mat-step-header {
  padding: 0 !important;
  min-width: 130px !important;
  overflow: visible !important;
}
.yellow-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon.mat-step-icon-state-noicon {
  background-color: #bdbdbd;
}
.yellow-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon {
  height: 15px !important;
  width: 15px !important;
  margin-top: 5px;
}
.yellow-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon .mat-icon {
  font-size: 14px;
  height: 14px;
  width: 14px;
}
.yellow-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon .currentState {
  color: #42c778;
  font-size: 20px;
  height: 20px;
  width: 20px;
  margin-top: -27px;
}
.yellow-theme .order-detail__steps mat-stepper mat-step-header .mat-step-label {
  padding: 5px 5px 0 5px !important;
  font-size: 0.7rem;
}
.yellow-theme .order-detail__steps mat-stepper mat-step-header .mat-step-label .disabled {
  color: #bdbdbd;
}
.yellow-theme .order-detail__steps .mat-stepper-horizontal-line {
  display: none !important;
}
.yellow-theme .order-detail__steps .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:after,
.yellow-theme .order-detail__steps .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:before {
  top: 13px !important;
}
.yellow-theme .order-detail__steps .mat-horizontal-stepper-wrapper {
  width: fit-content !important;
  margin: auto !important;
}
.yellow-theme .order-detail__tabs {
  background-color: #ffffff;
  color: #000000;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.yellow-theme .order-detail__tabs mat-tab-group .mat-tab-body-wrapper {
  height: 100%;
}
.yellow-theme .order-detail__tabs mat-tab-group .newMessagesIcon {
  color: #e5104c;
}
.yellow-theme .order-detail__tabs mat-tab-group .mat-tab-label {
  opacity: 1 !important;
}
.yellow-theme .order-detail__tabs .desktopTabs .item {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.yellow-theme .order-detail__tabs .desktopTabs .item:first-child {
  background-color: #f4f4f9;
}
.yellow-theme .messages {
  background-color: white;
}
.yellow-theme .messages__list .message__content {
  background-color: #e4e9f2;
  color: #000000;
}
.yellow-theme .messages__list .message__content.budget {
  background-color: #42c778;
  color: #fff;
}
.yellow-theme .messages__list .message__content.remote-sign {
  background-color: #ffd449;
  color: #ffffff;
}
.yellow-theme .messages__list .message__content.payment {
  background-color: white;
  color: #ffffff;
}
.yellow-theme .messages__list .message__content .budget-icon {
  background-color: #69d394;
}
.yellow-theme .messages__list .message__content .remote-sign-icon {
  background-color: #ffe07c;
}
.yellow-theme .messages__list .message__content .payment-icon {
  background-color: white;
}
.yellow-theme .messages__list .message__date {
  color: #1a1a1a;
}
.yellow-theme .messages__list .message.customer .message__content {
  background-color: #ffc331;
  color: #ffffff;
}
.yellow-theme .messages__input {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.yellow-theme .messages__input__wrapper {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.yellow-theme .messages__input__wrapper .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -6px;
}
.yellow-theme .messages__input__wrapper .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -4px;
}
.yellow-theme .messages__input__wrapper .send-icon {
  color: #000000;
}
.yellow-theme .messages__input__wrapper .attach-icon {
  color: #000000;
}
.yellow-theme .navbar {
  background-color: white !important;
  color: #000000 !important;
}
.yellow-theme .navbar mat-divider {
  background-color: #000000;
}
@media screen and (max-width: 768px) {
  .yellow-theme .navbar {
    background-color: #ffc939 !important;
    color: #ffffff !important;
  }
  .yellow-theme .navbar mat-divider {
    background-color: white;
  }
}
.yellow-theme .attachments-item-container {
  background-color: #e4e9f2;
}
.yellow-theme .attachments-item-container .file-name {
  color: #ffc939;
}
.yellow-theme .attachments-item-container .file-name mat-icon {
  color: #ffc939;
}
@media screen and (max-width: 992px) {
  .yellow-theme ngx-extended-pdf-viewer #mainContainer {
    min-width: unset !important;
    max-width: 100% !important;
  }
  .yellow-theme #toolbarViewerLeft pdf-find-button,
.yellow-theme #toolbarViewerLeft pdf-paging-area {
    display: none !important;
  }
  .yellow-theme #toolbarViewerRight {
    display: none !important;
  }
  .yellow-theme #toolbarViewerMiddle {
    display: flex !important;
  }
}
.yellow-theme .budget-viewer-wrapper .mat-dialog-container {
  padding: 0px;
}
.yellow-theme .budget-viewer__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.yellow-theme .budget-viewer__header h2 {
  color: #ffc939;
}
.yellow-theme .budget-viewer__content__lines .budget-line {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.yellow-theme .budget-viewer__content__lines .budget-line.accepted {
  border: 1px solid #56cd86;
  background-color: #56cd86;
  color: white;
}
.yellow-theme .budget-viewer__content__lines .budget-line.accepted.authorized {
  border: 1px solid #31a560;
  background-color: #31a560;
}
.yellow-theme .budget-viewer__content__lines .budget-line.accepted.authorized .mat-checkbox-disabled .mat-checkbox-label {
  color: white;
}
.yellow-theme .budget-viewer__content__lines .budget-line mat-checkbox {
  width: 100%;
  padding: 15px;
}
.yellow-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout {
  width: 100%;
}
.yellow-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
  width: 90%;
}
@media screen and (max-width: 768px) {
  .yellow-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
    width: 80%;
  }
}
.yellow-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.yellow-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label .checkbox-budget-label-1 {
  font-weight: 600;
}
.yellow-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label .checkbox-budget-label-2 {
  font-weight: 500;
  margin-right: 30px;
}
@media screen and (max-width: 768px) {
  .yellow-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label .checkbox-budget-label-2 {
    margin-right: 15px;
  }
}
.yellow-theme .budget-viewer__content__lines .budget-line mat-checkbox.mat-checkbox-checked .mat-checkbox-background {
  background-color: white;
}
.yellow-theme .budget-viewer__content__lines .budget-line mat-checkbox.mat-checkbox-checked .mat-checkbox-background .mat-checkbox-checkmark {
  fill: #42c778 !important;
}
.yellow-theme .budget-viewer__content__lines .budget-line mat-checkbox.mat-checkbox-checked .mat-checkbox-background .mat-checkbox-checkmark-path {
  stroke: #42c778 !important;
}
.yellow-theme .budget-viewer__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.yellow-theme .payments-viewer-wrapper .mat-dialog-container {
  padding: 0px;
}
.yellow-theme .payments-viewer__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.yellow-theme .payments-viewer__header h2 {
  color: #ffc939;
}
.yellow-theme .payments-viewer__content__lines .payments-line {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.yellow-theme .payments-viewer__content__lines .payments-line.pending {
  border: 1px solid #fff8ea;
  background-color: #fff8ea;
  color: white;
}
.yellow-theme .payments-viewer__content__lines .payments-line.accepted {
  border: 1px solid #2b9155;
  background-color: #2b9155;
  color: white;
}
.yellow-theme .payments-viewer__content__lines .payments-line.error {
  border: 1px solid #c10c0c;
  background-color: #c10c0c;
  color: white;
}
.yellow-theme .payments-viewer__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.yellow-theme .signature-viewer-wrapper .mat-dialog-container {
  padding: 0px;
}
.yellow-theme .signature-viewer__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.yellow-theme .signature-viewer__header h2 {
  color: #ffc939;
}
.yellow-theme .signature-viewer__content .mat-step-icon.mat-step-icon-state-edit mat-icon {
  border-radius: 20px;
  padding: 5px;
  background-color: #42c778;
}
.yellow-theme .signature-viewer__content .mat-step-icon.mat-step-icon-state-number {
  background-color: #ffc331;
}
.yellow-theme .signature-viewer__content mat-stepper {
  background-color: transparent;
  min-width: 100%;
}
.yellow-theme .signature-viewer__content mat-stepper mat-step-header {
  padding: 0 !important;
  overflow: visible !important;
}
.yellow-theme .signature-viewer__content mat-stepper mat-step-header .mat-step-label {
  padding: 5px 5px 0 5px !important;
  font-size: 0.7rem;
}
.yellow-theme .signature-viewer__content mat-stepper mat-step-header .mat-step-label .disabled {
  color: #bdbdbd;
}
.yellow-theme .signature-viewer__content .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:after,
.yellow-theme .signature-viewer__content .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:before,
.yellow-theme .signature-viewer__content .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 13px !important;
}
.yellow-theme .signature-viewer__content .mat-horizontal-content-container {
  padding: 5px 0 0 0;
}
.yellow-theme .signature-viewer__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.yellow-theme .remote-signature-viewer-wrapper .mat-dialog-container {
  padding: 0;
}
.yellow-theme .unverified-signature-warning {
  display: none !important;
}
.yellow-theme .card-docs__section__content__budget, .yellow-theme .card-docs__section__content__remote-sign, .yellow-theme .card-docs__section__content__payment {
  background-color: #42c778;
  color: white;
  font-size: bold;
}
.yellow-theme .card-docs__section__content__budget mat-icon, .yellow-theme .card-docs__section__content__remote-sign mat-icon, .yellow-theme .card-docs__section__content__payment mat-icon {
  background-color: #69d394;
}
.yellow-theme .card-docs__section__content__budget mat-icon.alert-icon, .yellow-theme .card-docs__section__content__remote-sign mat-icon.alert-icon, .yellow-theme .card-docs__section__content__payment mat-icon.alert-icon {
  color: yellow;
  background-color: transparent;
  margin-left: -7px;
}
.yellow-theme .card-docs__section__content__budget.pending, .yellow-theme .card-docs__section__content__remote-sign.pending, .yellow-theme .card-docs__section__content__payment.pending {
  background-color: #ffd449;
  color: white;
}
.yellow-theme .card-docs__section__content__budget.pending mat-icon, .yellow-theme .card-docs__section__content__remote-sign.pending mat-icon, .yellow-theme .card-docs__section__content__payment.pending mat-icon {
  background-color: #ffe07c;
}
.yellow-theme .card-docs__section__content__budget.pending mat-icon.alert-icon, .yellow-theme .card-docs__section__content__remote-sign.pending mat-icon.alert-icon, .yellow-theme .card-docs__section__content__payment.pending mat-icon.alert-icon {
  background-color: transparent;
  margin-left: -7px;
}
.yellow-theme .card-docs__section__content__budget.payments, .yellow-theme .card-docs__section__content__remote-sign.payments, .yellow-theme .card-docs__section__content__payment.payments {
  background-color: #ffefd0;
  color: #ffffff;
}
.yellow-theme .card-docs__section__content__budget.payments mat-icon, .yellow-theme .card-docs__section__content__remote-sign.payments mat-icon, .yellow-theme .card-docs__section__content__payment.payments mat-icon {
  background-color: white;
}
.yellow-theme .card-docs__section__content__budget.payments mat-icon.alert-icon, .yellow-theme .card-docs__section__content__remote-sign.payments mat-icon.alert-icon, .yellow-theme .card-docs__section__content__payment.payments mat-icon.alert-icon {
  background-color: transparent;
  margin-left: -7px;
}
.yellow-theme .card-docs__section__content__budget.payments.pendingPayments, .yellow-theme .card-docs__section__content__remote-sign.payments.pendingPayments, .yellow-theme .card-docs__section__content__payment.payments.pendingPayments {
  background-color: white;
  color: #ffffff;
}
.yellow-theme .card-docs__section__content__budget.payments.pendingPayments mat-icon, .yellow-theme .card-docs__section__content__remote-sign.payments.pendingPayments mat-icon, .yellow-theme .card-docs__section__content__payment.payments.pendingPayments mat-icon {
  background-color: white;
}
.yellow-theme .card-docs__section__content__budget.payments.pendingPayments mat-icon.alert-icon, .yellow-theme .card-docs__section__content__remote-sign.payments.pendingPayments mat-icon.alert-icon, .yellow-theme .card-docs__section__content__payment.payments.pendingPayments mat-icon.alert-icon {
  background-color: transparent;
  margin-left: -7px;
}
.yellow-theme .send-code-content {
  background-color: white;
  color: #000000;
}
.yellow-theme .send-code-content__link {
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  color: #ffc939;
  background-color: #ffffff;
}
.yellow-theme .send-code-content__link:hover {
  background-color: #ffc939;
  color: #ffffff !important;
}
.yellow-theme .ask-for-code-content {
  background-color: white;
  color: #000000;
}
.yellow-theme .global-message--success {
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;
}
.yellow-theme .global-message--success .mat-simple-snackbar-action {
  color: #155724 !important;
}
.yellow-theme .global-message--warning {
  color: #2c2c2c !important;
  background-color: rgb(206, 134, 0) !important;
  border-color: #be8f00 !important;
}
.yellow-theme .global-message--warning .mat-simple-snackbar-action {
  color: #2c2c2c !important;
}
.yellow-theme .global-message--error {
  color: rgb(245, 245, 245) !important;
  background-color: rgb(138, 0, 0) !important;
  border-color: rgb(114, 2, 2) !important;
}
.yellow-theme .global-message--error .mat-simple-snackbar-action {
  color: rgb(245, 219, 219) !important;
}
.yellow-theme html,
.yellow-theme body {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
  height: 100%;
}
.yellow-theme body {
  margin: 0;
}
.yellow-theme .ngx-summernote-view {
  display: none !important;
}
.yellow-theme .content-wrapper {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1120px;
}
.yellow-theme .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.yellow-theme .mat-app-background {
  height: 100%;
  background-color: #f4f4f9;
}
.yellow-theme .uppercase {
  text-transform: uppercase;
}
.yellow-theme .capitalize {
  text-transform: capitalize;
}
.yellow-theme .message-block {
  align-items: center;
  background-color: #848484;
  border-radius: 0.571rem;
  box-shadow: 0 10px 22px 0 rgba(0, 0, 0, 0.04);
  color: #fff;
  display: flex;
  font-size: 1.143rem;
  font-weight: 600;
  line-height: 1.5;
  overflow-wrap: anywhere;
  padding: 1.429rem 1.714rem;
  word-wrap: anywhere;
}
.yellow-theme .message-block .mat-icon {
  flex: 0 0 auto;
  margin-right: 1.143rem;
}
.yellow-theme .message-block.message-block__success {
  background-color: #ffd449;
  color: #ffffff;
}
.yellow-theme .message-block.message-block__warning {
  background-color: #ffb025;
  color: #fff;
}
.yellow-theme .message-block.message-block__error {
  background-color: #f34040;
  color: #fff;
}
.yellow-theme .hidden,
.yellow-theme *[hidden] {
  display: none !important;
  overflow: hidden !important;
}
.yellow-theme .hidden.block,
.yellow-theme *[hidden].block {
  display: hidden !important;
}
.yellow-theme .inlineBlock {
  display: inline-block;
}
.yellow-theme .underline {
  text-decoration: underline;
}
.yellow-theme .opacity-1 {
  opacity: 1;
}
.yellow-theme .opacity-08 {
  opacity: 0.8;
}
.yellow-theme .opacity-06 {
  opacity: 0.6;
}
.yellow-theme .opacity-04 {
  opacity: 0.4;
}
.yellow-theme .opacity-02 {
  opacity: 0.2;
}
.yellow-theme .opacity-0 {
  opacity: 0;
}
.yellow-theme .overlay-spinner {
  background: rgba(255, 255, 255, 0.4);
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.yellow-theme .overlay-spinner > .mat-spinner {
  margin: 3rem auto;
}
.yellow-theme .spin-icon {
  animation: spinner 0.6s linear infinite;
}
.yellow-theme .height-100 {
  height: 100%;
}
.yellow-theme .note-modal-backdrop {
  display: none !important;
}
.yellow-theme .note-editing-area {
  background-color: white;
}
.yellow-theme .note-editor .note-editing-area .note-editable table.table-no-bordered td,
.yellow-theme .note-editor .note-editing-area .note-editable table.table-no-bordered th {
  border: 0px;
}
.yellow-theme .autocomplete-panel-class,
.yellow-theme .notification-mention-menu {
  max-height: calc(var(--100vh) - 65px) !important;
  height: 550px !important;
}
.yellow-theme .notification-mention-menu .mat-menu-content {
  height: calc(100% - 16px);
}
.yellow-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #ffd449;
}
.yellow-theme .mat-form-field-appearance-outline .mat-form-field-label {
  color: #ffd449;
  font-weight: 600;
  text-transform: uppercase;
}
.yellow-theme .mat-form-field-appearance-outline input.mat-input-element {
  text-overflow: ellipsis;
}
.yellow-theme .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  z-index: 1;
  margin-top: 0.2em;
}
.yellow-theme .mat-form-field-appearance-outline.date-picker .mat-form-field-subscript-wrapper {
  margin-top: 2.2em;
}
.yellow-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
  color: #f6bc00;
}
.yellow-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-label {
  color: #f6bc00;
}
.yellow-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: transparent;
}
.yellow-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: #ffd449;
}
.yellow-theme .mat-form-field-appearance-outline.mat-form-field-disabled input.mat-input-element {
  color: #121212;
}
.yellow-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-form-field-infix,
.yellow-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-form-field-suffix {
  padding: 0.25em 0 0.75em 0;
  font-size: 1em;
}
.yellow-theme .smaller-mat-form-fields .mat-form-field-appearance-outline.date-picker .mat-form-field-infix,
.yellow-theme .smaller-mat-form-fields .mat-form-field-appearance-outline.date-picker .mat-form-field-suffix {
  padding: 0.25em 0 0.6em 0;
  margin-top: -0.3em;
}
.yellow-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-infix, .yellow-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-suffix {
  padding: 0.2em 0 0.25em 0;
}
.yellow-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-infix .mat-input-element, .yellow-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-suffix .mat-input-element {
  position: absolute;
  margin-top: -11px;
}
.yellow-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-form-field-infix .mat-select-arrow {
  margin-top: 10px;
}
.yellow-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-select-empty + .mat-form-field-label-wrapper .mat-form-field-label {
  margin-top: -0.75em;
}
.yellow-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-select + .mat-form-field-label-wrapper .mat-form-field-label {
  margin-top: -0.3em;
}
.mat-select .yellow-theme .smaller-mat-form-fields__no-margin.mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding: 0 0;
  margin: 0 0;
}
.yellow-theme .smaller-mat-form-fields__no-margin.mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding: 0 0;
  margin: 0 0;
}
.yellow-theme .smaller-mat-form-fields .mat-form-field__no-border .mat-form-field-outline-start,
.yellow-theme .smaller-mat-form-fields .mat-form-field__no-border .mat-form-field-outline-gap,
.yellow-theme .smaller-mat-form-fields .mat-form-field__no-border .mat-form-field-outline-end {
  border: none;
}
.yellow-theme .smaller-mat-form-fields .mat-form-field__border-more-radius .mat-form-field-outline-start, .yellow-theme .smaller-mat-form-fields.mat-form-field__border-more-radius .mat-form-field-outline-start {
  border-radius: 28px 0 0 28px !important;
  min-width: 28px;
}
.yellow-theme .smaller-mat-form-fields .mat-form-field__border-more-radius .mat-form-field-outline-end, .yellow-theme .smaller-mat-form-fields.mat-form-field__border-more-radius .mat-form-field-outline-end {
  border-radius: 0 28px 28px 0 !important;
}
.yellow-theme button.mat-button, .yellow-theme button.mat-flat-button {
  font-size: 1rem;
}
.yellow-theme button.mat-button.mat-button-fill, .yellow-theme button.mat-flat-button.mat-button-fill {
  width: 100%;
}
.yellow-theme button.mat-button-w-100-left {
  width: 100%;
  text-align: left;
}
.yellow-theme button.mat-flat-button:hover {
  opacity: 0.85;
}
.yellow-theme button.close-modal-button.mat-icon-button {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.3);
  color: #ffb921;
}
.yellow-theme button.close-modal-button.mat-icon-button:hover {
  background-color: rgba(255, 255, 255, 0.4);
}
.yellow-theme button.mat-square-icon {
  border-radius: 5px;
  padding: 0 0;
  min-width: 36px;
  line-height: 36px;
}
.yellow-theme button.mat-square-icon.square-28 {
  min-width: 28px;
  line-height: 28px;
}
.yellow-theme button.mat-dark {
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
}
.yellow-theme button.mat-dark:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
.yellow-theme button .mat-icon {
  overflow: visible;
}
.yellow-theme .mat-flat-button.mat-primary.mat-button-disabled,
.yellow-theme .mat-flat-button.mat-button-disabled.mat-button-disabled,
.yellow-theme .mat-raised-button.mat-primary.mat-button-disabled,
.yellow-theme .mat-raised-button.mat-button-disabled.mat-button-disabled,
.yellow-theme .mat-fab.mat-primary.mat-button-disabled,
.yellow-theme .mat-fab.mat-button-disabled.mat-button-disabled,
.yellow-theme .mat-mini-fab.mat-primary.mat-button-disabled,
.yellow-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: #ffd449;
  color: #ffffff;
  opacity: 0.5;
}
.yellow-theme .mat-button.v-align-bottom .mat-button-wrapper > *,
.yellow-theme .mat-flat-button.v-align-bottom .mat-button-wrapper > *,
.yellow-theme .mat-stroked-button.v-align-bottom .mat-button-wrapper > *,
.yellow-theme .mat-raised-button.v-align-bottom .mat-button-wrapper > *,
.yellow-theme .mat-icon-button.v-align-bottom .mat-button-wrapper > *,
.yellow-theme .mat-fab.v-align-bottom .mat-button-wrapper > *,
.yellow-theme .mat-mini-fab.v-align-bottom .mat-button-wrapper > * {
  vertical-align: text-bottom;
}
.yellow-theme .mat-button.no-padding,
.yellow-theme .mat-flat-button.no-padding,
.yellow-theme .mat-stroked-button.no-padding,
.yellow-theme .mat-raised-button.no-padding,
.yellow-theme .mat-icon-button.no-padding,
.yellow-theme .mat-fab.no-padding,
.yellow-theme .mat-mini-fab.no-padding {
  padding: 0px;
}
.yellow-theme mat-expansion-panel.no-padding .mat-expansion-panel-header {
  padding: 0 !important;
}
.yellow-theme mat-expansion-panel.no-padding .mat-expansion-indicator {
  padding: 0 10px;
}
.yellow-theme .mat-expansion-panel-header {
  border-radius: initial;
  border-bottom: 1px solid #fff2c8;
}
.yellow-theme .mat-expansion-panel-header.mat-expanded {
  height: 49px;
}
.yellow-theme .mat-expansion-panel-header .mat-expansion-indicator {
  border-width: 0 3px 3px 0;
  color: #000000;
}
.yellow-theme .mat-expansion-panel-header .mat-expansion-panel-header-title {
  color: #ffd449;
  font-size: 1rem;
  font-weight: 600;
}
.yellow-theme .header-color-primary .mat-expansion-panel-header {
  background-color: #ffd449 !important;
}
.yellow-theme .header-color-primary .mat-expansion-panel-header:hover {
  background-color: #ffcf42 !important;
}
.yellow-theme .header-color-primary .mat-expansion-indicator::after {
  color: #ffffff;
}
.yellow-theme .header-color-primary .mat-expansion-panel-header-title {
  color: #ffffff;
}
.yellow-theme .mat-expanded > .mat-expansion-panel-content {
  padding-top: 16px;
}
.yellow-theme mat-expansion-panel.no-padding .mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0 0 16px 0;
}
.yellow-theme .mat-select-panel.bigger-selection-panel-class {
  max-height: calc(var(--100vh) - 100px);
}
.yellow-theme .mat-select-panel.bigger-selection-panel-class .mat-optgroup-label {
  overflow: visible !important;
}
.yellow-theme .mat-tab-page-sections .mat-tab-header {
  border-bottom: 0px;
}
.yellow-theme .mat-tab-page-sections .mat-tab-header .mat-tab-label {
  font-size: 1.5rem;
  font-weight: 400;
  padding: 0 15px;
}
.yellow-theme .mat-tab-page-sections.font-size-smaller .mat-tab-header .mat-tab-label {
  font-size: 1.1rem;
  font-weight: 200;
}
.yellow-theme .mat-tab-group.mat-primary .mat-ink-bar,
.yellow-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  height: 3px;
  background-color: #ffd449;
}
.yellow-theme .mat-tab-label.text-red,
.yellow-theme .mat-tab-label.mat-tab-label-active.text-red {
  color: #c10c0c;
}
.yellow-theme .mat-item-card {
  margin-bottom: 20px;
  max-width: calc(50% - 20px);
}
.yellow-theme .mat-item-card__title {
  font-size: 1rem;
  color: #26273b;
}
.yellow-theme .mat-item-card__icons .mat-icon {
  padding-right: 24px;
  color: #ffd449;
}
.yellow-theme .mat-item-card__icons .mat-icon:last-child {
  padding-right: 0;
}
.yellow-theme .mat-table {
  background-color: transparent;
}
.yellow-theme .mat-table.table-ellipsis {
  width: 100%;
  table-layout: fixed;
}
.yellow-theme .mat-table.table-ellipsis th,
.yellow-theme .mat-table.table-ellipsis td {
  overflow: hidden;
  width: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 10px;
}
.yellow-theme .mat-table.table-ellipsis th.no-ellipsis,
.yellow-theme .mat-table.table-ellipsis td.no-ellipsis {
  overflow: inherit;
}
.yellow-theme .mat-table .mat-header-row .mat-header-cell {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  color: #000000;
}
.yellow-theme .mat-table tr.mat-row {
  background-color: white;
  height: 65px;
}
.yellow-theme .mat-table tr.mat-row .mat-cell {
  font-size: 16px;
  color: #26273b;
}
.yellow-theme .mat-table tr.mat-row .mat-cell.action-buttons {
  color: #ffd449;
  font-size: 24px;
}
.yellow-theme .mat-paginator {
  background-color: transparent;
}
.yellow-theme .mat-checkbox-layout {
  white-space: normal !important;
}
.yellow-theme mat-checkbox.light-border .mat-checkbox-frame {
  border-color: #ffffff;
}
.yellow-theme .mat-tooltip {
  font-size: 0.9em;
  max-width: 350px !important;
}
.yellow-theme .mat-menu-panel.dark-theme {
  min-width: 150px;
  background-color: #000000;
}
.yellow-theme .mat-menu-panel.dark-theme .mat-menu-item {
  color: #ffffff;
}
.yellow-theme .mat-menu-panel.dark-theme .mat-divider {
  background-color: #000000;
}
.yellow-theme .draggable-item {
  border-radius: 5px;
  background-color: #f4f4f9;
  margin: 5px 0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.yellow-theme .draggable-item .cdk-drag-handle {
  cursor: grab;
}
.yellow-theme .draggable-item .cdk-drag-handle:active {
  cursor: grabbing;
}
.yellow-theme .draggable-item.cdk-drag-preview {
  pointer-events: fill !important;
  box-shadow: 0px 0px 25px 0px rgb(0, 0, 0);
}
.yellow-theme .draggable-item.cdk-drag-preview .cdk-drag-handle {
  cursor: grabbing;
}
.yellow-theme .remove-padding mat-dialog-container {
  padding: 0px;
}
.yellow-theme .mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Saira;
}
.yellow-theme .mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.yellow-theme .mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.yellow-theme .mat-h1,
.yellow-theme .mat-headline,
.yellow-theme .mat-typography .mat-h1,
.yellow-theme .mat-typography .mat-headline,
.yellow-theme .mat-typography h1 {
  font: 900 24px / 32px Saira;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.yellow-theme .mat-h2,
.yellow-theme .mat-title,
.yellow-theme .mat-typography .mat-h2,
.yellow-theme .mat-typography .mat-title,
.yellow-theme .mat-typography h2 {
  font: 600 20px / 28px Saira;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.yellow-theme .mat-h3,
.yellow-theme .mat-subheading-2,
.yellow-theme .mat-typography .mat-h3,
.yellow-theme .mat-typography .mat-subheading-2,
.yellow-theme .mat-typography h3 {
  font: 400 16px / 28px Saira;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.yellow-theme .mat-h4,
.yellow-theme .mat-subheading-1,
.yellow-theme .mat-typography .mat-h4,
.yellow-theme .mat-typography .mat-subheading-1,
.yellow-theme .mat-typography h4 {
  font: 600 14px / 1em Saira;
  letter-spacing: 3px;
  margin: 0 0 16px;
}
.yellow-theme .mat-h5,
.yellow-theme .mat-typography .mat-h5,
.yellow-theme .mat-typography h5 {
  font: 400 calc(16px * 0.83) / 20px Saira;
  margin: 0 0 12px;
}
.yellow-theme .mat-h6,
.yellow-theme .mat-typography .mat-h6,
.yellow-theme .mat-typography h6 {
  font: 400 calc(16px * 0.67) / 20px Saira;
  margin: 0 0 12px;
}
.yellow-theme .mat-body-strong,
.yellow-theme .mat-body-2,
.yellow-theme .mat-typography .mat-body-strong,
.yellow-theme .mat-typography .mat-body-2 {
  font: 400 18px / 26px Saira;
  letter-spacing: normal;
}
.yellow-theme .mat-body,
.yellow-theme .mat-body-1,
.yellow-theme .mat-typography .mat-body,
.yellow-theme .mat-typography .mat-body-1,
.yellow-theme .mat-typography {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
}
.yellow-theme .mat-body p,
.yellow-theme .mat-body-1 p,
.yellow-theme .mat-typography .mat-body p,
.yellow-theme .mat-typography .mat-body-1 p,
.yellow-theme .mat-typography p {
  margin: 0 0 12px;
}
.yellow-theme .mat-small,
.yellow-theme .mat-caption,
.yellow-theme .mat-typography .mat-small,
.yellow-theme .mat-typography .mat-caption {
  font: 400 12px / 20px Saira;
  letter-spacing: normal;
}
.yellow-theme .mat-display-4,
.yellow-theme .mat-typography .mat-display-4 {
  font: 400 112px / 112px Saira;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.yellow-theme .mat-display-3,
.yellow-theme .mat-typography .mat-display-3 {
  font: 900 56px / 56px Saira;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.yellow-theme .mat-display-2,
.yellow-theme .mat-typography .mat-display-2 {
  font: 900 40px / 1.2 Saira;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.yellow-theme .mat-display-1,
.yellow-theme .mat-typography .mat-display-1 {
  font: 600 32px / 1.13 Saira;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.yellow-theme .mat-bottom-sheet-container {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
}
.yellow-theme .mat-button, .yellow-theme .mat-raised-button, .yellow-theme .mat-icon-button, .yellow-theme .mat-stroked-button,
.yellow-theme .mat-flat-button, .yellow-theme .mat-fab, .yellow-theme .mat-mini-fab {
  font-family: Saira;
  font-size: 14px;
  font-weight: 600;
}
.yellow-theme .mat-button-toggle {
  font-family: Saira;
}
.yellow-theme .mat-card {
  font-family: Saira;
}
.yellow-theme .mat-card-title {
  font-size: 24px;
  font-weight: 600;
}
.yellow-theme .mat-card-header .mat-card-title {
  font-size: 20px;
}
.yellow-theme .mat-card-subtitle,
.yellow-theme .mat-card-content {
  font-size: 16px;
}
.yellow-theme .mat-checkbox {
  font-family: Saira;
}
.yellow-theme .mat-checkbox-layout .mat-checkbox-label {
  line-height: 26px;
}
.yellow-theme .mat-chip {
  font-size: 18px;
  font-weight: 400;
}
.yellow-theme .mat-chip .mat-chip-trailing-icon.mat-icon,
.yellow-theme .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.yellow-theme .mat-table {
  font-family: Saira;
}
.yellow-theme .mat-header-cell {
  font-size: 12px;
  font-weight: 400;
}
.yellow-theme .mat-cell, .yellow-theme .mat-footer-cell {
  font-size: 16px;
}
.yellow-theme .mat-calendar {
  font-family: Saira;
}
.yellow-theme .mat-calendar-body {
  font-size: 13px;
}
.yellow-theme .mat-calendar-body-label,
.yellow-theme .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 600;
}
.yellow-theme .mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.yellow-theme .mat-dialog-title {
  font: 600 20px / 28px Saira;
  letter-spacing: normal;
}
.yellow-theme .mat-expansion-panel-header {
  font-family: Saira;
  font-size: 14px;
  font-weight: 600;
}
.yellow-theme .mat-expansion-panel-content {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
}
.yellow-theme .mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Saira;
  letter-spacing: normal;
}
.yellow-theme .mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.yellow-theme .mat-form-field-prefix .mat-icon,
.yellow-theme .mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.yellow-theme .mat-form-field-prefix .mat-icon-button,
.yellow-theme .mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.yellow-theme .mat-form-field-prefix .mat-icon-button .mat-icon,
.yellow-theme .mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.yellow-theme .mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.yellow-theme .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.yellow-theme .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34363em) scale(0.75);
  width: 133.3334533333%;
}
.yellow-theme .mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34362em) scale(0.75);
  width: 133.3334633333%;
}
.yellow-theme .mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.yellow-theme .mat-form-field-label {
  top: 1.34375em;
}
.yellow-theme .mat-form-field-underline {
  bottom: 1.34375em;
}
.yellow-theme .mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.yellow-theme .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.yellow-theme .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.yellow-theme .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.yellow-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00136px);
  width: 133.3336933333%;
}
.yellow-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00137px);
  width: 133.3337033333%;
}
.yellow-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00138px);
  width: 133.3337133333%;
}
.yellow-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.yellow-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.yellow-theme .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .yellow-theme .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.yellow-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28086em) scale(0.75);
  }
  .yellow-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28085em) scale(0.75);
  }
  .yellow-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28084em) scale(0.75);
  }
}
.yellow-theme .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.yellow-theme .mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.yellow-theme .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.yellow-theme .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59363em) scale(0.75);
  width: 133.3334533333%;
}
.yellow-theme .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59362em) scale(0.75);
  width: 133.3334633333%;
}
.yellow-theme .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.yellow-theme .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.yellow-theme .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.yellow-theme .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59363em) scale(0.75);
  width: 133.3334533333%;
}
.yellow-theme .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59362em) scale(0.75);
  width: 133.3334633333%;
}
.yellow-theme .mat-grid-tile-header,
.yellow-theme .mat-grid-tile-footer {
  font-size: 16px;
}
.yellow-theme .mat-grid-tile-header .mat-line,
.yellow-theme .mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.yellow-theme .mat-grid-tile-header .mat-line:nth-child(n+2),
.yellow-theme .mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.yellow-theme input.mat-input-element {
  margin-top: -0.0625em;
}
.yellow-theme .mat-menu-item {
  font-family: Saira;
  font-size: 16px;
  font-weight: 400;
}
.yellow-theme .mat-paginator,
.yellow-theme .mat-paginator-page-size .mat-select-trigger {
  font-family: Saira;
  font-size: 12px;
}
.yellow-theme .mat-radio-button {
  font-family: Saira;
}
.yellow-theme .mat-select {
  font-family: Saira;
}
.yellow-theme .mat-select-trigger {
  height: 1.125em;
}
.yellow-theme .mat-slide-toggle-content {
  font-family: Saira;
}
.yellow-theme .mat-slider-thumb-label-text {
  font-family: Saira;
  font-size: 12px;
  font-weight: 400;
}
.yellow-theme .mat-stepper-vertical, .yellow-theme .mat-stepper-horizontal {
  font-family: Saira;
}
.yellow-theme .mat-step-label {
  font-size: 16px;
  font-weight: 400;
}
.yellow-theme .mat-step-sub-label-error {
  font-weight: normal;
}
.yellow-theme .mat-step-label-error {
  font-size: 18px;
}
.yellow-theme .mat-step-label-selected {
  font-size: 18px;
  font-weight: 400;
}
.yellow-theme .mat-tab-group {
  font-family: Saira;
}
.yellow-theme .mat-tab-label, .yellow-theme .mat-tab-link {
  font-family: Saira;
  font-size: 14px;
  font-weight: 600;
}
.yellow-theme .mat-toolbar,
.yellow-theme .mat-toolbar h1,
.yellow-theme .mat-toolbar h2,
.yellow-theme .mat-toolbar h3,
.yellow-theme .mat-toolbar h4,
.yellow-theme .mat-toolbar h5,
.yellow-theme .mat-toolbar h6 {
  font: 600 20px / 28px Saira;
  letter-spacing: normal;
  margin: 0;
}
.yellow-theme .mat-tooltip {
  font-family: Saira;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.yellow-theme .mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.yellow-theme .mat-list-item {
  font-family: Saira;
}
.yellow-theme .mat-list-option {
  font-family: Saira;
}
.yellow-theme .mat-list-base .mat-list-item {
  font-size: 16px;
}
.yellow-theme .mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.yellow-theme .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.yellow-theme .mat-list-base .mat-list-option {
  font-size: 16px;
}
.yellow-theme .mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.yellow-theme .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.yellow-theme .mat-list-base .mat-subheader {
  font-family: Saira;
  font-size: 18px;
  font-weight: 400;
}
.yellow-theme .mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.yellow-theme .mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.yellow-theme .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.yellow-theme .mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.yellow-theme .mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.yellow-theme .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.yellow-theme .mat-list-base[dense] .mat-subheader {
  font-family: Saira;
  font-size: 12px;
  font-weight: 400;
}
.yellow-theme .mat-option {
  font-family: Saira;
  font-size: 16px;
}
.yellow-theme .mat-optgroup-label {
  font: 400 18px / 26px Saira;
  letter-spacing: normal;
}
.yellow-theme .mat-simple-snackbar {
  font-family: Saira;
  font-size: 16px;
}
.yellow-theme .mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 600;
}
.yellow-theme .mat-tree {
  font-family: Saira;
}
.yellow-theme .mat-tree-node,
.yellow-theme .mat-nested-tree-node {
  font-weight: 400;
  font-size: 16px;
}
.yellow-theme .mat-ripple {
  overflow: hidden;
  position: relative;
}
.yellow-theme .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.yellow-theme .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.yellow-theme .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .yellow-theme .mat-ripple-element {
  display: none;
}

.yellow-theme .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .yellow-theme .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.yellow-theme .cdk-overlay-container, .yellow-theme .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.yellow-theme .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.yellow-theme .cdk-overlay-container:empty {
  display: none;
}
.yellow-theme .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.yellow-theme .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.yellow-theme .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.yellow-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .yellow-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.yellow-theme .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.yellow-theme .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.yellow-theme .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.yellow-theme .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.yellow-theme .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.yellow-theme .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.yellow-theme textarea.cdk-textarea-autosize {
  resize: none;
}
.yellow-theme textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.yellow-theme textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.yellow-theme .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.yellow-theme .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.yellow-theme .mat-focus-indicator {
  position: relative;
}
.yellow-theme .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.yellow-theme .mat-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .yellow-theme {
  --mat-focus-indicator-display: block;
}

.yellow-theme .mat-mdc-focus-indicator {
  position: relative;
}
.yellow-theme .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.yellow-theme .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .yellow-theme {
  --mat-mdc-focus-indicator-display: block;
}

.yellow-theme .mat-ripple-element {
  background-color: rgba(18, 18, 18, 0.1);
}
.yellow-theme .mat-option {
  color: #121212;
}
.yellow-theme .mat-option:hover:not(.mat-option-disabled), .yellow-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.yellow-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.yellow-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: #121212;
}
.yellow-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.yellow-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ffd449;
}
.yellow-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #9fa8da;
}
.yellow-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e81253;
}
.yellow-theme .mat-optgroup-label {
  color: #26273b;
}
.yellow-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.yellow-theme .mat-pseudo-checkbox {
  color: #26273b;
}
.yellow-theme .mat-pseudo-checkbox::after {
  color: white;
}
.yellow-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.yellow-theme .mat-primary .mat-pseudo-checkbox-checked,
.yellow-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #ffd449;
}
.yellow-theme .mat-pseudo-checkbox-checked,
.yellow-theme .mat-pseudo-checkbox-indeterminate,
.yellow-theme .mat-accent .mat-pseudo-checkbox-checked,
.yellow-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #9fa8da;
}
.yellow-theme .mat-warn .mat-pseudo-checkbox-checked,
.yellow-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e81253;
}
.yellow-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.yellow-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.yellow-theme .mat-app-background, .yellow-theme.mat-app-background {
  background-color: white;
  color: #121212;
}
.yellow-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(18, 18, 18, 0.2), 0px 1px 1px 0px rgba(18, 18, 18, 0.14), 0px 1px 3px 0px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(18, 18, 18, 0.2), 0px 3px 4px 0px rgba(18, 18, 18, 0.14), 0px 1px 8px 0px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 5px 8px 0px rgba(18, 18, 18, 0.14), 0px 1px 14px 0px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 6px 10px 0px rgba(18, 18, 18, 0.14), 0px 1px 18px 0px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(18, 18, 18, 0.2), 0px 7px 10px 1px rgba(18, 18, 18, 0.14), 0px 2px 16px 1px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(18, 18, 18, 0.2), 0px 8px 10px 1px rgba(18, 18, 18, 0.14), 0px 3px 14px 2px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(18, 18, 18, 0.2), 0px 9px 12px 1px rgba(18, 18, 18, 0.14), 0px 3px 16px 2px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(18, 18, 18, 0.2), 0px 10px 14px 1px rgba(18, 18, 18, 0.14), 0px 4px 18px 3px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(18, 18, 18, 0.2), 0px 11px 15px 1px rgba(18, 18, 18, 0.14), 0px 4px 20px 3px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(18, 18, 18, 0.2), 0px 12px 17px 2px rgba(18, 18, 18, 0.14), 0px 5px 22px 4px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(18, 18, 18, 0.2), 0px 13px 19px 2px rgba(18, 18, 18, 0.14), 0px 5px 24px 4px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(18, 18, 18, 0.2), 0px 14px 21px 2px rgba(18, 18, 18, 0.14), 0px 5px 26px 4px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(18, 18, 18, 0.2), 0px 15px 22px 2px rgba(18, 18, 18, 0.14), 0px 6px 28px 5px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(18, 18, 18, 0.2), 0px 16px 24px 2px rgba(18, 18, 18, 0.14), 0px 6px 30px 5px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(18, 18, 18, 0.2), 0px 17px 26px 2px rgba(18, 18, 18, 0.14), 0px 6px 32px 5px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(18, 18, 18, 0.2), 0px 18px 28px 2px rgba(18, 18, 18, 0.14), 0px 7px 34px 6px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(18, 18, 18, 0.2), 0px 19px 29px 2px rgba(18, 18, 18, 0.14), 0px 7px 36px 6px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(18, 18, 18, 0.2), 0px 20px 31px 3px rgba(18, 18, 18, 0.14), 0px 8px 38px 7px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(18, 18, 18, 0.2), 0px 21px 33px 3px rgba(18, 18, 18, 0.14), 0px 8px 40px 7px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(18, 18, 18, 0.2), 0px 22px 35px 3px rgba(18, 18, 18, 0.14), 0px 8px 42px 7px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(18, 18, 18, 0.2), 0px 23px 36px 3px rgba(18, 18, 18, 0.14), 0px 9px 44px 8px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(18, 18, 18, 0.2), 0px 24px 38px 3px rgba(18, 18, 18, 0.14), 0px 9px 46px 8px rgba(18, 18, 18, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.yellow-theme .mat-autocomplete-panel {
  background: white;
  color: #121212;
}
.yellow-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.yellow-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: #121212;
}
.yellow-theme .mat-badge-content {
  color: white;
  background: #ffd449;
}
.cdk-high-contrast-active .yellow-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.yellow-theme .mat-badge-accent .mat-badge-content {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.yellow-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e81253;
}
.yellow-theme .mat-badge-disabled .mat-badge-content {
  background: #c1c1c1;
  color: rgba(0, 0, 0, 0.38);
}
.yellow-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(18, 18, 18, 0.2), 0px 16px 24px 2px rgba(18, 18, 18, 0.14), 0px 6px 30px 5px rgba(18, 18, 18, 0.12);
  background: white;
  color: #121212;
}
.yellow-theme .mat-button, .yellow-theme .mat-icon-button, .yellow-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.yellow-theme .mat-button.mat-primary, .yellow-theme .mat-icon-button.mat-primary, .yellow-theme .mat-stroked-button.mat-primary {
  color: #ffd449;
}
.yellow-theme .mat-button.mat-accent, .yellow-theme .mat-icon-button.mat-accent, .yellow-theme .mat-stroked-button.mat-accent {
  color: #9fa8da;
}
.yellow-theme .mat-button.mat-warn, .yellow-theme .mat-icon-button.mat-warn, .yellow-theme .mat-stroked-button.mat-warn {
  color: #e81253;
}
.yellow-theme .mat-button.mat-primary.mat-button-disabled, .yellow-theme .mat-button.mat-accent.mat-button-disabled, .yellow-theme .mat-button.mat-warn.mat-button-disabled, .yellow-theme .mat-button.mat-button-disabled.mat-button-disabled, .yellow-theme .mat-icon-button.mat-primary.mat-button-disabled, .yellow-theme .mat-icon-button.mat-accent.mat-button-disabled, .yellow-theme .mat-icon-button.mat-warn.mat-button-disabled, .yellow-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .yellow-theme .mat-stroked-button.mat-primary.mat-button-disabled, .yellow-theme .mat-stroked-button.mat-accent.mat-button-disabled, .yellow-theme .mat-stroked-button.mat-warn.mat-button-disabled, .yellow-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(18, 18, 18, 0.26);
}
.yellow-theme .mat-button.mat-primary .mat-button-focus-overlay, .yellow-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .yellow-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #ffd449;
}
.yellow-theme .mat-button.mat-accent .mat-button-focus-overlay, .yellow-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .yellow-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #9fa8da;
}
.yellow-theme .mat-button.mat-warn .mat-button-focus-overlay, .yellow-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .yellow-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e81253;
}
.yellow-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .yellow-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .yellow-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.yellow-theme .mat-button .mat-ripple-element, .yellow-theme .mat-icon-button .mat-ripple-element, .yellow-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.yellow-theme .mat-button-focus-overlay {
  background: #121212;
}
.yellow-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.yellow-theme .mat-flat-button, .yellow-theme .mat-raised-button, .yellow-theme .mat-fab, .yellow-theme .mat-mini-fab {
  color: #121212;
  background-color: white;
}
.yellow-theme .mat-flat-button.mat-primary, .yellow-theme .mat-raised-button.mat-primary, .yellow-theme .mat-fab.mat-primary, .yellow-theme .mat-mini-fab.mat-primary {
  color: white;
}
.yellow-theme .mat-flat-button.mat-accent, .yellow-theme .mat-raised-button.mat-accent, .yellow-theme .mat-fab.mat-accent, .yellow-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.yellow-theme .mat-flat-button.mat-warn, .yellow-theme .mat-raised-button.mat-warn, .yellow-theme .mat-fab.mat-warn, .yellow-theme .mat-mini-fab.mat-warn {
  color: white;
}
.yellow-theme .mat-flat-button.mat-primary.mat-button-disabled, .yellow-theme .mat-flat-button.mat-accent.mat-button-disabled, .yellow-theme .mat-flat-button.mat-warn.mat-button-disabled, .yellow-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .yellow-theme .mat-raised-button.mat-primary.mat-button-disabled, .yellow-theme .mat-raised-button.mat-accent.mat-button-disabled, .yellow-theme .mat-raised-button.mat-warn.mat-button-disabled, .yellow-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .yellow-theme .mat-fab.mat-primary.mat-button-disabled, .yellow-theme .mat-fab.mat-accent.mat-button-disabled, .yellow-theme .mat-fab.mat-warn.mat-button-disabled, .yellow-theme .mat-fab.mat-button-disabled.mat-button-disabled, .yellow-theme .mat-mini-fab.mat-primary.mat-button-disabled, .yellow-theme .mat-mini-fab.mat-accent.mat-button-disabled, .yellow-theme .mat-mini-fab.mat-warn.mat-button-disabled, .yellow-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(18, 18, 18, 0.26);
}
.yellow-theme .mat-flat-button.mat-primary, .yellow-theme .mat-raised-button.mat-primary, .yellow-theme .mat-fab.mat-primary, .yellow-theme .mat-mini-fab.mat-primary {
  background-color: #ffd449;
}
.yellow-theme .mat-flat-button.mat-accent, .yellow-theme .mat-raised-button.mat-accent, .yellow-theme .mat-fab.mat-accent, .yellow-theme .mat-mini-fab.mat-accent {
  background-color: #9fa8da;
}
.yellow-theme .mat-flat-button.mat-warn, .yellow-theme .mat-raised-button.mat-warn, .yellow-theme .mat-fab.mat-warn, .yellow-theme .mat-mini-fab.mat-warn {
  background-color: #e81253;
}
.yellow-theme .mat-flat-button.mat-primary.mat-button-disabled, .yellow-theme .mat-flat-button.mat-accent.mat-button-disabled, .yellow-theme .mat-flat-button.mat-warn.mat-button-disabled, .yellow-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .yellow-theme .mat-raised-button.mat-primary.mat-button-disabled, .yellow-theme .mat-raised-button.mat-accent.mat-button-disabled, .yellow-theme .mat-raised-button.mat-warn.mat-button-disabled, .yellow-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .yellow-theme .mat-fab.mat-primary.mat-button-disabled, .yellow-theme .mat-fab.mat-accent.mat-button-disabled, .yellow-theme .mat-fab.mat-warn.mat-button-disabled, .yellow-theme .mat-fab.mat-button-disabled.mat-button-disabled, .yellow-theme .mat-mini-fab.mat-primary.mat-button-disabled, .yellow-theme .mat-mini-fab.mat-accent.mat-button-disabled, .yellow-theme .mat-mini-fab.mat-warn.mat-button-disabled, .yellow-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: #f7f7f7;
}
.yellow-theme .mat-flat-button.mat-primary .mat-ripple-element, .yellow-theme .mat-raised-button.mat-primary .mat-ripple-element, .yellow-theme .mat-fab.mat-primary .mat-ripple-element, .yellow-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.yellow-theme .mat-flat-button.mat-accent .mat-ripple-element, .yellow-theme .mat-raised-button.mat-accent .mat-ripple-element, .yellow-theme .mat-fab.mat-accent .mat-ripple-element, .yellow-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.yellow-theme .mat-flat-button.mat-warn .mat-ripple-element, .yellow-theme .mat-raised-button.mat-warn .mat-ripple-element, .yellow-theme .mat-fab.mat-warn .mat-ripple-element, .yellow-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.yellow-theme .mat-stroked-button:not([class*=mat-elevation-z]), .yellow-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(18, 18, 18, 0.2), 0px 8px 10px 1px rgba(18, 18, 18, 0.14), 0px 3px 14px 2px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-fab:not([class*=mat-elevation-z]), .yellow-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 6px 10px 0px rgba(18, 18, 18, 0.14), 0px 1px 18px 0px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .yellow-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(18, 18, 18, 0.2), 0px 12px 17px 2px rgba(18, 18, 18, 0.14), 0px 5px 22px 4px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .yellow-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.yellow-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.yellow-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.yellow-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.yellow-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.yellow-theme .mat-button-toggle-appearance-standard {
  color: #121212;
  background: white;
}
.yellow-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.yellow-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.yellow-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.yellow-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.yellow-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: #26273b;
}
.yellow-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #121212;
}
.yellow-theme .mat-button-toggle-disabled {
  color: rgba(18, 18, 18, 0.26);
  background-color: #eeeeee;
}
.yellow-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.yellow-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.yellow-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.yellow-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.yellow-theme .mat-card {
  background: white;
  color: #121212;
}
.yellow-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(18, 18, 18, 0.2), 0px 1px 1px 0px rgba(18, 18, 18, 0.14), 0px 1px 3px 0px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-card-subtitle {
  color: #26273b;
}
.yellow-theme .mat-checkbox-frame {
  border-color: #26273b;
}
.yellow-theme .mat-checkbox-checkmark {
  fill: white;
}
.yellow-theme .mat-checkbox-checkmark-path {
  stroke: white !important;
}
.yellow-theme .mat-checkbox-mixedmark {
  background-color: white;
}
.yellow-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .yellow-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #ffd449;
}
.yellow-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .yellow-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #9fa8da;
}
.yellow-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .yellow-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e81253;
}
.yellow-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .yellow-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.yellow-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.yellow-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.yellow-theme .mat-checkbox .mat-ripple-element {
  background-color: #121212;
}
.yellow-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.yellow-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #ffd449;
}
.yellow-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.yellow-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #9fa8da;
}
.yellow-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.yellow-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e81253;
}
.yellow-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: #121212;
}
.yellow-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: #121212;
  opacity: 0.4;
}
.yellow-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(18, 18, 18, 0.2), 0px 3px 4px 0px rgba(18, 18, 18, 0.14), 0px 1px 8px 0px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.yellow-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.yellow-theme .mat-chip.mat-standard-chip::after {
  background: #121212;
}
.yellow-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #ffd449;
  color: white;
}
.yellow-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.yellow-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.yellow-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e81253;
  color: white;
}
.yellow-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.yellow-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.yellow-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.yellow-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.yellow-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.yellow-theme .mat-table {
  background: white;
}
.yellow-theme .mat-table thead, .yellow-theme .mat-table tbody, .yellow-theme .mat-table tfoot,
.yellow-theme mat-header-row, .yellow-theme mat-row, .yellow-theme mat-footer-row,
.yellow-theme [mat-header-row], .yellow-theme [mat-row], .yellow-theme [mat-footer-row],
.yellow-theme .mat-table-sticky {
  background: inherit;
}
.yellow-theme mat-row, .yellow-theme mat-header-row, .yellow-theme mat-footer-row,
.yellow-theme th.mat-header-cell, .yellow-theme td.mat-cell, .yellow-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.yellow-theme .mat-header-cell {
  color: #26273b;
}
.yellow-theme .mat-cell, .yellow-theme .mat-footer-cell {
  color: #121212;
}
.yellow-theme .mat-calendar-arrow {
  fill: rgba(18, 18, 18, 0.54);
}
.yellow-theme .mat-datepicker-toggle,
.yellow-theme .mat-datepicker-content .mat-calendar-next-button,
.yellow-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(18, 18, 18, 0.54);
}
.yellow-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.yellow-theme .mat-calendar-table-header,
.yellow-theme .mat-calendar-body-label {
  color: #26273b;
}
.yellow-theme .mat-calendar-body-cell-content,
.yellow-theme .mat-date-range-input-separator {
  color: #121212;
  border-color: transparent;
}
.yellow-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.yellow-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.yellow-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.yellow-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.yellow-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.yellow-theme .mat-calendar-body-in-range::before {
  background: rgba(255, 212, 73, 0.2);
}
.yellow-theme .mat-calendar-body-comparison-identical,
.yellow-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.yellow-theme .mat-calendar-body-comparison-bridge-start::before,
.yellow-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 212, 73, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.yellow-theme .mat-calendar-body-comparison-bridge-end::before,
.yellow-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 212, 73, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.yellow-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.yellow-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.yellow-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.yellow-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.yellow-theme .mat-calendar-body-selected {
  background-color: #ffd449;
  color: white;
}
.yellow-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 212, 73, 0.4);
}
.yellow-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.yellow-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.yellow-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 212, 73, 0.3);
}
@media (hover: hover) {
  .yellow-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 212, 73, 0.3);
  }
}
.yellow-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
  background-color: white;
  color: #121212;
}
.yellow-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(159, 168, 218, 0.2);
}
.yellow-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.yellow-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.yellow-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.yellow-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(159, 168, 218, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.yellow-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.yellow-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(159, 168, 218, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.yellow-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.yellow-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.yellow-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.yellow-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.yellow-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.yellow-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(159, 168, 218, 0.4);
}
.yellow-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.yellow-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.yellow-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(159, 168, 218, 0.3);
}
@media (hover: hover) {
  .yellow-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(159, 168, 218, 0.3);
  }
}
.yellow-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(232, 18, 83, 0.2);
}
.yellow-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.yellow-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.yellow-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.yellow-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(232, 18, 83, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.yellow-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.yellow-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(232, 18, 83, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.yellow-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.yellow-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.yellow-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.yellow-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.yellow-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e81253;
  color: white;
}
.yellow-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(232, 18, 83, 0.4);
}
.yellow-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.yellow-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.yellow-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(232, 18, 83, 0.3);
}
@media (hover: hover) {
  .yellow-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(232, 18, 83, 0.3);
  }
}
.yellow-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(18, 18, 18, 0.2), 0px 24px 38px 3px rgba(18, 18, 18, 0.14), 0px 9px 46px 8px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-datepicker-toggle-active {
  color: #ffd449;
}
.yellow-theme .mat-datepicker-toggle-active.mat-accent {
  color: #9fa8da;
}
.yellow-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e81253;
}
.yellow-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.yellow-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(18, 18, 18, 0.2), 0px 24px 38px 3px rgba(18, 18, 18, 0.14), 0px 9px 46px 8px rgba(18, 18, 18, 0.12);
  background: white;
  color: #121212;
}
.yellow-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.yellow-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.yellow-theme .mat-expansion-panel {
  background: white;
  color: #121212;
}
.yellow-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.yellow-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .yellow-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .yellow-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .yellow-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.yellow-theme .mat-expansion-panel-header-title {
  color: #121212;
}
.yellow-theme .mat-expansion-panel-header-description,
.yellow-theme .mat-expansion-indicator::after {
  color: #26273b;
}
.yellow-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(18, 18, 18, 0.26);
}
.yellow-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.yellow-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.yellow-theme .mat-form-field-label {
  color: rgba(38, 39, 59, 0.6);
}
.yellow-theme .mat-hint {
  color: rgba(38, 39, 59, 0.6);
}
.yellow-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #ffd449;
}
.yellow-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #9fa8da;
}
.yellow-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e81253;
}
.yellow-theme .mat-focused .mat-form-field-required-marker {
  color: #9fa8da;
}
.yellow-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.yellow-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #ffd449;
}
.yellow-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #9fa8da;
}
.yellow-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e81253;
}
.yellow-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #ffd449;
}
.yellow-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #9fa8da;
}
.yellow-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e81253;
}
.yellow-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e81253;
}
.yellow-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.yellow-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e81253;
}
.yellow-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.yellow-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e81253;
}
.yellow-theme .mat-error {
  color: #e81253;
}
.yellow-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: #26273b;
}
.yellow-theme .mat-form-field-appearance-legacy .mat-hint {
  color: #26273b;
}
.yellow-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.yellow-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.yellow-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.yellow-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.yellow-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(18, 18, 18, 0.04);
}
.yellow-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(18, 18, 18, 0.02);
}
.yellow-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.yellow-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.yellow-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.yellow-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.yellow-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.yellow-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #ffd449;
}
.yellow-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #9fa8da;
}
.yellow-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e81253;
}
.yellow-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e81253;
}
.yellow-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.yellow-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.yellow-theme .mat-icon.mat-primary {
  color: #ffd449;
}
.yellow-theme .mat-icon.mat-accent {
  color: #9fa8da;
}
.yellow-theme .mat-icon.mat-warn {
  color: #e81253;
}
.yellow-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: #26273b;
}
.yellow-theme .mat-input-element:disabled,
.yellow-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.yellow-theme .mat-input-element {
  caret-color: #ffd449;
}
.yellow-theme .mat-input-element::placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.yellow-theme .mat-input-element::-moz-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.yellow-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.yellow-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.yellow-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #9fa8da;
}
.yellow-theme .mat-form-field.mat-warn .mat-input-element,
.yellow-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e81253;
}
.yellow-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e81253;
}
.yellow-theme .mat-list-base .mat-list-item {
  color: #121212;
}
.yellow-theme .mat-list-base .mat-list-option {
  color: #121212;
}
.yellow-theme .mat-list-base .mat-subheader {
  color: #26273b;
}
.yellow-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.yellow-theme .mat-list-option:hover, .yellow-theme .mat-list-option:focus,
.yellow-theme .mat-nav-list .mat-list-item:hover,
.yellow-theme .mat-nav-list .mat-list-item:focus,
.yellow-theme .mat-action-list .mat-list-item:hover,
.yellow-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.yellow-theme .mat-list-single-selected-option, .yellow-theme .mat-list-single-selected-option:hover, .yellow-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.yellow-theme .mat-menu-panel {
  background: white;
}
.yellow-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-menu-item {
  background: transparent;
  color: #121212;
}
.yellow-theme .mat-menu-item[disabled],
.yellow-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.yellow-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.yellow-theme .mat-menu-item .mat-icon-no-color,
.yellow-theme .mat-menu-submenu-icon {
  color: rgba(18, 18, 18, 0.54);
}
.yellow-theme .mat-menu-item:hover:not([disabled]),
.yellow-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.yellow-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.yellow-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.yellow-theme .mat-paginator {
  background: white;
}
.yellow-theme .mat-paginator,
.yellow-theme .mat-paginator-page-size .mat-select-trigger {
  color: #26273b;
}
.yellow-theme .mat-paginator-decrement,
.yellow-theme .mat-paginator-increment {
  border-top: 2px solid rgba(18, 18, 18, 0.54);
  border-right: 2px solid rgba(18, 18, 18, 0.54);
}
.yellow-theme .mat-paginator-first,
.yellow-theme .mat-paginator-last {
  border-top: 2px solid rgba(18, 18, 18, 0.54);
}
.yellow-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.yellow-theme .mat-icon-button[disabled] .mat-paginator-increment,
.yellow-theme .mat-icon-button[disabled] .mat-paginator-first,
.yellow-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.yellow-theme .mat-progress-bar-background {
  fill: #fff4d2;
}
.yellow-theme .mat-progress-bar-buffer {
  background-color: #fff4d2;
}
.yellow-theme .mat-progress-bar-fill::after {
  background-color: #ffd449;
}
.yellow-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #e7e9f6;
}
.yellow-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #e7e9f6;
}
.yellow-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #9fa8da;
}
.yellow-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9c4d4;
}
.yellow-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9c4d4;
}
.yellow-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e81253;
}
.yellow-theme .mat-progress-spinner circle, .yellow-theme .mat-spinner circle {
  stroke: #ffd449;
}
.yellow-theme .mat-progress-spinner.mat-accent circle, .yellow-theme .mat-spinner.mat-accent circle {
  stroke: #9fa8da;
}
.yellow-theme .mat-progress-spinner.mat-warn circle, .yellow-theme .mat-spinner.mat-warn circle {
  stroke: #e81253;
}
.yellow-theme .mat-radio-outer-circle {
  border-color: #26273b;
}
.yellow-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffd449;
}
.yellow-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.yellow-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .yellow-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .yellow-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #ffd449;
}
.yellow-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #9fa8da;
}
.yellow-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.yellow-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .yellow-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .yellow-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #9fa8da;
}
.yellow-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e81253;
}
.yellow-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.yellow-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .yellow-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .yellow-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e81253;
}
.yellow-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.yellow-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.yellow-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.yellow-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.yellow-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.yellow-theme .mat-radio-button .mat-ripple-element {
  background-color: #121212;
}
.yellow-theme .mat-select-value {
  color: #121212;
}
.yellow-theme .mat-select-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.yellow-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.yellow-theme .mat-select-arrow {
  color: #26273b;
}
.yellow-theme .mat-select-panel {
  background: white;
}
.yellow-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.yellow-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #ffd449;
}
.yellow-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #9fa8da;
}
.yellow-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e81253;
}
.yellow-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e81253;
}
.yellow-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.yellow-theme .mat-drawer-container {
  background-color: white;
  color: #121212;
}
.yellow-theme .mat-drawer {
  background-color: white;
  color: #121212;
}
.yellow-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.yellow-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(18, 18, 18, 0.2), 0px 16px 24px 2px rgba(18, 18, 18, 0.14), 0px 6px 30px 5px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.yellow-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.yellow-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.yellow-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.yellow-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.yellow-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #9fa8da;
}
.yellow-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(159, 168, 218, 0.54);
}
.yellow-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #9fa8da;
}
.yellow-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffd449;
}
.yellow-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 212, 73, 0.54);
}
.yellow-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #ffd449;
}
.yellow-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e81253;
}
.yellow-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(232, 18, 83, 0.54);
}
.yellow-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e81253;
}
.yellow-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: #121212;
}
.yellow-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(18, 18, 18, 0.2), 0px 1px 1px 0px rgba(18, 18, 18, 0.14), 0px 1px 3px 0px rgba(18, 18, 18, 0.12);
  background-color: #fafafa;
}
.yellow-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.yellow-theme .mat-slider-track-background {
  background-color: rgba(18, 18, 18, 0.26);
}
.yellow-theme .mat-slider.mat-primary .mat-slider-track-fill,
.yellow-theme .mat-slider.mat-primary .mat-slider-thumb,
.yellow-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #ffd449;
}
.yellow-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.yellow-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(255, 212, 73, 0.2);
}
.yellow-theme .mat-slider.mat-accent .mat-slider-track-fill,
.yellow-theme .mat-slider.mat-accent .mat-slider-thumb,
.yellow-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #9fa8da;
}
.yellow-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.yellow-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(159, 168, 218, 0.2);
}
.yellow-theme .mat-slider.mat-warn .mat-slider-track-fill,
.yellow-theme .mat-slider.mat-warn .mat-slider-thumb,
.yellow-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e81253;
}
.yellow-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.yellow-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(232, 18, 83, 0.2);
}
.yellow-theme .mat-slider:hover .mat-slider-track-background,
.yellow-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(18, 18, 18, 0.38);
}
.yellow-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.yellow-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.yellow-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(18, 18, 18, 0.26);
}
.yellow-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(18, 18, 18, 0.26);
}
.yellow-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.yellow-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(18, 18, 18, 0.87);
}
.yellow-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.yellow-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(18, 18, 18, 0.26);
}
.yellow-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(18, 18, 18, 0.26);
  background-color: transparent;
}
.yellow-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .yellow-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(18, 18, 18, 0.38);
}
.yellow-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .yellow-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(18, 18, 18, 0.26);
}
.yellow-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(18, 18, 18, 0.7);
}
.yellow-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(18, 18, 18, 0.7), rgba(18, 18, 18, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(18, 18, 18, 0.7), rgba(18, 18, 18, 0.7) 2px, transparent 0, transparent);
}
.yellow-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(18, 18, 18, 0.7), rgba(18, 18, 18, 0.7) 2px, transparent 0, transparent);
}
.yellow-theme .mat-step-header.cdk-keyboard-focused, .yellow-theme .mat-step-header.cdk-program-focused, .yellow-theme .mat-step-header:hover:not([aria-disabled]), .yellow-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.yellow-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .yellow-theme .mat-step-header:hover {
    background: none;
  }
}
.yellow-theme .mat-step-header .mat-step-label,
.yellow-theme .mat-step-header .mat-step-optional {
  color: #26273b;
}
.yellow-theme .mat-step-header .mat-step-icon {
  background-color: #26273b;
  color: white;
}
.yellow-theme .mat-step-header .mat-step-icon-selected,
.yellow-theme .mat-step-header .mat-step-icon-state-done,
.yellow-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #ffd449;
  color: white;
}
.yellow-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.yellow-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.yellow-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.yellow-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.yellow-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.yellow-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.yellow-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.yellow-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e81253;
  color: white;
}
.yellow-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e81253;
}
.yellow-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: #121212;
}
.yellow-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e81253;
}
.yellow-theme .mat-stepper-horizontal, .yellow-theme .mat-stepper-vertical {
  background-color: white;
}
.yellow-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.yellow-theme .mat-horizontal-stepper-header::before,
.yellow-theme .mat-horizontal-stepper-header::after,
.yellow-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.yellow-theme .mat-sort-header-arrow {
  color: #26273b;
}
.yellow-theme .mat-tab-nav-bar,
.yellow-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.yellow-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.yellow-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.yellow-theme .mat-tab-label, .yellow-theme .mat-tab-link {
  color: #121212;
}
.yellow-theme .mat-tab-label.mat-tab-disabled, .yellow-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.yellow-theme .mat-tab-header-pagination-chevron {
  border-color: #121212;
}
.yellow-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.yellow-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.yellow-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.yellow-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .yellow-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.yellow-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.yellow-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .yellow-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .yellow-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.yellow-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.yellow-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 242, 200, 0.3);
}
.yellow-theme .mat-tab-group.mat-primary .mat-ink-bar, .yellow-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #ffd449;
}
.yellow-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .yellow-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .yellow-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .yellow-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.yellow-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .yellow-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.yellow-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.yellow-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .yellow-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .yellow-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.yellow-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.yellow-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.yellow-theme .mat-tab-group.mat-accent .mat-ink-bar, .yellow-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #9fa8da;
}
.yellow-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .yellow-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .yellow-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .yellow-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.yellow-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .yellow-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.yellow-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.yellow-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .yellow-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .yellow-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.yellow-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.yellow-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 184, 203, 0.3);
}
.yellow-theme .mat-tab-group.mat-warn .mat-ink-bar, .yellow-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e81253;
}
.yellow-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .yellow-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .yellow-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .yellow-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.yellow-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .yellow-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.yellow-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.yellow-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .yellow-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .yellow-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.yellow-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.yellow-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 242, 200, 0.3);
}
.yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #ffd449;
}
.yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.yellow-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .yellow-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.yellow-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.yellow-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .yellow-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .yellow-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.yellow-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.yellow-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #9fa8da;
}
.yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.yellow-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .yellow-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.yellow-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.yellow-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .yellow-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .yellow-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.yellow-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.yellow-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 184, 203, 0.3);
}
.yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e81253;
}
.yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.yellow-theme .mat-toolbar {
  background: whitesmoke;
  color: #121212;
}
.yellow-theme .mat-toolbar.mat-primary {
  background: #ffd449;
  color: white;
}
.yellow-theme .mat-toolbar.mat-accent {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.yellow-theme .mat-toolbar.mat-warn {
  background: #e81253;
  color: white;
}
.yellow-theme .mat-toolbar .mat-form-field-underline,
.yellow-theme .mat-toolbar .mat-form-field-ripple,
.yellow-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.yellow-theme .mat-toolbar .mat-form-field-label,
.yellow-theme .mat-toolbar .mat-focused .mat-form-field-label,
.yellow-theme .mat-toolbar .mat-select-value,
.yellow-theme .mat-toolbar .mat-select-arrow,
.yellow-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.yellow-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.yellow-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.yellow-theme .mat-tree {
  background: white;
}
.yellow-theme .mat-tree-node,
.yellow-theme .mat-nested-tree-node {
  color: #121212;
}
.yellow-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 6px 10px 0px rgba(18, 18, 18, 0.14), 0px 1px 18px 0px rgba(18, 18, 18, 0.12);
}
.yellow-theme .mat-simple-snackbar-action {
  color: #9fa8da;
}

.black-theme .order {
  background-color: white;
  color: #000000;
}
.black-theme .order .line1 .detail-btn {
  color: black;
}
.black-theme .order .important-text {
  color: black;
  font-weight: 600;
}
.black-theme .order .status .status-chip {
  background-color: #4d4d4d;
  color: #ffffff;
}
.black-theme .order .status .status-chip.closed {
  background-color: #42c778;
  color: #fff;
}
.black-theme .banner {
  background-color: white;
  color: #000000;
}
.black-theme .banner__content__title {
  color: #666666;
}
.black-theme .login {
  background-color: white;
  color: #000000;
}
.black-theme .login__header {
  background-color: #e0e0e0;
  color: #000000;
}
.black-theme .login-content {
  background-color: white;
  color: #000000;
}
.black-theme .login-content__link {
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  color: black;
  background-color: #ffffff;
}
.black-theme .login-content__link:hover {
  background-color: black;
  color: #ffffff !important;
}
.black-theme .login-content {
  background-color: white;
  color: #000000;
}
.black-theme .login-content .link-send {
  color: black;
}
.black-theme .login-content .link-send:hover {
  color: #000000;
}
.black-theme .login-content {
  background-color: white;
  color: #000000;
}
.black-theme .landing-container .sidenav-content {
  background-color: white;
  color: #000000;
}
.black-theme .landing-container .sidenav-content__linkContainer__link.static {
  background-color: #ffffff;
  color: black;
}
.black-theme .landing-container .sidenav-content__footer {
  background-color: rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 768px) {
  .black-theme .landing-container .landing-content {
    background-color: #f4f4f9;
    color: black;
  }
}
@media (max-height: 555px) {
  .black-theme .landing-container .landing-content {
    background-color: #f4f4f9;
    color: black;
  }
}
.black-theme .orders {
  background-color: rgba(255, 255, 255, 0);
  color: #000000;
}
.black-theme .orders__wrapper {
  background-color: black;
  color: #ffffff;
}
.black-theme .orders__see-all-btn .see-all-orders-btn {
  background-color: black;
  color: #ffffff;
  border: 1px solid gray;
}
.black-theme .orders__see-all-btn .see-all-orders-btn:hover {
  background-color: #1a1a1a;
}
.black-theme .orders__banners {
  background-color: #f4f4f9;
  color: #000000;
}
.black-theme .orders__banners .see-more-banners-btn {
  background-color: #fafafa;
  color: #000000;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.black-theme .orders__banners .see-more-banners-btn:hover {
  background-color: #e0e0e0;
}
.black-theme .footer {
  background-color: #9595a8;
  color: white;
}
.black-theme .footer__facility__item__head {
  font-weight: bold;
}
.black-theme .footer__facility__item mat-divider {
  background-color: white;
}
.black-theme .all-orders {
  background-color: rgba(255, 255, 255, 0);
  color: #000000;
}
.black-theme .all-orders__wrapper {
  background-color: #f4f4f9;
  color: #000000;
}
.black-theme .all-orders__wrapper__header {
  background-color: white;
  color: black;
}
.black-theme .all-orders__wrapper__filter .filter-field .mat-form-field-outline {
  background-color: white;
}
.black-theme .order-detail__header {
  background-color: white;
  color: #000000;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.black-theme .order-detail__header .title {
  color: black;
}
.black-theme .order-detail__steps {
  background-color: white;
  color: #000000;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.black-theme .order-detail__steps mat-stepper {
  background-color: transparent;
  pointer-events: none;
  min-width: 100%;
}
.black-theme .order-detail__steps mat-stepper mat-step-header {
  padding: 0 !important;
  min-width: 130px !important;
  overflow: visible !important;
}
.black-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon.mat-step-icon-state-noicon {
  background-color: #bdbdbd;
}
.black-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon {
  height: 15px !important;
  width: 15px !important;
  margin-top: 5px;
}
.black-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon .mat-icon {
  font-size: 14px;
  height: 14px;
  width: 14px;
}
.black-theme .order-detail__steps mat-stepper mat-step-header .mat-step-icon .currentState {
  color: #42c778;
  font-size: 20px;
  height: 20px;
  width: 20px;
  margin-top: -27px;
}
.black-theme .order-detail__steps mat-stepper mat-step-header .mat-step-label {
  padding: 5px 5px 0 5px !important;
  font-size: 0.7rem;
}
.black-theme .order-detail__steps mat-stepper mat-step-header .mat-step-label .disabled {
  color: #bdbdbd;
}
.black-theme .order-detail__steps .mat-stepper-horizontal-line {
  display: none !important;
}
.black-theme .order-detail__steps .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:after,
.black-theme .order-detail__steps .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:before {
  top: 13px !important;
}
.black-theme .order-detail__steps .mat-horizontal-stepper-wrapper {
  width: fit-content !important;
  margin: auto !important;
}
.black-theme .order-detail__tabs {
  background-color: #ffffff;
  color: #000000;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.black-theme .order-detail__tabs mat-tab-group .mat-tab-body-wrapper {
  height: 100%;
}
.black-theme .order-detail__tabs mat-tab-group .newMessagesIcon {
  color: #e5104c;
}
.black-theme .order-detail__tabs mat-tab-group .mat-tab-label {
  opacity: 1 !important;
}
.black-theme .order-detail__tabs .desktopTabs .item {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.black-theme .order-detail__tabs .desktopTabs .item:first-child {
  background-color: #f4f4f9;
}
.black-theme .messages {
  background-color: white;
}
.black-theme .messages__list .message__content {
  background-color: #e4e9f2;
  color: #000000;
}
.black-theme .messages__list .message__content.budget {
  background-color: #42c778;
  color: #fff;
}
.black-theme .messages__list .message__content.remote-sign {
  background-color: black;
  color: #ffffff;
}
.black-theme .messages__list .message__content.payment {
  background-color: #8c8c8c;
  color: #ffffff;
}
.black-theme .messages__list .message__content .budget-icon {
  background-color: #69d394;
}
.black-theme .messages__list .message__content .remote-sign-icon {
  background-color: #1a1a1a;
}
.black-theme .messages__list .message__content .payment-icon {
  background-color: #a6a6a6;
}
.black-theme .messages__list .message__date {
  color: #1a1a1a;
}
.black-theme .messages__list .message.customer .message__content {
  background-color: black;
  color: #ffffff;
}
.black-theme .messages__input {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.black-theme .messages__input__wrapper {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.black-theme .messages__input__wrapper .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -6px;
}
.black-theme .messages__input__wrapper .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -4px;
}
.black-theme .messages__input__wrapper .send-icon {
  color: #000000;
}
.black-theme .messages__input__wrapper .attach-icon {
  color: #000000;
}
.black-theme .navbar {
  background-color: white !important;
  color: #000000 !important;
}
.black-theme .navbar mat-divider {
  background-color: #000000;
}
@media screen and (max-width: 768px) {
  .black-theme .navbar {
    background-color: black !important;
    color: #ffffff !important;
  }
  .black-theme .navbar mat-divider {
    background-color: white;
  }
}
.black-theme .attachments-item-container {
  background-color: #e4e9f2;
}
.black-theme .attachments-item-container .file-name {
  color: black;
}
.black-theme .attachments-item-container .file-name mat-icon {
  color: black;
}
@media screen and (max-width: 992px) {
  .black-theme ngx-extended-pdf-viewer #mainContainer {
    min-width: unset !important;
    max-width: 100% !important;
  }
  .black-theme #toolbarViewerLeft pdf-find-button,
.black-theme #toolbarViewerLeft pdf-paging-area {
    display: none !important;
  }
  .black-theme #toolbarViewerRight {
    display: none !important;
  }
  .black-theme #toolbarViewerMiddle {
    display: flex !important;
  }
}
.black-theme .budget-viewer-wrapper .mat-dialog-container {
  padding: 0px;
}
.black-theme .budget-viewer__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.black-theme .budget-viewer__header h2 {
  color: black;
}
.black-theme .budget-viewer__content__lines .budget-line {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.black-theme .budget-viewer__content__lines .budget-line.accepted {
  border: 1px solid #56cd86;
  background-color: #56cd86;
  color: white;
}
.black-theme .budget-viewer__content__lines .budget-line.accepted.authorized {
  border: 1px solid #31a560;
  background-color: #31a560;
}
.black-theme .budget-viewer__content__lines .budget-line.accepted.authorized .mat-checkbox-disabled .mat-checkbox-label {
  color: white;
}
.black-theme .budget-viewer__content__lines .budget-line mat-checkbox {
  width: 100%;
  padding: 15px;
}
.black-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout {
  width: 100%;
}
.black-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
  width: 90%;
}
@media screen and (max-width: 768px) {
  .black-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
    width: 80%;
  }
}
.black-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.black-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label .checkbox-budget-label-1 {
  font-weight: 600;
}
.black-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label .checkbox-budget-label-2 {
  font-weight: 500;
  margin-right: 30px;
}
@media screen and (max-width: 768px) {
  .black-theme .budget-viewer__content__lines .budget-line mat-checkbox .mat-checkbox-layout .mat-checkbox-label .checkbox-budget-label .checkbox-budget-label-2 {
    margin-right: 15px;
  }
}
.black-theme .budget-viewer__content__lines .budget-line mat-checkbox.mat-checkbox-checked .mat-checkbox-background {
  background-color: white;
}
.black-theme .budget-viewer__content__lines .budget-line mat-checkbox.mat-checkbox-checked .mat-checkbox-background .mat-checkbox-checkmark {
  fill: #42c778 !important;
}
.black-theme .budget-viewer__content__lines .budget-line mat-checkbox.mat-checkbox-checked .mat-checkbox-background .mat-checkbox-checkmark-path {
  stroke: #42c778 !important;
}
.black-theme .budget-viewer__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.black-theme .payments-viewer-wrapper .mat-dialog-container {
  padding: 0px;
}
.black-theme .payments-viewer__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.black-theme .payments-viewer__header h2 {
  color: black;
}
.black-theme .payments-viewer__content__lines .payments-line {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.black-theme .payments-viewer__content__lines .payments-line.pending {
  border: 1px solid gray;
  background-color: gray;
  color: white;
}
.black-theme .payments-viewer__content__lines .payments-line.accepted {
  border: 1px solid #2b9155;
  background-color: #2b9155;
  color: white;
}
.black-theme .payments-viewer__content__lines .payments-line.error {
  border: 1px solid #c10c0c;
  background-color: #c10c0c;
  color: white;
}
.black-theme .payments-viewer__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.black-theme .signature-viewer-wrapper .mat-dialog-container {
  padding: 0px;
}
.black-theme .signature-viewer__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.black-theme .signature-viewer__header h2 {
  color: black;
}
.black-theme .signature-viewer__content .mat-step-icon.mat-step-icon-state-edit mat-icon {
  border-radius: 20px;
  padding: 5px;
  background-color: #42c778;
}
.black-theme .signature-viewer__content .mat-step-icon.mat-step-icon-state-number {
  background-color: black;
}
.black-theme .signature-viewer__content mat-stepper {
  background-color: transparent;
  min-width: 100%;
}
.black-theme .signature-viewer__content mat-stepper mat-step-header {
  padding: 0 !important;
  overflow: visible !important;
}
.black-theme .signature-viewer__content mat-stepper mat-step-header .mat-step-label {
  padding: 5px 5px 0 5px !important;
  font-size: 0.7rem;
}
.black-theme .signature-viewer__content mat-stepper mat-step-header .mat-step-label .disabled {
  color: #bdbdbd;
}
.black-theme .signature-viewer__content .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:after,
.black-theme .signature-viewer__content .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:before,
.black-theme .signature-viewer__content .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 13px !important;
}
.black-theme .signature-viewer__content .mat-horizontal-content-container {
  padding: 5px 0 0 0;
}
.black-theme .signature-viewer__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.black-theme .remote-signature-viewer-wrapper .mat-dialog-container {
  padding: 0;
}
.black-theme .unverified-signature-warning {
  display: none !important;
}
.black-theme .card-docs__section__content__budget, .black-theme .card-docs__section__content__remote-sign, .black-theme .card-docs__section__content__payment {
  background-color: #42c778;
  color: white;
  font-size: bold;
}
.black-theme .card-docs__section__content__budget mat-icon, .black-theme .card-docs__section__content__remote-sign mat-icon, .black-theme .card-docs__section__content__payment mat-icon {
  background-color: #69d394;
}
.black-theme .card-docs__section__content__budget mat-icon.alert-icon, .black-theme .card-docs__section__content__remote-sign mat-icon.alert-icon, .black-theme .card-docs__section__content__payment mat-icon.alert-icon {
  color: yellow;
  background-color: transparent;
  margin-left: -7px;
}
.black-theme .card-docs__section__content__budget.pending, .black-theme .card-docs__section__content__remote-sign.pending, .black-theme .card-docs__section__content__payment.pending {
  background-color: black;
  color: white;
}
.black-theme .card-docs__section__content__budget.pending mat-icon, .black-theme .card-docs__section__content__remote-sign.pending mat-icon, .black-theme .card-docs__section__content__payment.pending mat-icon {
  background-color: #1a1a1a;
}
.black-theme .card-docs__section__content__budget.pending mat-icon.alert-icon, .black-theme .card-docs__section__content__remote-sign.pending mat-icon.alert-icon, .black-theme .card-docs__section__content__payment.pending mat-icon.alert-icon {
  background-color: transparent;
  margin-left: -7px;
}
.black-theme .card-docs__section__content__budget.payments, .black-theme .card-docs__section__content__remote-sign.payments, .black-theme .card-docs__section__content__payment.payments {
  background-color: #737373;
  color: #ffffff;
}
.black-theme .card-docs__section__content__budget.payments mat-icon, .black-theme .card-docs__section__content__remote-sign.payments mat-icon, .black-theme .card-docs__section__content__payment.payments mat-icon {
  background-color: #8d8d8d;
}
.black-theme .card-docs__section__content__budget.payments mat-icon.alert-icon, .black-theme .card-docs__section__content__remote-sign.payments mat-icon.alert-icon, .black-theme .card-docs__section__content__payment.payments mat-icon.alert-icon {
  background-color: transparent;
  margin-left: -7px;
}
.black-theme .card-docs__section__content__budget.payments.pendingPayments, .black-theme .card-docs__section__content__remote-sign.payments.pendingPayments, .black-theme .card-docs__section__content__payment.payments.pendingPayments {
  background-color: #8c8c8c;
  color: #ffffff;
}
.black-theme .card-docs__section__content__budget.payments.pendingPayments mat-icon, .black-theme .card-docs__section__content__remote-sign.payments.pendingPayments mat-icon, .black-theme .card-docs__section__content__payment.payments.pendingPayments mat-icon {
  background-color: #a6a6a6;
}
.black-theme .card-docs__section__content__budget.payments.pendingPayments mat-icon.alert-icon, .black-theme .card-docs__section__content__remote-sign.payments.pendingPayments mat-icon.alert-icon, .black-theme .card-docs__section__content__payment.payments.pendingPayments mat-icon.alert-icon {
  background-color: transparent;
  margin-left: -7px;
}
.black-theme .send-code-content {
  background-color: white;
  color: #000000;
}
.black-theme .send-code-content__link {
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  color: black;
  background-color: #ffffff;
}
.black-theme .send-code-content__link:hover {
  background-color: black;
  color: #ffffff !important;
}
.black-theme .ask-for-code-content {
  background-color: white;
  color: #000000;
}
.black-theme .global-message--success {
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;
}
.black-theme .global-message--success .mat-simple-snackbar-action {
  color: #155724 !important;
}
.black-theme .global-message--warning {
  color: #2c2c2c !important;
  background-color: rgb(206, 134, 0) !important;
  border-color: #be8f00 !important;
}
.black-theme .global-message--warning .mat-simple-snackbar-action {
  color: #2c2c2c !important;
}
.black-theme .global-message--error {
  color: rgb(245, 245, 245) !important;
  background-color: rgb(138, 0, 0) !important;
  border-color: rgb(114, 2, 2) !important;
}
.black-theme .global-message--error .mat-simple-snackbar-action {
  color: rgb(245, 219, 219) !important;
}
.black-theme html,
.black-theme body {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
  height: 100%;
}
.black-theme body {
  margin: 0;
}
.black-theme .ngx-summernote-view {
  display: none !important;
}
.black-theme .content-wrapper {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1120px;
}
.black-theme .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.black-theme .mat-app-background {
  height: 100%;
  background-color: #f4f4f9;
}
.black-theme .uppercase {
  text-transform: uppercase;
}
.black-theme .capitalize {
  text-transform: capitalize;
}
.black-theme .message-block {
  align-items: center;
  background-color: #848484;
  border-radius: 0.571rem;
  box-shadow: 0 10px 22px 0 rgba(0, 0, 0, 0.04);
  color: #fff;
  display: flex;
  font-size: 1.143rem;
  font-weight: 600;
  line-height: 1.5;
  overflow-wrap: anywhere;
  padding: 1.429rem 1.714rem;
  word-wrap: anywhere;
}
.black-theme .message-block .mat-icon {
  flex: 0 0 auto;
  margin-right: 1.143rem;
}
.black-theme .message-block.message-block__success {
  background-color: black;
  color: #ffffff;
}
.black-theme .message-block.message-block__warning {
  background-color: #ffb025;
  color: #fff;
}
.black-theme .message-block.message-block__error {
  background-color: #f34040;
  color: #fff;
}
.black-theme .hidden,
.black-theme *[hidden] {
  display: none !important;
  overflow: hidden !important;
}
.black-theme .hidden.block,
.black-theme *[hidden].block {
  display: hidden !important;
}
.black-theme .inlineBlock {
  display: inline-block;
}
.black-theme .underline {
  text-decoration: underline;
}
.black-theme .opacity-1 {
  opacity: 1;
}
.black-theme .opacity-08 {
  opacity: 0.8;
}
.black-theme .opacity-06 {
  opacity: 0.6;
}
.black-theme .opacity-04 {
  opacity: 0.4;
}
.black-theme .opacity-02 {
  opacity: 0.2;
}
.black-theme .opacity-0 {
  opacity: 0;
}
.black-theme .overlay-spinner {
  background: rgba(255, 255, 255, 0.4);
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.black-theme .overlay-spinner > .mat-spinner {
  margin: 3rem auto;
}
.black-theme .spin-icon {
  animation: spinner 0.6s linear infinite;
}
.black-theme .height-100 {
  height: 100%;
}
.black-theme .note-modal-backdrop {
  display: none !important;
}
.black-theme .note-editing-area {
  background-color: white;
}
.black-theme .note-editor .note-editing-area .note-editable table.table-no-bordered td,
.black-theme .note-editor .note-editing-area .note-editable table.table-no-bordered th {
  border: 0px;
}
.black-theme .autocomplete-panel-class,
.black-theme .notification-mention-menu {
  max-height: calc(var(--100vh) - 65px) !important;
  height: 550px !important;
}
.black-theme .notification-mention-menu .mat-menu-content {
  height: calc(100% - 16px);
}
.black-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: black;
}
.black-theme .mat-form-field-appearance-outline .mat-form-field-label {
  color: black;
  font-weight: 600;
  text-transform: uppercase;
}
.black-theme .mat-form-field-appearance-outline input.mat-input-element {
  text-overflow: ellipsis;
}
.black-theme .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  z-index: 1;
  margin-top: 0.2em;
}
.black-theme .mat-form-field-appearance-outline.date-picker .mat-form-field-subscript-wrapper {
  margin-top: 2.2em;
}
.black-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
  color: black;
}
.black-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-label {
  color: black;
}
.black-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: transparent;
}
.black-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: black;
}
.black-theme .mat-form-field-appearance-outline.mat-form-field-disabled input.mat-input-element {
  color: #121212;
}
.black-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-form-field-infix,
.black-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-form-field-suffix {
  padding: 0.25em 0 0.75em 0;
  font-size: 1em;
}
.black-theme .smaller-mat-form-fields .mat-form-field-appearance-outline.date-picker .mat-form-field-infix,
.black-theme .smaller-mat-form-fields .mat-form-field-appearance-outline.date-picker .mat-form-field-suffix {
  padding: 0.25em 0 0.6em 0;
  margin-top: -0.3em;
}
.black-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-infix, .black-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-suffix {
  padding: 0.2em 0 0.25em 0;
}
.black-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-infix .mat-input-element, .black-theme .smaller-mat-form-fields__button-height.mat-form-field-appearance-outline .mat-form-field-suffix .mat-input-element {
  position: absolute;
  margin-top: -11px;
}
.black-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-form-field-infix .mat-select-arrow {
  margin-top: 10px;
}
.black-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-select-empty + .mat-form-field-label-wrapper .mat-form-field-label {
  margin-top: -0.75em;
}
.black-theme .smaller-mat-form-fields .mat-form-field-appearance-outline .mat-select + .mat-form-field-label-wrapper .mat-form-field-label {
  margin-top: -0.3em;
}
.mat-select .black-theme .smaller-mat-form-fields__no-margin.mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding: 0 0;
  margin: 0 0;
}
.black-theme .smaller-mat-form-fields__no-margin.mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding: 0 0;
  margin: 0 0;
}
.black-theme .smaller-mat-form-fields .mat-form-field__no-border .mat-form-field-outline-start,
.black-theme .smaller-mat-form-fields .mat-form-field__no-border .mat-form-field-outline-gap,
.black-theme .smaller-mat-form-fields .mat-form-field__no-border .mat-form-field-outline-end {
  border: none;
}
.black-theme .smaller-mat-form-fields .mat-form-field__border-more-radius .mat-form-field-outline-start, .black-theme .smaller-mat-form-fields.mat-form-field__border-more-radius .mat-form-field-outline-start {
  border-radius: 28px 0 0 28px !important;
  min-width: 28px;
}
.black-theme .smaller-mat-form-fields .mat-form-field__border-more-radius .mat-form-field-outline-end, .black-theme .smaller-mat-form-fields.mat-form-field__border-more-radius .mat-form-field-outline-end {
  border-radius: 0 28px 28px 0 !important;
}
.black-theme button.mat-button, .black-theme button.mat-flat-button {
  font-size: 1rem;
}
.black-theme button.mat-button.mat-button-fill, .black-theme button.mat-flat-button.mat-button-fill {
  width: 100%;
}
.black-theme button.mat-button-w-100-left {
  width: 100%;
  text-align: left;
}
.black-theme button.mat-flat-button:hover {
  opacity: 0.85;
}
.black-theme button.close-modal-button.mat-icon-button {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  background-color: rgba(166, 166, 166, 0.3);
  color: black;
}
.black-theme button.close-modal-button.mat-icon-button:hover {
  background-color: rgba(166, 166, 166, 0.4);
}
.black-theme button.mat-square-icon {
  border-radius: 5px;
  padding: 0 0;
  min-width: 36px;
  line-height: 36px;
}
.black-theme button.mat-square-icon.square-28 {
  min-width: 28px;
  line-height: 28px;
}
.black-theme button.mat-dark {
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
}
.black-theme button.mat-dark:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
.black-theme button .mat-icon {
  overflow: visible;
}
.black-theme .mat-flat-button.mat-primary.mat-button-disabled,
.black-theme .mat-flat-button.mat-button-disabled.mat-button-disabled,
.black-theme .mat-raised-button.mat-primary.mat-button-disabled,
.black-theme .mat-raised-button.mat-button-disabled.mat-button-disabled,
.black-theme .mat-fab.mat-primary.mat-button-disabled,
.black-theme .mat-fab.mat-button-disabled.mat-button-disabled,
.black-theme .mat-mini-fab.mat-primary.mat-button-disabled,
.black-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: black;
  color: #ffffff;
  opacity: 0.5;
}
.black-theme .mat-button.v-align-bottom .mat-button-wrapper > *,
.black-theme .mat-flat-button.v-align-bottom .mat-button-wrapper > *,
.black-theme .mat-stroked-button.v-align-bottom .mat-button-wrapper > *,
.black-theme .mat-raised-button.v-align-bottom .mat-button-wrapper > *,
.black-theme .mat-icon-button.v-align-bottom .mat-button-wrapper > *,
.black-theme .mat-fab.v-align-bottom .mat-button-wrapper > *,
.black-theme .mat-mini-fab.v-align-bottom .mat-button-wrapper > * {
  vertical-align: text-bottom;
}
.black-theme .mat-button.no-padding,
.black-theme .mat-flat-button.no-padding,
.black-theme .mat-stroked-button.no-padding,
.black-theme .mat-raised-button.no-padding,
.black-theme .mat-icon-button.no-padding,
.black-theme .mat-fab.no-padding,
.black-theme .mat-mini-fab.no-padding {
  padding: 0px;
}
.black-theme mat-expansion-panel.no-padding .mat-expansion-panel-header {
  padding: 0 !important;
}
.black-theme mat-expansion-panel.no-padding .mat-expansion-indicator {
  padding: 0 10px;
}
.black-theme .mat-expansion-panel-header {
  border-radius: initial;
  border-bottom: 1px solid #b3b3b3;
}
.black-theme .mat-expansion-panel-header.mat-expanded {
  height: 49px;
}
.black-theme .mat-expansion-panel-header .mat-expansion-indicator {
  border-width: 0 3px 3px 0;
  color: #000000;
}
.black-theme .mat-expansion-panel-header .mat-expansion-panel-header-title {
  color: black;
  font-size: 1rem;
  font-weight: 600;
}
.black-theme .header-color-primary .mat-expansion-panel-header {
  background-color: black !important;
}
.black-theme .header-color-primary .mat-expansion-panel-header:hover {
  background-color: black !important;
}
.black-theme .header-color-primary .mat-expansion-indicator::after {
  color: #ffffff;
}
.black-theme .header-color-primary .mat-expansion-panel-header-title {
  color: #ffffff;
}
.black-theme .mat-expanded > .mat-expansion-panel-content {
  padding-top: 16px;
}
.black-theme mat-expansion-panel.no-padding .mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0 0 16px 0;
}
.black-theme .mat-select-panel.bigger-selection-panel-class {
  max-height: calc(var(--100vh) - 100px);
}
.black-theme .mat-select-panel.bigger-selection-panel-class .mat-optgroup-label {
  overflow: visible !important;
}
.black-theme .mat-tab-page-sections .mat-tab-header {
  border-bottom: 0px;
}
.black-theme .mat-tab-page-sections .mat-tab-header .mat-tab-label {
  font-size: 1.5rem;
  font-weight: 400;
  padding: 0 15px;
}
.black-theme .mat-tab-page-sections.font-size-smaller .mat-tab-header .mat-tab-label {
  font-size: 1.1rem;
  font-weight: 200;
}
.black-theme .mat-tab-group.mat-primary .mat-ink-bar,
.black-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  height: 3px;
  background-color: black;
}
.black-theme .mat-tab-label.text-red,
.black-theme .mat-tab-label.mat-tab-label-active.text-red {
  color: #c10c0c;
}
.black-theme .mat-item-card {
  margin-bottom: 20px;
  max-width: calc(50% - 20px);
}
.black-theme .mat-item-card__title {
  font-size: 1rem;
  color: #26273b;
}
.black-theme .mat-item-card__icons .mat-icon {
  padding-right: 24px;
  color: black;
}
.black-theme .mat-item-card__icons .mat-icon:last-child {
  padding-right: 0;
}
.black-theme .mat-table {
  background-color: transparent;
}
.black-theme .mat-table.table-ellipsis {
  width: 100%;
  table-layout: fixed;
}
.black-theme .mat-table.table-ellipsis th,
.black-theme .mat-table.table-ellipsis td {
  overflow: hidden;
  width: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 10px;
}
.black-theme .mat-table.table-ellipsis th.no-ellipsis,
.black-theme .mat-table.table-ellipsis td.no-ellipsis {
  overflow: inherit;
}
.black-theme .mat-table .mat-header-row .mat-header-cell {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  color: #000000;
}
.black-theme .mat-table tr.mat-row {
  background-color: white;
  height: 65px;
}
.black-theme .mat-table tr.mat-row .mat-cell {
  font-size: 16px;
  color: #26273b;
}
.black-theme .mat-table tr.mat-row .mat-cell.action-buttons {
  color: black;
  font-size: 24px;
}
.black-theme .mat-paginator {
  background-color: transparent;
}
.black-theme .mat-checkbox-layout {
  white-space: normal !important;
}
.black-theme mat-checkbox.light-border .mat-checkbox-frame {
  border-color: #ffffff;
}
.black-theme .mat-tooltip {
  font-size: 0.9em;
  max-width: 350px !important;
}
.black-theme .mat-menu-panel.dark-theme {
  min-width: 150px;
  background-color: #000000;
}
.black-theme .mat-menu-panel.dark-theme .mat-menu-item {
  color: #ffffff;
}
.black-theme .mat-menu-panel.dark-theme .mat-divider {
  background-color: #000000;
}
.black-theme .draggable-item {
  border-radius: 5px;
  background-color: #f4f4f9;
  margin: 5px 0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.black-theme .draggable-item .cdk-drag-handle {
  cursor: grab;
}
.black-theme .draggable-item .cdk-drag-handle:active {
  cursor: grabbing;
}
.black-theme .draggable-item.cdk-drag-preview {
  pointer-events: fill !important;
  box-shadow: 0px 0px 25px 0px rgb(0, 0, 0);
}
.black-theme .draggable-item.cdk-drag-preview .cdk-drag-handle {
  cursor: grabbing;
}
.black-theme .remove-padding mat-dialog-container {
  padding: 0px;
}
.black-theme .mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Saira;
}
.black-theme .mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.black-theme .mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.black-theme .mat-h1,
.black-theme .mat-headline,
.black-theme .mat-typography .mat-h1,
.black-theme .mat-typography .mat-headline,
.black-theme .mat-typography h1 {
  font: 900 24px / 32px Saira;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.black-theme .mat-h2,
.black-theme .mat-title,
.black-theme .mat-typography .mat-h2,
.black-theme .mat-typography .mat-title,
.black-theme .mat-typography h2 {
  font: 600 20px / 28px Saira;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.black-theme .mat-h3,
.black-theme .mat-subheading-2,
.black-theme .mat-typography .mat-h3,
.black-theme .mat-typography .mat-subheading-2,
.black-theme .mat-typography h3 {
  font: 400 16px / 28px Saira;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.black-theme .mat-h4,
.black-theme .mat-subheading-1,
.black-theme .mat-typography .mat-h4,
.black-theme .mat-typography .mat-subheading-1,
.black-theme .mat-typography h4 {
  font: 600 14px / 1em Saira;
  letter-spacing: 3px;
  margin: 0 0 16px;
}
.black-theme .mat-h5,
.black-theme .mat-typography .mat-h5,
.black-theme .mat-typography h5 {
  font: 400 calc(16px * 0.83) / 20px Saira;
  margin: 0 0 12px;
}
.black-theme .mat-h6,
.black-theme .mat-typography .mat-h6,
.black-theme .mat-typography h6 {
  font: 400 calc(16px * 0.67) / 20px Saira;
  margin: 0 0 12px;
}
.black-theme .mat-body-strong,
.black-theme .mat-body-2,
.black-theme .mat-typography .mat-body-strong,
.black-theme .mat-typography .mat-body-2 {
  font: 400 18px / 26px Saira;
  letter-spacing: normal;
}
.black-theme .mat-body,
.black-theme .mat-body-1,
.black-theme .mat-typography .mat-body,
.black-theme .mat-typography .mat-body-1,
.black-theme .mat-typography {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
}
.black-theme .mat-body p,
.black-theme .mat-body-1 p,
.black-theme .mat-typography .mat-body p,
.black-theme .mat-typography .mat-body-1 p,
.black-theme .mat-typography p {
  margin: 0 0 12px;
}
.black-theme .mat-small,
.black-theme .mat-caption,
.black-theme .mat-typography .mat-small,
.black-theme .mat-typography .mat-caption {
  font: 400 12px / 20px Saira;
  letter-spacing: normal;
}
.black-theme .mat-display-4,
.black-theme .mat-typography .mat-display-4 {
  font: 400 112px / 112px Saira;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.black-theme .mat-display-3,
.black-theme .mat-typography .mat-display-3 {
  font: 900 56px / 56px Saira;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.black-theme .mat-display-2,
.black-theme .mat-typography .mat-display-2 {
  font: 900 40px / 1.2 Saira;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.black-theme .mat-display-1,
.black-theme .mat-typography .mat-display-1 {
  font: 600 32px / 1.13 Saira;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.black-theme .mat-bottom-sheet-container {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
}
.black-theme .mat-button, .black-theme .mat-raised-button, .black-theme .mat-icon-button, .black-theme .mat-stroked-button,
.black-theme .mat-flat-button, .black-theme .mat-fab, .black-theme .mat-mini-fab {
  font-family: Saira;
  font-size: 14px;
  font-weight: 600;
}
.black-theme .mat-button-toggle {
  font-family: Saira;
}
.black-theme .mat-card {
  font-family: Saira;
}
.black-theme .mat-card-title {
  font-size: 24px;
  font-weight: 600;
}
.black-theme .mat-card-header .mat-card-title {
  font-size: 20px;
}
.black-theme .mat-card-subtitle,
.black-theme .mat-card-content {
  font-size: 16px;
}
.black-theme .mat-checkbox {
  font-family: Saira;
}
.black-theme .mat-checkbox-layout .mat-checkbox-label {
  line-height: 26px;
}
.black-theme .mat-chip {
  font-size: 18px;
  font-weight: 400;
}
.black-theme .mat-chip .mat-chip-trailing-icon.mat-icon,
.black-theme .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.black-theme .mat-table {
  font-family: Saira;
}
.black-theme .mat-header-cell {
  font-size: 12px;
  font-weight: 400;
}
.black-theme .mat-cell, .black-theme .mat-footer-cell {
  font-size: 16px;
}
.black-theme .mat-calendar {
  font-family: Saira;
}
.black-theme .mat-calendar-body {
  font-size: 13px;
}
.black-theme .mat-calendar-body-label,
.black-theme .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 600;
}
.black-theme .mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.black-theme .mat-dialog-title {
  font: 600 20px / 28px Saira;
  letter-spacing: normal;
}
.black-theme .mat-expansion-panel-header {
  font-family: Saira;
  font-size: 14px;
  font-weight: 600;
}
.black-theme .mat-expansion-panel-content {
  font: 400 16px / 20px Saira;
  letter-spacing: normal;
}
.black-theme .mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Saira;
  letter-spacing: normal;
}
.black-theme .mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.black-theme .mat-form-field-prefix .mat-icon,
.black-theme .mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.black-theme .mat-form-field-prefix .mat-icon-button,
.black-theme .mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.black-theme .mat-form-field-prefix .mat-icon-button .mat-icon,
.black-theme .mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.black-theme .mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.black-theme .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.black-theme .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34361em) scale(0.75);
  width: 133.3334733333%;
}
.black-theme .mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.3436em) scale(0.75);
  width: 133.3334833333%;
}
.black-theme .mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.black-theme .mat-form-field-label {
  top: 1.34375em;
}
.black-theme .mat-form-field-underline {
  bottom: 1.34375em;
}
.black-theme .mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.black-theme .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.black-theme .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.black-theme .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.black-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00142px);
  width: 133.3337533333%;
}
.black-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00143px);
  width: 133.3337633333%;
}
.black-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00144px);
  width: 133.3337733333%;
}
.black-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.black-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.black-theme .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .black-theme .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.black-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2808em) scale(0.75);
  }
  .black-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28079em) scale(0.75);
  }
  .black-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28078em) scale(0.75);
  }
}
.black-theme .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.black-theme .mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.black-theme .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.black-theme .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59361em) scale(0.75);
  width: 133.3334733333%;
}
.black-theme .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.5936em) scale(0.75);
  width: 133.3334833333%;
}
.black-theme .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.black-theme .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.black-theme .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.black-theme .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59361em) scale(0.75);
  width: 133.3334733333%;
}
.black-theme .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.5936em) scale(0.75);
  width: 133.3334833333%;
}
.black-theme .mat-grid-tile-header,
.black-theme .mat-grid-tile-footer {
  font-size: 16px;
}
.black-theme .mat-grid-tile-header .mat-line,
.black-theme .mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.black-theme .mat-grid-tile-header .mat-line:nth-child(n+2),
.black-theme .mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.black-theme input.mat-input-element {
  margin-top: -0.0625em;
}
.black-theme .mat-menu-item {
  font-family: Saira;
  font-size: 16px;
  font-weight: 400;
}
.black-theme .mat-paginator,
.black-theme .mat-paginator-page-size .mat-select-trigger {
  font-family: Saira;
  font-size: 12px;
}
.black-theme .mat-radio-button {
  font-family: Saira;
}
.black-theme .mat-select {
  font-family: Saira;
}
.black-theme .mat-select-trigger {
  height: 1.125em;
}
.black-theme .mat-slide-toggle-content {
  font-family: Saira;
}
.black-theme .mat-slider-thumb-label-text {
  font-family: Saira;
  font-size: 12px;
  font-weight: 400;
}
.black-theme .mat-stepper-vertical, .black-theme .mat-stepper-horizontal {
  font-family: Saira;
}
.black-theme .mat-step-label {
  font-size: 16px;
  font-weight: 400;
}
.black-theme .mat-step-sub-label-error {
  font-weight: normal;
}
.black-theme .mat-step-label-error {
  font-size: 18px;
}
.black-theme .mat-step-label-selected {
  font-size: 18px;
  font-weight: 400;
}
.black-theme .mat-tab-group {
  font-family: Saira;
}
.black-theme .mat-tab-label, .black-theme .mat-tab-link {
  font-family: Saira;
  font-size: 14px;
  font-weight: 600;
}
.black-theme .mat-toolbar,
.black-theme .mat-toolbar h1,
.black-theme .mat-toolbar h2,
.black-theme .mat-toolbar h3,
.black-theme .mat-toolbar h4,
.black-theme .mat-toolbar h5,
.black-theme .mat-toolbar h6 {
  font: 600 20px / 28px Saira;
  letter-spacing: normal;
  margin: 0;
}
.black-theme .mat-tooltip {
  font-family: Saira;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.black-theme .mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.black-theme .mat-list-item {
  font-family: Saira;
}
.black-theme .mat-list-option {
  font-family: Saira;
}
.black-theme .mat-list-base .mat-list-item {
  font-size: 16px;
}
.black-theme .mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.black-theme .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.black-theme .mat-list-base .mat-list-option {
  font-size: 16px;
}
.black-theme .mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.black-theme .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.black-theme .mat-list-base .mat-subheader {
  font-family: Saira;
  font-size: 18px;
  font-weight: 400;
}
.black-theme .mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.black-theme .mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.black-theme .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.black-theme .mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.black-theme .mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.black-theme .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.black-theme .mat-list-base[dense] .mat-subheader {
  font-family: Saira;
  font-size: 12px;
  font-weight: 400;
}
.black-theme .mat-option {
  font-family: Saira;
  font-size: 16px;
}
.black-theme .mat-optgroup-label {
  font: 400 18px / 26px Saira;
  letter-spacing: normal;
}
.black-theme .mat-simple-snackbar {
  font-family: Saira;
  font-size: 16px;
}
.black-theme .mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 600;
}
.black-theme .mat-tree {
  font-family: Saira;
}
.black-theme .mat-tree-node,
.black-theme .mat-nested-tree-node {
  font-weight: 400;
  font-size: 16px;
}
.black-theme .mat-ripple {
  overflow: hidden;
  position: relative;
}
.black-theme .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.black-theme .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.black-theme .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .black-theme .mat-ripple-element {
  display: none;
}

.black-theme .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .black-theme .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.black-theme .cdk-overlay-container, .black-theme .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.black-theme .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.black-theme .cdk-overlay-container:empty {
  display: none;
}
.black-theme .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.black-theme .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.black-theme .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.black-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .black-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.black-theme .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.black-theme .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.black-theme .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.black-theme .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.black-theme .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.black-theme .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.black-theme textarea.cdk-textarea-autosize {
  resize: none;
}
.black-theme textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.black-theme textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.black-theme .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.black-theme .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.black-theme .mat-focus-indicator {
  position: relative;
}
.black-theme .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.black-theme .mat-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .black-theme {
  --mat-focus-indicator-display: block;
}

.black-theme .mat-mdc-focus-indicator {
  position: relative;
}
.black-theme .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.black-theme .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .black-theme {
  --mat-mdc-focus-indicator-display: block;
}

.black-theme .mat-ripple-element {
  background-color: rgba(18, 18, 18, 0.1);
}
.black-theme .mat-option {
  color: #121212;
}
.black-theme .mat-option:hover:not(.mat-option-disabled), .black-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.black-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.black-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: #121212;
}
.black-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.black-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: black;
}
.black-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #9fa8da;
}
.black-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e81253;
}
.black-theme .mat-optgroup-label {
  color: #26273b;
}
.black-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.black-theme .mat-pseudo-checkbox {
  color: #26273b;
}
.black-theme .mat-pseudo-checkbox::after {
  color: white;
}
.black-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.black-theme .mat-primary .mat-pseudo-checkbox-checked,
.black-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: black;
}
.black-theme .mat-pseudo-checkbox-checked,
.black-theme .mat-pseudo-checkbox-indeterminate,
.black-theme .mat-accent .mat-pseudo-checkbox-checked,
.black-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #9fa8da;
}
.black-theme .mat-warn .mat-pseudo-checkbox-checked,
.black-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e81253;
}
.black-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.black-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.black-theme .mat-app-background, .black-theme.mat-app-background {
  background-color: white;
  color: #121212;
}
.black-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(18, 18, 18, 0.2), 0px 1px 1px 0px rgba(18, 18, 18, 0.14), 0px 1px 3px 0px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(18, 18, 18, 0.2), 0px 3px 4px 0px rgba(18, 18, 18, 0.14), 0px 1px 8px 0px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 5px 8px 0px rgba(18, 18, 18, 0.14), 0px 1px 14px 0px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 6px 10px 0px rgba(18, 18, 18, 0.14), 0px 1px 18px 0px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(18, 18, 18, 0.2), 0px 7px 10px 1px rgba(18, 18, 18, 0.14), 0px 2px 16px 1px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(18, 18, 18, 0.2), 0px 8px 10px 1px rgba(18, 18, 18, 0.14), 0px 3px 14px 2px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(18, 18, 18, 0.2), 0px 9px 12px 1px rgba(18, 18, 18, 0.14), 0px 3px 16px 2px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(18, 18, 18, 0.2), 0px 10px 14px 1px rgba(18, 18, 18, 0.14), 0px 4px 18px 3px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(18, 18, 18, 0.2), 0px 11px 15px 1px rgba(18, 18, 18, 0.14), 0px 4px 20px 3px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(18, 18, 18, 0.2), 0px 12px 17px 2px rgba(18, 18, 18, 0.14), 0px 5px 22px 4px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(18, 18, 18, 0.2), 0px 13px 19px 2px rgba(18, 18, 18, 0.14), 0px 5px 24px 4px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(18, 18, 18, 0.2), 0px 14px 21px 2px rgba(18, 18, 18, 0.14), 0px 5px 26px 4px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(18, 18, 18, 0.2), 0px 15px 22px 2px rgba(18, 18, 18, 0.14), 0px 6px 28px 5px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(18, 18, 18, 0.2), 0px 16px 24px 2px rgba(18, 18, 18, 0.14), 0px 6px 30px 5px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(18, 18, 18, 0.2), 0px 17px 26px 2px rgba(18, 18, 18, 0.14), 0px 6px 32px 5px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(18, 18, 18, 0.2), 0px 18px 28px 2px rgba(18, 18, 18, 0.14), 0px 7px 34px 6px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(18, 18, 18, 0.2), 0px 19px 29px 2px rgba(18, 18, 18, 0.14), 0px 7px 36px 6px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(18, 18, 18, 0.2), 0px 20px 31px 3px rgba(18, 18, 18, 0.14), 0px 8px 38px 7px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(18, 18, 18, 0.2), 0px 21px 33px 3px rgba(18, 18, 18, 0.14), 0px 8px 40px 7px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(18, 18, 18, 0.2), 0px 22px 35px 3px rgba(18, 18, 18, 0.14), 0px 8px 42px 7px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(18, 18, 18, 0.2), 0px 23px 36px 3px rgba(18, 18, 18, 0.14), 0px 9px 44px 8px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(18, 18, 18, 0.2), 0px 24px 38px 3px rgba(18, 18, 18, 0.14), 0px 9px 46px 8px rgba(18, 18, 18, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.black-theme .mat-autocomplete-panel {
  background: white;
  color: #121212;
}
.black-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.black-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: #121212;
}
.black-theme .mat-badge-content {
  color: white;
  background: black;
}
.cdk-high-contrast-active .black-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.black-theme .mat-badge-accent .mat-badge-content {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.black-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e81253;
}
.black-theme .mat-badge-disabled .mat-badge-content {
  background: #c1c1c1;
  color: rgba(0, 0, 0, 0.38);
}
.black-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(18, 18, 18, 0.2), 0px 16px 24px 2px rgba(18, 18, 18, 0.14), 0px 6px 30px 5px rgba(18, 18, 18, 0.12);
  background: white;
  color: #121212;
}
.black-theme .mat-button, .black-theme .mat-icon-button, .black-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.black-theme .mat-button.mat-primary, .black-theme .mat-icon-button.mat-primary, .black-theme .mat-stroked-button.mat-primary {
  color: black;
}
.black-theme .mat-button.mat-accent, .black-theme .mat-icon-button.mat-accent, .black-theme .mat-stroked-button.mat-accent {
  color: #9fa8da;
}
.black-theme .mat-button.mat-warn, .black-theme .mat-icon-button.mat-warn, .black-theme .mat-stroked-button.mat-warn {
  color: #e81253;
}
.black-theme .mat-button.mat-primary.mat-button-disabled, .black-theme .mat-button.mat-accent.mat-button-disabled, .black-theme .mat-button.mat-warn.mat-button-disabled, .black-theme .mat-button.mat-button-disabled.mat-button-disabled, .black-theme .mat-icon-button.mat-primary.mat-button-disabled, .black-theme .mat-icon-button.mat-accent.mat-button-disabled, .black-theme .mat-icon-button.mat-warn.mat-button-disabled, .black-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .black-theme .mat-stroked-button.mat-primary.mat-button-disabled, .black-theme .mat-stroked-button.mat-accent.mat-button-disabled, .black-theme .mat-stroked-button.mat-warn.mat-button-disabled, .black-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(18, 18, 18, 0.26);
}
.black-theme .mat-button.mat-primary .mat-button-focus-overlay, .black-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .black-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: black;
}
.black-theme .mat-button.mat-accent .mat-button-focus-overlay, .black-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .black-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #9fa8da;
}
.black-theme .mat-button.mat-warn .mat-button-focus-overlay, .black-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .black-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e81253;
}
.black-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .black-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .black-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.black-theme .mat-button .mat-ripple-element, .black-theme .mat-icon-button .mat-ripple-element, .black-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.black-theme .mat-button-focus-overlay {
  background: #121212;
}
.black-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.black-theme .mat-flat-button, .black-theme .mat-raised-button, .black-theme .mat-fab, .black-theme .mat-mini-fab {
  color: #121212;
  background-color: white;
}
.black-theme .mat-flat-button.mat-primary, .black-theme .mat-raised-button.mat-primary, .black-theme .mat-fab.mat-primary, .black-theme .mat-mini-fab.mat-primary {
  color: white;
}
.black-theme .mat-flat-button.mat-accent, .black-theme .mat-raised-button.mat-accent, .black-theme .mat-fab.mat-accent, .black-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.black-theme .mat-flat-button.mat-warn, .black-theme .mat-raised-button.mat-warn, .black-theme .mat-fab.mat-warn, .black-theme .mat-mini-fab.mat-warn {
  color: white;
}
.black-theme .mat-flat-button.mat-primary.mat-button-disabled, .black-theme .mat-flat-button.mat-accent.mat-button-disabled, .black-theme .mat-flat-button.mat-warn.mat-button-disabled, .black-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .black-theme .mat-raised-button.mat-primary.mat-button-disabled, .black-theme .mat-raised-button.mat-accent.mat-button-disabled, .black-theme .mat-raised-button.mat-warn.mat-button-disabled, .black-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .black-theme .mat-fab.mat-primary.mat-button-disabled, .black-theme .mat-fab.mat-accent.mat-button-disabled, .black-theme .mat-fab.mat-warn.mat-button-disabled, .black-theme .mat-fab.mat-button-disabled.mat-button-disabled, .black-theme .mat-mini-fab.mat-primary.mat-button-disabled, .black-theme .mat-mini-fab.mat-accent.mat-button-disabled, .black-theme .mat-mini-fab.mat-warn.mat-button-disabled, .black-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(18, 18, 18, 0.26);
}
.black-theme .mat-flat-button.mat-primary, .black-theme .mat-raised-button.mat-primary, .black-theme .mat-fab.mat-primary, .black-theme .mat-mini-fab.mat-primary {
  background-color: black;
}
.black-theme .mat-flat-button.mat-accent, .black-theme .mat-raised-button.mat-accent, .black-theme .mat-fab.mat-accent, .black-theme .mat-mini-fab.mat-accent {
  background-color: #9fa8da;
}
.black-theme .mat-flat-button.mat-warn, .black-theme .mat-raised-button.mat-warn, .black-theme .mat-fab.mat-warn, .black-theme .mat-mini-fab.mat-warn {
  background-color: #e81253;
}
.black-theme .mat-flat-button.mat-primary.mat-button-disabled, .black-theme .mat-flat-button.mat-accent.mat-button-disabled, .black-theme .mat-flat-button.mat-warn.mat-button-disabled, .black-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .black-theme .mat-raised-button.mat-primary.mat-button-disabled, .black-theme .mat-raised-button.mat-accent.mat-button-disabled, .black-theme .mat-raised-button.mat-warn.mat-button-disabled, .black-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .black-theme .mat-fab.mat-primary.mat-button-disabled, .black-theme .mat-fab.mat-accent.mat-button-disabled, .black-theme .mat-fab.mat-warn.mat-button-disabled, .black-theme .mat-fab.mat-button-disabled.mat-button-disabled, .black-theme .mat-mini-fab.mat-primary.mat-button-disabled, .black-theme .mat-mini-fab.mat-accent.mat-button-disabled, .black-theme .mat-mini-fab.mat-warn.mat-button-disabled, .black-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: #f7f7f7;
}
.black-theme .mat-flat-button.mat-primary .mat-ripple-element, .black-theme .mat-raised-button.mat-primary .mat-ripple-element, .black-theme .mat-fab.mat-primary .mat-ripple-element, .black-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.black-theme .mat-flat-button.mat-accent .mat-ripple-element, .black-theme .mat-raised-button.mat-accent .mat-ripple-element, .black-theme .mat-fab.mat-accent .mat-ripple-element, .black-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.black-theme .mat-flat-button.mat-warn .mat-ripple-element, .black-theme .mat-raised-button.mat-warn .mat-ripple-element, .black-theme .mat-fab.mat-warn .mat-ripple-element, .black-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.black-theme .mat-stroked-button:not([class*=mat-elevation-z]), .black-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(18, 18, 18, 0.2), 0px 8px 10px 1px rgba(18, 18, 18, 0.14), 0px 3px 14px 2px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-fab:not([class*=mat-elevation-z]), .black-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 6px 10px 0px rgba(18, 18, 18, 0.14), 0px 1px 18px 0px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .black-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(18, 18, 18, 0.2), 0px 12px 17px 2px rgba(18, 18, 18, 0.14), 0px 5px 22px 4px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .black-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.black-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.black-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.black-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.black-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.black-theme .mat-button-toggle-appearance-standard {
  color: #121212;
  background: white;
}
.black-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.black-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.black-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.black-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.black-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: #26273b;
}
.black-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #121212;
}
.black-theme .mat-button-toggle-disabled {
  color: rgba(18, 18, 18, 0.26);
  background-color: #eeeeee;
}
.black-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.black-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.black-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.black-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.black-theme .mat-card {
  background: white;
  color: #121212;
}
.black-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(18, 18, 18, 0.2), 0px 1px 1px 0px rgba(18, 18, 18, 0.14), 0px 1px 3px 0px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 0px rgba(18, 18, 18, 0.14), 0px 0px 0px 0px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-card-subtitle {
  color: #26273b;
}
.black-theme .mat-checkbox-frame {
  border-color: #26273b;
}
.black-theme .mat-checkbox-checkmark {
  fill: white;
}
.black-theme .mat-checkbox-checkmark-path {
  stroke: white !important;
}
.black-theme .mat-checkbox-mixedmark {
  background-color: white;
}
.black-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .black-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: black;
}
.black-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .black-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #9fa8da;
}
.black-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .black-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e81253;
}
.black-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .black-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.black-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.black-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.black-theme .mat-checkbox .mat-ripple-element {
  background-color: #121212;
}
.black-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.black-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: black;
}
.black-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.black-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #9fa8da;
}
.black-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.black-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e81253;
}
.black-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: #121212;
}
.black-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: #121212;
  opacity: 0.4;
}
.black-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(18, 18, 18, 0.2), 0px 3px 4px 0px rgba(18, 18, 18, 0.14), 0px 1px 8px 0px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.black-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.black-theme .mat-chip.mat-standard-chip::after {
  background: #121212;
}
.black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: black;
  color: white;
}
.black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e81253;
  color: white;
}
.black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.black-theme .mat-table {
  background: white;
}
.black-theme .mat-table thead, .black-theme .mat-table tbody, .black-theme .mat-table tfoot,
.black-theme mat-header-row, .black-theme mat-row, .black-theme mat-footer-row,
.black-theme [mat-header-row], .black-theme [mat-row], .black-theme [mat-footer-row],
.black-theme .mat-table-sticky {
  background: inherit;
}
.black-theme mat-row, .black-theme mat-header-row, .black-theme mat-footer-row,
.black-theme th.mat-header-cell, .black-theme td.mat-cell, .black-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.black-theme .mat-header-cell {
  color: #26273b;
}
.black-theme .mat-cell, .black-theme .mat-footer-cell {
  color: #121212;
}
.black-theme .mat-calendar-arrow {
  fill: rgba(18, 18, 18, 0.54);
}
.black-theme .mat-datepicker-toggle,
.black-theme .mat-datepicker-content .mat-calendar-next-button,
.black-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(18, 18, 18, 0.54);
}
.black-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.black-theme .mat-calendar-table-header,
.black-theme .mat-calendar-body-label {
  color: #26273b;
}
.black-theme .mat-calendar-body-cell-content,
.black-theme .mat-date-range-input-separator {
  color: #121212;
  border-color: transparent;
}
.black-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.black-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.black-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.black-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.black-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.black-theme .mat-calendar-body-in-range::before {
  background: rgba(0, 0, 0, 0.2);
}
.black-theme .mat-calendar-body-comparison-identical,
.black-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.black-theme .mat-calendar-body-comparison-bridge-start::before,
.black-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.black-theme .mat-calendar-body-comparison-bridge-end::before,
.black-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 0, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.black-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.black-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.black-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.black-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.black-theme .mat-calendar-body-selected {
  background-color: black;
  color: white;
}
.black-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 0, 0, 0.4);
}
.black-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.black-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.black-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 0, 0, 0.3);
}
@media (hover: hover) {
  .black-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
.black-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
  background-color: white;
  color: #121212;
}
.black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(159, 168, 218, 0.2);
}
.black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.black-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(159, 168, 218, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.black-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(159, 168, 218, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(159, 168, 218, 0.4);
}
.black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.black-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.black-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(159, 168, 218, 0.3);
}
@media (hover: hover) {
  .black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(159, 168, 218, 0.3);
  }
}
.black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(232, 18, 83, 0.2);
}
.black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.black-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(232, 18, 83, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.black-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(232, 18, 83, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e81253;
  color: white;
}
.black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(232, 18, 83, 0.4);
}
.black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.black-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.black-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(232, 18, 83, 0.3);
}
@media (hover: hover) {
  .black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(232, 18, 83, 0.3);
  }
}
.black-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(18, 18, 18, 0.2), 0px 24px 38px 3px rgba(18, 18, 18, 0.14), 0px 9px 46px 8px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-datepicker-toggle-active {
  color: black;
}
.black-theme .mat-datepicker-toggle-active.mat-accent {
  color: #9fa8da;
}
.black-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e81253;
}
.black-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.black-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(18, 18, 18, 0.2), 0px 24px 38px 3px rgba(18, 18, 18, 0.14), 0px 9px 46px 8px rgba(18, 18, 18, 0.12);
  background: white;
  color: #121212;
}
.black-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.black-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.black-theme .mat-expansion-panel {
  background: white;
  color: #121212;
}
.black-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(18, 18, 18, 0.2), 0px 2px 2px 0px rgba(18, 18, 18, 0.14), 0px 1px 5px 0px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.black-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .black-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .black-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .black-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.black-theme .mat-expansion-panel-header-title {
  color: #121212;
}
.black-theme .mat-expansion-panel-header-description,
.black-theme .mat-expansion-indicator::after {
  color: #26273b;
}
.black-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(18, 18, 18, 0.26);
}
.black-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.black-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.black-theme .mat-form-field-label {
  color: rgba(38, 39, 59, 0.6);
}
.black-theme .mat-hint {
  color: rgba(38, 39, 59, 0.6);
}
.black-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: black;
}
.black-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #9fa8da;
}
.black-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e81253;
}
.black-theme .mat-focused .mat-form-field-required-marker {
  color: #9fa8da;
}
.black-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.black-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: black;
}
.black-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #9fa8da;
}
.black-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e81253;
}
.black-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: black;
}
.black-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #9fa8da;
}
.black-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e81253;
}
.black-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e81253;
}
.black-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.black-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e81253;
}
.black-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.black-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e81253;
}
.black-theme .mat-error {
  color: #e81253;
}
.black-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: #26273b;
}
.black-theme .mat-form-field-appearance-legacy .mat-hint {
  color: #26273b;
}
.black-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.black-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.black-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.black-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.black-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(18, 18, 18, 0.04);
}
.black-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(18, 18, 18, 0.02);
}
.black-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.black-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.black-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.black-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.black-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.black-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: black;
}
.black-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #9fa8da;
}
.black-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e81253;
}
.black-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e81253;
}
.black-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.black-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.black-theme .mat-icon.mat-primary {
  color: black;
}
.black-theme .mat-icon.mat-accent {
  color: #9fa8da;
}
.black-theme .mat-icon.mat-warn {
  color: #e81253;
}
.black-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: #26273b;
}
.black-theme .mat-input-element:disabled,
.black-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.black-theme .mat-input-element {
  caret-color: black;
}
.black-theme .mat-input-element::placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.black-theme .mat-input-element::-moz-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.black-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.black-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.black-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #9fa8da;
}
.black-theme .mat-form-field.mat-warn .mat-input-element,
.black-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e81253;
}
.black-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e81253;
}
.black-theme .mat-list-base .mat-list-item {
  color: #121212;
}
.black-theme .mat-list-base .mat-list-option {
  color: #121212;
}
.black-theme .mat-list-base .mat-subheader {
  color: #26273b;
}
.black-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.black-theme .mat-list-option:hover, .black-theme .mat-list-option:focus,
.black-theme .mat-nav-list .mat-list-item:hover,
.black-theme .mat-nav-list .mat-list-item:focus,
.black-theme .mat-action-list .mat-list-item:hover,
.black-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.black-theme .mat-list-single-selected-option, .black-theme .mat-list-single-selected-option:hover, .black-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.black-theme .mat-menu-panel {
  background: white;
}
.black-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-menu-item {
  background: transparent;
  color: #121212;
}
.black-theme .mat-menu-item[disabled],
.black-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.black-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.black-theme .mat-menu-item .mat-icon-no-color,
.black-theme .mat-menu-submenu-icon {
  color: rgba(18, 18, 18, 0.54);
}
.black-theme .mat-menu-item:hover:not([disabled]),
.black-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.black-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.black-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.black-theme .mat-paginator {
  background: white;
}
.black-theme .mat-paginator,
.black-theme .mat-paginator-page-size .mat-select-trigger {
  color: #26273b;
}
.black-theme .mat-paginator-decrement,
.black-theme .mat-paginator-increment {
  border-top: 2px solid rgba(18, 18, 18, 0.54);
  border-right: 2px solid rgba(18, 18, 18, 0.54);
}
.black-theme .mat-paginator-first,
.black-theme .mat-paginator-last {
  border-top: 2px solid rgba(18, 18, 18, 0.54);
}
.black-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.black-theme .mat-icon-button[disabled] .mat-paginator-increment,
.black-theme .mat-icon-button[disabled] .mat-paginator-first,
.black-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.black-theme .mat-progress-bar-background {
  fill: #bfbfbf;
}
.black-theme .mat-progress-bar-buffer {
  background-color: #bfbfbf;
}
.black-theme .mat-progress-bar-fill::after {
  background-color: black;
}
.black-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #e7e9f6;
}
.black-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #e7e9f6;
}
.black-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #9fa8da;
}
.black-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9c4d4;
}
.black-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9c4d4;
}
.black-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e81253;
}
.black-theme .mat-progress-spinner circle, .black-theme .mat-spinner circle {
  stroke: black;
}
.black-theme .mat-progress-spinner.mat-accent circle, .black-theme .mat-spinner.mat-accent circle {
  stroke: #9fa8da;
}
.black-theme .mat-progress-spinner.mat-warn circle, .black-theme .mat-spinner.mat-warn circle {
  stroke: #e81253;
}
.black-theme .mat-radio-outer-circle {
  border-color: #26273b;
}
.black-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: black;
}
.black-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.black-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .black-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .black-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: black;
}
.black-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #9fa8da;
}
.black-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.black-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .black-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .black-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #9fa8da;
}
.black-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e81253;
}
.black-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.black-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .black-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .black-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e81253;
}
.black-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.black-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.black-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.black-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.black-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.black-theme .mat-radio-button .mat-ripple-element {
  background-color: #121212;
}
.black-theme .mat-select-value {
  color: #121212;
}
.black-theme .mat-select-placeholder {
  color: rgba(38, 39, 59, 0.42);
}
.black-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.black-theme .mat-select-arrow {
  color: #26273b;
}
.black-theme .mat-select-panel {
  background: white;
}
.black-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(18, 18, 18, 0.2), 0px 4px 5px 0px rgba(18, 18, 18, 0.14), 0px 1px 10px 0px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.black-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: black;
}
.black-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #9fa8da;
}
.black-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e81253;
}
.black-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e81253;
}
.black-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.black-theme .mat-drawer-container {
  background-color: white;
  color: #121212;
}
.black-theme .mat-drawer {
  background-color: white;
  color: #121212;
}
.black-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.black-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(18, 18, 18, 0.2), 0px 16px 24px 2px rgba(18, 18, 18, 0.14), 0px 6px 30px 5px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.black-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.black-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.black-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #9fa8da;
}
.black-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(159, 168, 218, 0.54);
}
.black-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #9fa8da;
}
.black-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: black;
}
.black-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.54);
}
.black-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: black;
}
.black-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e81253;
}
.black-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(232, 18, 83, 0.54);
}
.black-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e81253;
}
.black-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: #121212;
}
.black-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(18, 18, 18, 0.2), 0px 1px 1px 0px rgba(18, 18, 18, 0.14), 0px 1px 3px 0px rgba(18, 18, 18, 0.12);
  background-color: #fafafa;
}
.black-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.black-theme .mat-slider-track-background {
  background-color: rgba(18, 18, 18, 0.26);
}
.black-theme .mat-slider.mat-primary .mat-slider-track-fill,
.black-theme .mat-slider.mat-primary .mat-slider-thumb,
.black-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: black;
}
.black-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.black-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.2);
}
.black-theme .mat-slider.mat-accent .mat-slider-track-fill,
.black-theme .mat-slider.mat-accent .mat-slider-thumb,
.black-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #9fa8da;
}
.black-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.black-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(159, 168, 218, 0.2);
}
.black-theme .mat-slider.mat-warn .mat-slider-track-fill,
.black-theme .mat-slider.mat-warn .mat-slider-thumb,
.black-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e81253;
}
.black-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.black-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(232, 18, 83, 0.2);
}
.black-theme .mat-slider:hover .mat-slider-track-background,
.black-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(18, 18, 18, 0.38);
}
.black-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.black-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.black-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(18, 18, 18, 0.26);
}
.black-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(18, 18, 18, 0.26);
}
.black-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(18, 18, 18, 0.12);
}
.black-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.black-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(18, 18, 18, 0.87);
}
.black-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.black-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(18, 18, 18, 0.26);
}
.black-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(18, 18, 18, 0.26);
  background-color: transparent;
}
.black-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .black-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(18, 18, 18, 0.38);
}
.black-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .black-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(18, 18, 18, 0.26);
}
.black-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(18, 18, 18, 0.7);
}
.black-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(18, 18, 18, 0.7), rgba(18, 18, 18, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(18, 18, 18, 0.7), rgba(18, 18, 18, 0.7) 2px, transparent 0, transparent);
}
.black-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(18, 18, 18, 0.7), rgba(18, 18, 18, 0.7) 2px, transparent 0, transparent);
}
.black-theme .mat-step-header.cdk-keyboard-focused, .black-theme .mat-step-header.cdk-program-focused, .black-theme .mat-step-header:hover:not([aria-disabled]), .black-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.black-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .black-theme .mat-step-header:hover {
    background: none;
  }
}
.black-theme .mat-step-header .mat-step-label,
.black-theme .mat-step-header .mat-step-optional {
  color: #26273b;
}
.black-theme .mat-step-header .mat-step-icon {
  background-color: #26273b;
  color: white;
}
.black-theme .mat-step-header .mat-step-icon-selected,
.black-theme .mat-step-header .mat-step-icon-state-done,
.black-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: black;
  color: white;
}
.black-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.black-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.black-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.black-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.black-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.black-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.black-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.black-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e81253;
  color: white;
}
.black-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e81253;
}
.black-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: #121212;
}
.black-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e81253;
}
.black-theme .mat-stepper-horizontal, .black-theme .mat-stepper-vertical {
  background-color: white;
}
.black-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.black-theme .mat-horizontal-stepper-header::before,
.black-theme .mat-horizontal-stepper-header::after,
.black-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.black-theme .mat-sort-header-arrow {
  color: #26273b;
}
.black-theme .mat-tab-nav-bar,
.black-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.black-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.black-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.black-theme .mat-tab-label, .black-theme .mat-tab-link {
  color: #121212;
}
.black-theme .mat-tab-label.mat-tab-disabled, .black-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.black-theme .mat-tab-header-pagination-chevron {
  border-color: #121212;
}
.black-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.black-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.black-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.black-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .black-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .black-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .black-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 179, 179, 0.3);
}
.black-theme .mat-tab-group.mat-primary .mat-ink-bar, .black-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: black;
}
.black-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .black-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .black-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .black-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.black-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .black-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .black-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .black-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.black-theme .mat-tab-group.mat-accent .mat-ink-bar, .black-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #9fa8da;
}
.black-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .black-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .black-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .black-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.black-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .black-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .black-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .black-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 184, 203, 0.3);
}
.black-theme .mat-tab-group.mat-warn .mat-ink-bar, .black-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e81253;
}
.black-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .black-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .black-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .black-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.black-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .black-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .black-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .black-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 179, 179, 0.3);
}
.black-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .black-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .black-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: black;
}
.black-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .black-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.black-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .black-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.black-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.black-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.black-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.black-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.black-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.black-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.black-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .black-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .black-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .black-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.black-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .black-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .black-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #9fa8da;
}
.black-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .black-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.black-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .black-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.black-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.black-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.black-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.black-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.black-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.black-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.black-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .black-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .black-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .black-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 184, 203, 0.3);
}
.black-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .black-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .black-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e81253;
}
.black-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .black-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.black-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .black-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.black-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.black-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.black-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.black-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.black-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.black-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.black-theme .mat-toolbar {
  background: whitesmoke;
  color: #121212;
}
.black-theme .mat-toolbar.mat-primary {
  background: black;
  color: white;
}
.black-theme .mat-toolbar.mat-accent {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.black-theme .mat-toolbar.mat-warn {
  background: #e81253;
  color: white;
}
.black-theme .mat-toolbar .mat-form-field-underline,
.black-theme .mat-toolbar .mat-form-field-ripple,
.black-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.black-theme .mat-toolbar .mat-form-field-label,
.black-theme .mat-toolbar .mat-focused .mat-form-field-label,
.black-theme .mat-toolbar .mat-select-value,
.black-theme .mat-toolbar .mat-select-arrow,
.black-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.black-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.black-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.black-theme .mat-tree {
  background: white;
}
.black-theme .mat-tree-node,
.black-theme .mat-nested-tree-node {
  color: #121212;
}
.black-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(18, 18, 18, 0.2), 0px 6px 10px 0px rgba(18, 18, 18, 0.14), 0px 1px 18px 0px rgba(18, 18, 18, 0.12);
}
.black-theme .mat-simple-snackbar-action {
  color: #9fa8da;
}