@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin login-theme($theme) {
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  .login {
    background-color: mat-color($background, background);
    color: mat-contrast($background, background);
    &__header {
      background-color: mat-color($primary, 50);
      color: mat-contrast($primary, 50);
    }
  }
}
