@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin payments-viewer-dialog-theme($theme) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  .payments-viewer-wrapper .mat-dialog-container {
    padding: 0px;
  }
  .payments-viewer {
    &__header {
      border-bottom: 1px solid mat-color($foreground, divider);
      h2 {
        color: mat-color($primary, 700);
      }
    }
    &__content {
      &__lines {
        .payments-line {
          border: 1px solid mat-color($foreground, divider);
          &.pending {
            border: 1px solid lighten(mat-color($primary, A400), 5%);
            background-color: lighten(mat-color($primary, A400), 5%);
            color: mat-color($background, background);
          }
          &.accepted {
            border: 1px solid darken(mat-color($primary, statusSuccess), 15%);
            background-color: darken(mat-color($primary, statusSuccess), 15%);
            color: mat-color($background, background);
          }
          &.error {
            border: 1px solid darken(mat-color($primary, statusError), 20%);
            background-color: darken(mat-color($primary, statusError), 20%);
            color: mat-color($background, background);
          }
        }
      }
    }
    &__footer {
      border-top: 1px solid mat-color($foreground, divider);
    }
  }
}
