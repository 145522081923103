@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin all-orders-list-theme($theme) {
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  .all-orders {
    background-color: rgba(mat-color($background, background), 0);
    color: mat-contrast($background, background);
    &__wrapper {
      background-color: mat-color($background, background-secondary);
      color: mat-contrast($background, background-secondary);
      &__header {
        background-color: mat-color($background, background);
        color: mat-color($primary, 700);
      }
      &__filter {
        .filter-field .mat-form-field-outline {
          background-color: mat-color($background, background);
        }
      }
    }
  }
}
