@import '@angular/material/theming';
@import 'utils/core';

@mixin global-message-theme($theme, $main-typo) {
  // As the colors mustn't been affected by the theme they are hardcoded
  .global-message--success {
    color: #155724 !important;
    background-color: #d4edda !important;
    border-color: #c3e6cb !important;
    .mat-simple-snackbar-action {
      color: #155724 !important;
    }
  }

  .global-message--warning {
    color: #2c2c2c !important;
    background-color: rgb(206, 134, 0) !important;
    border-color: #be8f00 !important;
    .mat-simple-snackbar-action {
      color: #2c2c2c !important;
    }
  }

  .global-message--error {
    color: rgb(245, 245, 245) !important;
    background-color: rgb(138, 0, 0) !important;
    border-color: rgb(114, 2, 2) !important;
    .mat-simple-snackbar-action {
      color: rgb(245, 219, 219) !important;
    }
  }
}
