@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin login-dni-theme($theme) {
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  .login-content {
    background-color: mat-color($background, background);
    color: mat-contrast($background, background);
  }
}
