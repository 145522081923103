@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin landing-theme($theme) {
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  .landing-container {
    .sidenav-content {
      background-color: mat-color($background, background);
      color: mat-contrast($background, background);
      &__linkContainer {
        &__link {
          &.static {
            background-color: mat-contrast($primary, 700);
            color: mat-color($primary, 700);
          }
        }
      }
      &__footer {
        background-color: rgba(mat-contrast($background, background), 0.2);
      }
    }
    .landing-content {
      @include respond-to($tablet-small) {
        background-color: mat-color($background, background-secondary);
        color: mat-color($primary, 500);
      }
      @media (max-height: 555px) {
        background-color: mat-color($background, background-secondary);
        color: mat-color($primary, 500);
      }
    }
  }
}
